import React from "react";
import { useSelector } from "react-redux";
import FinalFoodCard from "../../Components/Cards/FoodCard/FinalFoodCard";
import ScrollableFoodList from "../../Containers/FoodDetail/ScrollableFoodList";
import {isProductAvailable} from "../../Functions/helpersFunctions";

const PhoneFoodList = ({ foodItems, productId, categoryId, orderType }) => {
  const menu = useSelector((state) => state.menu);

  const getSimilarResults = (foodItemId) => {
    if (
      menu.products[foodItemId].categoryId === categoryId &&
      menu.products[foodItemId].productId !== productId
    ) {
      return menu.products[foodItemId];
    }
    return null;
  };

  return (
    <div className='space-y-8'>
      <div className='block md:hidden font-open-sans font-bold text-left text-base text-464646'>
        View Similar results
      </div>
      {/**Food Cards visible for less than medium screeen */}
      <div className='block md:hidden space-y-4'>
        {Object.keys(foodItems).map((foodItemId, index) => {
          const foodItem = getSimilarResults(foodItemId);
          if(foodItem && !foodItem.isProductAvailable){
              return
          }
          return (
            foodItem && (
              <FinalFoodCard
                key={foodItem.productId}
                productAvailable={foodItem.isProductAvailable}
                productId={foodItem.productId}
                foodItem={foodItem}
                productType={foodItem.productType}
                description={foodItem.description}
                discount={foodItem.discount}
                selectedVariant={foodItem.selectedVariant}
                displayVariants={foodItem.displayVariants} // TRUE | FALSE (Sizes)
                variants={foodItem.variants} // Sizes
                image={foodItem.image}
                isCustomizable={foodItem.isCustomizable}
                isVeg={foodItem.isVeg}
                menuTags={foodItem.menuTags}
                tags={foodItem.tags}
                name={foodItem.name}
                ratings={foodItem.ratings}
                tax={foodItem.tax}
                type={orderType}
                // foodItemNotAvailableDueToTime={
                //   foodItem.notAvailableDueToTime
                // }
                // foodItemNotAvailableDueToDate={
                //   foodItem.notAvailableDueToDate
                // }
                subscriptionFrom={
                  foodItem.subscriptionFrom
                }
                subscriptionTo={
                  foodItem.subscriptionTo
                }
              />
            )
          );
        })}
      </div>
      {/**Scrollable list of Food Cards visible from medium screen */}
      <div className='hidden md:block'>
        <ScrollableFoodList
          categoryId={categoryId}
          productId={productId}
          foodItems={foodItems}
          orderType={orderType}
        />
      </div>
    </div>
  );
};

export default PhoneFoodList;
