import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

export default function OrderNotExist() {
	// console.log('useRouteMatch', useLocation());
	return (
		<div className='h-screen justify-center items-center font-roboto flex'>
			<div className=''>
				<div className='flex items-center'>
					<div className='text-4xl p-4'>404</div>
					<div className='p-4 border-l border-gray-400'>
						Order Doesn't exist
					</div>
				</div>
				<div className='flex justify-center mt-4'>
					<Link to='/'>
						<button className='bg-gray-700 text-white w-32 h-10 rounded hover:bg-gray-900'>
							Home
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
