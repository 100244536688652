import Banner from '../../Components/Banner/Banner';
import PhoneBanner from '../../Components/Banner/PhoneBanner';
import MenuJumbotron from '../../Components/Banner/MenuJumbotron';
import OfferCard from '../../Components/Cards/OfferCard/OfferCard';
import { Redirect } from 'react-router-dom';
import MenuCard from '../../Components/Cards/MenuCard/MenuCard';
import Family from '../../Assets/Icons/001-family.svg';
import Healthy from '../../Assets/Icons/Filled outline.svg';
import Bulk from '../../Assets/Icons/Bulk.svg';
import Kids from '../../Assets/Icons/Filled outline-1.svg';

import eventPlanner from '../../Assets/Icons/event-planner.svg';

import PhoneSpecialFoodCard from '../../Components/Cards/FoodCard/PhoneSpecialFoodCard';
import PhoneMenuCard from '../../Components/Cards/MenuCard/PhoneMenuCard';
import EventsCard from '../../Components/Cards/EventsCard/EventsCard';

import image from '../../Assets/Icons/Group 159514.svg';
import image1 from '../../Assets/Icons/Group 159522.svg';

import { FOOD_CARDS_HOME } from '../../Constants/constants';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useLayoutEffect, useState } from 'react';
import FinalFoodCard from '../../Components/Cards/FoodCard/FinalFoodCard';
import {
	authActions,
	modalActions,
	orderActions,
	storeActions,
} from '../../Redux/Actions';
import { useParams } from 'react-router';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import SideNav from '../../Components/SideNav/SideNav';
import ChangeLocationModal from '../../Components/Change Location Modal/ChangeLocationModal';
import {
	storeClosedOrNot,
} from '../../Functions/helpersFunctions';
import { clientService, storeService } from '../../Redux/Services';
import toast from 'react-hot-toast';
import CustomizationSlider from '../FoodDetail/CustomizationSlider';
import { useAccess } from '../../Functions/utils';
import { Loader } from '../../Components/Spinner/Loader';

export default function Restaurant() {
	const [DEFAULT_COORDS, setDefaultCoords] = useState({
		lat: 28.6523392,
		lng: 77.185024,
	});
	const dispatch = useDispatch();
	const { menu, orderInfo, store, client, modal } = useSelector(
		(state) => state
	);
	const { bypass = true } = client;
	const defaultCategory = menu.menu[0]?.categoryId;
	const params = useParams();
	const { restaurantId } = params;
	const { showChangeLocationModal } = modal;
	const localStorageAuth = JSON.parse(localStorage.getItem('auth'));

	useEffect(() => {
		dispatch(authActions.redirect(null));
	}, [dispatch]);

	useEffect(() => {
		if (orderInfo.deliveryMode !== 'pickup') {
			if (
				localStorageAuth.selectedUserAddress &&
				(localStorageAuth.selectedUserAddress.location['lat'] === '' ||
					localStorageAuth.selectedUserAddress.location['lng'] ===
						null ||
					localStorageAuth.selectedUserAddress.location['lng'] ===
						'' ||
					localStorageAuth.selectedUserAddress.location['lng'] ===
						null)
			) {
				dispatch(modalActions.toggleChangeLocationModal(true));
			} else {
				// console.log(localStorageAuth, 'localstorageauth');
				findDeliveryStores(
					localStorageAuth.selectedUserAddress.location,
					'useEffect'
				);
			}
		}
	}, [store.storeName]);

	const { orderType, deliverNow } = orderInfo;

	useEffect(() => {
		if (orderType === 'regular' && deliverNow === true) {
			(async () => {
				try {
					const response = await storeService.getMealTime({
						data: {
							store: parseInt(restaurantId),
						},
					});

					const { mealType: type = 'REGULAR', time } = response ?? {};

					if (type !== 'REGULAR') {
						const formattedTime = moment(time, 'DD-MM-YYYY').format(
							'MM/DD/YYYY'
						);
						dispatch(
							orderActions.update({
								deliverNow: false,
								startDate: formattedTime,
								laterDate: formattedTime,
								time: null,
								laterTime: null,
							})
						);
					}
				} catch {}
			})();
		}
	}, [restaurantId, dispatch, orderType, deliverNow]);

	const checkTime = () => {
		const laterTime = cloneDeep(orderInfo.time);
		const endsOn = moment(
			menu.specialMenu.endsOn,
			'DD-MM-YYYY HH:mm'
		).format('YYYY-MM-DD HH:mm');
		const laterDate = orderInfo.startDate;
		let laterDateCopy = cloneDeep(laterDate);
		laterDateCopy = moment(laterDateCopy, 'YYYY-MM-DD')
			.format('YYYY-MM-DD')
			.concat(` ${laterTime}`);
		// console.log(
		// 	laterDateCopy,
		// 	endsOn,
		// 	moment(laterDateCopy).isBefore(endsOn),
		// 	'laterDateCopy'
		// );
		if (orderInfo.orderType == 'schedule') {
			let endDate = orderInfo.endDate;
			endDate = moment(endDate, 'YYYY-MM-DD')
				.format('YYYY-MM-DD')
				.concat(` ${laterTime}`);
			if (moment(endsOn).isBefore(endDate)) {
				return false;
			} else {
				return true;
			}
		} else if (moment(laterDateCopy).isBefore(endsOn)) {
			return true;
		} else {
			return false;
		}
	};
	const checkNormalTime = () => {
		const endsOn = moment(
			menu.specialMenu.endsOn,
			'DD-MM-YYYY HH:mm'
		).format('YYYY-MM-DD HH:mm');
		let currTime = moment(new Date(), 'DD-MM-YYYY HH:mm').format(
			'YYYY-MM-DD HH:mm'
		);
		// console.log(
		// 	currTime,
		// 	endsOn,
		// 	'endsOn currTime',
		// 	moment(currTime).isBefore(endsOn)
		// );
		if (moment(currTime).isBefore(endsOn)) {
			return true;
		} else {
			return false;
		}
	};
	const [selectFoodItem, setSelectFoodItem] = useState({
		foodItem: {},
		appliedCustomization: {},
		index: 0,
	});
	const [isCustomizationsVisible, setIsCustomizationsVisible] =
		useState(false);
	const showCustomization = (productId, appliedCustomization, index) => {
		setSelectFoodItem({
			foodItem: menu.products[productId],
			appliedCustomization: JSON.parse(
				JSON.stringify(appliedCustomization)
			),
			index,
		});
		setIsCustomizationsVisible(true);
	};
	let Menus = [
		{
			image: Family,
			id: 0,
			heading: 'Family Menu',
			para: 'fOR OUR REGULAR FAMILY PEOPLE WHO orders food keeping the family in mind',
			link: `/regular/all/${defaultCategory}`,
		},
		// {
		// 	image: Bulk,
		// 	id: 1,
		// 	heading: 'Bulk Menu',
		// 	para: 'fOR OUR REGULAR FAMILY PEOPLE WHO orders food keeping the family in mind',
		// 	link: `/bulk/bulk/${defaultCategory}`,
		// },
		{
			image: Kids,
			id: 2,
			heading: 'Kids Menu',
			para: 'fOR OUR REGULAR FAMILY PEOPLE WHO orders food keeping the family in mind',
			link: `/regular/kids/${defaultCategory}`,
		},
		{
			image: Healthy,
			id: 2,
			heading: 'Healthy Menu',
			para: 'fOR OUR REGULAR FAMILY PEOPLE WHO orders food keeping the family in mind',
			link: `/regular/healthy/${defaultCategory}`,
		},
	];
	const Categories = [
		{
			image: Family,
			id: 0,
			heading: 'Family Menu',
			link: `/regular/all/${defaultCategory}`,
		},
		{
			image: eventPlanner,
			id: 1,
			heading: 'Event Planner',
			link: `/bulk/bulk/${defaultCategory}`,
		},
		{
			image: Healthy,
			id: 2,
			heading: 'Fitness',
			link: `/regular/healthy/${defaultCategory}`,
		},
	];
	const events = [
		{
			image: image,
			text: 'Tired of ordering food every day on time?',
			buttonText: 'Explore Meal Plans',
		},
		{
			image: image1,
			text: 'Have your Birthday/ Anniversary/Event?',
			buttonText: 'Explore Bulk Order Menu',
		},
	];
	const access = useAccess(params);
	const findDeliveryStores = (location, type = 'map') => {
		// console.log(location, location.geometry.location.lng, 'location');
		// debugger;
		var changedLocation = {
			lat: '',
			lng: '',
		};
		if (type !== 'map') {
			changedLocation = location;
		} else {
			changedLocation = {
				lat: location.geometry.location.lat,
				lng: location.geometry.location.lng,
			};
		}
		clientService
			.getDeliveryStore({
				params: {
					lat: changedLocation.lat,
					long: changedLocation.lng,
					...(access?.sendMobile
						? { mobile: localStorageAuth?.user }
						: {}),
				},
			})
			.then((stores) => {
				let exist = false;
				// console.log(stores, 'existtttttttttttttttttttttttttt');
				if (stores.length) {
					stores.forEach((currentStore) => {
						if (restaurantId == currentStore.storeId) {
							dispatch(
								storeActions.setStoreData(
									storeClosedOrNot(orderInfo, store)
								)
							);
							exist = true;
							return;
						}
					});
				}
				if (!exist) {
					dispatch(
						storeActions.setStoreData({
							isClosed: true,
							isServisable: false,
						})
					);
					toast.error("Store Doesn't deliver here!");
				}
			})
			.catch((err) => {
				dispatch(
					storeActions.setStoreData({
						isClosed: true,
						isServisable: false,
					})
				);
				toast.error(err);
			});
	};

	if (bypass)
		return (
			<>
				{defaultCategory != null ? (
					<Redirect
						to={
							orderInfo.orderType == 'schedule'
								? `/${restaurantId}/menu-card/schedule/schedule/${defaultCategory}`
								: `/${restaurantId}/menu-card/regular/all/${defaultCategory}`
						}
					/>
				) : (
					<Loader />
				)}
			</>
		);

	return (
		<div>
			<SideNav
				onClose={() => {
					dispatch(modalActions.toggleChangeLocationModal(false));
					dispatch(
						storeActions.setStoreData({
							isClosed: true,
							isServisable: false,
						})
					);
				}}
				visible={showChangeLocationModal}
				mode='dark'
			>
				<ChangeLocationModal
					findDeliveryStores={findDeliveryStores}
					DEFAULT_COORDS={DEFAULT_COORDS}
					restaurantId={restaurantId}
				/>
			</SideNav>
			{/* Desktop View  */}
			<div className='hidden lg:block'>
				<Banner />
				<div className='flex overflow-x-scroll pb-2 bgGrayScrollbar my-6'>
					<div className='flex flex-nowrap space-x-3'>
						{store.promotions.map((offer) => (
							<OfferCard image={offer} />
						))}
					</div>
				</div>
				{/* menu card for medium size screens */}
				<div className='hidden  sm:flex lg:hidden overflow-x-scroll  hide-scroll-bar mb-6'>
					<div className='flex flex-nowrap space-x-3'>
						{Menus.map((tempMenu) => (
							<div
								className={`w-24 ${
									menu.menuTags.includes(tempMenu.heading) ||
									tempMenu.id == 0
										? `block`
										: 'hidden'
								}`}
							>
								<PhoneMenuCard {...tempMenu} />
							</div>
						))}
					</div>
				</div>
				<div className='text-lg font-open-sans font-semibold uppercase'>
					Our Menu-Card for you today!
				</div>

				<div
					className={`grid grid-cols-6 sm:grid-rows-4 ${
						menu.menuTags.length > 1
							? `lg:grid-rows-3`
							: `lg:grid-rows-2`
					} lg:grid-cols-6 grid-flow-col mt-6 gap-4`}
				>
					<div
						className={`items-center justify-around hidden  ${
							menu.menuTags.length > 1 ? `lg:flex` : 'lg:hidden'
						} col-span-6 row-span-1  lg:row-start-1 `}
						style={{
							width: '100%',
						}}
					>
						{Menus.map((tempMenu, idx) => {
							const menuNameExist = menu.menuTags.includes(
								tempMenu.heading
							);
							if (!menuNameExist) return;
							return (
								<div
									className={`mx-4`}
									//  ${
									// 	(store.bulkOrdering && tempMenu.id === 1) ||
									// 	tempMenu.id !== 1
									// 		? 'lg:block'
									// 		: 'lg:hidden'
									// }
									//  }
								>
									{/* {console.log(menu.menuTags.includes(tempMenu.heading))} */}
									<MenuCard {...tempMenu} />
								</div>
							);
						})}
					</div>
					<div
						className={`hidden row-span-2 col-span-6 lg:col-span-3 sm:block ${
							menu.menuTags.length > 1
								? `lg:row-start-2`
								: 'lg:row-start-1'
						}`}
					>
						<MenuJumbotron type='specefic' />
					</div>
					<div
						className={`row-span-2 col-span-6 lg:col-span-3 ${
							menu.menuTags.length > 1
								? `lg:row-start-2`
								: 'lg:row-start-1'
						}`}
					>
						<MenuJumbotron type='general' />
					</div>
				</div>
				{Object.keys(menu.specialMenu).length > 0 &&
					menu.specialMenu.products.length > 0 &&
					(orderInfo.deliverNow
						? checkNormalTime()
						: checkTime()) && (
						<>
							<div className='mt-6 text-lg font-open-sans font-semibold uppercase'>
								{menu.specialMenu.name}
							</div>
							<div className='hidden bg-no-repeat rounded-md bg-white mt-6 p-9  sm:grid sm:grid-cols-2 sm:grid-rows-2 gap-6'>
								{menu.specialMenu.products
									?.slice(0, FOOD_CARDS_HOME)
									.map((foodItemKey, index) => {
										const foodItem =
											menu.products[foodItemKey];
										return (
											<div className='col-span-2 lg:col-span-1'>
												<FinalFoodCard
													key={foodItemKey}
													productAvailable={
														foodItem.isProductAvailable
													}
													productId={
														foodItem.productId
													}
													showCustomization={
														showCustomization
													}
													productType={
														foodItem.productType
													}
													description={
														foodItem.description
													}
													discount={foodItem.discount}
													selectedVariant={
														foodItem.selectedVariant
													}
													displayVariants={
														foodItem.displayVariants
													} // TRUE | FALSE (Sizes)
													variants={foodItem.variants} // Sizes
													image={foodItem.image}
													isCustomizable={
														foodItem.isCustomizable
													}
													isVeg={foodItem.isVeg}
													menuTags={foodItem.menuTags}
													tags={foodItem.tags}
													name={foodItem.name}
													ratings={foodItem.ratings}
													tax={foodItem.tax}
													type={orderInfo.orderType}
													// foodItemNotAvailableDueToTime={
													// 	foodItem.notAvailableDueToTime
													// }
													// foodItemNotAvailableDueToDate={
													// 	foodItem.notAvailableDueToDate
													// }
													subscriptionFrom={
														foodItem.subscriptionFrom
													}
													subscriptionTo={
														foodItem.subscriptionTo
													}
													isProductSpecial='special'
													foodItem={foodItem}
												/>
											</div>
										);
									})}
							</div>
						</>
					)}
				<div className='grid grid-cols-7 mt-6 gap-4'>
					{store.mealSubscription && (
						<div className='col-span-7 lg:col-span-4'>
							<div className='font-open-sans font-semibold'>
								Schedule YOUR MEAL WITH US!
							</div>
							<div className='mt-4'>
								<EventsCard
									image={events[0].image}
									text={events[0].text}
									small={false}
									buttonText={events[0].buttonText}
									link={`schedule/schedule/${defaultCategory}`}
								/>
							</div>
						</div>
					)}
					{store.bulkOrdering && (
						<div className='col-span-7 lg:col-span-3'>
							<div className='font-open-sans font-semibold'>
								Bulk order bookings
							</div>
							<div className='mt-4'>
								<EventsCard
									image={events[1].image}
									text={events[1].text}
									small={true}
									buttonText={events[1].buttonText}
									link={`bulk/bulk/${defaultCategory}`}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
			{/* Mobile View  */}
			<div className='block lg:hidden mt-36'>
				<div className='flex overflow-x-scroll hide-scroll-bar mt-6 px-6'>
					<div className='flex flex-nowrap space-x-3'>
						{store.promotions.map((offer) => (
							<OfferCard image={offer} />
						))}
					</div>
				</div>
				<div className='flex overflow-x-scroll hide-scroll-bar mt-6 mx-6 pb-4 justify-between basis-full'>
					<div className='flex w-full flex-nowrap space-x-3 justify-between items-center '>
						{Menus.map((tempMenu) => (
							<div
								className={`w-44 ${
									menu.menuTags.includes(tempMenu.heading) &&
									menu.menuTags.length > 1
										? `block`
										: 'hidden'
								}`}
							>
								<PhoneMenuCard {...tempMenu} />
							</div>
						))}
					</div>
				</div>
				<div className='mt-6 px-6 mb-6'>
					<MenuJumbotron type='specefic' />
				</div>
				{Object.keys(menu.specialMenu).length > 0 && (
					<div className='mt-4 font-open-sans font-bold text-base px-6'>
						{menu.specialMenu.products.length > 0 &&
							(orderInfo.deliverNow
								? checkNormalTime()
								: checkTime()) &&
							menu.specialMenu.name}
					</div>
				)}
				{Object.keys(menu.specialMenu).length > 0 && (
					<div className='rounded my-6 space-y-0.5'>
						{menu.specialMenu.products.length > 0 &&
							(orderInfo.deliverNow
								? checkNormalTime()
								: checkTime()) &&
							menu.specialMenu.products
								?.slice(0, FOOD_CARDS_HOME)
								.map((foodItemKey, index) => {
									const foodItem = menu.products[foodItemKey];
									// debugger
									return (
										<div className='col-span-2 lg:col-span-1'>
											<PhoneSpecialFoodCard
												foodItem={foodItem}
												key={foodItem.productId}
												index={index}
												name={foodItem.name}
												productId={foodItem.productId}
												productType={
													foodItem.productType
												}
												discount={foodItem.discount}
												selectedVariant={
													foodItem.selectedVariant
												}
												variants={foodItem.variants}
												type={orderInfo.orderType}
												isCustomizable={
													foodItem.isCustomizable
												}
												showCustomization={
													showCustomization
												}
											/>
										</div>
									);
								})}
					</div>
				)}
				<div className='px-6'>
					<MenuJumbotron type='general' />
				</div>
				{/* {(store.mealScheduling || store.bulkOrdering)&& menu.menuTags.length > 2 && (
					<div className='mt-4 font-open-sans font-bold text-base px-6'>
						Help us to know who you are?
					</div>
				)}
				{(store.mealScheduling || store.bulkOrdering) && menu.menuTags.length > 2 && (
					<div className='flex overflow-x-scroll mt-6 mx-6 pb-4'>
						<div className='flex flex-nowrap space-x-3'>
							{Categories.map((tempMenu) => (
								<div
									className={`w-24 h-24 ${
											( tempMenu.id===0|| menu.menuTags.includes(tempMenu.heading))? 'block'
											: 'hidden'
									}`}
								>
									<PhoneMenuCard {...tempMenu} />
								</div>
							))}
						</div>
					</div>
				)} */}
				{store.mealSubscription && (
					<div className='px-6 mt-6'>
						<PhoneBanner />
					</div>
				)}
				{store.bulkOrdering && (
					<div className='flex space-x-4 h-32 my-6 px-6'>
						{store.bulkOrdering && (
							<div className='flex-1'>
								<PhoneMenuCard
									image={Bulk}
									heading='Book Bulk Orders'
									link={`/bulk/bulk/${defaultCategory}`}
								/>
							</div>
						)}
						{(store.mealSubscription || store.bulkOrdering) &&
							menu.menuTags.includes('Kids Menu') && (
								<div className='flex-1'>
									<PhoneMenuCard
										image={Kids}
										heading='Food For your kids'
										link={`/regular/kids/${defaultCategory}`}
									/>
								</div>
							)}
					</div>
				)}
			</div>
			<SideNav
				onClose={() => setIsCustomizationsVisible(false)}
				visible={isCustomizationsVisible}
				closable={false}
				mode='light'
			>
				{isCustomizationsVisible && (
					<CustomizationSlider
						type='SAVE'
						index={selectFoodItem.index}
						appliedCustomization={
							selectFoodItem.appliedCustomization
						}
						image={selectFoodItem.foodItem.image}
						name={selectFoodItem.foodItem.name}
						isVeg={selectFoodItem.foodItem.isVeg}
						selectedVariant={
							selectFoodItem.foodItem.selectedVariant
						}
						variants={selectFoodItem.foodItem.variants}
						customization={selectFoodItem.foodItem.customisation}
						product={selectFoodItem.foodItem}
						handleClose={() => {
							setIsCustomizationsVisible(false);
							document.body.classList.remove('stop-scrolling');
						}}
						orderType={orderInfo.orderType}
					/>
				)}
			</SideNav>
		</div>
	);
}
