/** @format */

import { useEffect, useState } from 'react';
import Pin from '../../Assets/Icons/Pin.svg';
import Clock from '../../Assets/Icons/Clock.svg';
import MarkedRadio from '../../Assets/Icons/MarkedRadio.svg';
import UnMarkedRadio from '../../Assets/Icons/UnMarkedRadio.svg';
import { useHistory } from 'react-router-dom';
import {
	cartActions,
	customizationActions,
	orderActions,
	authActions,
	storeActions,
	modalActions,
} from '../../Redux/Actions';
import moveArrowIcon from '../../Assets/Images/right-arrow-icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { tConvert } from '../../Functions/GetTime';
import toast from 'react-hot-toast';
import OrderModal from '../../Components/Modal/OrderModal';
import { store } from '../../Functions/store';
import SideNav from '../SideNav/SideNav';
import { storeService } from '../../Redux/Services';

const StoreBranches = ({
	stores,
	mode,
	setIsVisible,
	isVisible,
	locations,
}) => {
	const [selectedBranch, setSelectedBranch] = useState(0);
	const [branchData, setBranchData] = useState(false);
	let showMealSubscription = false;
	let showMealScheduling = false;
	const history = useHistory();
	const dispatch = useDispatch();
	const { orderInfo, auth, store } = useSelector((state) => state);

	const changeBranch = (index) => {
		setSelectedBranch(index);
	};
	// useEffect(() => {
	//   showMealSubscription = false
	// 	showMealScheduling = false
	// }, [isVisible]);
	// useEffect(() => {
	// 	stores.forEach((store, index) => {
	// 			setSelectedBranch(index);
	// 			return;
	// 	});
	// }, [stores]);

	// const closeModal = () => {
	//   setIsOpen(false);
	// };
	let setbranch = 0;
	var selectedStore = {
		scheduleMeal: false,
		schedule: false,
	};
	const enterStore = (index) => {
		const storeId = stores[index].storeId;
		dispatch(storeActions.setStore(stores[index]));
		orderInfo.storeId = storeId;
		orderInfo.deliveryMode = mode;
		dispatch(orderActions.updateOrderStore(orderInfo));
		setBranchData(stores[index]);
		const store = stores[index];
		if (!store.mealScheduling && !store.mealSubscription) {
			dispatch(
				orderActions.updateOrderStore({
					...orderInfo,
					orderType: 'regular',
					noOfDays: 1,
					deliverNow: true,
					type: 'regular',
				})
			);
			cartActions.clearCartStore();
			history.push(`${storeId}/home`);
		} else if (store.mealScheduling && store.mealSubscription) {
			showMealSubscription = true;
			showMealScheduling = true;
			setIsVisible(true);
		} else if (store.mealScheduling) {
			showMealScheduling = true;
			setIsVisible(true);
		} else if (store.mealSubscription) {
			showMealSubscription = true;
			setIsVisible(true);
		}

		// 	}
		// dispatch(storeActions.getStore(storeId))
		// .then((currentStore)=>{
		// 	selectedStore = currentStore
		// 	console.log(currentStore, 'selectedstore')
		// // 	if (!currentStore.mealScheduling && !currentStore.scheduling) {
		// // 		history.push(`${currentStore.storeId}/home`);
		// // 	} else {
		// 		setIsVisible(true);
		// // 	}
		// })
	};

	return (
		<div className='space-y-8'>
			<div className='flex flex-col'>
				<div
					className={`lg:overflow-y-auto  ${
						Object.keys(locations).length == 1 && mode == 'pickup'
							? 'max-h-44 lg:h-3/4 xl:h-4/4'
							: 'lg:max-h-32 xl:max-h-44'
					}`}
					style={{
						height: '100%',
						maxHeight: '100%',
					}}
				>
					{stores?.map?.((branch, index) => (
						<div
							key={branch.storeId}
							className={
								'flex items-center py-3 xl:py-4 border-b border-gray-300 cursor-pointer'
							}
							onClick={() => {
								changeBranch(index);
								enterStore(index);
								setbranch = index;
							}}
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							{/* <div className=''>
								{index === selectedBranch ? (
									<img
										src={MarkedRadio}
										alt='marked branch'
										className='h-4 w-4 sm:h-5 sm:w-5'
									/>
								) : (
									<img
										src={UnMarkedRadio}
										alt='unmarked branch'
										className='h-4 w-4 sm:h-5 sm:w-5'
									/>
								)}
							</div> */}
							<div
								className='flex flex-col sm:flex-row sm:w-full sm:justify-between sm:space-x-4'
								style={{
									flexBasis: '85%',
								}}
							>
								<div className='sm:w-6/12'>
									<div className='font-open-sans font-semibold text-left text-base text-black'>
										{branch.storeName}
									</div>
									<div className='font-open-sans text-left text-2xs mt-1 sm:mt-0'>
										{branch.address}
									</div>
								</div>
								<div className='flex sm:hidden mt-2 space-x-2'>
									{mode === 'delivery' && (
										<div className='flex space-x-2 '>
											<div>
												<img
													src={Pin}
													alt='pin icon'
													className='h-4 w-4'
												/>
											</div>
											<div className='inline-block font-open-sans text-left text-xs'>
												{branch.distance?.toFixed(2)} km
											</div>
										</div>
									)}
									<div className='flex space-x-2 '>
										<div>
											<img
												src={Clock}
												alt='clock icon'
												className='h-4 w-4'
											/>
										</div>
										<div className='inline-block font-open-sans text-left text-xs'>
											{branch.openingTime +
												' - ' +
												branch.closingTime}
										</div>
									</div>
								</div>
								{mode === 'delivery' && (
									<div className='hidden sm:flex sm:w-2/12 items-center space-x-1'>
										<div>
											<img
												src={Pin}
												alt='pin icon'
												className='h-3 w-3 sm:h-4 sm:w-4'
											/>
										</div>
										<div className='inline-block font-open-sans text-left text-2xs sm:text-xs'>
											{branch.distance?.toFixed(2)} km
										</div>
									</div>
								)}
								<div className='hidden sm:flex sm:w-4/12 items-center space-x-1'>
									<div>
										<img
											src={Clock}
											alt='clock icon'
											className='h-3 w-3 sm:h-4 sm:w-4'
										/>
									</div>
									<div className='inline-block font-open-sans text-left text-2xs sm:text-xs'>
										{tConvert(branch.openingTime) +
											'-' +
											tConvert(branch.closingTime)}
									</div>
								</div>
							</div>
							<div
								className='flex items-center justify-center'
								style={{
									flexBasis: '10%',
								}}
							>
								<img
									src={moveArrowIcon}
									alt='enter store'
									className='h-4 w-4 sm:h-5 sm:w-5 '
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default StoreBranches;
