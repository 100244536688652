/** @format */

import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Loader } from "../../Components/Spinner/Loader";
import { useParams,useHistory } from "react-router-dom";
import { storeActions,menuActions } from "../../Redux/Actions";

export default function Subscription() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { restaurantId } = useParams();
  const { orderInfo ,auth} = useSelector((state) => state);

  useEffect(() => {
    if (orderInfo.orderType == "regular"){
        history.push(`/${restaurantId}/home`);
    }
    const orderDetails = {
      orderType: orderInfo.orderType,
      deliverNow: orderInfo.deliverNow,
      time: orderInfo.time,
    };
    dispatch(storeActions.getStore(restaurantId));
    dispatch(menuActions.getMenu(restaurantId, orderInfo));
  }, []);

  if (auth.redirectTo) {
    history.push(auth.redirectTo);
  }

  return <Loader />;
}
