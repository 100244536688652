import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import DownArrowBlack from "../../Assets/Icons/DownArrowBlack.png";

const CustomSelect = ({ locations, changeStore }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("None");
  useEffect(() => {
    if (Object.keys(locations).length == 1) {
      changeStore(locations[Object.keys(locations)[0]]);
    }
  });
  return (
    <>
      {Object.keys(locations).length != 1 && (
        <div>
          <div
            className="font-open-sans flex justify-between items-center border border-E0E0E0 bg-FFFFFF p-4 rounded cursor-pointer"
            onClick={() =>
              Object.keys(locations).length > 0
                ? setIsOpen(!isOpen)
                : toast.error("No locations available")
            }
          >
            <div>{selectedLocation}</div>
            <div className="h-4 w-4">
              <img
                src={DownArrowBlack}
                alt="down arrow"
                className="h-full w-full"
              />
            </div>
          </div>
          <div className="max-h-60 overflow-auto">
            {isOpen &&
              Object.keys(locations).map((loc, index) => (
                <div
                  className="font-open-sans border border-E0E0E0 bg-FFFFFF p-4 cursor-pointer hover:bg-gray-200"
                  onClick={() => {
                    setSelectedLocation(loc);
                    changeStore(locations[loc]);
                    setIsOpen(false);
                  }}
                >
                  {loc}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomSelect;
