/** @format */

import React, { useState } from 'react';
import Home from '../../../Assets/Icons/Home-Outline.svg';
import StudentSlider from '../../../Containers/CheckOut/StudentSlider';
import Modal from '../../Modal/Modal';
import SideNav from '../../SideNav/SideNav';
import { Loader } from '../../../Components/Spinner/Loader';
import { useWindowDimensions } from '../../../Functions/utils';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { useDispatch, useSelector } from 'react-redux';

export default function SavedStudentCard({
	addressLine1,
	addressLine2,
	state,
	city,
	pincode,
	deliveryPossible,
	selected,
	onSelect,
	index,
	deleteAddress,
	lat,
	lng,
	email,
	name,
	qrcodeimage,
	hide
}) {
	const { width } = useWindowDimensions();
	const [isaddressSliderVisible, setIsaddressSliderVisible] = useState(false);
	const [isDeleteModal, setIsDeleteModal] = useState(false);
	const [qrCodeModal, setIsQrcodeModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { client } = useSelector((state) => state);
	const setDeliveryChargesAndCheckServiceAbility = () => {
		onSelect(index);
		setIsLoading(false);
	};
    const downloadImage = () => {
		const a = document.createElement('a');
		a.download = 'QrCode.png';
		a.href = qrcodeimage;
		a.click();
	  }
	return (
		<React.Fragment>
			{isLoading && <Loader />}
			<div
				id='address-box'
				onClick={width < 576 ? (e) => setDeliveryChargesAndCheckServiceAbility(e) : null}
				className={`flex px-4 sm:px-9 py-4 items-start border sm:border-none ${
					selected ? 'border-7FB069' : 'border-E9E9E9'
				} font-open-sans`}
			>			
				{(client?.scanAndPay && qrcodeimage)  &&
					(<div className='flex mt-1 cursor-pointer'
					onClick={()=>{setIsQrcodeModal(true)}}>
						<QrCode2Icon/>
					</div>)
				}
				{
				(!client?.scanAndPay || !qrcodeimage) &&
				 	(<img className='mt-2' src={Home} alt='home' />)
				 }

				<div className='flex-grow mx-4 sm:ml-8 w-2/3 '>
					<div className='font-semibold text-xs sm:text-base'>
						{addressLine1},{addressLine2}-{city}
					</div>
				</div>
				 { !hide && 
				 (selected ? (
					<button className='bg-7FB069 rounded h-10 w-1/5 text-white hidden sm:block'>
						SELECTED
					</button>
				) : (
					<button
						onClick={setDeliveryChargesAndCheckServiceAbility}
						className='border border-7FB069 text-7FB069 rounded h-10 w-1/5 hidden sm:block'
					>
						SELECT
					</button>
				))}
				{!hide && <div className=' flex flex-col items-end justify-between space-y-3 sm:hidden'>
					<button
						className='bg-7FB0692C text-3xs px-2 py-1 rounded-sm font-semibold'
						onClick={setDeliveryChargesAndCheckServiceAbility}
					>
						SELECT CHILD
					</button>
				</div>}
			</div>
			{/* To show Address Edititng Slider  */}
			<SideNav
				visible={isaddressSliderVisible}
				onClose={() => setIsaddressSliderVisible(false)}
				mode='dark'
			>
				<StudentSlider
					type='EDIT'
					index={index}
					email={email}
					addressLine1={addressLine1}
					addressLine2={addressLine2}
					state={state}
					lat={lat}
					lng={lng}
					city={city}
					pincode={pincode}
					email= {email}
					name = {name}
					close={() => setIsaddressSliderVisible(false)}
					mapId={`map3-${index}`}
				/>
			</SideNav>
			{/* Modal to prevent selecting an address where delivery is not possible  */}
			<Modal
				visible={isDeleteModal}
				title={`Do You Want To Delete this Address Permanently`}
				close={() => setIsDeleteModal(false)}
				closingText='Cancel'
				submit={() => {
					deleteAddress(index);
					setIsDeleteModal(false);
				}}
				submittingText='Delete'
			/>
			<Modal
				visible={qrCodeModal}
				title={`Download and print the QRCode and paste it on the back of Child ID-Card `}
				image = {qrcodeimage}
				close={() => setIsQrcodeModal(false)}
				closingText='Close'
				submit={() => {
					downloadImage(qrcodeimage);
					setIsQrcodeModal(false);
				}}
				submittingText='Download'
			/>
		</React.Fragment>
	);
}
