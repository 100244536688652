import moment from "moment";
import React from "react";

function SubscriptionCard({ foodItem }) {
  return (
    <div className="flex px-4 py-2.5 space-x-2 bg-white cursor-pointer">
      <div className="w-12 h-12">
        <img alt="food" src={foodItem.image} className="w-12 h-12" />
      </div>
      <div className="flex-grow">
        <div className="font-open-sans font-semibold text-sm">{foodItem?.name}</div>
        <div className="text-8B8B8B font-open-sans font-normal text-xs">
            {foodItem?.description}          
        </div>
        <div className="text-464646 font-open-sans font-bold text-xs">
            {foodItem?.date}          
        </div>
      </div>
    </div>
  );
}

export default SubscriptionCard;
