/** @format */
import SubHeader from '../../Components/Header/SubHeader';
import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import SubscriptionCancelled from '../../Assets/Images/wrong.gif'
import SubscriptionPlaced from '../../Assets/Icons/subscription-placed.svg';
import Modal from '../../Components/Modal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import DownArrowBlack from '../../Assets/Icons/DownArrowBlack.png';
import UpArrowBlack from '../../Assets/Icons/UpArrowBlack.png';
import { useEffect } from 'react';
import {
	authActions,
	subscriptionActions,
} from '../../Redux/Actions';
import ConfirmSubscriptionOrder from './ConfirmSubscriptionOrder';
import DisabledDatesCalender from '../../Components/Cards/ScheduledOrderCard/DisabledDatesCalender';
import CustomizedHook from '../../Components/Cards/ScheduledOrderCard/DisabledDatesContainer';
import moment from 'moment';
import toast from 'react-hot-toast';
import SubscriptionDaysContainer from '../../Components/SubscriptionDaysContainer/SubscriptionDaysContainer';
// import { dispatch } from "react-hot-toast/dist/core/store";

export default function ConfirmSubscription() {
	const [selectedDate, handleDateChange] = useState(new Date());
	const toggleSection = (stageNo) => {
		let sectionArray = showItems;
		let value = showItems[stageNo - 1];
		sectionArray[stageNo - 1] = !value;
		setShowItems([...sectionArray]);
	};
	const { restaurantId, subscriptionId } = useParams();
	const [cancelledDates, setCancelledDates] = useState('')
	const { subscription } = useSelector((state) => state);
	const [cancelSubscriptionConfirmation, setCancelSubscriptionConfirmation] = useState(false)
	const [showItems, setShowItems] = useState([false, false, false, false]);
	const dispatch = useDispatch();
	const history = useHistory();
	const [visible, setVisible] = useState(false);
	const {
		// subscription,
		schedule,
		store,
		orderInfo,
		cart: order,
		auth,
		feedback,
	} = useSelector((state) => state);
	
	useEffect(() => {
		dispatch(authActions.redirect(null));
	}, [dispatch]);

	useEffect(() => {
		dispatch(subscriptionActions.getCompletedMeals({
			subscriptionId,
			userId : auth.user
		}))
	}, [subscriptionId]);
	
	useEffect(() => {
		for (let currentSubscription of subscription.pastOrders) {
			if (currentSubscription.id == subscriptionId) {
				setCancelledDates(
					currentSubscription.cancelledDates.reduce((accCancelledDays, currentCancelledDay)=>{
						let tempAccCancelledDays = ''
						if(!accCancelledDays){
							tempAccCancelledDays = currentCancelledDay.mealDate.split(' ')[0]
						}else{
							tempAccCancelledDays = `${accCancelledDays},${currentCancelledDay.mealDate.split(' ')[0]}`
						}
						return tempAccCancelledDays
					},'')
				)
				return;
			}
		}
		// debugger
		
	}, [subscription.pastOrders]);

	useEffect(()=>{
		dispatch(subscriptionActions.SubscriptionDetailRequest(subscriptionId));
	},[subscriptionId])
	
	const convertDate = (type)=>{
		const convertedDate = selectedDate.toLocaleDateString('en-US').split('/')
		// console.log(convertedDate, 'convertedDate')
		const dateYear = convertedDate[2]
		let dateMonth = `0${convertedDate[0]}`
		let currentDate = `0${convertedDate[1]}`
		if (currentDate.length > 2) {
			currentDate = currentDate.substring(1, 3);
		}
		if(type==='modal'){
			const monthName = moment(new Date(selectedDate)).format('MMM')
			return `${currentDate}, ${monthName} ${dateYear}`
		}
		if (dateMonth.length > 2) {
			dateMonth = dateMonth.substring(1, 3);
		}
		
		const tempDate = `${dateYear}-${dateMonth}-${currentDate}`;
		return tempDate;
	}
	
	const OuterBox = (props) => (
		<div
			className='sm:bg-white mt-4 sm:px-6 shadow sm:py-4 py-4 cursor-pointer '
			onClick={() => toggleSection(props.stageNo)}
		>
			<div className='flex cursor-pointer justify-between bg-white rounded sm:rounded-none mx-4 px-6 py-4 sm:px-0 sm:py-0 max-w-full'>
				<div className='text-md font-semibold'>
					{props.stageName}
				</div>
				<div className='cursor-pointer'>
					{/*   Downward arrow */}
					<img
						src={
							showItems[props.stageNo - 1]
								? UpArrowBlack
								: DownArrowBlack
						}
						alt='down/up arrow'
						className='w-4 h-4 mr-4'
					/>
				</div>
			</div>
			{showItems[props.stageNo - 1] && props.children}
		</div>
	);

	const changeDateToWord = (date)=>{
		const convertedDate = date.split('-')
		const monthName = moment(date, 'YYYY-MM-DD').format('MMM')
		return `${convertedDate[2]}, ${monthName} ${convertedDate[0]}`
	}
	const cancelSubscription = () => {
		dispatch(
			subscriptionActions.cancelSubscription({
				subscriptionId,
				showSnackbars: true,
			})
		);
		history.push(`/${store.storeId}/home`)
	};
	const dayName = {
		0: 'SUNDAY',
		1: 'MONDAY',
		2: 'TUESDAY',
		3: 'WEDNESDAY',
		4: 'THURSDAY',
		5: 'FRIDAY',
		6: 'SATURDAY',
	};
	const cancelMeal = ()=>{
		const cancellationDate = convertDate()
		if(cancelledDates.includes(cancellationDate)){
			toast.error('Meal For The Day Already Cancelled')
			return;
		}
		if(cancellationDate<subscription.subscriptionDetail.scheduled.scheduledFrom){
			toast.error('Cancellation Date cannot be smaller than Subscription Start Date')
			return;
		}
		if(cancellationDate>subscription.subscriptionDetail.scheduled.scheduledTo){
			toast.error('Cancellation Date cannot be greater than Subscription End Date')
			return;
		}
		const day = new Date(selectedDate).getDay();
		if(!subscription.subscriptionDetail.scheduled.daysOfWeek.includes(dayName[day])){
			toast.error('Cannot cancel for meal for the day')
			return true
		}
			const subscriptionDetails = {
			mealDate : `${cancellationDate} ${subscription.subscriptionDetail.scheduled.time}`,
			subscriptionId
		}
		dispatch(subscriptionActions.mealCancellation({subscriptionDetails, showSnackbar : false}))
		if(cancelledDates)
		setCancelledDates(`${cancelledDates},${convertDate()}`)
		else
		setCancelledDates(`${convertDate()}`)
	}
	// console.log(useParams(), store.storeId, 'storeId');
	// if (auth.redirectTo) {
	// 	history.push(auth.redirectTo);
	// }

	// const handleStarChange = (number) => {
	// 	const feedback = {
	// 		subscriptionId: subscriptionId,
	// 		feedback: {
	// 			orderRating: number,
	// 		},
	// 	};
	// 	dispatch(feedbackActions.setOrderingExperience(feedback));
	// };
	// if (auth.redirectTo) {
	// 	history.push(auth.redirectTo);
	// }
	// debugger
	const currentDayGreaterThanEndDate = ()=>{
		const scheduledTo = subscription.subscriptionDetail.scheduled.scheduledTo
		console.log(moment(new Date()).format('YYYY-MM-DD'), scheduledTo)
		return true;
	}
	const getImage = ()=>{
		if(subscription.subscriptionDetail.status=='CANCELLED' || subscription.subscriptionDetail.status=='FAILED'){
			return SubscriptionCancelled
		}
		return SubscriptionPlaced
	}
	return (
		<>
		{!subscription.isLoading &&	
		<>
		<div className='hidden lg:block'>
				<SubHeader
					title='Back To Menu'
					link={`/${restaurantId}/home`}
				/>
			</div>
			<div className='flex flex-col md:flex-row pt-1 mt-4'>
				<div className='block bg-F8FFF5 flex-1 mt-4 md:mt-14 lg:mt-0'>
				<div className='block md:hidden text-D17B69 text-center font-bold'>{`#Subscription Id: ${subscriptionId}`}</div>
				<div className='flex items-center h-full justify-center'>
						<div className='block' >
							<img
								src={getImage()}
								alt='successfully placed'
								className='h-64 w-64'
							/>
							<div className='hidden md:block text-D17B69 text-center font-bold'>{`#Subscription Id: ${subscriptionId}`}</div>
						</div>
					</div>
				</div>
				<div className='flex-1 bg-white mt-4 md:mt-14 lg:mt-0'>
					<div className='flex justify-center items-center '>
					<div className='flex justify-center w-10/12 items-center font-open-sans'>
						<div className='w-10/12 py-8 lg:w-8/12'>
							<div className='hidden md:block text-D17B69 text-center font-bold mb-4'>{`#Subscription Id: ${subscriptionId}`}</div>
							{/* Displaying Items that are ordered   */}
							<div className=' text-sm'>
								<span className='mr text-gray-500 font-semibold'>
									Subscribed On:
								</span>
								<span className='font-bold'>
									{
										` ${changeDateToWord(subscription.subscriptionDetail
											.createdDate.split(' ')[0])} at ${subscription.subscriptionDetail
												.createdDate.split(' ')[1]}`
									}
								</span>
								<div className='text-sm'>
									{subscription.subscriptionDetail.items.map((currentItem, index)=>{
										if(index===subscription.subscriptionDetail.items.length-1){
											return currentItem.productName
										}
										return `${currentItem.productName}, `
									})}
								</div>
								<div className='flex items-center justify-start'>
							<span className='font-medium text-sm my-1 mr-2'>
								Completed Meals
							</span>
							<div>
							<span className='font-medium'>
								{subscription.completedOrders.orders.length}
							</span>
							</div>
						</div>
								<div className='text-sm  py-1'>
									<span className='pr-1'>Price per Order:{' '}</span>
									&#x20B9;{' '}
									<span className='text-sm'>
									{subscription.subscriptionDetail.orderValue}
									</span>
								</div>
								{/* <div className='text-sm  py-1'>
									<span className=''>
										Meal Time : 
									</span>{' '}
									<span className='text-sm'>{
										subscription.subscriptionDetail
											.scheduled.time
									}</span>
								</div> */}
								<div className='text-sm sm:text-sm'>
									<span className=''>
										From :{' '}
									</span>{' '}
									<span className='text-sm'>{
										changeDateToWord(subscription.subscriptionDetail
											.scheduled.scheduledFrom??`- - -`)
									}{' '}
									</span>
									<span className='text-sm '>to</span>{' '}
									<span className='text-sm'>{
										changeDateToWord(subscription.subscriptionDetail
											.scheduled.scheduledTo??`- - -`)
									}</span>
								</div>
								<div>
								<div className=' flex items-center justify-start'>
										{/* <span className='text-green-600  text-xs sm:text-sm '>
											Selected Days : {' '}
										</span> */}
										{/* <span className='text-xs sm:text-sm'>
								{subscription.subscriptionDetail.scheduled.daysOfWeek
									.split(',')
									.map((currentDay) => {
										return <span>{currentDay.substr(0,3)} </span>;
									})}
							</span> */}
										<SubscriptionDaysContainer selectedDays={subscription.subscriptionDetail.scheduled.daysOfWeek
									.split(',')}/>
									</div>
									{/* <img className='w-10' alt='scheduled' src={CurrentOrder} /> */}
								</div>
								{cancelledDates.length>0 && <div>
								<span className='text-green-600  text-sm'>
											Cancelled Dates : {' '}
								</span>
								<span className=''>
								{cancelledDates}
								</span>
								</div>}
							{/* {subscription.subscriptionDetail.status !==
									'CANCELLED' && order.status!=='FAILED' &&<div className='flex items-center justify-start'>
									<DisabledDatesCalender
										scheduledFrom={
											subscription.subscriptionDetail
												.scheduled.scheduledFrom
										}
										scheduledTo={
											subscription.subscriptionDetail
												.scheduled.scheduledTo
										}
										daysOfWeek={
											subscription.subscriptionDetail
												.scheduled.daysOfWeek
										}
										selectedDate={selectedDate}
										cancelledDays={cancelledDates}
										handleDateChange={handleDateChange}
									/>
									<div className=''>

									</div>
									<div className='ml-2 mt-4'>
										<button
											className='font-open-sans text-white text-center h-auto bg-red-400 text-gray-200 hover:bg-red-600 p-2 rounded'
											onClick={() => {
												setVisible(true)
											}}
										>
											{' '}
											Cancel Meal
										</button>
									</div>
								</div>} */}
								{/* <CustomizedHook /> */}
								<span className='ml-2 text-gray-400'>
									{/* {`${order.address.addressLine1}`} */}
								</span>
							</div>
							<div className='flex py-5 px-2 space-x-4'>
								<button
									className='text-sm w-full h-auto text-8593FF border-2 border-8593FF rounded p-2'
									onClick={
										subscription.subscriptionDetail
											.status !== 'CANCELLED' && order.status!=='FAILED'
											? ()=>{
												setCancelSubscriptionConfirmation(true)
											}
											: ''
									}
								>
									{subscription.subscriptionDetail.status !==
									'CANCELLED' && order.status!=='FAILED'
										? `CLICK HERE TO CANCEL SUBSCRIPTION`
										: 'SUBSCRIPTION CANCELLED'}
								</button>
							</div>
						</div>
					</div>
					</div>
				</div>
				<Modal
					visible={visible}
					title={`Are You Sure you want to Cancel Meal for ${convertDate('modal')}`}
					// text='Message Description'
					submit={() =>{
						cancelMeal()
						 setVisible(false)}}
					close={() => setVisible(false)}
					closingText='Close'
					submittingText='Cancel Meal'
				/>
				<Modal
					visible={cancelSubscriptionConfirmation}
					title={`Are You Sure you want to Cancel Subscription`}
					// text='Message Description'
					submit={() =>{
						cancelSubscription()
						setCancelSubscriptionConfirmation(false)}}
					close={() => setCancelSubscriptionConfirmation(false)}
					closingText='Close'
					submittingText='Cancel Subscription'
				/>
			</div>
			<div className=''>
				<OuterBox stageName='Orders' stageNo={5}>
					{subscription.completedOrders.orders.reverse().map((currentOrder) => {
						return (
							<ConfirmSubscriptionOrder order={currentOrder}/>
						);
					})}
					{subscription.completedOrders.orders.length===0 && <div className='text-base font-semibold px-4 py-4'>No Orders To Display</div>}
				</OuterBox>
			</div>
			</>
			}
		</>
	);
}