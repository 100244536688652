import React, { useState } from "react";
import LoginImage from "../../Assets/Images/LoginImage.svg";
import Google from "../../Assets/Icons/Google.png";
import OtpCard from "../OtpCard/OtpCard";
import { loginService } from "../../Redux/Services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { authActions, creditActions } from "../../Redux/Actions";
import toast from "react-hot-toast";
import SignUpUser from "../SignUpUser/SignUpUser";
import ResendPin from "../ResendPin/ResendPin";

const Login = ({ closeLoginSlider }) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [authType, setAuthType] = useState("signIn");
  const [verify, setVerify] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [resendPin, setResendPin] = useState(false);
  const [token, setToken] = useState("");

  const { client } = useSelector((state) => state);

  const checkOtp = (OTP, type) => {
    loginService
      .verifyOTP(OTP, type, token)
      .then(
        (res) => {
          if (res?.statusCode === 200) {
            type === "mobile" && setMobileVerified(true);
            type === "email" && setEmailVerified(true);
            toast.success(res?.message ?? "Success");
          } else if (res?.statusCode === 201) {
            setEmailVerified(true);
            setMobileVerified(true);
            toast.success(res?.message ?? "Success");
            setVerify(false);
            handleTabChange("signIn");
          } else {
            console.log({ res });
            toast.error(
              res?.errorMessage ?? res?.message ?? "Some error occured"
            );
          }
        },
        (err) => {
          // debugger
          toast.error(err);
        }
      )
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const signInUser = async () => {
    let phoneRegx = /^\d{10}$/;
    let emailRegx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (phone && !phone.match(phoneRegx))
      toast.error("Phone number is not valid");

    if (email && !email.match(emailRegx))
      toast.error("Email address not Valid");

    if (!email && !phone)
      toast.error("Please Enter Number or Email to Sign In");

    if (email && phone)
      toast.error("Please enter either of mobile or email to Login");

    if ((phone.match(phoneRegx) || email.match(emailRegx)) && !pin)
      toast.error("Please Enter Passcode");

    if (
      (phone.match(phoneRegx) || email.match(emailRegx)) &&
      !(email && phone) &&
      pin
    ) {
      try {
        const response = await loginService.signInUser(
          pin,
          email && email,
          phone && phone
        );
        if (response?.statusCode === 200) {
          toast.success(response?.message ?? "Successfully Signed In");
          handleTabChange();
          dispatch(
            authActions.userLogin({
              token: response?.data?.token,
              ...response?.data?.userDetails,
              addresses: response?.data?.userDetails?.addresses?.reverse(),
              user: response?.data?.userDetails?.mobile,
            })
          );
          authType === "signIn" && closeLoginSlider();
          document.body.classList.remove("stop-scrolling");

          client.walletEnabled && dispatch(creditActions?.getCreditDetails());
        } else {
          toast.error(response?.errorMessage ?? "Some Error Occured");
        }
      } catch (error) {
        toast.error(error?.message ?? error ?? "Some error occured");
      }
    }
  };

  const resendUserPin = async () => {
    let phoneRegx = /^\d{10}$/;
    let emailRegx =
      /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;

    if (phone && !phone.match(phoneRegx))
      toast.error("Phone number is not valid");

    if (email && !email.match(emailRegx))
      toast.error("Email address not Valid");

    if (!email && !phone) toast.error("Please Enter Number or Email");

    if (email && phone) toast.error("Please enter either of mobile or email");

    if (
      (phone.match(phoneRegx) || email.match(emailRegx)) &&
      !(email && phone)
    ) {
      try {
        const response = await loginService.resendPin(
          email && email,
          phone && phone
        );
        if (response?.statusCode === 200) {
          toast.success(response?.message ?? "Success");
          handleTabChange("signIn");
        } else {
          toast.error(response?.errorMessage ?? "Some Error Occured");
        }
      } catch (error) {
        toast.error(error?.message ?? error ?? "Some error occured");
      }
    }
  };

  const handleTabChange = (type) => {
    type && setAuthType(type);
    setName("");
    setPhone("");
    setEmail("");
    setPin("");
    setResendPin(false);
    if(verify){
      setVerify(false);
    }
  };

  const handleCancelSignUp = () => {
    if (verify) {
      setVerify(false);
    }
    setName("");
    setPhone("");
    setEmail("");
    setPin("");
  }

  return (
    <div className="flex flex-col w-11/12 md:w-2/3 mx-auto py-24 space-y-8 font-open-sans">
      <div className="flex justify-between items-center">
        <div className="flex">
          <div
            style={{
              borderBottom: authType === "signIn" ? "4px solid black" : "",
            }}
            onClick={() => !resendPin && handleTabChange("signIn")}
            className="font-bold text-center text-black text-base xl:text-lg px-5 py-1 cursor-pointer"
          >
            {verify && authType === "signIn"
              ? "Verify"
              : resendPin
              ? "Resend Passcode"
              : "Sign In"}
          </div>
          <div
            style={{
              borderBottom: authType === "signUp" ? "4px solid black" : "",
            }}
            onClick={() => !verify && !resendPin && handleTabChange("signUp")}
            className="font-bold text-center text-black text-base xl:text-lg px-5 py-1 cursor-pointer"
          >
            {verify && authType === "signUp" ? "Verify" : "Sign Up"}
          </div>
        </div>
        <div className="h-28 w-32">
          <img src={LoginImage} alt="sign" className="h-full w-full" />
        </div>
      </div>

      <div>
        {!resendPin && authType === "signIn" && (
          <div className="flex flex-col mx-auto space-y-8">
            <div className="space-y-12 font-open-sans">
              <div className="space-y-2">
                <div className="flex items-center gap-4">
                  <div className="font-bold text-left text-base text-212121 capitalize">
                    Mobile:
                  </div>
                  <div className="flex border-b-4 border-7FB069 w-full">
                    <input
                      type="text"
                      value="+91"
                      disabled
                      className="bg-FFFFFFAD bg-no-repeat border-t border-l border-gray-300 rounded-l h-14 w-16 px-4 py-3"
                    />
                    <input
                      type="number"
                      className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-14 w-full px-4 py-3 focus:outline-none"
                      value={phone}
                      maxLength={10}
                      onChange={(e) => {
                        if (e.target.value.length < 11)
                          setPhone(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="font-bold text-center text-e2e2e2">OR</div>

                <div className="flex items-center justify-between gap-8">
                  <div className="font-bold text-left text-base text-212121 ">
                    Email
                  </div>
                  <div className="border-b-4 border-7FB069 w-full">
                    <input
                      type="email"
                      className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-14 w-full px-4 py-3 focus:outline-none"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Your Email "
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <div className="flex items-center gap-8 ">
                  <div className="font-bold text-left text-base text-212121">
                    Passcode
                  </div>
                  <OtpCard
                    checkOtpHandler={setPin}
                    otpType={"pin"}
                    hideConfirm
                  />
                </div>
                <div className="pt-2 text-xs ">
                  Enter{" "}
                  <span className="text-black font-bold"> First Four </span>{" "}
                  digits of Your Passcode
                </div>
              </div>
            </div>
            {!verify && (
              <button
                className="bg-8593FF bg-no-repeat rounded py-4 font-semibold text-center text-white text-base cursor-pointer hover:bg-indigo-500 cursor-pointer"
                onClick={signInUser}
              >
                SIGN IN
              </button>
            )}
          </div>
        )}

        {!resendPin && authType === "signUp" && (
          <SignUpUser
            emailVerified={emailVerified}
            mobileVerified={mobileVerified}
            verify={verify}
            setVerify={setVerify}
            phone={phone}
            setPhone={setPhone}
            email={email}
            setEmail={setEmail}
            token={token}
            checkOtp={checkOtp}
            name={name}
            setName={setName}
            setToken={setToken}
            pin={pin}
            setPin={setPin}
            handleCancelSignUp={handleCancelSignUp}
          />
        )}

        {resendPin && (
          <ResendPin
            resendUserPin={resendUserPin}
            phone={phone}
            setPhone={setPhone}
            email={email}
            setEmail={setEmail}
          />
        )}
      </div>

      <div className="flex justify-around">
        {!verify && !resendPin && authType === "signIn" && (
          <div className="flex space-x-8 justify-between">
            <div
              className="text-center text-base text-0372BC cursor-pointer"
              onClick={() => {
                setResendPin(true);
              }}
            >
              Resend Passcode
            </div>
          </div>
        )}
        {!verify && resendPin && authType === "signIn" && (
          <div className="flex space-x-8 justify-between">
            <div
              className="text-center text-base text-0372BC cursor-pointer"
              onClick={() => {
                handleTabChange("signIn");
              }}
            >
              Back To Sign In
            </div>
          </div>
        )}
      </div>
      {/*<div className='text-center text-base text-0372BC cursor-pointer'>
          Forgot Password?
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default Login;
