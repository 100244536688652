import { notificationConstants } from "../Constants";

const initialState ="";

export function notification(state = initialState, action) {
  switch (action.type) {
    case notificationConstants.NOTIFICATION_UPDATE:
      return action.payload;
    case notificationConstants.NOTIFICATION_CLEAR:
      return "";
    default:
      return state;
  }
}