// import { authHeader, handleResponse, mobileHeader } from "../Helpers";
import urls from "../../urls";

export const feedbackService = {
    updateFeedback
};

function updateFeedback(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
      req = {
        user:auth.user,
        ...req,
    };

    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json',token :auth.token },
        body: JSON.stringify({ ...req })
    }

    return fetch(urls['feedback'], requestOptions)
        .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (data.status === "Failed") {
        const error = data.message;
        return Promise.reject(error);
      }
      return data.data;
    });
  }