import urls from "../../urls";

export const orderService = {
  placeOrder,
  trackOrder,
  getPastOrders,
  cancelOrder,
  updatePaymentStatus,
  applyPromo,
};

function placeOrder(req) {
  // console.log(req.type==='SCHEDULE', 'reqqqqq')
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", token: auth?.token },
    body: JSON.stringify({ ...req }),
  };
  if (req.type === "SCHEDULE") {
    return fetch(urls["orderSubscription"], requestOptions).then(
      handleResponse
    );
  }
  return fetch(urls["orderPlacement"], requestOptions).then(handleResponse);
}

function trackOrder(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const params = {
    ...req,
  };
  const url = toQueryStringURL(urls["trackOrder"], params);
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", token: auth.token },
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data?.statusCode === 500) {
      const error = {
        statusCode: data?.statusCode,
        message: "Session expired",
      };
      return Promise.reject(error);
    }
    if (data?.statusCode !== 200) {
      const error = {
        statusCode: data?.statusCode,
        message: data?.errorMessage ?? data?.message,
      };
      return Promise.reject(error);
    }
    return data?.data;
  });
}

function getPastOrders() {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const params = {
    user: auth.user,
  };
  const url = toQueryStringURL(urls["pastOrder"], params);

  const requestOptions = {
    method: "GET",
    headers: {
      token: auth.token,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function cancelOrder(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: "DELETE",
    headers: { token: auth?.token, "Content-Type": "application/json" },
    body: JSON.stringify({
      ...req,
      user: auth.user,
    }),
  };

  return fetch(urls["orderPlacement"], requestOptions).then(handleResponse);
}

function updatePaymentStatus(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: "PATCH",
    headers: { token: auth.token, "Content-Type": "application/json" },
    body: JSON.stringify({ ...req, user: auth.user }),
  };

  return fetch(urls["orderPlacement"], requestOptions).then(handleResponse);
}

function toQueryStringURL(queryURL, params) {
  const url = new URL(queryURL);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  return url;
}

function applyPromo(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", token: auth.token },
    body: JSON.stringify(req),
  };

  return fetch(urls["applyPromo"], requestOptions).then(handleResponse);
}
