import { modalConstants } from '../Constants';

export const modalActions = {
	togglePaytmModal,
	toggleLoginModal,
	toggleComboModal,
	toggleChangeLocationModal,
	toggleChangePinModal,
	toggleEditProfileModal,
};

function toggleLoginModal(show) {
	if (show) {
		return { type: modalConstants.SHOW_LOGIN };
	} else {
		return { type: modalConstants.HIDE_LOGIN };
	}
}

function togglePaytmModal(show) {
	if (show) {
		return { type: modalConstants.SHOW_PAYTM };
	} else {
		return { type: modalConstants.HIDE_PAYTM };
	}
}

function toggleComboModal(show, data = {}) {
	if (show) {
		return { type: modalConstants.SHOW_COMBO, payload: data };
	} else {
		return { type: modalConstants.HIDE_COMBO, payload: data };
	}
}

function toggleChangeLocationModal(show) {
    if (show) {
        return { type: modalConstants.SHOW_CHANGE_LOCATION }
    } else {
        return { type: modalConstants.HIDE_CHANGE_LOCATION }
    }
}

function toggleChangePinModal(show){
	if (show) {
        return { type: modalConstants.SHOW_CHANGE_PIN }
    } else {
        return { type: modalConstants.HIDE_CHANGE_PIN }
    }
}

function toggleEditProfileModal(show){
	if (show) {
        return { type: modalConstants.SHOW_EDIT_PROFILE }
    } else {
        return { type: modalConstants.HIDE_EDIT_PROFILE }
    }
}