import { notificationConstants } from "../Constants";

function createNotification(message) {
  return (dispatch) => {
    dispatch(update(message));
  };
  function update(payload) {
    return { type: notificationConstants.NOTIFICATION_UPDATE, payload };
  }
}

export const notificationActions = {
    createNotification,
};