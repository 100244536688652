import { useEffect, useState } from 'react';
import cookingImg from '../../Assets/Images/cooking.gif';
import Carousel from 'react-elastic-carousel';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const AcceptedSteps = (props) => {
	// console.log(props, '123props')
	const QUEUE_SIZE = 5;
	const [state, setState] = useState({
		processQueue: [],
		processImageQueue: [],
	});
	const { cooking } = props;
	const { processQueue, processImageQueue } = state;

	useEffect(() => {
		let tempQueue = [];
		for (const stage of cooking) {
			const processes = stage.processes;
			tempQueue = [...tempQueue, ...processes];
		}

		tempQueue = tempQueue
			.filter((process) => process.isStarted)
			.sort((a, b) => {
				const aStartTime = new Date(a.startedAt);
				const bStartTime = new Date(b.startedAt);
				if (a.isCompleted && b.isCompleted) {
					return aStartTime - bStartTime;
				} else if (a.isCompleted) {
					return -1;
				} else if (b.isCompleted) {
					return 1;
				} else {
					return aStartTime - bStartTime;
				}
			});

		let processImageQueue = [];

		let tempImageQueue = tempQueue.slice();

		let isEveryImageAvailable = true;

		for (const process of tempImageQueue) {
			if (process.isCompleted) {
				if (process.processImage) {
					processImageQueue.push({
						src: process.processImage,
						caption: process.processName,
						timestamp: process.timestamp,
					});
				}
			}

			let tempSKUs = [];

			tempSKUs = process.skus.reduce((acc, curr) => {
				// console.log(curr);
				if (curr.isConsumed) {
					if (curr.skuImage) {
						acc.push({
							src: curr.skuImage,
							caption: `${process.processName}: ${curr.name}`,
							timestamp: curr.timestamp,
						});
					} else {
						isEveryImageAvailable = false;
					}
				}

				return acc;
			}, []);
			processImageQueue = [...processImageQueue, ...tempSKUs];
		}

		processImageQueue.sort((a, b) => {
			const aTimestamp = new Date(a.timestamp);
			const bTimestamp = new Date(b.timestamp);
			return aTimestamp - bTimestamp;
		});

		if (!isEveryImageAvailable) {
			processImageQueue.length = 0;
		}

		if (props.active && processImageQueue.length > 0) {
			processImageQueue.unshift({
				src: cookingImg,
				caption: 'Your order has been accepted',
				timestamp: null,
			});
		}

		setState({
			...state,
			processQueue: tempQueue.slice(-QUEUE_SIZE),
			processImageQueue,
		});
	}, [cooking]);
	const myArrow = ({ type, onClick, isEdge }) => {
		// console.log(type);
		return type === 'PREV' ? (
			<button
				className='btn left items-center'
				onClick={onClick}
				disabled={isEdge}
			>
				<ChevronLeft />
			</button>
		) : (
			<button
				className='btn right items-center'
				disabled={isEdge}
				onClick={onClick}
			>
				<ChevronRight />
			</button>
		);
	};
	let currIdx = 0;
	// console.log(processImageQueue.length, 'img length');
	const stepStatus = (
		<div
			className='step-status'
			style={{
				margin: '1rem',
			}}
		>
			{processImageQueue.length > 0 ? (
				<Carousel
					itemsToShow={1}
					itemsToScroll={1}
					renderArrow={myArrow}
					pagination={false}
					disableArrowsOnEnd={true}
					enableAutoPlay={true}
					autoPlaySpeed={3500}
					className='custom-carousel-root'
				>
					{processImageQueue.map((obj, idx) => {
						currIdx = idx;
						// console.log(currIdx, 'curr');
						return (
							<div className='step-img-container'>
								<img className='step-img' src={obj.src} />
								<p
									className='text-base font-semibold'
									style={{
										display: 'flex',
										justifyContent: 'space-around',
										alignItems: 'center',
										textAlign: 'center',
									}}
								>
									{obj.caption}
								</p>
								<p
									className='text-base font-semibold'
									style={{
										display: 'flex',
										justifyContent: 'space-around',
										alignItems: 'center',
										textAlign: 'center',
									}}
								>
									<span className='timestamp'>
										{obj.timestamp && `[${obj.timestamp}]`}
									</span>
								</p>
							</div>
						);
					})}
				</Carousel>
			) : (
				<>
					<div className='overflow-hidden w-full h-72 rounded-xl'>
						<img className='object-cover h-full w-full' src={cookingImg} alt='cooking food'/>
					</div>
					<div className='flex items-center justify-center flex-grow font-open-sans font-semibold text-lg text-black items-center'>
							<p>
								{props.active
									? 'Your order is being cooked'
									: 'Your order has been cooked'}
							</p>
					</div>
				</>
			)}
		</div>
	);
	return stepStatus;
};
export default AcceptedSteps;
