import { combineReducers } from 'redux';

import { client } from './client.reducers';
import { auth } from './auth.reducers';
import { store } from './store.reducers';
import { cart } from './cart.reducers';
import { menu } from './menu.reducers';
// import { modal } from './modal.reducers';
import { order } from './order.reducers';
import { customization } from './customization.reducers';
import { notification } from './notification.reducers';
// import { filters } from './filter.reducers';
import { feedback } from './feedback.reducers';
import { orderTrack } from './orderTrack.reducers';
import { modal } from './modal.reducers';
import { subscription } from './subscription.reducers';
import { credit } from './credit.reducers';

const rootReducer = combineReducers({
	client,
	store,
	auth,
	menu,
	cart,
	orderInfo: order,
	customization,
	notification,
	orderTrack,
	modal,
	feedback,
	subscription,
	credit 

});

export default rootReducer;
