import { subscriptionConstants } from '../Constants';

const initialState = {
	orderId: '',
	orderStatus: '',
	stages: [100, 200, 300, 400],
	items: {},
	riderDetails: null,
	orderType: 'DELIVERY',
	placedAt: '',
	orderValue: '',
	isPaymentCOD: true,
	invoice: null,
	totalDiscount: '',
	deliveryCharges: '',
	packagingCharges: '',
	orderCancellationNumber: '',
	taxCharges: 0,
	orderRating: 0,
	deliveryRating: 0,
	paymentStatus: '',
	pastOrders: [],
	subscriptionIndex: '',
	isLoading: false,
	subscriptionDetail: {
		items: [],
		createdDate : '',
		status: '',
		scheduled: {
			time: '',
			daysOfWeek: '',
			scheduledTo: ``,
			scheduledFrom: ``,
		},
		cancelledDates : []
	},
	completedOrders : {
		orders: [],
		fullFilledMeals : ''
	}
};

export function subscription(state = initialState, action) {
	switch (action.type) {
		case subscriptionConstants.SET_SUBSCRIPTION_ID:
			return {
				...state,
				...action.payload,
			};
		case subscriptionConstants.SUBSCRIPTION_TRACKING_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case subscriptionConstants.SUBSCRIPTION_TRACKING_FAILURE:
			return {
				...state,
				...action.payload,
			};
		case subscriptionConstants.PAST_SUBSCRIPTIONS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case subscriptionConstants.PAST_SUBSCRIPTIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
				pastOrders: action.payload??[],
			};
		case subscriptionConstants.PAST_SUBSCRIPTIONS_FAILURE:
			return {
				...state,
				isLoading: false,
				pastOrders: [],
				error: action.payload,
			};
		case subscriptionConstants.PAST_SUBSCRIPTION_DETAIL_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case subscriptionConstants.PAST_SUBSCRIPTION_DETAIL_SUCCESS:
			return {
				...state,
				isLoading: false,
				subscriptionDetail: {...action.payload},
			};
		case subscriptionConstants.PAST_SUBSCRIPTION_DETAIL_FAILURE:
			return {
				...state,
				isLoading: false,
				...action.payload,
			};
		case subscriptionConstants.MEAL_CANCELLATION_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case subscriptionConstants.MEAL_CANCELLATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				...action.payload,
			};
		case subscriptionConstants.MEAL_CANCELLATION_FAILURE:
			return {
				...state,
				isLoading: false,
				...action.payload,
			};
		case subscriptionConstants.CANCEL_SUBSCRIPTION_REQUEST:
			return {
				...state,
				isLoading: true,
				...action.payload,
			};
		case subscriptionConstants.CANCEL_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				isLoading: false,
				...action.payload,
			};
		case subscriptionConstants.CANCEL_SUBSCRIPTION_FAILURE:
			return {
				...state,
				isLoading: false,
			};
			case subscriptionConstants.COMPLETED_MEALS_REQUEST:
				return {
					...state,
					isLoading: true,
					...action.payload,
				};
			case subscriptionConstants.COMPLETED_MEALS_SUCCESS:
				return {
					...state,
					isLoading: false,
					completedOrders : {...action.payload},
				};
			case subscriptionConstants.COMPLETED_MEALS_FAILURE:
				return {
					...state,
					isLoading: false,
				};
		default:
			return state;
	}
}
