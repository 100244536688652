import { updateLocalStorage } from "../../Functions/CartFunctions";
import { cartConstants } from "../Constants";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { getCustomizationTotal } from "../Services";
import toast from "react-hot-toast";

// import { clientActions } from './notifications.actions';

function updateCart(payload) {
  // console.log(payload);
  return { type: cartConstants.CART_UPDATE, payload };
}

function clearCart() {
  return { type: cartConstants.CART_CLEAR };
}

const calculateTotalAmount = (value, qty, item) => {
  let amt,
    totalAmount = 0;
  amt = amt + value;
  let customizationTotal = getCustomizationTotal(item) * qty;
  totalAmount = totalAmount + amt + customizationTotal;
  return amt + customizationTotal;
};

const getPrice = (item) => {
  let calculatedTotalAmount = calculateTotalAmount(
    item.selectedVariant.discountedPrice,
    item.quantity,
    item
  );

  if (item.selectedVariant.discountedPrice) {
    return calculatedTotalAmount;
  } else {
    return 0;
  }
};
const compareNums = (firstNum, secondNum) => {
  const key1 = firstNum.id;
  const key2 = secondNum.id;
  return key1 > key2;
};
// adds into cart
export const compareAndAddToCart = (foodItem, amt, type) => {
  return (dispatch, getState) => {
    const cart = cloneDeep(getState().cart);
    let foodObj = {
      extraData: { ...foodItem },
      productId: foodItem.productId,
      name: foodItem.name,
      selectedVariant: foodItem.selectedVariant,
      variants: foodItem.variants,
      discountedPrice: foodItem.selectedVariant.discountedPrice
        ? foodItem.selectedVariant.discountedPrice
        : null,
      price: foodItem.selectedVariant.price,
      appliedCustomization: foodItem.appliedCustomization,
      finalCustomisation: foodItem.finalCustomisation,
      isCMSEnabled: foodItem.isCMSEnabled,
      isCookingEnabled: foodItem.isCookingEnabled,
      isQCEnabled: foodItem.isQCEnabled,
      isIMSEnabled: foodItem.isIMSEnabled,
      tax: foodItem.tax,
      brandId: foodItem.brandId,
      categoryId: foodItem.categoryId,
      productType: foodItem.productType,
      maxQty: foodItem?.maxQty,
      taxIncluded: foodItem?.taxIncluded ?? false,
    };
    // console.log(foodItem.appliedCustomization)
    // const appliedCustomization = {
    // 	'to': foodItem.appliedCustomization[Object.keys(foodItem.appliedCustomization)[0]].to.sort(function compareNums(firstObj,secondObj){
    // 		const key1 = firstObj.id
    // 		const key2 = secondObj.id
    // 		if(key1<key2) return -1;
    // 		return key1>key2;
    // 	}),
    // 	'from': foodItem.appliedCustomization[Object.keys(foodItem.appliedCustomization)[0]].from.sort(function compareNums(firstObj,secondObj){
    // 		const key1 = firstObj.id
    // 		const key2 = secondObj.id
    // 		if(key1<key2) return -1;
    // 		return key1>key2;})
    // }
    // console.log(foodObj, 'foodObj')
    let tempCart = cart;
    let oldItem = false;

    tempCart.forEach((item, index) => {
      if (item.productId === foodItem.productId && item?.selectedVariant?.id === foodItem?.selectedVariant?.id) {
        let cartObj = {
          extraData: { ...item },
          productId: item.productId,
          name: item.name,
          selectedVariant: item.selectedVariant,
          variants: item.variants,
          discountedPrice: foodItem.selectedVariant.discountedPrice
            ? foodItem.selectedVariant.discountedPrice +
              getCustomizationTotal(foodItem)
            : null,
          price: item.selectedVariant.price + getCustomizationTotal(foodItem),
          appliedCustomization: item.appliedCustomization,
          finalCustomisation: item.finalCustomisation,
          isCMSEnabled: item.isCMSEnabled,
          isCookingEnabled: item.isCookingEnabled,
          isQCEnabled: item.isQCEnabled,
          isIMSEnabled: item.isIMSEnabled,
          tax: item.tax,
          brandId: item.brandId,
          categoryId: item.categoryId,
          productType: foodItem.productType,
          maxQty: foodItem?.maxQty,
          taxIncluded: foodItem?.taxIncluded ?? false,
        };
        // compare the cart food item with newLy added item
        // if (isEqual(cartObj, foodObj)) {
        oldItem = true;
        // updating quantity of item already stored
        tempCart[index].quantity = tempCart[index].quantity + amt;
        dispatch(updateCart([...JSON.parse(JSON.stringify(tempCart))]));
        updateLocalStorage("cart", JSON.stringify(tempCart));
        let localStoreOrderInformation = JSON.parse(
          localStorage.getItem("orderInfo")
        );
        localStoreOrderInformation = {
          ...localStoreOrderInformation,
          orderType: type,
        };
        updateLocalStorage(
          "orderInfo",
          JSON.stringify(localStoreOrderInformation)
        );
        // }
      }
    });

    if (!oldItem) {
      // pushing of new food item to cart as new entry
      foodObj.quantity = amt;
      let temp = [...cart, foodObj];
      dispatch(updateCart([...JSON.parse(JSON.stringify(temp))]));
      updateLocalStorage("cart", JSON.stringify(temp));
      let localStoreOrderInformation = JSON.parse(
        localStorage.getItem("orderInfo")
      );
      localStoreOrderInformation = {
        ...localStoreOrderInformation,
        orderType: type,
      };
      updateLocalStorage(
        "orderInfo",
        JSON.stringify(localStoreOrderInformation)
      );
    }
  };
};
// removes from cart
export const compareAndRemoveFromCart = (foodItem, amt) => {
  return (dispatch, getState) => {
    const cart = getState().cart;
    // food object to compare
    let foodObj = {
      productId: foodItem.productId,
      name: foodItem.name,
      selectedVariant: foodItem.selectedVariant,
      appliedCustomization: foodItem.appliedCustomization,
      variants: foodItem.variants,
    };

    let tempCart = cart.slice();
    for (let index = tempCart.length - 1; index >= 0; index--) {
      if (tempCart[index].productId === foodObj.productId) {
        // compare the cart food item with newLy added item
        if (tempCart[index].quantity > amt) {
          // updating quantity of item already stored
          tempCart[index].quantity = tempCart[index].quantity - amt;
          dispatch(updateCart([...JSON.parse(JSON.stringify(tempCart))]));
          updateLocalStorage("cart", JSON.stringify(tempCart));
          break;
        } else {
          tempCart.splice(index, 1);
          dispatch(updateCart([...JSON.parse(JSON.stringify(tempCart))]));
          updateLocalStorage("cart", JSON.stringify(tempCart));
          break;
        }
      }
    }
  };
};
// add to cart using index (used from order card)
export const addToCartByIndex = (index, amt) => {
  return (dispatch, getState) => {
    const cart = getState().cart;
    const subTypeName =
      cart[index]?.extraData?.subType?.subCategoryName?.split("-")[0];
    if(cart[index]?.extraData?.isSingleMeals){
      toast.error(`You can add maximum 1 ${subTypeName}`);
      return;
    }
    cart[index].quantity = cart[index].quantity + amt;
    dispatch(updateCart([...JSON.parse(JSON.stringify(cart))]));
    updateLocalStorage("cart", JSON.stringify(cart));
  };
};
// removes from cart using index (used from order card)
export const removeFromCartByIndex = (index, amt) => {
  return (dispatch, getState) => {
    const cart = getState().cart;

    let qty = cart[index].quantity;
    if (qty > amt) {
      cart[index].quantity = cart[index].quantity - amt;
    } else {
      cart.splice(index, 1);
    }
    dispatch(updateCart([...JSON.parse(JSON.stringify(cart))]));
    updateLocalStorage("cart", JSON.stringify(cart));
  };
};

export const updateCartStore = (cart) => {
  return (dispatch) => {
    dispatch(updateCart(cart));
    updateLocalStorage("cart", JSON.stringify(cart));
  };
};

export const clearCartStore = () => {
  return (dispatch) => {
    dispatch(clearCart());
    updateLocalStorage("cart", JSON.stringify([]));
  };
};

export const cartActions = {
  compareAndAddToCart,
  compareAndRemoveFromCart,
  addToCartByIndex,
  removeFromCartByIndex,
  updateCartStore,
  clearCartStore,
};
