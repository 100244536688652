/** @format */

import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cartActions, modalActions, orderActions } from "../../Redux/Actions";
import React, { useEffect, Fragment, useState } from "react";
import SearchIcon from "../../Assets/Icons/003-loupe.svg";
import RemoveIcon from "../../Assets/Icons/remove-sign.svg";
import { addAccordingToType } from "../../Functions/CartFunctions";
import { storeClosedOrNot } from "../../Functions/helpersFunctions";
import CustomizationSlider from "../../Containers/FoodDetail/CustomizationSlider";
import SideNav from "../SideNav/SideNav";

const cellSno = "font-caveat col-span-1 border-r text-md font-semibold pt-4";
const cellOrderDetails =
  "font-caveat col-span-5 border-r text-md font-semibold pt-4  px-3";
const cellQty =
  "col-span-3 border-r text-md font-semibold pt-4  flex justify-center";
const cellAmount =
  "font-caveat col-span-3 text-md font-semibold pt-4 text-center ";

const customCellOrderDetails =
  "font-caveat col-start-2 col-span-5 border-r border-l text-md font-semibold  py-1 px-6 text-8B8B8B";
const customCellAmount =
  "font-caveat col-start-10 col-span-3   border-l text-md font-semibold  text-center ";
const initialState = {
  searchQuery: "",
  searchResults: [],
  showResults: false,
};
export default function Search({
  products,
  isCustomizationsVisible,
  showCustomization,
  availableProducts,
  showSearch,
  setShowSearch,
  newQuery,
  setQuery,
}) {
  const dispatch = useDispatch();
  const { menu, cart, orderInfo, customization, store } = useSelector(
    (state) => state
  );
  const [itemCount, setItemCount] = useState(0);

  const [handlestate, sethandleState] = useState({
    searchQuery: "",
    searchResults: [],
    showResults: false,
  });
  const { searchQuery, searchResults, showResults } = handlestate;
  const handleSubmit = (event) => {
    event.preventDefault();
    // if (searchQuery.length === 0) {
    //   return;
    // }
    // sethandleState({
    //   ...handlestate,
    //   searchQuery: "",
    //   showResults: false,
    // });
  };
  useEffect(() => {
    sethandleState({
      ...initialState,
    });
  }, [showSearch]);
  const handleInput = (event = null) => {
    let query = "";
    if (event) query = event.target.value;
    else query = searchQuery;
    let searchResults = [];
    if (query.length >= 3) {
      searchResults = Object.keys(availableProducts).reduce((acc, curr) => {
        const product = products[curr];
        product.productId = curr;
        if (product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
          acc = [...acc, product];
        }
        return acc;
      }, []);
    }
    if (query.length >= 1) {
      setShowSearch(true);
    } else if (query.length === 0) {
      setShowSearch(false);
    }
    setQuery(query);
    sethandleState({
      ...handlestate,
      searchQuery: query,
      searchResults,
      showResults: false,
    });
  };

  const handleFocus = () => {
    sethandleState({
      ...handlestate,
      showResults: false,
    });
    // setShowSearch(true);
  };

  const hideSearchResults = () => {
    sethandleState({
      ...handlestate,
      showResults: false,
    });
  };
  let totalAmount = 0;

  const calculateTotalAmount = (value, qty, item) => {
    let amt = 0;
    amt = amt + value * qty;
    let customizationTotal = getCustomizationTotal(item, qty);
    totalAmount = totalAmount + amt + customizationTotal;
    return amt + customizationTotal;
  };
  const addToCart = (productId) => {
    // if (cart.length === 0) {
    //   dispatch(orderActions.updateOrderStore({ orderType: orderType }));
    // }
    // Add item to cart
    // debugger
    if (storeClosedOrNot(orderInfo, store)) {
      return;
    }
    if (menu.products[productId]["productType"] == "COMBO") {
      dispatch(modalActions.toggleComboModal(true, { productId }));
      return;
    } else if (
      menu.products[productId]["isCustomizable"] &&
      showCustomization
    ) {
      showCustomization(productId, {}, -1);
      return;
    }
    addAccordingToType(
      menu.products,
      productId,
      customization,
      orderInfo.orderType,
      itemCount,
      setItemCount,
      dispatch,
      orderInfo
    );
  };

  const addItem = (index) => {
    if (orderInfo.orderType === "bulk") {
      dispatch(cartActions.addToCartByIndex(index, 1));
    } else if (orderInfo.orderType === "schedule") {
      dispatch(cartActions.addToCartByIndex(index, 1));
    } else {
      dispatch(cartActions.addToCartByIndex(index, 1));
    }
  };

  const removeItem = (index, qty) => {
    if (orderInfo.orderType === "bulk") {
      if (qty === 10) {
        dispatch(cartActions.removeFromCartByIndex(index, 10));
      } else {
        dispatch(cartActions.removeFromCartByIndex(index, 1));
      }
    } else if (orderInfo.orderType === "schedule") {
      dispatch(cartActions.removeFromCartByIndex(index, 1));
    } else {
      dispatch(cartActions.removeFromCartByIndex(index, 1));
    }
  };

  const getPrice = (item) => {
    let calculatedTotalAmount = calculateTotalAmount(
      item.selectedVariant.discountedPrice
        ? item.selectedVariant.discountedPrice
        : item.selectedVariant.price,
      item.quantity,
      item
    );
    return calculatedTotalAmount;
  };

  const getCustomizationTotal = (item, qty) => {
    let total = 0;
    // debugger
    Object.keys(item.finalCustomisation.addedItems).forEach((itemkey) => {
      item.appliedCustomization[itemkey].forEach((option) => {
        total = total + option.price[item.selectedVariant.id];
      });
    });

    Object.keys(item.finalCustomisation.replacedItems).forEach((processId) => {
      item.appliedCustomization[processId]["to"].forEach((option) => {
        for (const items of item.finalCustomisation.replacedItems[processId][
          "to"
        ]) {
          if (option.id == items) {
            total = total + option.price[item.selectedVariant.id];
          }
        }
      });
    });

    Object.keys(item.finalCustomisation.replacedItems).forEach((processId) => {
      item.appliedCustomization[processId]["from"].forEach((option) => {
        for (const items of item.finalCustomisation.replacedItems[processId][
          "from"
        ]) {
          if (option.id == items) {
            total = total - option.price[item.selectedVariant.id];
          }
        }
      });
    });
    return total * qty;
  };

  return (
    <div
      className="relative w-2/4 border-D4D5D9 border rounded items-center"
      onMouseLeave={hideSearchResults}
    >
      <div className="flex w-full h-full items-center" onSubmit={handleSubmit}>
        <input
          className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
          onInput={handleInput}
          value={newQuery}
          onFocus={handleFocus}
          placeholder="Search for Dishes"
        />
        <div className="flex items-center pr-4 pl-2 cursor-pointer">
          <button
            type="submit"
            onClick={() => {
              if (showSearch) {
                sethandleState({
                  ...handlestate,
                  searchQuery: "",
                  showResults: false,
                });
                setQuery("");
                setShowSearch(false);
              }
            }}
          >
            <img
              className="h-4 w-auto"
              src={showSearch ? RemoveIcon : SearchIcon}
              alt="search"
            />
          </button>
        </div>
      </div>
      {searchQuery.length >= 3 && showResults && (
        <div className="absolute border bg-white w-full py-2 px-3 max-h-96 overflow-y-auto">
          {searchResults.length > 0 ? (
            searchResults.map((result, index) => {
              const productId = result.productId;
              let product = result;
              const productIdx = cart.findIndex(
                (x) => x.productId === productId
              );
              if (productIdx >= 0) {
                product = cart[productIdx];
                product = {
                  ...product,
                  displayVariants: result.displayVariants,
                  variants: result.variants,
                  selectedVariant: result.selectedVariant,
                };
              }
              return (
                <div className="grid grid-cols-12 lg:max-h-96 lg:overflow-y-auto bgWhiteScrollbar">
                  <Fragment key={index}>
                    {/* {console.log("index",index)} */}
                    <div className={cellOrderDetails}>
                      {result.name}({result.selectedVariant.displayName})
                    </div>
                    <div className={cellQty}>
                      {productIdx >= 0 ? (
                        <div className="flex h-7 items-center border border-E6E6E6 space-x-1 px-1">
                          <ion-icon
                            onClick={() =>
                              removeItem(productIdx, product.quantity)
                            }
                            style={{
                              color: "#8593FF",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            name="remove"
                          ></ion-icon>
                          <div className="text-xs">{product.quantity}</div>
                          <ion-icon
                            style={{
                              color: "#8593FF",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() => addItem(productIdx)}
                            name="add"
                          ></ion-icon>
                        </div>
                      ) : result.isProductAvailable ? (
                        <div
                          className={`bg-no-repeat ${
                            storeClosedOrNot(orderInfo, store)
                              ? "bg-707070"
                              : "bg-8593FF hover:bg-indigo-500"
                          } rounded text-center not-italic font-semibold font-open-sans text-xs w-3/4 xl:text-xs text-FFFFFF py-1 xl:py-2 px-2 xl:px-5 cursor-pointer `}
                          onClick={() => addToCart(product.productId)}
                        >
                          Add Item
                        </div>
                      ) : (
                        <div className="bg-no-repeat bg-707070 rounded text-center not-italic font-semibold font-open-sans text-xs w-3/4 xl:text-xs text-FFFFFF py-1 xl:py-2 px-2 xl:px-5 cursor-pointer  hover:bg-indigo-500">
                          Item Out Of Stock
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        color:
                          result.selectedVariant.discountedPrice !== "Free"
                            ? "black"
                            : "#7FB069",
                      }}
                      className={cellAmount}
                    >
                      &#x20B9;{" "}
                      {productIdx >= 0
                        ? getPrice(product)
                        : result.selectedVariant.price}
                    </div>
                  </Fragment>
                </div>
              );
            })
          ) : (
            <> No Items Found </>
          )}
        </div>
      )}
    </div>
  );
}
