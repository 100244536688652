import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { modalActions } from "../../Redux/Actions";
import OtpCard from "../OtpCard/OtpCard";
import closeIcon from "../../Assets/Icons/closeIcon.svg";
import toast from "react-hot-toast";
import styles from "./ChangePinModal.module.css";
import { loginService } from "../../Redux/Services";

function ChangePinModal(props) {
  const { shouldShow, toggleChangePinModal } = props;
  const dispatch = useDispatch();
  const [oldPin, setOldPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [emptyInput, setEmptyInput] = useState(false);

  useEffect(() => {
    setEmptyInput(false);
  }, []);

  const handleClose = () => {
    dispatch(toggleChangePinModal(false));
    setOldPin("");
    setNewPin("");
    setConfirmPin("");
    setEmptyInput(true);
    document.body.classList.remove("stop-scrolling");
  };

  const handleChangePin = async () => {
    if (!oldPin) toast.error("Please enter Old Passcode");
    if (oldPin && !newPin) toast.error("Please enter New Passcode");
    if (newPin && !confirmPin) toast.error("Please enter Confirm Passcode");

    if (oldPin && newPin && confirmPin) {
      const req = {
        data: {
          oldPin,
          newPin,
          confirmNewPin: confirmPin,
        },
      };
      try {
        const response = await loginService.changePin(req);
        if (response?.statusCode === 200) {
          toast.success(response?.message ?? "Successfully Passcode Changed");
          handleClose();
        } else {
          toast.error(response?.errorMessage ?? "Some error occured");
        }
      } catch (error) {
        toast.error(error.message ?? error ?? "Some Error occured");
      }
    }
  };

  return (
    <div className={`${styles.root} ${shouldShow ? "flex" : "hidden"}`}>
      <div className={`space-y-4 ${styles["modal-wrapper"]} `}>
        <div className={styles.close} onClick={handleClose}>
          <img src={closeIcon} alt="closeModal" />
        </div>
        <h1>CHANGE PASSCODE</h1>
        <div
          className={`flex items-center justify-between gap-1 ${styles["pin-input"]}`}
        >
          <div className="font-bold text-left text-base text-212121">
            Old Passcode:
          </div>
          <OtpCard
            checkOtpHandler={setOldPin}
            otpType={"oldPin"}
            hideConfirm
            shouldEmpty={emptyInput}
          />
        </div>

        <div
          className={`flex items-center justify-between gap-1 ${styles["pin-input"]}`}
        >
          <div className="font-bold text-left text-base text-212121">
            New Passcode:
          </div>
          <OtpCard
            checkOtpHandler={setNewPin}
            otpType={"newPin"}
            hideConfirm
            shouldEmpty={emptyInput}
          />
        </div>

        <div
          className={`flex items-center justify-between gap-1 ${styles["pin-input"]}`}
        >
          <div className="font-bold text-left text-base text-212121">
            Confirm Passcode:
          </div>
          <OtpCard
            checkOtpHandler={setConfirmPin}
            otpType={"confirmPin"}
            hideConfirm
            shouldEmpty={emptyInput}
          />
        </div>
        <button
          className="bg-8593FF bg-no-repeat rounded py-4 font-semibold text-center text-white text-base cursor-pointer hover:bg-indigo-500"
          onClick={handleChangePin}
        >
          CONFIRM
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const shouldShow = state?.modal?.showChangePinModal;
  return {
    shouldShow,
  };
};

const mapDispatchToProps = {
  toggleChangePinModal: modalActions?.toggleChangePinModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePinModal);
