import {
  CookingDetails,
  OrderDetails,
  OrderDispatch,
  QualityCheck,
  Delivered,
} from "./TrackData";


// return component according to stage 
export const getComponent = (stage, props) => {
  // console.log(props,' 1props')
  switch (stage) {
    case 100:
      return (
        <OrderDetails
          key={stage}
          orderId={props.orderId}
          stageName={props.stageName}
          name={props.name}
          serve={props.serve}
          ingredients={props.ingredients}
          note={props.note}
          chef={props.chef}
          cookingTime={props.cookingTime}
          image={props.image}
          selectedVariant={props.selectedVariant}
        />
      );

    case 200:
      return (
        <CookingDetails
          key={stage}
          itemStage={props.key}
          stageName={props.stageName}
          cookingData={props.cookingData}
        />
      );
    case 400:
      return (
        <QualityCheck
          key={stage}
          stageName={props.stageName}
          temp={props.temp}
          weight={props.weight}
          image={props.image}
          finishTime={props.finishTime}
          selectedVariant={props.selectedVariant}
          nutrition={props?.nutrition}
        />
      );
    case 500:
      return (
        <OrderDispatch
          key={stage}
          stageName={props.stageName}
          driverName={props.driverName}
          driverNumber={props.driverNumber}
          driverRating={props.driverRating}
          foodItems={props.foodItems}
          mapImage={props.mapImage}
          arrivingTime={props.arrivingTime}
          longitude={props.longitude}
          latitude={props.latitude}
        />
      );
    case 600:
      return (
        <OrderDispatch
          key={stage}
          stageName={props.stageName}
          driverName={props.driverName}
          driverNumber={props.driverNumber}
          driverRating={props.driverRating}
          foodItems={props.foodItems}
          mapImage={props.mapImage}
          arrivingTime={props.arrivingTime}
          longitude={props.longitude}
          latitude={props.latitude}
        />
      );
    case 700:
      return (
        <Delivered
          key={stage}
          foodItems={props.foodItems}
          stageName={props.stageName}
          deliverTime={props.deliverTime}
          driverName={props.driverName}
          driverNumber={props.driverName}
          driverRating={props.driverRating}
          invoice={props.invoice}
        />
      );
    default:
      return;
  }
};
