import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
// import Map from '../../Components/Map/MapRoot'
import MapIcon from "../../Assets/Icons/Map.svg";
import ChangeLocationModal from "../../Components/Change Location Modal/ChangeLocationModal";
import Maps from "../../Components/Maps/Maps";
import { useWindowDimensions } from "../../Functions/utils";
import { authActions } from "../../Redux/Actions/auth.actions";
import urls from "../../urls";

export default function AddressSlider(props) {
  const { auth, orderInfo } = useSelector((state) => state);
  const dispatch = useDispatch();
  console.log(props);
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState(
    auth.selectedUserAddress.address
  );
  const { mapId, type } = props;
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [pincode, setPincode] = useState();
  const [email, setEmail] = useState();
  const [userName, setUserName] = useState();

  const [latlng, setLatLng] = useState(
    cloneDeep(auth.selectedUserAddress.location)
  );

  const authAddresses = cloneDeep(auth?.addresses ?? []);

  useEffect(() => {
    setAddressLine1(props.addressLine1 || "");
    // setAddressLine2(props.addressLine2);
    setCity(props.city || "");
    setState(props.state || "");
    setPincode(props.pincode || "");
    setLatLng(
      props.type === "EDIT"
        ? { lat: props.lat, lng: props.lng }
        : cloneDeep(auth.selectedUserAddress.location)
    );
    setAddressLine2(
      props.type === "EDIT"
        ? props.addressLine2
        : cloneDeep(auth.selectedUserAddress.address)
    );
    // Set the actual data recieved from backend
    setEmail(props.email);
    setUserName(props?.name ?? auth?.name);
  }, [props]);

  const clearState = () => {
    setAddressLine1("");
    setAddressLine2("");
    setCity("");
    setState("");
    setPincode("");
    setEmail("");
    setUserName("");
  };

  const changeHandler = (name, value) => {
    if (name === "addressLine1") setAddressLine1(value);
    else if (name === "addressLine2") setAddressLine2(value);
    else if (name === "city") setCity(value);
    else if (name === "state") setState(value);
    else if (name === "pincode") setPincode(value);
    else if (name === "userName") setUserName(value);
    else if (name === "email") setEmail(value);
  };

  const handleMarkerDrag = (pos) => {
    fetch(
      `${urls.GEOCODE_API_BASE_URL}${pos.lat},${pos.lng}&key=${urls.GEOCODE_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        const results = data.results;
        const topResult = results[0];
        setAddressLine2(topResult.formatted_address);
        setLatLng(topResult.geometry.location);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!addressLine1 || !email) {
      toast.error("Fill all the fields");
      return;
    }

    const emailRegax =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegax.test(String(email).toLowerCase())) {
      toast.error("Enter a valid Email");
      return;
    }
    // if (props.type === 'EDIT') {
    // 	authAddresses[props.index] = {
    // 		userName,
    // 		addressLine1,
    // 		addressLine2,
    // 		email,
    // 		state,
    // 		lat:latlng.lat,
    // 		long:latlng.lng
    // 	};
    // } else {
    // 	authAddresses.unshift({
    // 		userName,
    // 		addressLine1,
    // 		addressLine2,
    // 		city,
    // 		email,
    // 		state,
    // 		pincode,
    // 		lat:latlng.lat,
    // 		long:latlng.lng
    // 	});
    // }
    dispatch(authActions.updateUser({ address: addressLine2 }));
    dispatch(
      authActions.addAddress({
        name: userName,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        email: email,
        lat: latlng.lat,
        long: latlng.lng,
      })
    );

    // cleaning up state
    clearState();
    document.body.classList.remove("stop-scrolling");
    props.close();
  };

  const { width, height } = useWindowDimensions();

  return (
    <div className="font-open-sans w-9/12 sm:w-7/12 m-auto my-4">
      <form>
        <div className="flex justify-between mt-16">
          <div className="font-bold text-xl">Set Delivery Location</div>
          <img src={MapIcon} className="w-24 h-auto" alt="map" />
        </div>
        {/* Google Map  */}
        <div className=" w-full mt-10">
          <Maps
            handleMarkerDrag={handleMarkerDrag}
            coords={latlng}
            mapId={mapId}
            width={width > 600 ? "450px" : "350px"}
            height={width > 600 ? "400px" : "300px"}
          />
        </div>
        <div className="mt-5 h-20 py-2 px-6 border border-8593FF rounded">
          <div className="text-8B8B8B text-xs">Address</div>
          <input
            className="focus:outline-none mt-2 text-base font-semibold w-full"
            onChange={(e) => changeHandler("addressLine2", e.target.value)}
            value={addressLine2}
            type="text"
            name="addressLine2"
            disabled
          />
        </div>
        <div className="mt-5 h-20 py-2 px-6 border border-8593FF rounded">
          <div className="text-8B8B8B text-xs">User Name</div>
          <input
            className="focus:outline-none mt-2 text-base font-semibold w-full"
            onChange={(e) => changeHandler("userName", e.target.value)}
            value={userName}
            type="text"
            name="userName"
          />
        </div>
        <div className="mt-5 h-20 py-2 px-6 border border-8593FF rounded">
          <div className="text-8B8B8B text-xs">Email</div>
          <input
            className="focus:outline-none mt-2 text-base font-semibold w-full"
            onChange={(e) => changeHandler("email", e.target.value)}
            value={email}
            type="text"
            name="email"
          />
        </div>
        <div className="mt-5 h-20 py-2 px-6 border border-8593FF rounded">
          <div className="text-8B8B8B text-xs">Flat No/ House No :</div>
          <input
            className="focus:outline-none mt-2 text-base font-semibold w-full"
            onChange={(e) => changeHandler("addressLine1", e.target.value)}
            value={addressLine1}
            type="text"
            name="addressLine1"
          />
        </div>
        <button
          onClick={onSubmit}
          className="mt-10 h-16 w-full bg-8593FF rounded font-semibold font-open-sans text-xs text-FFFFFF capitalize  cursor-pointer  hover:bg-indigo-500"
        >
          {props.type !== "EDIT" ? "ADD AS NEW ADDRESS" : "SAVE ADDRESS"}
        </button>
      </form>
    </div>
  );
}
