import { Close } from "@material-ui/icons";
import { Button } from "@mui/material";
import React, { useEffect, useRef } from "react";
import styles from "./SearchBar.module.css";

const Searchbar = (props) => {
  const { query, placeholder, showSearch, setShowSearch } = props;

  const searchRef = useRef(null);

  const handleSearch = (e) => {
    const { value } = e.target;
    props.handleSearch(value);
  };

  const closeSearch = () => {
    props.handleSearch("");
    setShowSearch(false);
  };

  useEffect(() => {
    return () => {
      props.handleSearch("");
      setShowSearch(false);
    };
  }, []);

  useEffect(() => {
    console.log(showSearch, searchRef, searchRef.current, "show search");
    if (showSearch && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showSearch]);

  return (
    <div
      className={`${styles.root} ${showSearch ? styles.show : ""} ${
        styles.boxShadow
      } bg-D17B69`}
    >
      <div className={`${styles["input-container"]}  bg-white`}>
        <input
          value={query}
          onInput={handleSearch}
          name="search"
          className={`${styles.input} ${styles.boxShadow}`}
          placeholder={placeholder}
					ref={searchRef}
        />
        <div
          className={styles.clear}
          onClick={() => {
            props.handleSearch("");
          }}
        >
          <Button
            variant="text"
            style={{
              fontWeight: "500",
              color: "white",
              // padding: "10px 0px",
            }}
          >
            Clear
          </Button>
        </div>
      </div>
      <div className={styles["close-container"]}>
        <Close className={styles.close} onClick={closeSearch} />
      </div>
    </div>
  );
};

Searchbar.defaultProps = {
  query: "",
  placeholder: "Search by Order Id or Address.",
};

export default Searchbar;
