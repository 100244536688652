import { CircularProgress, Modal } from "@material-ui/core";
import { CheckCircleOutline, Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect, useDispatch, useSelector } from "react-redux";
import { useWindowDimensions } from "../../Functions/utils";
import { authActions, modalActions, orderActions } from "../../Redux/Actions";
// import cancelOrderImg from "../../../Images/cancel-order.gif";
import Maps from "../Maps/Maps";
import "./ChangeLocation.scss";

// const DEFAULT_COORDS = {
// 	lat: 28.6523392,
// 	lng: 77.185024,
// };

const GEOCODE_API_BASE_URL =
  "https://maps.googleapis.com/maps/api/geocode/json?latlng=";

function ChangeLocationModal(props) {
  const dispatch = useDispatch();
  const { DEFAULT_COORDS, restaurantId, setValue } = props;
  const { orderInfo, modal } = useSelector((state) => state);
  const [addresses, setAddresses] = useState([]);
  const [coords, setCoords] = useState({ ...DEFAULT_COORDS });
  const [selectedAddress, setSelectedAddress] = useState(-1);
  useEffect(() => {
    setCoords(DEFAULT_COORDS);
    handleMarkerDrag(DEFAULT_COORDS);
  }, [DEFAULT_COORDS, modal.showChangeLocationModal]);
  const API_KEY = "AIzaSyDSFW1Q6S3ghhiuDbhCLgaFcVuSfGr05GQ";
  const handleMarkerDrag = (pos) => {
    setCoords(pos);
    setSelectedAddress(-1);
    fetch(`${GEOCODE_API_BASE_URL}${pos.lat},${pos.lng}&key=${API_KEY}`)
      .then((response) => response.json())
      .then((data) => {
        const results = data.results;
        const newAddresses = [];
        for (
          let address = 0;
          address < 3 && address < results.length;
          address++
        ) {
          newAddresses.push(results[address]);
        }
        setAddresses(newAddresses);
      });
  };
  const handleClose = () => {
    props.toggleChangeLocationModal(false);
    setAddresses([]);
  };
  const selectAddress = (idx) => {
    return () => {
      setSelectedAddress(parseInt(idx, 10));
    };
  };
  const handleConfirmClick = () => {
    if (selectedAddress === -1) {
      toast.error("Please select an address");
      return;
    }
    const address = addresses[selectedAddress];
    props.findDeliveryStores(address);
    document.body.classList.remove("stop-scrolling");
    dispatch(
      authActions.setOrderAddress({
        address: address.formatted_address,
        location: address.geometry.location,
      })
    );
    setValue(address.formatted_address);
    dispatch(
      authActions.updateUser({
        address: address.formatted_address,
        location: address.geometry.location,
      })
    );
    handleClose();
  };
  const { width, height } = useWindowDimensions();
  const mapModal = (
    <div
      className="modal-container location-modal"
      style={{
        top: width > 600 ? "360px" : "320px",
      }}
    >
      <p className="modal-heading">Select your location</p>
      <div className="modal-body">
        <Maps
          handleMarkerDrag={handleMarkerDrag}
          coords={coords}
          width={width > 600 ? "350px" : "300px"}
          height={width > 600 ? "350px" : "275px"}
        />
        {addresses.map((address, idx) => {
          const isSelected = idx === selectedAddress;
          return (
            <div
              className="address-list-item"
              onClick={selectAddress(idx)}
              data-id={idx}
              data-selected={isSelected}
            >
              <CheckCircleOutline className="address-checked" />
              <div className="addresses">
                <span className="address">{address.formatted_address}</span>
              </div>
            </div>
          );
        })}
      </div>
      <button
        type="button"
        className="confirm-btn btn"
        onClick={handleConfirmClick}
        // disabled = {selectedAddress===-1}
      >
        Confirm
      </button>
    </div>
  );

  return (
    <div
      aria-labelledby="cancel-order-modal"
      aria-describedby="modal for order cancellation"
      className="mt-8"
    >
      {mapModal}
    </div>
  );
}

ChangeLocationModal.defaultProps = {
  isOrderCancellable: false,
  handleConfirmClick: () => {},
  DEFAULT_COORDS: { lat: 28.6523392, lng: 77.185024 },
  restaurantId: "",
  setValue: () => {},
};

function mapStateToProps(state) {
  // const { showChangeLocationModal } = state.modal;
  // const { customerSupport } = state.restaurant;
  // const { orderCancellationNumber } = state.order;
  return {
    // showChangeLocationModal,
    // customerSupport,
    // orderCancellationNumber,
  };
}

const actionCreators = {
  toggleChangeLocationModal: modalActions.toggleChangeLocationModal,
};

export default connect(mapStateToProps, actionCreators)(ChangeLocationModal);
