import { updateLocalStorage } from "../../Functions/CartFunctions";
import { customizationConstants } from "../Constants";

function updateCustomizationStore(customization) {
  return (dispatch) => {
    dispatch(update(customization));
    updateLocalStorage("customization", JSON.stringify(customization));
  };
  function update(payload) {
    return { type: customizationConstants.CUSTOMIZATION_UPDATE, payload };
  }
}

function clearCustomizationStore() {
  return (dispatch) => {
    dispatch(clear());
    updateLocalStorage("customization", JSON.stringify({}));
  };
  function clear() {
    return { type: customizationConstants.CUSTOMIZATION_CLEAR };
  }
}

export const customizationActions = {
  updateCustomizationStore,
  clearCustomizationStore,
};
