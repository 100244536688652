import { Link, useParams } from "react-router-dom";

export default function EventsCard({ image, text, small, buttonText , link}) {
    const {restaurantId}=useParams()
    let width = small ? "w-1/2" : "w-1/3";
    let imagePos = small ? "self-end" : "";
    return (
        <div className="flex w-full h-52 bg-23232C rounded-md justify-center space-x-5 py-8">
            <div className={width}>
                <p className="font-open-sans font-light text-xl text-white">{text}</p>
                <Link  to={`/${restaurantId}/menu-card/${link}`}>
                    <button className="font-open-sans font-semibold mt-10 sm:mt-4 h-12 text-white w-44 text-xs bg-8593FF rounded  hover:bg-gray-700 hover:text-white">{buttonText}</button>
                </Link>
            </div>
            <div className={imagePos}>
                <img alt="event" src={image} className="w-full h-auto" />

            </div>

        </div>
    )
}
