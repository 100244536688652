import React from "react";

function ResendPin(props) {
  const { phone, setPhone, email, setEmail, resendUserPin } = props;

  return (
    <div className="flex flex-col mx-auto space-y-8">
      <div className="space-y-12 font-open-sans">
        <div className="space-y-2">
          <div className="flex items-center justify-between gap-2">
            <div className="font-bold text-left text-base text-212121 ">
              Number
            </div>
            <div className="flex border-b-4 border-7FB069 w-full">
              <input
                type="text"
                value="+91"
                disabled
                className="bg-FFFFFFAD bg-no-repeat border-t border-l border-gray-300 rounded-l h-14 w-16 px-4 py-3"
              />
              <input
                type="number"
                className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-14 w-full px-4 py-3 focus:outline-none"
                value={phone}
                maxLength={10}
                onChange={(e) => {
                  if (e.target.value.length < 11) setPhone(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="font-bold text-center text-e2e2e2">OR</div>

          <div className="flex items-center justify-between gap-8">
            <div className="font-bold text-left text-base text-212121 ">
              Email
            </div>
            <div className="border-b-4 border-7FB069 w-full">
              <input
                type="email"
                className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-14 w-full px-4 py-3 focus:outline-none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your Email "
              />
            </div>
          </div>
        </div>
      </div>
      <button
        className="bg-8593FF bg-no-repeat rounded py-4 font-semibold text-center text-white text-base cursor-pointer hover:bg-indigo-500"
        onClick={resendUserPin}
      >
        CONFIRM
      </button>
    </div>
  );
}

export default ResendPin;
