import PizzaImage from '../../../Assets/Images/PizzaImage.png';
import CancelledOrder from '../../../Assets/Icons/cancelled-order.svg';
import CurrentOrder from '../../../Assets/Icons/current-order.svg';
import { Link, useHistory } from 'react-router-dom';
import DownArrowBlack from '../../../Assets/Icons/DownArrowBlack.png';
import UpArrowBlack from '../../../Assets/Icons/UpArrowBlack.png';
import DropDownMenu from '@mui/material';
import { useState } from 'react';
import OrderCard from '../OrderCard/OrderCard';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { subscriptionActions } from '../../../Redux/Actions';
import ToggleDays from '../../Modal/ToggleDays';
import SubscriptionDaysContainer from '../../SubscriptionDaysContainer/SubscriptionDaysContainer';
import moment from 'moment';


const ScheduledOrderCard = (props) => {
	const { order, index } = props;
	const history = useHistory();
	// type --> past (past Orders) , current ( current orders ) cancelled ( cancelled orders )
	const toggleSection = (stageNo) => {
		let sectionArray = showItems;
		let value = showItems[stageNo - 1];
		sectionArray[stageNo - 1] = !value;
		setShowItems([...sectionArray]);
	};
	const { store, subscription } = useSelector((state) => state);
	const [showItems, setShowItems] = useState([false, false, false, false]);
	const OuterBox = (props) => (
		<div
			className='sm:bg-white sm:px-6 shadow sm:py-4 py-4 cursor-pointer'
			onClick={() => toggleSection(props.stageNo)}
		>
			<div className='flex cursor-pointer justify-between bg-white rounded sm:rounded-none mx-4 px-6 py-4 sm:px-0 sm:py-0 w-full'>
				<div className='font-open-sans font-semibold text-left text-sm sm:text-base text-black'>
					{props.stageName}
				</div>
				<div className='cursor-pointer'>
					{/*   Downward arrow */}
					<img
						src={
							showItems[props.stageNo - 1]
								? UpArrowBlack
								: DownArrowBlack
						}
						alt='down/up arrow'
						className='w-4 h-4 mr-4'
					/>
				</div>
			</div>
			{showItems[props.stageNo - 1] && props.children}
		</div>
	);
	const dispatch = useDispatch();
	const changeDateToWord = (date)=>{
		if(date){
			const convertedDate = date?.split('-')
			const monthName = moment(date, 'YYYY-MM-DD').format('MMM')
			return `${convertedDate[2]}, ${monthName} ${convertedDate[0]}`
		}
	}
	console.log(order.items, 'items')
	return (
		<div className='flex flex-col py-8 border-b border-gray-300 '>
			{/* <img
        src={PizzaImage}
        className='hidden md:block h-32 w-auto  object-cover rounded'
        alt='item'
      /> */}
			<div className='flex flex-row'>
				<div className='flex flex-col basis-7/12 width-full justify-evenly h-full flex-grow font-open-sans' style={{
					width : '100%'
				}}>
					<div
						className={`text-sm text-7FB069 font-semibold`}
					>
						#Subscription id {order.id}
					</div>
					<p className='text-xs sm:text-sm'>
						{order.items.map((item, index) => {
							return index < 3
								? `${item.productName} ${
										index != order.items.length - 1
											? ' , '
											: ''
								  }`
								: null;
						})}
						{order.items.length > 3
							? `....${order.items.length - 3} items more`
							: null}
					</p>
					{/* <div className='text-xs  sm:text-sm '>
						<span className='mr font-semibold'>
							No. Of Meals:{'    '}
						</span>

						<span className=''>
							-
							{/* {order.noOfMeals} */}
						{/* </span> */}
					{/* </div> } */}
						{/* <span className='mr text-gray-500'>
							Order Placed On :
						</span>
						{changeDateToWord(order.createdDate.split(' ')[0])} */}
					
					<div className='text-xs  sm:text-sm font-semibold'>
						&#x20B9; {' '}
						{order.orderValue}
					</div>
					{/* <div className='text-xs  sm:text-sm font-semibold'>
						<span className='text-gray-400'>Meal Time</span>{' '}
						{order.scheduled.time}
					</div> */}
					<div className='text-xs sm:text-sm'>
						{/* <span className='text-red-400 font-semibold'>from : </span>{' '}
						<span>{changeDateToWord(order.scheduled.scheduledFrom)}{' '}</span> */}
						<span className='text-red-400 font-semibold'>Ends On</span>{' '}
						<span>{changeDateToWord(order.scheduled.scheduledTo)}</span>
					</div>
					<div>
						
						{order.status!=='CANCELLED' && order.status!=='FAILED' && order.cancelledDates.length>0 && <><span className='text-green-600 font-semibold text-sm'>
											Cancelled Dates : {' '}
								</span>
								<span className='font-semibold text-xs'>
								{order.cancelledDates.map((currentCancelledDate)=>{
									return `${changeDateToWord(currentCancelledDate.mealDate.split(' ')[0])}; `
								})}
								</span>
								</>}
						{/* <img className='w-10' alt='scheduled' src={CurrentOrder} /> */}
					</div>
				</div>
				<div className=''>
					<div
						className={`flex flex-col justify-around h-full text-center ml-4 text-xs sm:text-sm md:mr-10`}
					>
						{/* {order.status!=='CANCELLED' && order.status!=='FAILED' && <div className='text-sm'>
							<div>
							<span className='font-medium text-xs sm:text-sm'>
								Completed Meals
							</span>
							</div>
							<div>
							<span className='font-medium'>
								{order.completedDays}{' '}
							</span>
							/{' '}
							<span className='font-semibold'>
								{order.noOfMeals}
							</span>
							</div>
						</div>} */}
						{order.status!=='CANCELLED' && order.status!=='FAILED' && <div>
							<span className='font-semibold'>
								Next Scheduled For:{'  '}
							</span>
							<span className='font-normal'>
								{changeDateToWord(order?.nextOrderTime?.split(' ')[0])}
								{' '}
								{/* at{' '}
								{order?.nextOrderTime?.split(' ')[1]} */}
							</span>
						</div>}
						{order.status==='CANCELLED' && order.status!=='FAILED' && <div>
							<span className='font-semibold'>
								Subscription Cancelled
							</span>
						</div>}
						{order.status!=='CANCELLED' && order.status==='FAILED' && <div>
							<span className='font-semibold'>
								Subscription Payment Failed
							</span>
						</div>}
					</div>
				</div>
			</div>
			{/* <div className='py-8 mt-2'>
				<OuterBox stageName='Delivered' stageNo={5}>
					{Object.keys(order.completedOrders ?? {})
						.reverse()
						.map((currentOrderId) => {
							const currentOrder =
								order.completedOrders[currentOrderId];
							return (
								<div className='my-3 px-1'>
									<OrderCard
										order={currentOrder}
										type='past'
									/>
								</div>
							);
						})}
				</OuterBox>
			</div> */}
			<div className='text-xs sm:text-sm flex items-center justify-start '>
							{/* <span className='text-green-600 font-semibold'>
								Selected Days :{' '}
							</span> */}
							<span>
								{/* {order.scheduled.daysOfWeek
									.split(',')
									.map((currentDay) => {
										return <span>{currentDay.substr(0,3)} </span>;
									})} */}
							</span>
							<SubscriptionDaysContainer selectedDays={order.scheduled.daysOfWeek
									.split(',')}/>
						</div>
			{order.status!=='FAILED' && <div className=' cursor-pointer flex space-x-4 sm:space-x-8 items-center mt-2'>
				<Link
					to={`/${store.storeId}/track-subscription/${order.id}`}
					className='text-8593FF text-xs sm:text-sm uppercase font-semibold'
				>
					View Details
				</Link>
			</div>}
			{/* <div className=' cursor-pointer flex space-x-4 sm:space-x-8 items-center mt-2'>
						{(type === 'current' ||
							type === 'past' ||
							type === 'scheduled') && (
							<div
								className='text-8593FF text-xs sm:text-sm uppercase'
								onClick={() =>
									history.push(
										`/${order.storeId}/track-order/${order.orderId}`
									)
								}
							>
								View Details
							</div>
						)}
					</div> */}
		</div>
	);
};
export default ScheduledOrderCard;
