import React from "react";
import Veg from "../../Assets/Images/Veg.svg";
import Nonveg from "../../Assets/Images/Nonveg.png";

const PhoneImageBanner = ({ image, isVeg, name }) => {
  return (
    <div className='relative w-full'>
      <img
        src={image}
        alt='food banner'
        className='object-cover w-full h-44 sm:h-56 md:h-72'
      />
      <div className='flex items-center absolute bottom-4 left-4 z-10 space-x-4'>
        <div className='font-open-sans font-bold text-left text-base text-FFFFFF capitalize'>
          {name}
        </div>
        <div className='w-5 h-5'>
          <img
            src={isVeg ? Veg : Nonveg}
            alt='food type symbol'
            className='w-full h-full'
          />
        </div>
      </div>
      <div className='absolute inset-0 opacity-50 bg-black bg-no-repeat'></div>
    </div>
  );
};

export default PhoneImageBanner;
