import { creditConstants } from "../Constants";

const initialState = {
  balance: null,
  isLoading: true,
  transactions:[]
};

export function credit(state = initialState, action) {
  switch (action.type) {
    case creditConstants.CREDIT_SET:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case creditConstants.CREDIT_PAYMENT_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
    case creditConstants.CREDIT_PAYMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case creditConstants.CREDIT_PAYMENT_FAILURE:
      return {
        isLoading: false,
      };
    case creditConstants.CREDIT_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case creditConstants.CREDIT_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case creditConstants.CREDIT_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
      case creditConstants.CREDIT_FAILURE:
        return {
          ...state,
          isLoading: false,
        }; 
    default:
      return state;
  }
}
