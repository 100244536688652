/** @format */

import React from "react";
import "./Loader.css";

export const Loader = () => {
  return (
    <div className='pan-root'>
      <div className='pan-wrapper'>
        <div class='pan-loader'>
          <div class='loader'></div>
          <div class='pan-container'>
            <div class='pan'></div>
            <div class='handle'></div>
          </div>
          <div class='shadow'></div>
        </div>
      </div>
    </div>
  );
};
