export const storeConstants = {
	STORE_REQUEST: 'STORE_REQUEST',
	STORE_SUCCESS: 'STORE_SUCCESS',
	STORE_FAILURE: 'STORE_FAILURE',

	GET_PROMO_CODES_REQUEST: 'GET_PROMO_CODES_REQUEST',
	GET_PROMO_CODES_SUCCESS: 'GET_PROMO_CODES_SUCCESS',
	GET_PROMO_CODES_FAILURE: 'GET_PROMO_CODES_FAILURE',

	REMOVE_PROMO: 'REMOVE_PROMO',

	SET_STORE_AVILABILITY: 'SET_STORE_AVILABILITY',
	SET_STORE_DELIVERY_CHARGES: 'SET_STORE_DELIVERY_CHARGES',

	SET_PICKUP_STORES: 'SET_PICKUP_STORES',
};
