import { useEffect, useState } from "react";

const ScrollToTop = ({ onClickHandler, icon, scrollYOffset }) => {
  const [isHide, setIsHide] = useState(true);

  // Will control the Scroll to top Icon
  useEffect(() => {
    window.addEventListener("scroll", toggleScrollToTop);
  }, []);

  const toggleScrollToTop = () => {
    window.scrollY > scrollYOffset ? setIsHide(false) : setIsHide(true);
  };

  return (
    <div
      className={`${
        !isHide ? "opacity-100" : "opacity-0"
      } flex items-center justify-center fixed bottom-12 right-6 sm:right-11 md:right-24 bg-121216 rounded-full h-10 w-10 z-20 transition-opacity duration-500`}
      onClick={onClickHandler}>
      <ion-icon
        style={{
          color: "#FFFFFF",
          fontSize: "24px",
          cursor: "pointer",
        }}
        name={icon}></ion-icon>
    </div>
  );
};

export default ScrollToTop;
