import PizzaImage from "../../../Assets/Images/PizzaImage.png";
import CancelledOrder from "../../../Assets/Icons/cancelled-order.svg";
import CurrentOrder from "../../../Assets/Icons/current-order.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isStoreOpen } from "../../../Functions/helpersFunctions";
import { authActions, orderActions } from "../../../Redux/Actions";
import { useEffect } from "react";
import Modal from "../../Modal/Modal";
import { useState } from "react";

export default function OrderCard({ order, type, cancelOrder }) {
  const history = useHistory();
  const { user = {} } = order;
  const dispatch = useDispatch();
  const { store, auth } = useSelector((state) => state);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);

  useEffect(() => {
    if (auth?.redirectTo) {
      dispatch(authActions.redirect(null));
    }
  }, [dispatch]);

  return (
    <>
      <div className="flex sm:space-x-6 py-8 border-b border-gray-300">
        <div className="flex-grow font-open-sans">
          <div className="flex flex-col justify-evenly h-full">
            {user && (
              <div className="text-xs sm:text-sm font-semibold flex">
                {user?.addressLine1 && user?.addressLine1 + ","}
                {user?.addressLine2 && user?.addressLine2 + ","}
                {user?.city}
              </div>
            )}
            <div
              className={`text-xs sm:text-sm ${
                type === "cancelled" || type === "past"
                  ? "text-gray-400"
                  : "text-7FB069"
              } font-semibold`}
            >
              #Order id {order.orderId}
            </div>
            <p className="text-xs sm:text-sm">
              {order.foodItems.map((item, index) => {
                return index < 3
                  ? `${item.name} ${
                      index != order.foodItems.length - 1 ? " , " : ""
                    }`
                  : null;
              })}
              {order.foodItems.length > 3
                ? `....${order.foodItems.length - 3} items more`
                : null}
            </p>
            <div className="text-xs sm:text-sm font-semibold flex">
              &#x20B9; {order.orderValue}&nbsp;
            </div>
            {order?.scheduledAt && (
              <div className=" text-xs sm:text-sm">
                <span className="mr text-gray-500">
                  {type === "scheduled" && "Order Placed On "}
                </span>
                <b>Meal Consumption Date :</b>
                {order?.scheduledAt ?? " -"}
              </div>
            )}
            <div className=" text-xs sm:text-sm">
              <span className="mr text-gray-500">
                {type === "scheduled" && "Order Placed On "}
              </span>
              <b>Ordered Date :</b>
              {order?.placedAt}
            </div>
            {type === "scheduled" && (
              <div className="text-sm">
                <span className="text-red-400">From : </span>{" "}
                {order.startingFrom} <span className="text-red-400">to</span>{" "}
                {order.endsOn}
              </div>
            )}
            <div className=" cursor-pointer flex space-x-4 sm:space-x-4 items-center mt-2">
              {(type === "current" ||
                type === "past" ||
                type === "scheduled") && (
                <div
                  className="text-8593FF text-xs sm:text-sm uppercase"
                  onClick={() =>
                    history.push(
                      `/${order.storeId}/track-order/${order.orderId}`
                    )
                  }
                >
                  View Details
                </div>
              )}
              {type === "current" &&
                (order?.isPaymentCOD ||
                  order?.paymentStatus === "TXN_SUCCESS") &&
                isStoreOpen(store?.openingTime, store?.closingTime, true) && (
                  <div
                    className="text-B00020 text-xs sm:text-sm uppercase ml-0"
                    onClick={() => {
                      setCancelConfirmation(true);
                    }}
                  >
                    Cancel
                  </div>
                )}
              {type === "past" && order.invoice && (
                <>
                  <div
                    className="text-8593FF text-xs sm:text-sm cursor-pointer"
                    onClick={() => {
                      window.open(order.invoice);
                    }}
                  >
                    Download invoice
                  </div>
                </>
              )}
              {/* {type === "cancelled" && (
              <button className='text-xs sm:text-sm text-8593FF border border-8593FF px-6 py-1 rounded'>
                Order Now
              </button>
            )} */}
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex flex-col justify-evenly h-full text-center items-center ml-4 text-xs sm:text-sm ${
              type !== "past" && "md:mr-10"
            }`}
          >
            <div className="">Status</div>
            {type === "current" && (
              <img src={CurrentOrder} className="w-10" alt="current order" />
            )}
            {type === "cancelled" && (
              <img src={CancelledOrder} className="w-10" alt="cancelled" />
            )}
            {type === "scheduled" && (
              <img className="w-10" alt="scheduled" src={CurrentOrder} />
            )}
            {type === "current" && (
              <div className="font-semibold">
                {!order.isPaymentCOD && order.paymentStatus == "USER_PENDING"
                  ? "Payment pending .."
                  : "Order Placed"}
              </div>
            )}
            {type === "cancelled" && (
              <div className="font-semibold">
                {!order.isPaymentCOD && order.paymentStatus == "TXN_FAILURE"
                  ? "Payment failed"
                  : order.paymentStatus == "TXN_SUCCESS"
                  ? "Refund Issued"
                  : "Cancelled"}
              </div>
            )}
            {type === "past" && (
              <div className="w-8 h-8 rounded-full bg-7FB069 flex justify-center items-center">
                <i className="fas fa-check text-white"></i>
              </div>
            )}
            {type === "past" && (
              <div className="font-semibold">
                Delivered <br />
                On {order.updateAt}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={cancelConfirmation}
        title={`Are You Sure you want to Cancel Order`}
        text={`Order Id: ${order?.orderId}`}
        submit={() => {
          cancelOrder(order?.orderId);
          setCancelConfirmation(false);
        }}
        close={() => setCancelConfirmation(false)}
        closingText="Close"
        submittingText="Cancel Order"
      />
    </>
  );
}
