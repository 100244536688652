/** @format */

import React, { useState, useEffect } from "react";
import OfferTag from "../../../Assets/Icons/OfferTag.svg";
import UpArrow from "../../../Assets/Icons/UpArrow.png";
import RightArrow from "../../../Assets/Icons/RightArrow.png";
import DownArrow from "../../../Assets/Icons/DownArrow.png";
import { useSelector, useDispatch } from "react-redux";
import { storeActions, orderActions } from "../../../Redux/Actions";
import { isEmpty } from "../../../Functions/helpersFunctions";

// ! Offer json part from backend api, mapped according to backend variables
const offers = [
  {
    description: "Rs. 100 off on 300 and above Orders",
    couponCode: "GAIN100",
  },
  {
    description: "Rs. 199 off on 500 and above Orders",
    couponCode: "WIN199",
  },
  {
    description: "Rs. 499 off on 1200 and above Orders",
    couponCode: "JACKPOT",
  },
];

const PromoCodeCard = (paymentMode, selectedAddress) => {
  const [showOffer, setShowOffer] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  const { store, orderInfo } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { deliveryCharges, packagingCharges } = store;

  useEffect(() => {
    dispatch(storeActions.getPromoCodes());
    return () => {
      dispatch(orderActions.removePromo());
    };
  }, []);

  useEffect(() => {
    if (couponCode) {
      applyPromoCode();
    }
    return () => {
      setCouponCode("");
    };
  }, [couponCode]);

  const applyPromoCode = () => {
    dispatch(
      orderActions.applyPromo({
        couponCode,
        paymentMode,
        selectedAddress,
        deliveryCharges: parseFloat(deliveryCharges),
        packagingCharges,
      })
    );
  };

  return (
    <div className="bg-white bg-no-repeat rounded space-y-8 p-7">
      <div className="flex flex-col space-y-1">
        <div className="font-open-sans font-semibold text-base text-181818 capitalize">
          Offers
        </div>
        {/* <div className="flex space-x-2"> */}
        {/* <div className="h-4 w-4">
            <img src={OfferTag} alt="offer" className="h-full w-full" />
          </div> */}
        {/* <div className="flex-grow font-open-sans text-left text-sm text-black lowercase">
            {!isEmpty(orderInfo.selectedPromo)
              ? orderInfo.selectedPromo.couponCode
              : "Select a promo code"}
          </div> */}
        {/* <div
            className='flex space-x-2 cursor-pointer'
            onClick={() => setShowOffer(!showOffer)}>
            <div className='font-open-sans font-semibold text-left text-xs text-8593FF lowercase'>
              view offers
            </div>
            <div className='hidden sm:block sm:h-3.5 sm:w-3.5 '>
              <img
                src={showOffer ? UpArrow : DownArrow}
                alt='Up'
                className='h-full w-full'
              />
            </div>
          </div> */}
        {/* </div> */}
        <div>
          {/* <div className="flex justify-between"> */}
          {/* <div className='font-open-sans text-left text-xs text-8B8B8B'>
              Got an offer/ promo code?
            </div> */}
          {/* {!isEmpty(orderInfo.selectedPromo) && ( */}
          {/* <div
                className="font-open-sans text-right text-xs text-red-400 cursor-pointer"
                onClick={() => {
                  dispatch(orderActions.removePromo());
                  setCouponCode(null);
                }}
              > */}
          {/* REMOVE
              </div>
            )
          </div> */}
          {/* <div className='flex'>
            <input
              id="promoCode"
              type='text'
              className='flex-grow border-0 bg-F5F5F5 h-12 focus:border-0 px-4'
              value={couponCode?couponCode:""}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <div className='bg-8593FF bg-no-repeat w-12 p-3'>
              <img
                src={RightArrow}
                alt='right arrow'
                className='h-full w-full object-cover'
              />
            </div>
          </div> */}
        </div>
      </div>
      {showOffer && (
        <div className="flex flex-col space-y-4">
          <div className="font-open-sans text-left text-sm text-8B8B8B capitalize">
            Promo code/ offer for you
          </div>
          <div className="font-open-sans font-semibold text-left text-base text-181818 capitalize">
            {store.promoCodes.length
              ? "Offers Applicable"
              : "No Offers Applicable"}
          </div>
          {store.promoCodes.map((offer, index) => (
            <div className="flex space-x-2 items-center max-w-full">
              <div className="h-4 w-4 max-w-full">
                <img src={OfferTag} alt="offer" className="h-full w-full" />
              </div>
              <div className="flex-grow font-open-sans text-left text-xs text-121216 capitalize max-w-full break-all">
                Use <b>{offer.couponCode}</b> and get {offer.description}
              </div>
              <div
                className={`${
                  orderInfo.selectedPromo &&
                  orderInfo.selectedPromo.couponCode == offer.couponCode
                    ? "bg-7FB069 border-7FB069	text-xs text-white"
                    : "bg-FFFFFF border-8593FF text-8593FF text-xs"
                } border rounded cursor-pointer font-open-sans font-semibold text-left py-3 px-7 max-w-full`}
                onClick={() => {
                  // debugger
                  if (
                    orderInfo.selectedPromo &&
                    orderInfo.selectedPromo.couponCode == offer.couponCode
                  ) {
                    dispatch(orderActions.removePromo());
                    setCouponCode(null);
                  } else {
                    setCouponCode(offer.couponCode);
                  }
                }}
              >
                {orderInfo.selectedPromo &&
                orderInfo.selectedPromo.couponCode == offer.couponCode
                  ? "Remove"
                  : "Apply"}
              </div>
            </div>
          ))}
          <div className="font-open-sans font-semibold text-right text-2xs text-7FB069 capitalize">
            Terms and condition apply*
          </div>
        </div>
      )}
    </div>
  );
};

export default PromoCodeCard;
