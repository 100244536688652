import React from "react";


export default function OfferCard({ image }) {
  return (
    <div className='inline-block'>
      <div className='w-80 h-32 rounded-md shadow-sm bg-white hover:shadow-md transition-shadow duration-300 ease-in-out overflow-hidden'>
        <img
          src={image}
          className='object-cover h-full w-full'
          alt='offer'
        />
      </div>
    </div>
  );
}
