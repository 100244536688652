import React, { useEffect, useMemo, useState } from "react";
import StoreBranches from "../../Components/StoreBranches/StoreBranches";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useSelector, useDispatch, connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { clientService } from "../../Redux/Services";
import toast from "react-hot-toast";
import CustomSelect from "../../Components/CustomSelect/CustomSelect";
import Login from "../../Components/Login/Login";
import SideMenu from "../../Components/SideMenu/SideMenu";
import SideBar from "../../Components/SideBar/SideBar";
import SideNav from "../../Components/SideNav/SideNav";
import Profile from "../../Assets/Icons/user-profile.svg";
import { Link } from "react-router-dom";
import { authActions, modalActions, storeActions, userLogout } from "../../Redux/Actions";
import ChangeLocationModal from "../../Components/Change Location Modal/ChangeLocationModal";
import { Loader } from "@googlemaps/js-api-loader";
import { Autocomplete, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useWindowDimensions } from "../../Functions/utils";
import { Carousel } from "react-responsive-carousel";
import "./Home.css";
import OrderModal from "../../Components/Modal/OrderModal";
import { useAccess } from "../../Functions/utils";
import logoutIcon from "../../Assets/Icons/account-logout.svg";
import downArrow from "../../Assets/Icons/DownArrowBlack.png";
import upArrow from "../../Assets/Icons/UpArrowBlack.png";

const options = {
  fields: ["formatted_address", "geometry", "name"],
  types: ["sublocality_level_1", "sublocality", "political", "geocode"],
};

const Home = (props) => {
  // Mode can be delivery or pickup
  const dispatch = useDispatch();
  const [mode, setMode] = useState("");
  const { client, auth, modal } = useSelector((state) => state);
  const [stores, setStores] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchAreaOpen, setSearchAreaOpen] = useState(false);
  const [autocomplete, setAutoComplete] = useState();
  const { deliveryEnabled, pickupEnabled, clientType } = client;
  const [searchResults, setSearchResults] = useState([]);
  const [storeSearch, setStoreSearch] = useState('');
  const [value, setValue] = useState("");
  const [DEFAULT_COORDS, setDefaultCoords] = useState({
    lat: 28.6523392,
    lng: 77.185024,
  });
  const { showChangeLocationModal } = props;
  const localStorageAuth = JSON.parse(localStorage.getItem("auth"));

  const access = useAccess();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = client?.name;
    setMode(
      clientType != "SCHOOL" && clientType != "STAFF"
        ? deliveryEnabled
          ? "delivery"
          : "pickup"
        : "pickup"
    );
  }, [deliveryEnabled, pickupEnabled, clientType]);

  const findDeliveryStores = (location) => {
    console.log(location, location.geometry.location.lng);
    // setLocations({
    // 	address: location.formatted_address,
    // 	lat: location.geometry.location.lat,
    // 	lng: location.geometry.location.lng,
    // });
    clientService
      .getDeliveryStore({
        params: {
          lat: location.geometry.location.lat,
          long: location.geometry.location.lng,
          ...(access?.sendMobile ? { mobile: localStorageAuth?.user } : {}),
        },
      })
      .then((stores) => {
        if (stores.length) {
          setStores(stores);
        } else {
          toast.error("No Stores Found!");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const getDeliveryStores = (pincode) => {
    clientService
      .getDeliveryStores(pincode)
      .then((stores) => {
        if (stores.length) {
          setStores(stores);
        } else {
          toast.error("No Stores Found!");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const getPickupStores = (req) => {
    clientService
      .getPickupStores(req)
      .then((locations) => setLocations(locations))
      .catch((err) => toast.error(err));
  };

  const { sendMobile, loginFirst, configFetched } = access;
  const { auth: loggedIn, user: mobile } = auth;

  useEffect(() => {
    if (!localStorageAuth) {
      dispatch(userLogout());
    }
  }, [localStorageAuth]);


  useEffect(() => {
    dispatch(authActions.redirect(null));
  }, [dispatch]);

  useEffect(() => {
    if (configFetched) {
      const req = { params: {} };

      if (sendMobile && loggedIn) {
        req.params.mobile = mobile;
      }
      if (loginFirst && loggedIn) {
        getPickupStores(req);
      } else if (loginFirst === false) {
        getPickupStores(req);
      }
    }
  }, [loginFirst, sendMobile, configFetched, mobile, loggedIn]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipe: true,
  };
  const fetchLatitudeAndLongitude = (place_id) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=AIzaSyDSFW1Q6S3ghhiuDbhCLgaFcVuSfGr05GQ`
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res, 'ress');
        if (res.status !== "OVER_QUERY_LIMIT") {
          setDefaultCoords(
            res.results[0].geometry.location
              ? res.results[0].geometry.location
              : {
                  lat: 28.6523392,
                  lng: 77.185024,
                }
          );
          openChangeLocationModal();
        }
      });
  };
  const googlePlaceSearch = (e) => {
    const input = e.target.value;
    if (input.length > 3) {
      autocomplete
        .getPlacePredictions({ input, fields: options.fields })
        .then((res) => {
          if (res.predictions.length === 0) {
            res.predictions.push({
              description: "No location exist for this query",
            });
          }
          setSearchResults(res.predictions);
          // console.log(res.predictions, 'predictions');
        });
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (window.google) {
      setAutoComplete(new window.google.maps.places.AutocompleteService());
    } else {
      const loader = new Loader({
        apiKey: "AIzaSyDSFW1Q6S3ghhiuDbhCLgaFcVuSfGr05GQ",
        version: "weekly",
        libraries: ["places"],
      });
      loader
        .load()
        .then((google) => {
          setAutoComplete(new google.maps.places.AutocompleteService());
        })
        .catch((e) => {
          // console.log(e, 'error');
        });
    }
  }, []);

  const selected =
    "border-b-4 border-8593FF font-semibold text-181818 text-base text-center w-full py-2 cursor-pointer";

  const unselected =
    "border-b-4 font-semibold text-181818 text-base text-center w-full py-2 cursor-pointer";

  const openChangeLocationModal = () => {
    dispatch(modalActions.toggleChangeLocationModal(true));
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      function success(pos) {
        var crd = pos.coords;
        console.log(crd);
        setDefaultCoords({
          lat: crd.latitude,
          lng: crd.longitude,
        });
        openChangeLocationModal();
      }

      function error(err) {
        toast.error(err.message);
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };
  const { height, width } = useWindowDimensions();

  let centerSlidePercentage = 75;

  if (width <= 768) {
    centerSlidePercentage = 100;
  } else if (width < 1200) {
    centerSlidePercentage = 75;
  } else if (width < 1440) {
    centerSlidePercentage = 75;
  } else if (width < 1660) {
    centerSlidePercentage = 75;
  } else {
    centerSlidePercentage = 75;
  }

  const showPosition = (position) => {
    let location =
      "Latitude: " +
      position.coords.latitude +
      "Longitude: " +
      position.coords.longitude;
  };

  const [orderModalIsVisible, setOrderModalIsVisible] = useState(false);

  const useStyles = makeStyles(() => ({
    noBorder: {
      border: "none",
      borderWidth: "0px",
    },
    input: {
      flex: 1,
      border: "none",
      borderWidth: "0px",
    },
  }));

  const classes = useStyles();

  const handleLogOut = () => {
    dispatch(userLogout());
    setShowDropdown(false);
  };

  const filteredStores = useMemo(() => {
    return stores?.filter((store) => {
      if (store?.storeName?.toLowerCase().includes(storeSearch?.toLowerCase())) {
        return store;
      }
      if (store?.storeName?.toLowerCase() === storeSearch?.toLowerCase()) {
        return store;
      }
      if(storeSearch === '') {
        return store;
      }
    });
  }, [storeSearch, stores])

    return (
      <>
        <div className="flex  w-full justify-between items-center my-1 md:my-2">
          <div className="flex z-10 bg-white items-center  ml-2 md:ml-12 py-1">
            <Link to="/">
              <div className="w-12">
                <img
                  src={client.logo}
                  alt="restaurant logo"
                  className="h-full w-full "
                />
              </div>
            </Link>
            <div className="">
              <div className="font-open-sans font-semibold text-center text-xl xl:text-xl 2xl:text-xl text-black capitalize ml-4">
                {client.name}
              </div>
              {client.tagline && (
                <div className="font-open-sans text-center text-sm xl:text-lg 2xl:text-large text-8B8B8B capitalize">
                  {client.tagline}
                </div>
              )}
            </div>
          </div>
          <div className="mr-2 md:mr-12">
            {!auth?.auth ? (
              <>
                {" "}
                <Link to="/login">
                  <div className="flex lg:hidden items-center space-x-2.5 cursor-pointer">
                    <img
                      className="h-auto w-auto"
                      src={Profile}
                      alt="profile"
                    />
                    <p className="text-black text-sm">Sign In</p>
                  </div>
                </Link>
                <div
                  className="hidden lg:flex items-center space-x-2.5 cursor-pointer"
                  onClick={() => setIsVisible(true)}
                >
                  <img className="h-auto w-auto" src={Profile} alt="profile" />
                  <p className="text-black text-sm">Sign In</p>
                </div>
              </>
            ) : (
              <div className="flex items-center user-container">
                <div
                  className="flex items-center"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <img
                    className="h-auto w-auto mr-2"
                    src={Profile}
                    alt="profile"
                  />
                  <div className="font-bold text-sm md:text-md">
                    {auth?.user}
                  </div>
                </div>
                <div onClick={() => setShowDropdown(!showDropdown)}>
                  {!showDropdown ? (
                    <img className="dropdown-icon" src={downArrow} alt="drop" />
                  ) : (
                    <img className="dropdown-icon" src={upArrow} alt="up" />
                  )}
                </div>
                {showDropdown && (
                  <div
                    onClick={handleLogOut}
                    className="flex items-center user-dropdown"
                  >
                    <img src={logoutIcon} alt="logout" />
                    LogOut
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="landing-page">
          <div className="carousel-container">
            {client.isLoading ? (
              <div className="loader-container">
                <Loader />
              </div>
            ) : (
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showThumbs={false}
                showStatus={false}
                centerMode={true}
                centerSlidePercentage={centerSlidePercentage}
              >
                {client.homeImage.map((img, idx) => {
                  return (
                    <div key={idx} className="banner-container">
                      <img src={img} className="banner-img" alt="banner" />
                      {/* <p className="legend">Legend 1</p> */}
                    </div>
                  );
                })}
              </Carousel>
            )}
          </div>
          <div className="col-span-2 flex items-center justify-center relative">
            <div
              className="flex flex-col items-center justify-center bg-white absolute rounded-t z-10 w-7/12 md:w-6/12 lg:w-5/12 help-div-container"
              style={{
                width: width < "500px" ? "45%" : "",
              }}
            >
              {access?.loginFirst && !access?.allowed && (
                <div className="flex flex-col items-center justify-center rounded-t gap-4 absolute bg-white z-20 inset-0 glass">
                  <p>Log in to continue</p>
                  <button
                    onClick={() => {
                      dispatch(modalActions.toggleLoginModal(true));
                    }}
                    className="bg-7FB069 rounded p-2 text-white block"
                  >
                    LOGIN
                  </button>
                </div>
              )}

              <div
                className=" py-5 w-5/5 md:w-4/5 h-full h-1/2 "
                style={{
                  width: width < "500px" ? "95%" : "90%",
                }}
              >
                <div className="">
                  <div className="font-bold font-open-sans md:text-xl lg:text-2xl 2xl:text-3xl text-center text-181818 capitalize heading">
                    {client.clientType != "SCHOOL" &&
                    client.clientType != "STAFF"
                      ? "Help Us With Your Location"
                      : "Help Us With Your School"}
                  </div>
                  <div className="font-open-sans text-xs text-center text-747474 separator-home border-747474 capitalize">
                    {client.clientType != "SCHOOL" &&
                    client.clientType != "STAFF"
                      ? "Will Help Us Finding the nearest restaurant"
                      : "Will Help Us Deliver Your Meal"}
                  </div>
                </div>
                {client.clientType != "SCHOOL" &&
                client.clientType != "STAFF" ? (
                  <div className="flex mx-4 my-4">
                    {deliveryEnabled && (
                      <div
                        className={`${
                          mode === "delivery" ? selected : unselected
                        } `}
                        onClick={() => {
                          setStores([]);
                          setMode("delivery");
                        }}
                      >
                        {pickupEnabled
                          ? "Delivery"
                          : "Get Your Order Delivered To"}
                      </div>
                    )}
                    {pickupEnabled && (
                      <div
                        className={mode === "pickup" ? selected : unselected}
                        onClick={() => {
                          setStores([]);
                          setMode("pickup");
                        }}
                      >
                        {deliveryEnabled ? "Pick-Up" : "Pickup your order from"}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={selected}
                    onClick={() => {
                      setStores([]);
                      setMode("pickup");
                    }}
                  >
                    {"Your School's"}
                  </div>
                )}
                {mode === "delivery" &&
                client.clientType != "SCHOOL" &&
                client.clientType != "STAFF" ? (
                  <>
                    <div className="locate-me-container mx-4 border border-8B8B8B">
                      <div className="autocomplete-container ml-1">
                        <Autocomplete
                          freeSolo
                          className={classes.input}
                          // className='border border-8B8B8B mix-blend-luminosity w-full p-4 xl:p-4 font-open-sans text-left text-8B8B8B text-sm focus:none'
                          value={value}
                          id="free-solo-2-demo"
                          disableClearable
                          options={searchResults.map(
                            (option) => option.description
                          )}
                          label="Enter Locality/Area"
                          sx={classes.input}
                          // className={classes.textField}
                          InputProps
                          autoSelect={true}
                          onInput={googlePlaceSearch}
                          onChange={(event, newValue) => {
                            setValue(newValue);
                            searchResults.forEach((currentResult) => {
                              if (currentResult.description === newValue) {
                                fetchLatitudeAndLongitude(
                                  currentResult.place_id
                                );
                              }
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              classes={{
                                notchedOutline: classes.input,
                              }}
                              className={`border-none mix-blend-luminosity w-full p-4 xl:p-4 font-open-sans text-left text-8B8B8B text-sm focus:none bg-transparent ${classes.input}`}
                              sx={classes.input}
                              placeholder="Enter Location/Area"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                                underline: {
                                  "&&:before": {
                                    border: "none",
                                  },
                                  "&&:after": {
                                    border: "none",
                                  },
                                  "&:hover": {
                                    border: "none",
                                  },
                                },
                                disableUnderline: true,
                                classes: {
                                  notchedOutline: classes.noBorder,
                                },
                              }}
                            >
                              {/* <div className="text-center text-FFFFFF text-base">
                          abcd
                        </div> */}
                            </TextField>
                          )}
                        />
                      </div>
                      <div
                        className="flex justify-center items-center bg-no-repeat mix-blend-luminosity border-none rounded  font-open-sans p-1 cursor-pointer hover:bg-slate-400 locate-me-btn mr-1"
                        style={{}}
                        onClick={getLocation}
                      >
                        <MyLocationIcon />
                        {/* <div
                      className="text-center ml-1  text-xs text-black"
                      style={{
                        fontSize: width > 500 ? "1rem" : "0.55rem",
                        width: "100%",
                      }}
                    >
                      LOCATE ME
                    </div> */}
                      </div>
                    </div>
                    <div
                      className={`mx-4 overflow-y-auto mt-4 ${
                        Object.keys(locations).length == 1 && mode == "pickup"
                          ? "max-h-full"
                          : "h-2/4"
                      }`}
                      style={{
                        height:
                          Object.keys(locations).length == 1 && mode == "pickup"
                            ? width < 600
                              ? "62%"
                              : width > 800
                              ? "80%"
                              : "70%"
                            : "50%",
                      }}
                    >
                      <StoreBranches
                        locations={locations}
                        stores={stores}
                        isVisible={orderModalIsVisible}
                        setIsVisible={setOrderModalIsVisible}
                        mode={mode}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      overflowX: "hidden",
                      overflowY: "auto",
                      maxHeight: "calc(100% - 3rem - 1.5rem - 2rem)",
                    }}
                  >
                    <div className="font-open-sans space-y-4">
                      <CustomSelect
                        locations={locations}
                        changeStore={setStores}
                      />
                    </div>
                    <div
                      className={`mx-4 overflow-y-auto mt-4 ${
                        Object.keys(locations).length == 1 && mode == "pickup"
                          ? "max-h-full"
                          : "h-2/4"
                      }`}
                      style={{
                        height:
                          Object.keys(locations).length == 1 && mode == "pickup"
                            ? width < 600
                              ? "62%"
                              : width > 800
                              ? "80%"
                              : "70%"
                            : "50%",
                      }}
                    >
                      {stores?.length > 0 && (
                        <TextField
                          id="outlined-basic"
                          label="Search school"
                          variant="outlined"
                          onChange={(e) => setStoreSearch(e.target.value)}
                          value={storeSearch}
                          fullWidth
                        />
                      )}
                      <StoreBranches
                        locations={locations}
                        stores={filteredStores}
                        isVisible={orderModalIsVisible}
                        setIsVisible={setOrderModalIsVisible}
                        mode={mode}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <SideNav
          onClose={() => {
            setIsVisible(false);
            dispatch(modalActions.toggleLoginModal(false));
          }}
          mode="dark"
          visible={isVisible || modal.showLoginModal}
          type="login"
        >
          <Login
            closeLoginSlider={() => {
              setIsVisible(false);
              dispatch(modalActions.toggleLoginModal(false));
            }}
          />
        </SideNav>
        <SideNav
          onClose={() => {
            dispatch(modalActions.toggleChangeLocationModal(false));
            setSearchAreaOpen(false);
          }}
          visible={showChangeLocationModal}
          mode="dark"
        >
          <ChangeLocationModal
            findDeliveryStores={findDeliveryStores}
            DEFAULT_COORDS={DEFAULT_COORDS}
            setValue={setValue}
          />
        </SideNav>
        <SideBar onClose={() => setShowMenu(false)} visible={showMenu}>
          <SideMenu onClose={() => setShowMenu(false)} />
        </SideBar>
        <SideNav
          visible={orderModalIsVisible}
          onClose={() => setOrderModalIsVisible(false)}
          mode="dark"
        >
          <OrderModal
            // close={() => setIsVisible(false)}
            visible={orderModalIsVisible}
            stores={stores}
          />
        </SideNav>
      </>
    );
};

const mapStateToProps = (state) => {
  const { showChangeLocationModal } = state.modal;
  return {
    showChangeLocationModal,
  };
};
const mapDispatchToProps = {
  getDeliveryStore: storeActions.getStore,
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
