import { customizationConstants } from "../Constants";

const initialState = {};

export function customization(state = initialState, action) {
  switch (action.type) {
    case customizationConstants.CUSTOMIZATION_UPDATE:
      return { ...action.payload };
    case customizationConstants.CUSTOMIZATION_CLEAR:
      return {};
    default:
      return state;
  }
}
