import urls from "../../urls";

export const creditService = {
  fetchCreditDetails,
  intiateCredits,
  fullFillCredits,
  updateCreditStatus,
  fetchTranscationHistory
};

function fetchCreditDetails() {
  const auth = JSON.parse(localStorage.getItem("auth"));
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", token :auth.token},
    };
    return fetch(urls["fetchBalance"], requestOptions).then(handleResponse);
  };


function intiateCredits(req) {
    const auth = JSON.parse(localStorage.getItem("auth"));
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json",token :auth.token},
        body: JSON.stringify({...req,"txnType":"TOPUP"}),
      };
      return fetch(urls["intiateCredits"], requestOptions).then(handleResponse);    
    };

function fullFillCredits(req) {
    const auth = JSON.parse(localStorage.getItem("auth"));
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json",token :auth.token},
          body: JSON.stringify({...req}),
        };
        return fetch(urls["fullFillCredits"], requestOptions).then(handleResponse).catch((error) => {
          return Promise.reject("Some Error Occurred");
        });    
  };
    
function updateCreditStatus(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
      method: 'POST',
      headers: {  token :auth.token ,'Content-Type': 'application/json' },
      body: JSON.stringify({...req}),
  };

  return fetch(urls['updateCredits'], requestOptions)
      .then(handleResponse).catch((error) => {
        return Promise.reject("Some Error Occurred");
      });    
}


function fetchTranscationHistory(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
      method: 'POST',
      headers: {  token :auth.token ,'Content-Type': 'application/json' },
      body: JSON.stringify({ ...req })
  };

  return fetch(urls['fetchCreditsHistory'], requestOptions)
      .then(handleResponse).catch((error) => {
        return Promise.reject("Some Error Occurred");
      });    
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data.responseCode != "SS-001") {
      const error = {error: data?.responseMessage, responseCode: data?.responseCode};
      return Promise.reject(error);
    }
    return data.data;
  });
}

  function toQueryStringURL(queryURL, params) {
    const url = new URL(queryURL);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    return url;
}

