import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
	addAccordingToType,
	removeFromCart,
} from '../../../Functions/CartFunctions';
import {
	findIsDefaultCombo,
	getProcessObj,
	getSelectionCopy,
	handleAddCustomizationCopy,
	storeClosedOrNot,
} from '../../../Functions/helpersFunctions';
import { modalActions, orderActions } from '../../../Redux/Actions';

export default function PhoneSpecialFoodCard({
	foodItem,
	index,
	productId,
	productType,
	selectedVariant,
	name,
	discount,
	variants,
	type,
	isCustomizable,
	showCustomization,
}) {
	const { restaurantId } = useParams();
	const { orderInfo, cart, menu, customization, store } = useSelector(
		(state) => state
	);
	const { orderType } = useParams();
	const dispatch = useDispatch();
	const [itemCount, setItemCount] = useState(0);
	const history = useHistory();

	useEffect(() => {
		// fetches count from cart of the specific food item
		let count = 0;

		cart.forEach((item, index) => {
			if (item.productId === productId) {
				count = count + item.quantity;
			}
		});
		setItemCount(count);
	}, [cart]);

	// Handles addition to cart
	const addToCart = (e) => {
		e.stopPropagation();
		if (storeClosedOrNot(orderInfo, store)) {
			return;
		}
		// if (
		// 	(orderInfo.deliverNow === false &&
		// 		((orderInfo.time > store.closingTime &&
		// 			orderInfo.time > store.openingTime) ||
		// 			(orderInfo.time < store.closingTime &&
		// 				orderInfo.time < store.openingTime))) ||
		// 	(orderInfo.time > store.closingTime &&
		// 		orderInfo.time < store.openingTime) ||
		// 	(orderInfo.deliverNow && store.isClosed)
		// ) {
		// 	return;
		// }
		// If cart length empty

		if (cart.length === 0) {
			// Update Order type in store
			dispatch(
				orderActions.updateOrderStore({
					orderType: orderInfo.orderType,
				})
			);
		}
		if (productType === 'COMBO') {
			if (
				foodItem.variants.length === 1 &&
				findIsDefaultCombo(foodItem)
			) {
				const selection = getSelectionCopy(foodItem, menu.products);
				const appliedCustomization = {};
				const removedProcessList = {};
				addAccordingToType(
					menu.products,
					productId,
					customization,
					orderType,
					itemCount,
					setItemCount,
					dispatch,
					orderInfo,
					foodItem.name,
					foodItem.selectedVariant,
					handleAddCustomizationCopy(
						selection,
						foodItem,
						appliedCustomization,
						removedProcessList,
						menu.products,
						getProcessObj
					),
					1
				);
			} else dispatch(modalActions.toggleComboModal(true, { productId }));
			return;
		} else if (isCustomizable && showCustomization) {
			showCustomization(productId, {}, -1);
			return;
		}
		// Add item to cart
		addAccordingToType(
			menu.products,
			productId,
			customization,
			orderInfo.orderType,
			itemCount,
			setItemCount,
			dispatch,
			orderInfo
		);
	};

	const onRemove = (e) => {
		e.stopPropagation();
		removeFromCart(
			menu.products,
			productId,
			orderInfo.orderType,
			itemCount,
			setItemCount,
			dispatch
		);
	};
	const changeRoute = () => {
		history.push(
			`/${restaurantId}/food-item/${orderInfo.orderType}/${productId}`
		);
	};

	return (
		<div
			onClick={changeRoute}
			className='flex px-4 py-2.5 space-x-2 bg-white cursor-pointer'
		>
			<div className=''>
				<img alt='food' src={foodItem.image} className='w-12 h-12' />
			</div>
			<div className='flex-grow'>
				<div className='font-open-sans font-semibold text-sm'>
					{name}
				</div>
				<div className='text-8B8B8B font-open-sans font-normal text-xs'>
					{/* {foodItem.category} */}
					&#x20B9;{' '}
					{discount && discount > 0
						? selectedVariant.discountedPrice
						: selectedVariant.price}
				</div>
			</div>
			<div className='flex justify-between items-center'>
				{/* <div className='font-open-sans font-semibold text-sm mb-1'>
            
          </div> */}
				{itemCount ? (
					<div className='bg-8593FF bg-no-repeat border border-8593FF rounded text-center font-semibold font-open-sans  cursor-pointer'>
						<div className='flex items-center justify-between py-1.5 px-2 space-x-2'>
							<div
								className='text-black bg-FFFFFF rounded-full text-xs sm:text-sm px-2 cursor-pointer'
								onClick={onRemove}
							>
								-
							</div>
							<div className='italic text-xs sm:text-sm text-FFFFFF capitalize '>
								{itemCount}
							</div>
							<div
								className='text-black bg-FFFFFF rounded-full text-xs sm:text-sm px-1.5 cursor-pointer'
								onClick={addToCart}
							>
								+
							</div>
						</div>
					</div>
				) : (
					<div onClick={addToCart}>
						{/* Add Button for bulk and regular order */}
						{orderInfo.orderType === 'bulk' ? (
							<div className='bg-white font-semibold text-center text-xs text-8593FF border-2 border-8593FF rounded py-1 px-1 capitalize cursor-pointer'>
								<div className='font-semibold text-2xs sm:text-xs lg:text-2xs xl:text-xs capitalize '>
									Add Item
								</div>
								<div className='text-3xs sm:text-2xs lg:text-3xs xl:text-2xs text-8593FF '>
									min: 10 quantity
								</div>
							</div>
						) : (
							<div
								className={`${
									store.isClosed || store.isInactive
										? 'border-gray-400 text-gray-400'
										: 'border-8593FF text-8593FF'
								}bg-white font-semibold text-center text-xs  border-2 rounded py-1 px-3 capitalize cursor-pointer`}
							>
								<div>Add Item</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
}
