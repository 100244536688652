import { feedbackConstants } from "../Constants/feedback.constants";

const initialState = {
    orderId: null,
    selectedItem: {},
    isLoading: false,
    orderRating: 0,
}

export function feedback(state = initialState, action) {
    switch (action.type) {
        case feedbackConstants.SET_ITEM:
            return {
                ...state,
                ...action.payload
            }
        case feedbackConstants.INIT_ORDERING_EXPERIENCE:
            return {
                ...state,
                ...action.payload
            }
        case feedbackConstants.ORDERING_EXPERIENCE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case feedbackConstants.ORDERING_EXPERIENCE_SUCCESS:

        case feedbackConstants.ORDERING_EXPERIENCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                ...action.payload
            }
        case feedbackConstants.DELIVERY_EXPERIENCE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case feedbackConstants.DELIVERY_EXPERIENCE_SUCCESS:
        case feedbackConstants.DELIVERY_EXPERIENCE_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        case feedbackConstants.ITEM_FEEDBACK_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case feedbackConstants.ITEM_FEEDBACK_SUCCESS:
        case feedbackConstants.ITEM_FEEDBACK_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}