import React, { useState, useEffect } from 'react';
import SubHeader from '../../Components/Header/SubHeader';
import SelectItem from './SelectItem';
import TrackingStatus from './TrackingStatus';
import { getComponent } from './functions';
import { isEmpty } from '../../Functions/helpersFunctions';
import BillCard from '../../Components/Cards/BillCard/BillCard';
import Phone from '../../Assets/Icons/Phone.svg';
import DownArrowBlack from '../../Assets/Icons/DownArrowBlack.png';
import UpArrowBlack from '../../Assets/Icons/UpArrowBlack.png';
import HomeOutline from '../../Assets/Icons/Home-Outline.svg';
import FoodRating from '../../Assets/Icons/Rating-Food.svg';
import Star from '../../Components/Star/Star';
import { orderActions, feedbackActions } from '../../Redux/Actions';
// static data --> backend
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

function TrackOrder() {
	const dispatch = useDispatch();
	const { orderTrack, store, client, feedback } = useSelector(
		(state) => state
	);
	const { restaurantId, foodItemSlug, orderId } = useParams();
	const history = useHistory();
	const [noOfSelectedStars, setNoOfSelectedStars] = useState();

	const [showItems, setShowItems] = useState([false, false, false, false]);

	const toggleSection = (stageNo) => {
		let sectionArray = showItems;
		let value = showItems[stageNo - 1];
		sectionArray[stageNo - 1] = !value;
		setShowItems([...sectionArray]);
	};

	const handleStarChange = (number) => {
		const feedback = {
			orderId: orderId,
			feedback: {
				orderRating: number,
			},
		};
		dispatch(feedbackActions.setOrderingExperience(feedback));
	};
	useEffect(() => {
		dispatch(orderActions.setOrderId(orderId));
		dispatch(orderActions.startTracking());

		return () => {
			dispatch(orderActions.stopTracking());
		};
	}, [foodItemSlug]);
	useEffect(() => {
		if (orderTrack.orderStatus === 500 || orderTrack.orderStatus === 800) {
			dispatch(orderActions.stopTracking());
		}
	}, [orderTrack.orderStatus]);

	useEffect(() => {
		console.log('mount 1');
	}, [orderTrack]);

	const OuterBox = (props) => (
		<div className='sm:bg-white sm:px-6 sm:py-4'>
			<div className='flex justify-between bg-white rounded sm:rounded-none mx-4 px-6 py-4 sm:px-0 sm:py-0'>
				<div className='font-open-sans font-semibold text-left text-sm sm:text-base text-black'>
					{props.stageName}
				</div>
				<div
					className='cursor-pointer'
					onClick={() => toggleSection(props.stageNo)}
				>
					{/*   Downward arrow */}
					<img
						src={
							showItems[props.stageNo - 1]
								? UpArrowBlack
								: DownArrowBlack
						}
						alt='down/up arrow'
						className='w-5 h-5'
					/>
				</div>
			</div>
			{showItems[props.stageNo - 1] && props.children}
		</div>
	);
	return orderTrack.items[foodItemSlug] ? (
    <div>
      <div className="hidden lg:block">
        <SubHeader
          title="TRACK ITEM"
          link={`/${restaurantId}/track-order/${orderId}`}
        />
      </div>
      <div className="grid grid-cols-6 md:my-6 gap-4">
        <div className=" col-span-6 lg:col-span-4 space-y-2">
          <SelectItem
            items={orderTrack.items}
            item={orderTrack.items[foodItemSlug]}
          />
          <TrackingStatus type="ITEM_LEVEL" />
          {orderTrack.orderType == "PICKUP" &&
            orderTrack.items[foodItemSlug].statusCode === 500 && (
              <OuterBox stageName="Picked Up" stageNo={5}>
                {orderTrack.items[foodItemSlug].statusCode === 500 &&
                  getComponent(700, {
                    key: orderTrack.items[foodItemSlug].statusCode,
                    stageName: "Picked Up",
                    deliverTime: "",
                    foodItems: orderTrack.items,
                    driverName: "self",
                    driverNumber: "self",
                    driverRating: 0,
                    invoice: orderTrack.invoice,
                    latitude: "28.6305482",
                    longitude: "77.0810728",
                  })}
              </OuterBox>
            )}
          {orderTrack.orderType == "DELIVERY" &&
          orderTrack.items[foodItemSlug].statusCode >= 700 ? (
            orderTrack.items[foodItemSlug].statusCode === 700 ? (
              getComponent(700, {
                key: orderTrack.items[foodItemSlug].statusCode,
                stageName: "Delivered",
                deliverTime: "",
                foodItems: orderTrack.items,
                driverName: orderTrack.riderDetails
                  ? orderTrack.riderDetails.name
                  : "-",
                driverNumber: orderTrack.riderDetails
                  ? orderTrack.riderDetails.mobile
                  : "-",
                driverRating: orderTrack.driverRating
                  ? orderTrack.driverRating
                  : 0,
                invoice: orderTrack.invoice,
                latitude: orderTrack.riderDetails
                  ? orderTrack.riderDetails.latitude
                    ? orderTrack.riderDetails.latitude
                    : "28.6305482"
                  : "28.6305482",
                longitude:
                  orderTrack.riderDetails && orderTrack.riderDetails.longitude
                    ? orderTrack.riderDetails.longitude
                    : "77.0810728",
              })
            ) : (
              <OuterBox stageName="Delivered" stageNo={5}>
                {getComponent(700, {
                  key: orderTrack.items[foodItemSlug].statusCode,
                  stageName: "Delivered",
                  driverName: orderTrack.riderDetails
                    ? orderTrack.riderDetails.name
                    : "-",
                  driverNumber: orderTrack.riderDetails
                    ? orderTrack.riderDetails.mobile
                    : "-",
                  driverRating: orderTrack.driverRating
                    ? orderTrack.driverRating
                    : 0,
                  foodItems: orderTrack.items,
                  mapImage: "",
                  arrivingTime: "",
                  invoice: orderTrack.invoice,
                  latitude: orderTrack.riderDetails
                    ? orderTrack.riderDetails.latitude
                      ? orderTrack.riderDetails.latitude
                      : "28.6305482"
                    : "28.6305482",
                  longitude:
                    orderTrack.riderDetails && orderTrack.riderDetails.longitude
                      ? orderTrack.riderDetails.longitude
                      : "77.0810728",
                })}
              </OuterBox>
            )
          ) : null}

          {orderTrack.orderType == "DELIVERY" &&
          orderTrack.items[foodItemSlug].statusCode >= 500 ? (
            orderTrack.items[foodItemSlug].statusCode === 500 ? (
              getComponent(600, {
                key: orderTrack.items[foodItemSlug].statusCode,
                stageName: "Order Dispatch",
                driverName: orderTrack.riderDetails
                  ? orderTrack.riderDetails.name
                  : "-",
                driverNumber: orderTrack.riderDetails
                  ? orderTrack.riderDetails.mobile
                  : "-",
                driverRating: orderTrack.driverRating
                  ? orderTrack.driverRating
                  : 0,
                foodItems: orderTrack.items,
                mapImage: "",
                arrivingTime: "",
                latitude: orderTrack.riderDetails
                  ? orderTrack.riderDetails.latitude
                    ? orderTrack.riderDetails.latitude
                    : "28.6305482"
                  : "28.6305482",
                longitude:
                  orderTrack.riderDetails && orderTrack.riderDetails.longitude
                    ? orderTrack.riderDetails.longitude
                    : "77.0810728",
              })
            ) : orderTrack.items[foodItemSlug].statusCode === 600 ? (
              <OuterBox stageName="Order Dispatch" stageNo={4}>
                {getComponent(600, {
                  key: orderTrack.items[foodItemSlug].statusCode,
                  stageName: "Order Dispatch",
                  driverName: orderTrack.riderDetails
                    ? orderTrack.riderDetails.name
                    : "-",
                  driverNumber: orderTrack.riderDetails
                    ? orderTrack.riderDetails.mobile
                    : "-",
                  driverRating: orderTrack.driverRating
                    ? orderTrack.driverRating
                    : 0,
                  foodItems: orderTrack.items,
                  latitude: orderTrack.riderDetails
                    ? orderTrack.riderDetails.latitude
                      ? orderTrack.riderDetails.latitude
                      : "28.6305482"
                    : "28.6305482",
                  longitude:
                    orderTrack.riderDetails && orderTrack.riderDetails.longitude
                      ? orderTrack.riderDetails.longitude
                      : "77.0810728",
                  mapImage: "",
                  arrivingTime: "",
                  // deliveryMan:orderTrack.riderDetails.image
                })}
              </OuterBox>
            ) : null
          ) : null}
          {orderTrack.items[foodItemSlug].statusCode >= 400 &&
          !isEmpty(orderTrack.items[foodItemSlug].qc) ? (
            orderTrack.items[foodItemSlug].statusCode === 400 ? (
              getComponent(400, {
                key: orderTrack.items[foodItemSlug].statusCode,
                stageName: "Quality Check",
                temp: orderTrack.items[foodItemSlug].qc.temp,
                weight: orderTrack.items[foodItemSlug].qc.weight,
                image: orderTrack.items[foodItemSlug].qc.qcImage,
                finishTime: "-",
                selectedVariant: orderTrack.items[foodItemSlug].selectedVariant,
                nutrition: orderTrack?.items[foodItemSlug]?.qc?.nutrition
              })
            ) : (
              <OuterBox stageName="Quality Check" stageNo={3}>
                {getComponent(400, {
                  key: orderTrack.items[foodItemSlug].statusCode,
                  stageName: "Quality Check",
                  temp: orderTrack.items[foodItemSlug].qc.temp,
                  weight: orderTrack.items[foodItemSlug].qc.weight,
                  image: orderTrack.items[foodItemSlug].qc.qcImage,
                  finishTime: orderTrack.items[foodItemSlug].qc.qctime,
                  selectedVariant:
                    orderTrack.items[foodItemSlug].selectedVariant,
                  nutrition: orderTrack?.items[foodItemSlug]?.qc?.nutrition,
                })}
              </OuterBox>
            )
          ) : null}
          {orderTrack.items[foodItemSlug].statusCode >= 200 &&
          orderTrack.items[foodItemSlug].cooking.length > 0 ? (
            orderTrack.items[foodItemSlug].statusCode === 200 ? (
              getComponent(200, {
                key: orderTrack.items[foodItemSlug].statusCode,
                statusCode: orderTrack.orderStatus,
                stageName: "Cooking",
                cookingData: orderTrack.items[foodItemSlug].cooking,
              })
            ) : (
              <OuterBox stageName="Cooking" stageNo={2}>
                {getComponent(200, {
                  key: orderTrack.items[foodItemSlug].statusCode,
                  statusCode: orderTrack.orderStatus,
                  stageName: "Cooking",
                  cookingData: orderTrack.items[foodItemSlug].cooking,
                })}
              </OuterBox>
            )
          ) : null}
          {orderTrack.items[foodItemSlug].statusCode >= 100 ? (
            orderTrack.items[foodItemSlug].statusCode === 100 ? (
              getComponent(100, {
                key: orderTrack.items[foodItemSlug].statusCode,
                orderId: orderTrack.orderId,
                stageName: "Item waiting for acceptance",
                name: orderTrack.items[foodItemSlug].productName,
                serve: "-",
                ingredients: "-",
                note: "-",
                chef: "-",
                cookingTime: "-",
                image: orderTrack.items[foodItemSlug].productImage,
                selectedVariant: orderTrack.items[foodItemSlug].selectedVariant,
              })
            ) : (
              <OuterBox stageName="Order Details" stageNo={1}>
                {getComponent(100, {
                  key: orderTrack.items[foodItemSlug].statusCode,
                  orderId: orderTrack.orderId,
                  stageName: "Order Details",
                  name: orderTrack.items[foodItemSlug].productName,
                  serve: "-",
                  ingredients: "",
                  note: "-",
                  chef: "-",
                  cookingTime: "-",
                  image: orderTrack.items[foodItemSlug].productImage,
                  selectedVariant:
                    orderTrack.items[foodItemSlug].selectedVariant,
                })}
              </OuterBox>
            )
          ) : null}
        </div>
        <div className="col-span-6 lg:col-span-2 space-y-8">
          {/* Bill Details */}
          {/* <div className='hidden sm:block'>
            <BillCard />
          </div> */}
          <div className="space-y-2">
            {/* Address Section */}
            {orderTrack.orderType != "PICKUP" && (
              <div className="flex space-x-4 px-7 py-4 bg-FFFFFF bg-no-repeat rounded">
                <img src={HomeOutline} alt="Phone Icon" className="w-6 h-6" />
                <div className="space-y-2 font-open-sans">
                  <div className="font-semibold text-base text-left text-black">
                    {orderTrack.user.name}
                  </div>
                  <div className="text-left text-xs text-black w-2/3">
                    {orderTrack.user.address}
                  </div>
                </div>
              </div>
            )}
            {/* Restaurent Address  */}
            <div className="flex space-x-4 px-7 py-4 bg-FFFFFF bg-no-repeat rounded">
              <img src={Phone} alt="Phone Icon" className="w-6 h-6" />
              <div className="space-y-2 font-open-sans">
                <div className="font-semibold text-base text-left text-black">
                  {store.comapnyName}
                </div>
                <div className="text-left text-xs text-black">
                  {store.address}
                </div>
                <div className="text-left text-base text-black">
                  Need any help ? <b>call us</b>
                </div>
                <a
                  href={`tel:${store.orderPlacementNumber}`}
                  className="font-semibold text-xl text-8593FF"
                >
                  {store.orderPlacementNumber}
                </a>
              </div>
            </div>
            {/* Rate US Section */}
            <div className="flex space-x-4 px-7 pt-4 pb-16 bg-FFFFFF bg-no-repeat rounded">
              <img src={FoodRating} alt="Phone Icon" className="w-6 h-6" />
              <div className="space-y-2 font-open-sans">
                <div className="font-semibold text-base text-left text-black">
                  Don't forget to Rate US
                </div>
                <div className="flex space-x-4">
                  <Star
                    number={feedback.orderRating}
                    onSelect={setNoOfSelectedStars}
                    handleChange={handleStarChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default TrackOrder
