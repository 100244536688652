import urls from "../../urls";
import { getMenuUrl } from "./menu.service";

function handleResponse(response) {
  // console.log(typeof response,response,'text')
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data.error) {
      const error = data.error;
      return Promise.reject(error);
    }
    return data.data ?? { message: "Success" };
  });
}
function handleResponse1(response) {
  // console.log(response, 'handleResponse1')
  // response.text().then((result)=>console.log(result,'result'))
  // debugger;
  return JSON.parse(response).then((convertedData) => {
    // console.log(convertedData, 'convertedData')
    if (convertedData.error) {
      const error = convertedData.error;
      return Promise.reject(error);
    }

    return Promise.resolve(convertedData.data ?? { message: "Success" });
  });
  // console.log(data, 'data')
}
function placeSubscription(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: auth.token,
      mode: "no-cors",
    },
    body: JSON.stringify({ ...req }),
  };

  return fetch(urls["orderSubscription"], requestOptions).then(handleResponse);
}
function toQueryStringURL(queryURL, params) {
  const url = new URL(queryURL);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  return url;
}
function getSubscriptionLisitingService(restaurantId, userBasedMenu, req = {}) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: auth.token,
      mode: "no-cors",
    },
    body: JSON.stringify({ ...req?.data }),
  };
  const requestUrl = getMenuUrl(restaurantId, userBasedMenu, req);

  return fetch(requestUrl, requestOptions).then(
    handleResponse
  );
}
function trackSubscription(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const params = {
    ...req,
  };
  const url = toQueryStringURL(urls["trackOrder"], params);
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", token: auth.token },
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function getPastSubscription() {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const params = {
    user: auth.user,
  };
  const url = `${urls["subscriptionPastOrder"]}/user/${auth.user}`;

  const requestOptions = {
    method: "GET",
    headers: {
      token: auth.token,
      "Content-Type": "application/json",
      mode: "no-cors",
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}
function getSubscription(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const params = {
    ...req,
  };
  const url = `${urls["subscriptionPastOrder"]}/${req.subscriptionId}`;

  const requestOptions = {
    method: "GET",
    headers: {
      token: auth?.token,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}
function getSubscriptionOrders() {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const params = {
    user: auth.user,
  };
  const url = toQueryStringURL(urls["pastOrder"], params);

  const requestOptions = {
    method: "GET",
    headers: {
      token: auth.token,
      "Content-Type": "application/json",
    },
  };

  return fetch(url, requestOptions).then(handleResponse);
}
const cancelSubscription = (req) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const url = `${urls["cancelSubscription"]}/${req.subscriptionId}/cancel`;
  const requestOptions = {
    method: "POST",
    headers: {
      token: auth.token,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, requestOptions).then(handleResponse);
};

const cancelMeal = (req) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const url = `${urls["cancelSubscription"]}/${req.subscriptionId}/cancel/meal`;
  const requestOptions = {
    method: "POST",
    headers: {
      token: auth.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ meals: req.meals }),
  };
  return fetch(url, requestOptions).then(handleResponse);
};
const paymentUpdate = (req) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const url = `${urls["cancelSubscription"]}/${req.subscriptionId}/payment/update`;
  const requestOptions = {
    method: "POST",
    headers: {
      token: auth.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  };
  return fetch(url, requestOptions).then(handleResponse);
};
const getCompletedMeals = (req) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  // const url = `${urls['completedMeals']}subscriptionId=${req.subscriptionId}?userId=${req.userId}`;
  const url = `${urls["completedMeals"]}subscriptionId=${req.subscriptionId}&userId=${req.userId}`;
  const requestOptions = {
    method: "GET",
    headers: {
      token: auth?.token,
      "Content-Type": "application/json",
    },
  };
  return fetch(url, requestOptions).then(handleResponse);
};
export const subscriptionService = {
  getSubscriptionLisitingService,
  placeSubscription,
  trackSubscription,
  getPastSubscription,
  getSubscriptionOrders,
  cancelSubscription,
  paymentUpdate,
  cancelMeal,
  getCompletedMeals,
  getSubscription,
};
