import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useRouteMatch } from "react-router";
import { useSelector } from 'react-redux';

export default function NotFound() {
//   console.log("useRouteMatch",useRouteMatch())
const {auth} = useSelector((store)=>store)
const history = useHistory()
    if(auth.redirectTo){
        history.push(auth.redirectTo)
    }
    return (
        <div className="h-screen justify-center items-center font-roboto flex">
            <div className="">
                <div className="flex items-center">
                    <div className="text-4xl p-4">404</div>
                    <div className="p-4 border-l border-gray-400">This page could not be found</div>
                </div>
                <div className="flex justify-center mt-4">
                    <Link to='/'>
                        <button className="bg-gray-700 text-white w-32 h-10 rounded hover:bg-gray-900">Home</button>
                    </Link>
                </div>

            </div>
        </div>
    )
}
