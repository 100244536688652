import HotFood from "../../Assets/Icons/HotFood.png";
import WeightMachine from "../../Assets/Icons/WeightMachine.png";
import Tick from "../../Assets/Icons/tick.svg";
import DeliveredImage from "../../Assets/Images/delivered-image.png";
import deliveryMan from "../../Assets/Icons/Man.svg";
import Star from "../../Components/Star/Star";
import RatingCard from "./RatingCard";
import Phone from "../../Assets/Icons/Phone.png";
import threeStars from "../../Assets/Icons/Three-Stars.svg";
import item1 from "../../Assets/Images/Wheat@2x.png";
import item2 from "../../Assets/Images/PizzaImage@2x.png";
import Veg from "../../Assets/Images/Veg.svg";
import Nonveg from "../../Assets/Images/Nonveg.png";
import React, { useMemo } from "react";
import PackedFood from "../../Assets/Icons/PackedFood.svg";
import SideNav from "../../Components/SideNav/SideNav";
import { useEffect, useState } from "react";
import Chat from "../../Components/Chat/Chat";
import PhoneNutritionalFacts from "../FoodDetail/PhoneNutritionalFacts";
import WaiterImage from "../../Assets/Icons/waiter.svg";
import AcceptedSteps from "./AcceptedSteps";
import { useLocation, useRouteMatch } from "react-router-dom";
import { feedbackActions } from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import MapComponent from "./MapComponent";

export const OrderDetails = ({
  orderId,
  name,
  serve,
  ingredients,
  note,
  chef,
  cookingTime,
  image,
  selectedVariant,
}) => {
  const { client } = useSelector((store) => store);
  return (
    <div className="space-y-1 m-4 sm:m-0">
      {/* Food Detail */}
      <div className="flex justify-between bg-FFFFFF bg-no-repeat px-4 py-4 sm:py-8 sm:px-10  border-b-2 border-gray-200 space-x-2">
        <div className="flex-grow font-open-sans  space-y-1">
          <div className="font-semibold text-left text-base text-black">
            {name}
          </div>
          <div className="font-semibold text-left text-xs text-black">
            {client.clientType === "SCHOOL"
              ? "Confirm class for meal size"
              : selectedVariant?.serves !== null &&
                selectedVariant?.serves !== "" &&
                `Serves ${selectedVariant?.serves}`}
          </div>
          {/* <div className='text-left text-sm text-8B8B8B'>
            Ingridients: {ingredients}
          </div> */}
          <div className="font-semibold text-left text-xs text-D17B69 pt-4">
            Note: {note}
          </div>
        </div>
        <img
          src={image}
          alt="food item"
          className="object-cover rounded w-20 h-20 sm:w-28 sm:h-28"
        />
      </div>
      {/* Chef Detail */}
      <div className="bg-FFFFFF bg-no-repeat rounded py-8 px-10 space-y-1 font-open-sans">
        <div className="text-left text-xs text-black">Order Id: {orderId}</div>
        {
          <div className="text-left text-xs text-black pt-2">
            Prepared by:{" "}
            <div className="inline-block font-semibold text-sm">{chef}</div>
          </div>
        }
        {
          <div className="text-left text-xs text-black">
            Preparation Time:{" "}
            <div className="inline-block font-semibold text-sm">
              {cookingTime}
            </div>
          </div>
        }
      </div>
    </div>
  );
};
export const CookingDetails = (props) => {
  const { cookingData, key, itemStage } = props;
  // console.log(cookingData, itemStage, 'cookingData');
  const [isChatVisible, setIsChatVisible] = useState(false);
  return (
    <div className="py-2">
      {/* desktop view */}
      <div className="bg-white hidden lg:block p-4 md:p-14">
        <AcceptedSteps cooking={cookingData} active={itemStage === 200} />
        {cookingData.map((stage) => (
          <div className="font-open-sans">
            <div className="mb-6">
              <div className="font-bold text-lg">{stage.stageName}</div>
              <div className="text-sm text-gray-500">{stage.description}</div>
            </div>
            {stage.processes.map((item, index) => (
              <div
                key={index}
                // className='border-l-2 border-gray-500 border-dashed p-6 relative'
                className={`relative px-8 pb-8 ${
                  index !== stage.processes.length - 1
                    ? "border-l-2 border-gray-500 border-dashed p-0 relative"
                    : ""
                }`}
              >
                <div className="sm:flex justify-between">
                  {item.isCompleted ? (
                    <div className="h-5 w-5 rounded-full items-center justify-center flex bg-black absolute -top-1 -left-3 text-white">
                      <img src={Tick} className="w-3" alt="tick" />
                    </div>
                  ) : (
                    <div className="h-5 w-5 rounded-full items-center justify-center flex bg-white border border-black absolute -top-3 -left-3 text-black text-xs">
                      {index + 1}
                    </div>
                  )}

                  <div className="block">
                    <div className="text-base font-semibold">
                      {item.processName}
                    </div>
                    {/* <div className='text-xs'>{item.description}</div> */}
                    <div className="text-xs font-semibold mt-4">
                      Time :{item.timestamp}
                    </div>
                  </div>
                  <div
                    className="my-4 sm:my-0 grid grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 gap-2 basis-3/4"
                    style={{
                      flexBasis: "75%",
                    }}
                  >
                    {item.skus.map((sku) => (
                      <div className="col-span-1 flex flex-col items-center">
                        <img
                          className="w-16 h-auto rounded-md"
                          src={sku.image}
                          alt={sku.name}
                        />
                        <div className="text-center text-sm">{sku.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* {item.processImage && (
                <div className='flex justify-between items-center'>
                  <img
                    src={item.processImage}
                    className='w-1/2 h-1/2 rounded'
                    alt='Process'
                  />
                  {/* <button
                    onClick={() => setIsChatVisible(true)}
                    className='bg-8593FF w-32 h-10 rounded text-white text-sm'>
                    TALK TO CHEF
                  </button> */}
                {/* } */}
                <div className="fixed bottom-24 right-5 sm:right-8 md:right-24 z-20 flex items-end justify-end">
                  <div>
                    <img
                      onClick={() => setIsChatVisible(true)}
                      src={WaiterImage}
                      alt="waiter"
                      className=" w-12 h-12 sm:w-16 sm:h-16"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {/* mobile view */}
      <div className=" bg-white block lg:hidden  p-4  m-4 font-open-sans">
        {/* <div className='overflow-hidden relative rounded my-4'>
          <img
            className='h-32 w-full'
            src={cookingData[0].mainImage}
            alt='main'
          />
          <button
            onClick={() => setIsChatVisible(true)}
            className='absolute top-0 right-0 bg-8593FF w-32 h-10 rounded text-white text-sm'>
            TALK TO CHEF
          </button>
        </div> */}
        <div className="fixed bottom-24 right-5 sm:right-8 md:right-24 z-20 flex items-end justify-end">
          <div>
            <img
              onClick={() => setIsChatVisible(true)}
              src={WaiterImage}
              alt="waiter"
              className=" w-12 h-12 sm:w-16 sm:h-16"
            />
          </div>
        </div>
        <AcceptedSteps cooking={cookingData} active={itemStage === 200} />
        {cookingData.map((stage, cookingDataIndex) => (
          <div>
            <div className="mb-6">
              <div className="font-bold text-lg">{stage.stageName}</div>
              <div className="text-sm text-gray-500">{stage.description}</div>
            </div>
            {stage.processes.map((item, index) => (
              <div
                className={`relative pl-8 pb-2 ${
                  index !== stage.processes.length - 1
                    ? "border-l-2 border-dashed border-gray-300"
                    : "border-l-2 border-dashed border-F5F5F5"
                }`}
              >
                <div className="bg-white bg-no-repeat rounded-xl mb-4 px-4 py-4 space-y-2">
                  <div className="flex justify-between">
                    <div className="text-xs text-8B8B8B font-semibold uppercase">
                      step {index + 1}
                    </div>
                    <div className="text-xs text-8B8B8B font-semibold">
                      {item.timestamp}
                    </div>
                    {/* <AcceptedSteps cooking={}/> */}
                  </div>
                  <div className="font-open-sans font-semibold text-left text-xs sm:text-sm text-181818">
                    {item.processName}
                  </div>
                  {/* <div className='font-open-sans text-left text-2xs sm:text-sm text-8B8B8B'>
                    {item.description}
                  </div> */}
                  <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 gap-2">
                    {item.skus.map((sku, index) => (
                      <div className="rounded">
                        <img
                          src={sku.image}
                          alt="recipe"
                          className="object-cover w-full h-full"
                        />
                        <div className="text-center text-sm">{sku.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
                {item.isCompleted ? (
                  <div className="h-7 w-7 rounded-full items-center justify-center flex bg-black absolute -top-1 -left-3 text-white">
                    <img src={Tick} className="w-3" alt="tick" />
                  </div>
                ) : (
                  <div className="absolute top-0 -left-4 z-10 bg-F5F5F5 border-2 border-gray-300 text-gray-300 text-2xs rounded-full px-2.5 py-1.5">
                    {index + 1}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <SideNav onClose={setIsChatVisible} visible={isChatVisible} mode="dark">
        <Chat />
      </SideNav>
    </div>
  );
};

export const QualityCheck = ({
  temp,
  weight,
  image,
  finishTime,
  selectedVariant,
  nutrition
}) => {
  return (
    <div className="bg-FFFFFF bg-no-repeat rounded m-4 sm:m-0 px-4 py-4 lg:px-14 lg:py-16 space-y-3">
      <div className="font-open-sans font-bold text-left text-base text-black">
        Quality Check Results
      </div>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-6  sm:col-span-4 flex flex-col space-y-4">
          <div className="flex space-x-2">
            {temp && (
              <div className="bg-7FB069 bg-no-repeat font-open-sans text-center font-semibold text-white text-base h-14 space-x-4 py-4 w-full rounded">
                <img
                  src={HotFood}
                  alt="hot food"
                  className="w-5 h-5 inline-block"
                />
                <div className="inline-block">{temp}&deg; C</div>
              </div>
            )}
            {weight && (
              <div className="bg-7FB069 bg-no-repeat font-open-sans text-center font-semibold text-white text-base  h-14 py-4 space-x-4 w-full rounded">
                <img
                  src={WeightMachine}
                  alt="weight machine"
                  className="h-5 w-5 inline-block"
                />
                <div className="inline-block">{weight} gm</div>
              </div>
            )}
          </div>
          <div className="overflow-hidden w-full h-72 rounded-xl">
            <img
              src={image}
              alt="ordered food"
              className="object-cover h-full w-full"
            />
          </div>
          {nutrition && (
            <PhoneNutritionalFacts
              nutritionalFacts={selectedVariant.nutritionalFacts ?? nutrition}
            />
          )}
          <div className="flex items-center">
            <div className="flex-grow font-open-sans font-semibold text-left text-lg text-black">
              Food packed at:{" "}
              <div className="inline-block text-7FB069 lowercase">
                -{/* {finishTime} */}
                {/* - */}
              </div>
            </div>
            <div className="w-8 h-8">
              <img
                src={PackedFood}
                alt="food package"
                className="h-full w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const OrderDispatch = React.memo(
  ({
    driverName,
    driverNumber,
    driverRating,
    arrivingTime,
    mapImage,
    foodItems,
    latitude,
    longitude,
  }) => {
    useEffect(() => {
      console.log("mounted");
    }, []);
    let data = useMemo(() => {
      console.log("lat long", latitude, longitude);
      return `https://www.google.com/maps/embed/v1/place?key=AIzaSyC10NmGXi8N5-A7pvbW8dBNv2Sp0ngwsK0&q=${latitude},${longitude}`;
    }, [latitude, longitude]);

    return (
      <div className="flex flex-col bg-FFFFFF bg-no-repeat rounded m-4 lg:m-0 p-4 lg:p-14 space-y-8">
        <div className="flex flex-col space-y-4">
          {/* <img
          src={mapImage}
          alt='delivery service man'
          className='object-cover w-112 h-80'
        /> */}
          <MapComponent data={data} />

          <div className="flex justify-between">
            {/* <div className='bg-FFFFFF bg-no-repeat border border-8593FF rounded-md font-open-sans font-light text-center text-xs text-5A5A5A p-2'>
							ARRIVING IN:{' '}
							<div className='inline-block font-open-sans font-semibold text-center text-base text-D17B69'>
								{'30 Mins'}
							</div>
						</div> */}
            <div className="bg-FFFFFF bg-no-repeat border border-8593FF rounded-md font-open-sans  font-bold text-center text-base text-black p-2">
              ON TIME
            </div>
          </div>
        </div>
        <div className="flex space-x-6 py-8 border-b border-gray-300">
          <img src={deliveryMan} alt="delivery boy" className="w-5 h-7" />
          <div className=" flex-grow space-y-1 font-open-sans">
            <div className="font-semibold text-left  sm:text-lg text-black">
              {driverName}
            </div>
            <div className="flex items-center space-x-4">
              <img src={Phone} alt="phone" className="w-4 h-4" />
              <div className="font-semibold text-left  sm:text-base text-black">
                {driverNumber}
              </div>
              <div className="bg-FFFFFF bg-no-repeat border border-8593FF rounded-md text-center text-8593FF text-xs px-6 py-1 cursor-pointer hover:text-white hover:bg-indigo-400">
                Call
              </div>
            </div>
            <div className="text-left text-xs text-black pt-4">
              Our Delivery Valet {driverName} is on his way to deliver your food
            </div>
          </div>
          {/* <div className='hidden sm:flex space-x-2 md:space-x-4'>
						<Star number={driverRating} viewOnly={true} />
					</div> */}
        </div>

        {/* <div className='flex flex-col border-b border-gray-300 space-y-4 pb-8'> */}
        {/* {foodItems.map((item, index) => (
          <div className='flex items-center space-x-4 sm:px-10 font-open-sans'>
            <img
              src={item.veg ? Veg : Nonveg}
              alt='veg or nonveg symbol'
              className='w-5 h-5'
            />
            <div className='flex-grow font-semibold text-left text-sm sm:text-base text-181818'>
              {item.quantity} X {item.name}
            </div>
            <div className='font-semibold text-left text-xs text-8593FF'>
              Your food is on way
            </div>
          </div>
        ))} */}
        {/* </div> */}
      </div>
    );
  }
);
export const Delivered = ({
  driverName,
  driverNumber,
  driverRating,
  arrivingTime,
  foodItems,
  latitude,
  longitude,
  invoice,
}) => {
  const location = useRouteMatch().params;
  const dispatch = useDispatch();
  const { store } = useSelector((store) => store);
  const { foodItemSlug } = location;
  // console.log(foodItems, 'foodItems', useRouteMatch().params);
  const [qualityRating, setQualityRating] = useState(
    foodItems[location.foodItemSlug].productQualityRating
  );
  const [tasteRating, setTasteRating] = useState(
    foodItems[location.foodItemSlug].productTasteRating
  );
  const [packageRating, setPackageRating] = useState(
    foodItems[location.foodItemSlug].productPackagingRating
  );
  // useEffect(() => {
  // 	setQualityRating(foodItems[location.foodItemSlug].productQualityRating);
  // 	setTasteRating(foodItems[location.foodItemSlug].productTasteRating);
  // 	setPackageRating(
  // 		foodItems[location.foodItemSlug].productPackagingRating
  // 	);
  // }, []);
  useEffect(() => {
    setPackageRating(foodItems[location.foodItemSlug].productPackagingRating);
    setTasteRating(foodItems[location.foodItemSlug].productTasteRating);
    setQualityRating(foodItems[location.foodItemSlug].productQualityRating);
  }, [foodItems[location.foodItemSlug]]);
  const handleStarChange = (number, type) => {
    var body = {};
    if (type === "taste") {
      setTasteRating(number);
      body = { productTasteRating: number };
    } else if (type === "quality") {
      setQualityRating(number);
      body = { productQualityRating: number };
    } else if (type === "packaging") {
      setPackageRating(number);
      body = { productPackagingRating: number };
    }
    const feedback = {
      orderId: location.orderId,
      feedback: {
        item: {
          [location.foodItemSlug]: {
            productQualityRating: qualityRating,
            productTasteRating: tasteRating,
            productPackagingRating: packageRating,
            ...body,
          },
        },
      },
    };
    dispatch(feedbackActions.setItemFeedback(feedback));
  };
  const handleOrderFeedback = (number) => {
    const feedback = {
      orderId: location.orderId,
      feedback: {
        orderRating: number,
      },
    };
    dispatch(feedbackActions.setOrderingExperience(feedback));
  };
  return (
    <div className="font-open-sans">
      <div className="p-6 lg:p-14 bg-white">
        <div className="bg-F8FFF5 w-full lg:w-2/3 p-10 rounded-2xl shadow overflow-hidden">
          <img src={DeliveredImage} alt="Delivered" />
          {/* <div className='text-7FB069 text-sm text-center  sm:-mt-10 font-semibold'>
						{driverName !== 'self'
							? 'Delivered at -'
							: 'Picked up at -'}
					</div> */}
        </div>
      </div>
      <div className="flex border-t-2 bg-white border-gray-50 p-6 sm:px-10 sm:py-8 space-x-5">
        <div>
          <img src={deliveryMan} alt="delivery Man" />
        </div>
        <div className="flex-grow text-xs">
          {driverName !== "self" && (
            <div>
              Our Delivery Valet {driverName} have delivered food to you on
              time.
            </div>
          )}
          <div>
            For any issue call us on <b>{store.customerSupport}</b>
          </div>
          {invoice && (
            <div
              className="text-8593FF mt-5"
              onClick={() => {
                window.open(invoice);
              }}
            >
              Download Invoice
            </div>
          )}
        </div>
        {/* {driverName !== 'self' && (
					<div className='hidden sm:block space-x-4'>
						<Star number={driverRating} viewOnly={true} />
					</div>
				)} */}
      </div>
      <div className="px-4 sm:px-0">
        <div className="text-base font-bold my-6">Rate your experience</div>
        {/* <div className='bg-white p-5 font-open-sans rounded mb-4'>
					<div className='flex'>
						<div className='flex-grow'>
							<div className='flex flex-col space-y-4'>
								<div className='text-xs'>Rate this dish</div>
								<div className='flex space-x-8'>
									<Star number={3} handleChange={handleOrderFeedback}/>
								</div>
							</div>
						</div>
						<div>
							<img
								src={threeStars}
								className='h-7 w-7 sm:w-10 sm:h-10'
								alt='stars'
							/>
						</div>
					</div>
				</div> */}
      </div>

      <div className="px-4 sm:px-0 mb-4">
        <RatingCard
          itemName={foodItems[foodItemSlug].prouductName}
          qualityRating={qualityRating}
          packageRating={packageRating}
          tasteRating={tasteRating}
          ItemImage={foodItems[foodItemSlug].productImage}
          location={location.foodItemSlug}
          setQualityRating={setQualityRating}
          setPackageRating={setPackageRating}
          setTasteRating={setTasteRating}
          handleChange={handleStarChange}
        />
      </div>
    </div>
  );
};
