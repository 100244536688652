import { clientConstants } from '../Constants';
import { clientService } from '../Services';
import toast from "react-hot-toast";

export const clientActions = {
    getClientDetails
};

function getClientDetails(req) {
    return dispatch => {
        dispatch(request([]));
        clientService.getClientDetails(req)
            .then(client => {
                dispatch(success(client));
            }, error => {
                dispatch(failure(error));
                toast.error(error)
            });
    }
    function request(payload) { return { type: clientConstants.CLIENT_REQUEST, payload } }
    function success(payload) { return { type: clientConstants.CLIENT_SUCCESS, payload } }
    function failure(payload) { return { type: clientConstants.CLIENT_FAILURE, payload } }
}