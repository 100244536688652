import React from 'react';
import { useSelector } from 'react-redux';
import FinalFoodCard from '../../Components/Cards/FoodCard/FinalFoodCard';
import { isEmpty } from '../../Functions/helpersFunctions';

const ScrollableFoodList = ({
	categoryId,
	productId,
	foodItems,
	orderType,
}) => {
	const { menu } = useSelector((state) => state);

	const getSimilarResults = (foodItemId) => {
		if (
			menu.products[foodItemId].categoryId === categoryId &&
			menu.products[foodItemId].productId !== productId
		) {
			return menu.products[foodItemId];
		}
		return null;
	};

	return (
		isEmpty(foodItems) && (
			<div className='bg-no-repeat bg-white p-9 rounded'>
				<div className='flex flex-row justify-between'>
					<div className='text-left text-464646 capitalize not-italic font-semibold text-lg font-open-sans'>
						View Similar Results
					</div>
				</div>
				<div className='flex flex-nowrap mt-4 overflow-x-auto bgWhiteScrollbar space-x-4 py-4 '>
					{Object.keys(foodItems).map((foodItemId, index) => {
						const foodItem = getSimilarResults(foodItemId);
						return (
							foodItem && (
								<div key={foodItemId}>
									<div className='w-112'>
										<FinalFoodCard
											key={foodItem.productId}
											productAvailable={
												foodItem.isProductAvailable
											}
											foodItem={foodItem}
											productId={foodItem.productId}
											productType={foodItem.productType}
											description={foodItem.description}
											discount={foodItem.discount}
											selectedVariant={
												foodItem.selectedVariant
											}
											displayVariants={
												foodItem.displayVariants
											} // TRUE | FALSE (Sizes)
											variants={foodItem.variants} // Sizes
											image={foodItem.image}
											isCustomizable={
												foodItem.isCustomizable
											}
											isVeg={foodItem.isVeg}
											menuTags={foodItem.menuTags}
											tags={foodItem.tags}
											name={foodItem.name}
											ratings={foodItem.ratings}
											tax={foodItem.tax}
											type={orderType}
					// 						foodItemNotAvailableDueToTime={
                    //     foodItem.notAvailableDueToTime
                    //   }
                    //   foodItemNotAvailableDueToDate={
                    //     foodItem.notAvailableDueToDate
                    //   }
											subscriptionFrom={
												foodItem.subscriptionFrom
											}
											subscriptionTo={
												foodItem.subscriptionTo
											}
										/>
									</div>
								</div>
							)
						);
					})}
				</div>
			</div>
		)
	);
};

export default ScrollableFoodList;
