import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  cartActions,
  menuActions,
  modalActions,
  orderActions,
} from "../../Redux/Actions";
import SideNav from "../SideNav/SideNav";
import Veg from "../../Assets/Images/Veg.svg";
import Nonveg from "../../Assets/Images/Nonveg.png";
import SelectedTick from "../../Assets/Icons/SelectedTick.svg";
import toast from "react-hot-toast";
import { cloneDeep, get, remove, update } from "lodash";
import { useParams } from "react-router";
import Modal from "../Modal/Modal";
import { addAccordingToType } from "../../Functions/CartFunctions";
import styles from "./ComboModal.module.css";
import { calculeteNoOfDays } from "../../Functions/GetTime";
import {
  getProcessObj,
  handleAddCustomizationCopy,
} from "../../Functions/helpersFunctions";

// Classes for selected, not selected sizes and hover effects
const selectedSizeClass =
  "bg-7FB0692C hover:bg-7FB0692C bg-no-repeat border border-7FB0693B rounded-sm opacity-70 text-center not-italic font-semibold font-open-sans text-xs sm:text-sm xl:text-base text-2F2F2F cursor-pointer py-2 px-1";
const unSelectedSizeClass =
  "hover:bg-7FB0692C bg-no-repeat border border-7FB0693B rounded-sm opacity-70 text-center not-italic font-semibold font-open-sans text-xs sm:text-sm xl:text-base text-2F2F2F cursor-pointer py-2 px-1";

const initialState = {
  activeScreen: 0,
  selection: {},
};

function ComboModal(props) {
  const {
    show,
    data,
    cart,
    product,
    products,
    toggleComboModal,
    orderInfo,
    customization,
    menu,
    clientType,
  } = props;

  const [state, setState] = useState({
    ...initialState,
  });
  const [customisationState, setCustomisation] = useState({});
  const [appliedCustomization, setAppliedCustomization] = useState({});
  const [removedProcessList, setRemovedProcessList] = useState({});
  const [isFixedCombo, setIsFixedCombo] = useState(false);
  const { activeScreen, selection } = state;

  const activeGroup =
    product && product.selectedVariant
      ? Object.keys(product.customisation[product.selectedVariant.id])[
          activeScreen
        ]
      : null;
  useEffect(() => {
    if (isFixedCombo) {
      handleAddToCart();
      setIsFixedCombo(false);
    }
  }, [isFixedCombo]);
  const [itemCount, setItemCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const { orderType } = useParams();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    //if product is fixed combo directly add it into the cart
    // show all products that are default as selected
    if (product && product.selectedVariant && activeGroup) {
      const tempSelection = {};
      let fixedCombo = true;
      if (product.variants.length > 1) {
        fixedCombo = false;
      }
      const currentVariantItemsObj =
        product.customisation[product.selectedVariant.id];
      const currentVariantItems = Object.keys(currentVariantItemsObj);
      currentVariantItems.forEach((comboGroupItem) => {
        const comboGroupItems =
          currentVariantItemsObj[comboGroupItem]["comboGroupItem"];
        const comboGroupItemsObj = Object.keys(comboGroupItems);
        comboGroupItemsObj.forEach((currentItem) => {
          if (comboGroupItems[currentItem].isDefault) {
            tempSelection[comboGroupItem] = {
              ...comboGroupItems[currentItem],
              comboGroupItemId: currentItem,
              itemCustomization: {},
              name: products[comboGroupItems[currentItem].productId].name,
            };
          } else if (!comboGroupItems[currentItem].isDefault && fixedCombo) {
            fixedCombo = false;
          }
        });
      });
      // debugger
      setIsFixedCombo(fixedCombo);
      setState({
        ...state,
        selection: {
          ...selection,
          ...tempSelection,
        },
      });
    }
  }, [product?.selectedVariant]);
  useEffect(() => {
    // debugger
    if (data.isEdit) {
      // set state according to data
      setState({
        ...state,
        selection: cloneDeep(product.finalCustomisation),
      });
      const tempAppliedCustomization = {};
      const tempCustomisation = {};
      for (const groupId in product.finalCustomisation) {
        if (
          Object.prototype?.hasOwnProperty?.call(
            product.finalCustomisation,
            groupId
          )
        ) {
          const group = product.finalCustomisation[groupId];
          tempAppliedCustomization[groupId] = cloneDeep(
            get(group.itemCustomization, "appliedCustomization", {})
          );
          tempCustomisation[groupId] = products[group.productId].customisation;
        }
      }
      setAppliedCustomization(tempAppliedCustomization);
      setCustomisation(tempCustomisation);
    }
  }, [data]);

  useEffect(() => {
    if (product) {
      // const activeGroup = Object.keys(
      // 	product.customisation[product.selectedVariant.id]
      // )[activeScreen];
      // debugger;

      if (
        product.isCustomizable &&
        selection[activeGroup] &&
        products[selection[activeGroup].productId].isCustomizable
      ) {
        setCustomisation({
          ...customisationState,
          [activeGroup]:
            products[selection[activeGroup].productId].customisation,
        });
        if (
          !Object.prototype?.hasOwnProperty?.call(
            appliedCustomization,
            activeGroup
          ) ||
          (Object.prototype?.hasOwnProperty?.call(
            appliedCustomization,
            activeGroup
          ) &&
            appliedCustomization[activeGroup].productId !==
              selection[activeGroup].productId)
        ) {
          const groupItem =
            product.customisation[product.selectedVariant.id][
              selection.activeGroup
            ];
          setAppliedCustomization({
            ...appliedCustomization,
            [activeGroup]: initCustomization(
              products[selection[activeGroup].productId],
              false,
              groupItem.optionalGroup ? {} : groupItem
            ),
          });
        }
      }
    }
  }, [activeScreen, product, selection]);

  const initCustomization = (choiceItem, reset = false, groupItem = {}) => {
    // if  reset is false then update the applied customization from props
    // else it will be reset to default customization
    // const activeGroup = Object.keys(
    // 	product.customisation[product.selectedVariant.id]
    // )[activeScreen];
    if (!reset) {
      if (props.type === "SAVE" && customization[choiceItem.productId]) {
        return customization[choiceItem.productId].appliedCustomization;
      }
      if (props.type === "EDIT" && props.appliedCustomization) {
        return props.appliedCustomization;
      }
    }
    let selectedVariant = choiceItem.variants.find((variant) => {
      return variant.id === selection[activeGroup].variantId;
    });
    let tempAppliedCustomization = {};

    Object.keys(choiceItem.customisation).forEach((processId) => {
      const process = choiceItem.customisation[processId];

      if (
        process.variantIdApplicableOn.includes(selectedVariant.id.toString())
      ) {
        let processData = [];
        if (process.processType === "REPLACEABLE") {
          processData = {
            to: processData,
            from: [],
          };
        }
        if (!process.processRemovable || process.processType !== "ADDON") {
          tempAppliedCustomization = {
            ...tempAppliedCustomization,
            ...{ [process.processId]: processData },
          };
        }
      }
    });
    tempAppliedCustomization.productId = choiceItem.productId;
    return tempAppliedCustomization;
  };

  const getDefaultItems = (process) => {
    const defaultItems = Object.keys(process.items).reduce((acc, curr) => {
      const item = process.items[curr];
      if (item.isDefault) {
        return [...acc, item];
      } else {
        return acc;
      }
    }, []);
    return {
      defaultItems,
      totalDefaultItems: defaultItems.length,
    };
  };

  const getNonDefaultItems = (process) => {
    const nonDefaultItems = Object.keys(process.items).reduce((acc, curr) => {
      const item = process.items[curr];
      if (item.isDefault) {
        return acc;
      } else {
        return [...acc, item];
      }
    }, []);

    return {
      nonDefaultItems,
      totalNonDefaultItems: nonDefaultItems.length,
    };
  };

  const itemClickHandler = (process, item, isItemSelected) => {
    // const activeGroup = Object.keys(
    // 	product.customisation[product.selectedVariant.id]
    // )[activeScreen];
    // debugger;
    let processData = cloneDeep(
      appliedCustomization[activeGroup][process.processId]
    );
    let { defaultItems, totalDefaultItems } = getDefaultItems(process);
    if (process.processType === "REPLACEABLE") {
      // if only one is replaceable, acts as selection
      if (process.maxItemCustomizableLimit === 1 && totalDefaultItems === 1) {
        // if item is not selected only then the selection needs to be changed
        if (!isItemSelected) {
          // if default item is clicked, clear 'to' and 'from' to reset to default
          if (item.id === defaultItems[0].id) {
            processData["to"] = [];
            processData["from"] = [];
          } else {
            // if 'from' is empty, add default item - because customization is made and default is removed
            if (processData["from"].length === 0) {
              processData["from"] = [defaultItems[0]];
            }
            // add newly selected item
            processData["to"] = [item];
          }
        }
        // multi-item replacement logic
      } else {
        if (isItemSelected) {
          const index = processData["to"].findIndex(
            (processItem) => processItem.id === item.id
          );
          if (index > -1) {
            processData["to"].splice(index, 1);
          }
          if (item.isDefault) processData["from"].push(item);
        } else {
          const index = processData["from"].findIndex(
            (processItem) => processItem.id === item.id
          );
          if (index > -1) {
            processData["from"].splice(index, 1);
          }
          if (item.isDefault) {
            processData["to"].splice(index, 1);
          } else {
            // if number items added are greater than or equal to removed items, it is an invalid action to add another item without 'unselecting' its replacement
            if (processData["to"].length >= processData["from"].length) {
              toast.error("First remove an item to add its replacement");
              return;
            }
            processData["to"].push(item);
          }
        }
      }
    } else {
      // if addon limit is exceeded display error message
      if (
        process.processType === "ADDON" &&
        process.maxItemCustomizableLimit <=
          appliedCustomization[activeGroup][process.processId].length &&
        !isItemSelected
      ) {
        toast.error(
          `Cannot add more than ${process.maxItemCustomizableLimit} items.`
        );
        return;
      }
      // if removeable limit is exceeded display error message
      if (
        process.processType === "REMOVABLE" &&
        process.maxItemCustomizableLimit <=
          appliedCustomization[activeGroup][process.processId].length &&
        isItemSelected
      ) {
        toast.error(
          `Cannot remove more than ${process.maxItemCustomizableLimit} items.`
        );
        return;
      }
      if (
        (process.processType === "ADDON" && isItemSelected) ||
        (process.processType === "REMOVABLE" && !isItemSelected)
      ) {
        const index = processData.findIndex(
          (processItem) => processItem.id === item.id
        );
        if (index > -1) {
          processData.splice(index, 1);
        }
      } else processData.push(item);
    }
    let updatedAppliedCustomization = {
      ...appliedCustomization[activeGroup],
      ...{ [process.processId]: processData },
    };
    setAppliedCustomization({
      ...appliedCustomization,
      [activeGroup]: updatedAppliedCustomization,
    });
  };

  const getStatus = (process, item) => {
    let isItemSelected = false;
    let showRemoveBtn = true;
    let showAddBtn = true;
    // const activeGroup = Object.keys(
    // 	product.customisation[product.selectedVariant.id]
    // )[activeScreen];
    // debugger
    if (!(process.processId in appliedCustomization[activeGroup]))
      return {
        isItemSelected: false,
        showRemoveBtn: false,
        showAddBtn: false,
      };

    if (process.processType === "REPLACEABLE") {
      if (
        appliedCustomization[activeGroup][process.processId]["to"].find(
          (processItem) => processItem.id === item.id
        ) ||
        (!appliedCustomization[activeGroup][process.processId]["from"].find(
          (processItem) => processItem.id === item.id
        ) &&
          item.isDefault)
      )
        isItemSelected = true;
      if (
        appliedCustomization[activeGroup][process.processId]["from"].length <=
        appliedCustomization[activeGroup][process.processId]["to"].length
      )
        showAddBtn = false;
      if (
        item.isDefault &&
        process.maxItemCustomizableLimit <=
          appliedCustomization[activeGroup][process.processId]["from"].length
      )
        showRemoveBtn = false;
    } else {
      if (
        process.processType === "REMOVABLE" &&
        !appliedCustomization[activeGroup][process.processId].find(
          (processItem) => processItem.id === item.id
        )
      )
        isItemSelected = true;
      else if (
        process.processType === "ADDON" &&
        appliedCustomization[activeGroup][process.processId].find(
          (processItem) => processItem.id === item.id
        )
      ) {
        isItemSelected = true;
      }
    }

    if (
      process.processType === "ADDON" &&
      process.maxItemCustomizableLimit <=
        appliedCustomization[activeGroup][process.processId].length
    )
      showAddBtn = false;

    showAddBtn = showAddBtn && !isItemSelected;
    showRemoveBtn = showRemoveBtn && isItemSelected;

    return { isItemSelected, showRemoveBtn, showAddBtn };
  };

  // const getProcessObj = (group, processId) => {
  // 	for (let pId in customisationState[group]) {
  // 		const process = customisationState[group][pId];
  // 		if (pId === processId) return process;
  // 	}
  // 	return null;
  // };

  const getProcessDetails = (process) => {
    let displayText = "";

    switch (process.processType) {
      case "REPLACEABLE":
        displayText = `(You can replace any ${process.maxItemCustomizableLimit} ingredient(s))`;
        break;
      case "ADDON":
        displayText = `(You can add upto ${process.maxItemCustomizableLimit} ingredients(s))`;
        break;
      case "REMOVABLE":
        displayText = `(You can remove any ${process.maxItemCustomizableLimit} ingredient(s))`;
        break;
      default:
        displayText = "";
    }
    return displayText;
  };

  const processClickHandler = (process) => {
    // const activeGroup = Object.keys(
    // 	product.customisation[product.selectedVariant.id]
    // )[activeScreen];
    let updatedAppliedCustomization = { ...appliedCustomization };
    let updateRemovedProcessList = { ...removedProcessList };
    if (process.processId in updatedAppliedCustomization[activeGroup]) {
      delete updatedAppliedCustomization[activeGroup][process.processId];
      if (!updateRemovedProcessList[activeGroup]) {
        updateRemovedProcessList[activeGroup] = [];
      }
      updateRemovedProcessList[activeGroup].push(process.processId);
    } else {
      let processData = [];
      if (process.processType === "REPLACEABLE")
        processData = { to: processData, from: [] };
      updatedAppliedCustomization = {
        ...appliedCustomization,
        [activeGroup]: {
          ...updatedAppliedCustomization[activeGroup],
          ...{ [process.processId]: processData },
        },
      };
      const index = updateRemovedProcessList[activeGroup].indexOf(
        process.processId
      );
      if (index > -1) {
        updateRemovedProcessList[activeGroup].splice(index, 1);
      }
    }
    setAppliedCustomization(updatedAppliedCustomization);
    setRemovedProcessList(updateRemovedProcessList);
  };

  const resetCustomization = () => {
    // const activeGroup = Object.keys(
    // 	product.customisation[product.selectedVariant.id]
    // )[activeScreen];
    const choiceItem = products[selection[activeGroup].productId];
    setAppliedCustomization({
      ...appliedCustomization,
      [activeGroup]: initCustomization(choiceItem, true),
    });
    setRemovedProcessList({
      ...removedProcessList,
      [activeGroup]: [],
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const submitModal = () => {
    const product = products[product.productId];
    // 1. Empty Cart
    dispatch(cartActions.clearCartStore());
    // 2. Update Order type -> store and local storage
    dispatch(
      orderActions.updateOrderStore({
        orderType: orderType,
        noOfDays:
          orderType === "schedule"
            ? calculeteNoOfDays(
                new Date(orderInfo.startDate),
                new Date(orderInfo.endDate),
                orderInfo.frequencyDays
              )
            : 1,
        deliverNow:
          orderInfo.type == "later" || orderType == "schedule" ? false : true,
      })
    );
    // 3. Add Item to cart
    addAccordingToType(
      products,
      product.productId,
      customization,
      orderType,
      itemCount,
      setItemCount,
      dispatch,
      orderInfo,
      product.name,
      product.selectedVariant,
      selection
    );
    setIsOpen(false);
  };

  const handleClose = () => {
    toggleComboModal(false);
    document.body.classList.remove("stop-scrolling");
    setState({
      ...initialState,
    });
    setAppliedCustomization({});
    setRemovedProcessList({});
    setCustomisation({});
  };

  const handleScreenChange = (newScreen) => {
    setState({
      ...state,
      activeScreen: newScreen,
    });
  };

  const handleGroupItemSelection = ({ groupId, groupItem, groupItemId }) => {
    const newState = update(cloneDeep(state), "selection", (selectionObj) => {
      selectionObj[groupId] = {
        comboGroupItemId: groupItemId,
        productId: groupItem.productId,
        variantId: groupItem.variantId,
        itemCustomization: {},
        name: products[groupItem.productId].name,
      };
      return selectionObj;
    });
    setState(newState);
  };

  const validateCombo = () => {
    return Object.keys(product.customisation[product.selectedVariant.id]).some(
      (groupId) => {
        const group =
          product.customisation[product.selectedVariant.id][groupId];
        const isOptional =
          group.optionalGroup ||
          Object.keys(group.comboGroupItem).every((groupItemId) => {
            const groupItem = group.comboGroupItem[groupItemId];
            return !groupItem.isDefault;
          });
        const isGroupInvalid =
          !isOptional &&
          !Object.prototype?.hasOwnProperty?.call(selection, groupId);
        if (isGroupInvalid) {
          toast.error(`Please select an item from '${group.comboGroupName}'`);
        }
        return isGroupInvalid;
      }
    );
  };

  const handleAddToCart = () => {
    if (validateCombo()) return;
    // If cart length empty
    if (cart.length === 0) {
      // Update Order type in store
      // dispatch(
      //   orderActions.updateOrderStore({
      //     orderType: orderType,
      // 		noOfDays:
      // 		orderType === "schedule"
      // 			? calculeteNoOfDays(
      // 				new Date(orderInfo.startDate),
      // 				new Date(orderInfo.endDate),
      // 				orderInfo.frequencyDays
      // 			)
      // 			: 1,
      //     deliverNow:
      //       orderType === "schedule" ||
      //       (orderType === "regular" && orderInfo.type=='later')
      //         ? false
      //         : true,
      //   })
      // );
    }
    // If cart not empty, check the order type
    else {
      // if order type is not same, then show warning modal
      // modal says -> your order type is different and will clear cart
      if (orderType !== orderInfo.orderType) {
        // Activate Modal
        // If user does not changes type, then do not add item to cart
        // If user changes type
        // 1. Empty Cart
        // 2. Update Order type
        // 3. Add Item to cart
        setIsOpen(true);
        return;
      }
    }
    let quantity = 1;
    // remove previous customisation from cart when editing
    if (data.isEdit) {
      quantity = cart[data.index].quantity;
      dispatch(cartActions.removeFromCartByIndex(data.index), quantity);
    }
    // Add item to cart
    // debugger;
    addAccordingToType(
      products,
      product.productId,
      customization,
      orderType,
      itemCount,
      setItemCount,
      dispatch,
      orderInfo,
      product.name,
      product.selectedVariant,
      handleAddCustomizationCopy(
        selection,
        product,
        appliedCustomization,
        removedProcessList,
        products,
        getProcessObj
      ),
      quantity
    );

    handleClose();
  };

  // const handleAddCustomization = () => {
  // 	const selectionClone = cloneDeep(selection);
  // 	for (const group of Object.keys(
  // 		product.customisation[product.selectedVariant.id]
  // 	)) {
  // 		let finalItemCustomisation = {
  // 			addedItems: {},
  // 			removedItems: {},
  // 			replacedItems: {},
  // 			removedProcess: [],
  // 		};

  // 		if (!appliedCustomization[group]) {
  // 			continue;
  // 		}

  // 		if (
  // 			Object.prototype.hasOwnProperty.call(removedProcessList, group)
  // 		) {
  // 			finalItemCustomisation['removedProcess'] =
  // 				removedProcessList[group];
  // 		}
  // 		const product = products[selection[group].productId];
  // 		for (let processId in appliedCustomization[group]) {
  // 			// if productId key is found, skip it by continuing
  // 			if (processId === 'productId') {
  // 				// and if selection productId and appliedCustomization productId don't match,
  // 				// add empty customization and break out
  // 				if (
  // 					appliedCustomization[group][processId] !==
  // 					product.productId
  // 				) {
  // 					break;
  // 				}
  // 				continue;
  // 			}
  // 			let processObj = getProcessObj(group, processId, customisationState);
  // 			if (!processObj) continue;
  // 			if (processObj && processObj.processType === 'REPLACEABLE') {
  // 				let remainingItemsToAdd =
  // 					appliedCustomization[group][processId]['from'].length -
  // 					appliedCustomization[group][processId]['to'].length;
  // 				if (remainingItemsToAdd !== 0) {
  // 					toast.error(
  // 						`Add ${remainingItemsToAdd} more items in process: ${processObj.processName} for ${product.name}`
  // 					);
  // 					return;
  // 				}
  // 			}
  // 			if (processObj.processType === 'ADDON') {
  // 				if (appliedCustomization[group][processId].length > 0) {
  // 					finalItemCustomisation['addedItems'][processId] =
  // 						appliedCustomization[group][processId].map(
  // 							(item) => item.id
  // 						);
  // 				}
  // 			} else if (processObj.processType === 'REMOVABLE') {
  // 				if (appliedCustomization[group][processId].length > 0) {
  // 					finalItemCustomisation['removedItems'][processId] =
  // 						appliedCustomization[group][processId].map(
  // 							(item) => item.id
  // 						);
  // 				}
  // 			} else if (processObj.processType === 'REPLACEABLE') {
  // 				finalItemCustomisation['replacedItems'][processId] = {
  // 					to: [],
  // 					from: [],
  // 				};
  // 				if (
  // 					appliedCustomization[group][processId]['to'].length >
  // 						0 &&
  // 					appliedCustomization[group][processId]['from'].length >
  // 						0
  // 				) {
  // 					finalItemCustomisation['replacedItems'][processId][
  // 						'to'
  // 					] = appliedCustomization[group][processId]['to'].map(
  // 						(item) => item.id
  // 					);
  // 					finalItemCustomisation['replacedItems'][processId][
  // 						'from'
  // 					] = appliedCustomization[group][processId]['from'].map(
  // 						(item) => item.id
  // 					);
  // 				} else {
  // 					delete finalItemCustomisation['replacedItems'][
  // 						processId
  // 					];
  // 				}
  // 			}
  // 		}
  // 		selectionClone[group].itemCustomization = {
  // 			finalCustomisation: cloneDeep(finalItemCustomisation),
  // 			appliedCustomization: cloneDeep(appliedCustomization[group]),
  // 		};
  // 	}
  // 	return selectionClone;
  // };

  if (!show) return <></>;

  const generateBreadcrumbs = () => {
    return Object.keys(product.customisation[product.selectedVariant.id]).map(
      (groupId, idx) => {
        const group =
          product.customisation[product.selectedVariant.id][groupId];
        const isActive = idx === activeScreen;
        return (
          <span
            className={`font-open-sans pb-1 ${
              isActive
                ? "font-semibold border-b-2 border-black"
                : "opacity-50 hover:opacity-80"
            } text-xs sm:text-sm text-black text-left capitalize cursor-pointer w-max mb-2 sm:mb-0`}
            onClick={() => {
              handleScreenChange(idx);
            }}
          >
            {`${group.comboGroupName}${
              group.optionalGroup ? "(Optional)" : ""
            }`}
          </span>
        );
      }
    );
  };

  const generateCustomization = (choiceItem) => {
    // console.log('customisationState', customisationState);
    // const activeGroup = Object.keys(
    // 	product.customisation[product.selectedVariant.id]
    // )[activeScreen];
    return (
      <div className="py-6 space-y-8">
        <div class="font-semibold text-left text-xs sm:text-sm xl:text-base text-black">
          {`Customize your ${choiceItem.name}`}
        </div>
        {customisationState &&
          customisationState[activeGroup] &&
          Object.keys(customisationState[activeGroup]).map((processId, idx) => {
            const process = customisationState[activeGroup][processId];
            const { defaultItems, totalDefaultItems } =
              getDefaultItems(process);
            const { nonDefaultItems, totalNonDefaultItems } =
              getNonDefaultItems(process);
            const isCollapsed =
              process.processRemovable &&
              !(process.processId in appliedCustomization[activeGroup]); // added or removed
            let selectedVariant = products[choiceItem.productId].variants.find(
              (variant) => {
                return variant.id === selection[activeGroup].variantId;
              }
            );
            if (
              !process.variantIdApplicableOn.includes(
                selectedVariant.id.toString()
              )
            )
              return;
            return (
              <div className="space-y-4" key={idx}>
                <div className="space-y-2">
                  <div className="flex justify-between font-open-sans">
                    <div>
                      <div className="font-semibold text-left text-xs sm:text-sm xl:text-base text-black">
                        {process.processName}
                      </div>
                      <div className="text-left text-2xs sm:text-xs text-black">
                        {getProcessDetails(process)}
                      </div>
                    </div>
                    {process.processRemovable ? (
                      process.processId in appliedCustomization &&
                      appliedCustomization[activeGroup] ? (
                        <div
                          className="text-left text-xs text-8593FF cursor-pointer"
                          onClick={() => processClickHandler(process)}
                        >
                          Remove
                        </div>
                      ) : (
                        <div
                          className="text-left text-xs text-8593FF cursor-pointer"
                          onClick={() => processClickHandler(process)}
                        >
                          Add
                        </div>
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <div>
                  {!isCollapsed && (
                    <div>
                      {
                        // if REPLACEABLE && default, limit are more than 1 create two lists - 1 default and 1 for new items
                        process.processType === "REPLACEABLE" &&
                        process.maxItemCustomizableLimit >= 1 &&
                        totalDefaultItems > 1 ? (
                          <div className="space-y-2 border-b-2 pb-2 sm:border-0 sm:pb-0">
                            {/* default items list showing '-' sign on click, meaning it is being removed */}
                            <p className="text-left text-2xs sm:text-xs text-black">
                              Unselect the item to be replaced
                            </p>
                            <div className="flex space-x-8 overflow-x-auto hide-scroll-bar-phone bgWhiteScrollbar py-2 ">
                              {defaultItems.map((item, index) => {
                                let status = getStatus(process, item);
                                return (
                                  <div
                                    key={index}
                                    className="flex flex-col space-y-1 font-open-sans cursor-pointer"
                                    onClick={() =>
                                      itemClickHandler(
                                        process,
                                        item,
                                        status["isItemSelected"]
                                      )
                                    }
                                  >
                                    <div className="relative rounded h-24 w-24 sm:w-28 sm:h-28">
                                      <img
                                        src={item.image}
                                        alt="customized logo"
                                        className={`w-full h-full ${
                                          status["isItemSelected"]
                                            ? ""
                                            : "filter grayscale"
                                        }`}
                                      />
                                      {status["isItemSelected"] && (
                                        <div className="absolute -right-2 -top-2">
                                          <img
                                            src={SelectedTick}
                                            alt="selected option"
                                            className="h-5 w-5 sm:h-6 sm:w-6"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div className="text-center text-2xs sm:text-xs text-8B8B8B">
                                      {item.name}
                                    </div>
                                    {process.deltaPricing && (
                                      <div className="font-bold text-center text-2xs sm:text-xs text-black">
                                        Rs.
                                        {item.price[selectedVariant.id]}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                            {/* non default items list showing '+' sign on click, meaning it is being added */}
                            <p className="text-left text-2xs sm:text-xs text-black">
                              Select a replacement item
                            </p>
                            <div className="flex space-x-8 overflow-x-auto  hide-scroll-bar-phone bgWhiteScrollbar py-2 ">
                              {nonDefaultItems.map((item, index) => {
                                let status = getStatus(process, item);
                                return (
                                  <div
                                    key={index}
                                    className="flex flex-col space-y-1 font-open-sans cursor-pointer"
                                    onClick={() =>
                                      itemClickHandler(
                                        process,
                                        item,
                                        status["isItemSelected"]
                                      )
                                    }
                                  >
                                    <div className="relative rounded h-24 w-24 sm:w-28 sm:h-28 ">
                                      <img
                                        src={item.image}
                                        alt="customized logo"
                                        className={`w-full h-full ${
                                          status["isItemSelected"]
                                            ? ""
                                            : "filter grayscale"
                                        }`}
                                      />
                                      {status["isItemSelected"] && (
                                        <div className="absolute -right-2 -top-2">
                                          <img
                                            src={SelectedTick}
                                            alt="selected option"
                                            className="h-5 w-5 sm:h-6 sm:w-6"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div className="text-center text-2xs sm:text-xs text-8B8B8B">
                                      {item.name}
                                    </div>
                                    {process.deltaPricing && (
                                      <div className="font-bold text-center text-2xs sm:text-xs text-black">
                                        Rs. {item.price[selectedVariant.id]}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <div className="border-b-2 pb-2 sm:border-0 sm:pb-0">
                            <div className="flex space-x-8 overflow-x-auto  hide-scroll-bar-phone bgWhiteScrollbar py-2">
                              {Object.keys(process.items).map(
                                (itemId, itemIdx) => {
                                  const item = process.items[itemId];
                                  let status = getStatus(process, item);
                                  return (
                                    <div
                                      key={itemIdx}
                                      className="flex flex-col space-y-1 font-open-sans cursor-pointer"
                                      onClick={() =>
                                        itemClickHandler(
                                          process,
                                          item,
                                          status["isItemSelected"]
                                        )
                                      }
                                    >
                                      <div className="relative rounded h-24 w-24 sm:w-28 sm:h-28">
                                        <img
                                          src={item.image}
                                          alt="customized logo"
                                          className={`w-full h-full ${
                                            status["isItemSelected"]
                                              ? ""
                                              : "filter grayscale"
                                          }`}
                                        />
                                        {status["isItemSelected"] && (
                                          <div className="absolute -right-2 -top-2">
                                            <img
                                              src={SelectedTick}
                                              alt="selected option"
                                              className="h-5 w-5 sm:h-6 sm:w-6  "
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="text-center text-2xs sm:text-xs text-8B8B8B">
                                        {item.name}
                                      </div>
                                      {process.deltaPricing && (
                                        <div className="font-bold text-center text-2xs sm:text-xs text-black">
                                          Rs.
                                          {item.price[selectedVariant.id]}
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const generateGroupChoices = () => {
    // const activeGroup = Object.keys(
    // 	product.customisation[product.selectedVariant.id]
    // )[activeScreen];
    // debugger
    const groupChoicesDOM = Object.keys(
      product.customisation[product.selectedVariant.id][activeGroup]
        .comboGroupItem
    ).map((groupItemId) => {
      const groupItem =
        product.customisation[product.selectedVariant.id][activeGroup]
          .comboGroupItem[groupItemId];
      const choice = products[groupItem.productId];
      const productVariant = choice.variants.filter(
        (variant) => groupItem.variantId === variant.id
      )[0];
      const isSelected =
        get(selection[activeGroup], "productId") === parseInt(groupItemId, 10);
      return (
        <div
          key={groupItemId}
          className="flex flex-col space-y-1 font-open-sans cursor-pointer"
          onClick={() =>
            handleGroupItemSelection({
              groupId: activeGroup,
              groupItemId,
              groupItem,
            })
          }
        >
          <div className="relative rounded h-24 w-24 sm:w-28 sm:h-28 ">
            <img
              src={choice.image}
              alt="customized logo"
              className={`w-full h-full ${
                isSelected ? "" : "filter grayscale"
              }`}
            />
            {isSelected && (
              <div className="absolute -right-2 -top-2">
                <img
                  src={SelectedTick}
                  alt="selected option"
                  className="h-5 w-5 sm:h-6 sm:w-6"
                />
              </div>
            )}
          </div>
          <div className="text-center text-2xs sm:text-xs text-8B8B8B">
            {choice.name}
          </div>
          {groupItem.price && (
            <div className="font-bold text-center text-2xs sm:text-xs text-black">
              Rs. {groupItem.price || 0}
            </div>
          )}
        </div>
      );
    });
    return <>{groupChoicesDOM}</>;
  };

  const generateFooter = () => {
    const totalSteps = Object.keys(
      product.customisation[product.selectedVariant.id]
    ).length;

    const isCustomizable =
      product.isCustomizable &&
      selection[activeGroup] &&
      products[selection[activeGroup].productId].isCustomizable;

    let leftBtnDOM = isCustomizable ? (
      <div
        className="bg-8593FF bg-no-repeat rounded hover:bg-indigo-500 font-semibold text-center text-xs text-white px-2 py-5 sm:py-4 sm:px-5 md:px-4 xl:px-8 cursor-pointer w-full"
        onClick={() => resetCustomization()}
      >
        Reset Customization
      </div>
    ) : (
      <></>
    );

    // right side button, if there is a next step, acts as next button, else acts as add to cart on final step
    let rightBtnDOM =
      activeScreen < totalSteps - 1 ? (
        <div
          className="bg-8593FF bg-no-repeat rounded hover:bg-indigo-500 font-semibold text-center text-xs text-white px-2 py-5 sm:py-4 sm:px-5 md:px-4 xl:px-8 cursor-pointer w-full"
          onClick={() => handleScreenChange(activeScreen + 1)}
        >
          Next
        </div>
      ) : (
        <div
          className="bg-8593FF bg-no-repeat rounded hover:bg-indigo-500 font-semibold text-center text-xs text-white px-2 py-5 sm:py-4 sm:px-5 md:px-4 xl:px-8 cursor-pointer w-full"
          onClick={() => handleAddToCart()}
        >
          {data.isEdit ? "Save" : "Add To Cart"}
        </div>
      );

    return (
      <>
        {leftBtnDOM}
        {rightBtnDOM}
      </>
    );
  };

  const onSizeChange = (variant, productId) => {
    let tempMenu = cloneDeep(menu);
    setState({ ...initialState });
    tempMenu.products[productId].selectedVariant = { ...variant };
    dispatch(menuActions.updateMenu(tempMenu));
  };

  return (
    <SideNav
      visible={show}
      closable
      onClose={handleClose}
      mode="dark"
      isScrollable={false}
    >
      <Modal
        visible={isOpen}
        title={`Do you want change your order type from ${orderInfo.orderType} to ${orderType} category`}
        text="This will delete your previous stored cart"
        submit={submitModal}
        close={closeModal}
        closingText="Cancel"
        submittingText="Delete"
      />
      <div className="space-y-4 h-full relative ">
        {/* Image banner section */}
        <div className="relative">
          <img
            src={product.image}
            alt="customize pizza"
            className="object-cover w-full h-44 sm:h-60 lg:h-52 xl:h-60"
          />
          <div className="flex items-center absolute bottom-4 left-8 space-x-4 sm:space-x-8 z-20">
            <div>
              <img
                src={product.isVeg ? Veg : Nonveg}
                alt="food type"
                className="w-4 h-4 sm:w-5 sm:h-5"
              />
            </div>
            <div className="font-open-sans font-semibold text-left text-base sm:text-lg text-white">
              {product.name}
            </div>
          </div>
          <div className="absolute bg-FFFFFF bg-no-repeat border border-FFFFFFAD rounded-sm text-center font-semibold font-open-sans text-2xs xl:text-xs text-black p-1 bottom-4 right-8 z-20">
            {clientType === "SCHOOL"
              ? "Confirm class for meal size"
              : product.selectedVariant.serves !== null &&
                product.selectedVariant.serves !== "" &&
                `Serves ${product.selectedVariant.serves}`}
				
          </div>
          <div className="absolute bg-black opacity-50 inset-0"></div>
        </div>
        {/* Size seletion section */}
        <div
          className={`flex px-5 sm:px-20 lg:px-14 xl:px-20 space-x-8 items-center ${
            product.displayVariants ? "block" : "hidden"
          }`}
        >
          <div className="font-open-sans font-semibold text-xs sm:text-sm xl:text-base text-black text-left capitalize">
            {product.displayVariants ? "Choose a Variant" : "Size"}
          </div>
          {product.displayVariants ? (
            <div className="flex space-x-2">
              {product.variants.map((size, index) => (
                <div
                  key={size.id}
                  className={
                    size.id === product.selectedVariant.id
                      ? selectedSizeClass
                      : unSelectedSizeClass
                  }
                  onClick={
                    (orderInfo.orderType === "scheduled" &&
                      !size.subscriptionEnabled) ||
                    size.isDisabled
                      ? () =>
                          toast.error("This size is currently not available")
                      : () => onSizeChange(size, product.productId)
                  }
                >
                  {size.displayName}
                </div>
              ))}
            </div>
          ) : (
            <div className="flex space-x-2">
              <div className={selectedSizeClass}>
                {product.selectedVariant.displayName}
              </div>
            </div>
          )}
        </div>
        {/* Bread Crumbs */}
        <div className="px-5 sm:px-20 flex-col flex flex-wrap md:flex-row lg:px-14 xl:px-20 md:space-x-4 md:items-center">
          {generateBreadcrumbs()}
        </div>
        {/* Body */}
        <div
          className={`${styles.body} px-5 sm:px-20 lg:px-14 xl:px-20 items-center overflow-auto pb-28 sm:pb-60 lg:pb-40`}
        >
          <div className="flex overflow-x-auto space-x-8 hide-scroll-bar-phone bgWhiteScrollbar py-2 ">
            {generateGroupChoices()}
          </div>

          {product.isCustomizable &&
            selection[activeGroup] &&
            products[selection[activeGroup].productId].isCustomizable &&
            generateCustomization(products[selection[activeGroup].productId])}
        </div>
        {/* Footer */}
        <div className="px-5 py-5 z-10 border-t-2 sm:px-20 lg:px-14 xl:px-20 flex space-x-12 font-open-sans items-end absolute bottom-0 w-full bg-white">
          {generateFooter()}
        </div>
      </div>
    </SideNav>
  );
}

const mapStateToProps = (state) => {
  const { show, data } = state.modal.combo;
  let product = null;
  const { products } = state.menu;
  const { cart, orderInfo, customization, menu } = state;
  if (data.item) {
    data.item.displayVariants = data.item.variants.length > 1;
    data.item.customisation = data.item.appliedCustomization;
    data.item.isVeg = state.menu.products[data.item.productId].isVeg;
    data.item.isCustomizable =
      state.menu.products[data.item.productId].isCustomizable;
  } else {
    product = state.menu.products[data.productId];
  }
  if (false) {
    product.selectedVariant = {
      id: 741,
      size: "size1",
      description: "",
      displayName: "Small",
      isDefault: false,
      servingQtyPercentage: 100,
      qcData: {},
      minWeight: 0,
      maxWeight: 0,
      serves: "1",
      price: 200,
      isDisabled: false,
      customizationEnabled: true,
      discountedPrice: null,
      nutritionalFactsAvailable: true,
      nutritionalFacts: {
        Carbs: {
          value: "-",
          unit: "g",
        },
        Protein: {
          value: "-",
          unit: "g",
        },
        Fat: {
          value: "-",
          unit: "g",
        },
        Calcium: {
          value: "-",
          unit: "mg",
        },
        Calories: {
          value: "-",
          unit: "kcal",
        },
      },
    };
  }
  return {
    show,
    data,
    product: product ? product : data.item,
    products,
    cart,
    orderInfo,
    customization,
    menu,
  };
};

const mapDispatchToProps = {
  toggleComboModal: modalActions.toggleComboModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComboModal);
