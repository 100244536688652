import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "../../Redux/Actions";

const AddInstructions = () => {
  const { orderInfo } = useSelector((state) => state);
  const dispatch = useDispatch();

  const addInstruction = (e, type, index) => {
    // update instruction in store and localStorage
    if (type === "checked") {
      orderInfo.instructions.addons[index].checked = e.target.checked;
    }
    if (type === "add") {
      orderInfo.instructions.comments = e.target.value;
    }
    dispatch(orderActions.updateOrderStore(orderInfo));
  };

  return (
    <div className='p-6 mt-4 bg-white'>
      {/* <div className='mb-4'>Add Instructions</div>
      {orderInfo.instructions.addons.map((addon, index) => (
        <label key={index} className='checkbox-container'>
          {addon.instruction}
          <input
            name={addon.id}
            onChange={(e) => addInstruction(e, "checked", index)}
            type='checkbox'
            checked={addon.checked}
          />
          <span className='checkmark'></span>
        </label>
      ))} */}
      <div className='text-sm mt-6'>Instructions for the store</div>
      <textarea
        className='rounded w-full border text-sm p-2 resize-none focus:outline-none mt-3'
        placeholder='Write something ...we will try our best to add that'
        value={orderInfo.instructions.comments}
        onChange={(e) => addInstruction(e, "add")}
        rows='2'
        type='text'
      />
    </div>
  );
};

export default AddInstructions;
