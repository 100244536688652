/** @format */

import { cloneDeep } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Music from "../../Assets/Icons/002-music-player.svg";
import Order from "../../Assets/Icons/004-dinner.svg";
import More from "../../Assets/Icons/Group 159109.svg";
import LocationIcon from "../../Assets/Icons/Icon material-location-on.svg";
import Profile from "../../Assets/Icons/user-profile.svg";
import { storeClosedOrNot } from "../../Functions/helpersFunctions";
import { modalActions, orderActions } from "../../Redux/Actions";
import ComboModal from "../ComboModal/ComboModal";
import Login from "../Login/Login";
import Search from "../Search/Search";
import SideBar from "../SideBar/SideBar";
import SideMenu from "../SideMenu/SideMenu";
import SideNav from "../SideNav/SideNav";

export default function Header({
  PageName = "",
  history,
  showAddress = true,
  showSearch = true,
  showMusic = true,
  showCart = true,
  isCustomizationsVisible,
  showCustomization,
  showSearchNew,
  setShowSearch,
  query,
  setQuery,
}) {
  const [isaddressSliderVisible, setIsaddressSliderVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const {
    orderInfo: reduxOrderInfo,
    client,
    auth,
    menu,
    store,
    cart,
    modal,
  } = useSelector((state) => state);

  const orderInfo = cloneDeep(reduxOrderInfo);

  const { restaurantId } = useParams();
  const { storeId } = orderInfo;
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [showSearchState, setShowSearchState] = useState(showSearch);
  const [showCartState, setShowCartState] = useState(showCart);
  const [showAuthState, setShowAuthState] = useState(true);

  const [pushBack, setPushBack] = useState("");

  const { music } = client;
  const defaultCategory = menu.menu[0]?.categoryId;

  // useEffect(() => {
  //   setIsVisible(modal.showLoginModal);
  // },[modal.showLoginModal]);

  useEffect(() => {
    if (history.location.pathname.includes("login")) {
      setShowAuthState(false);
    } else {
      setShowAuthState(true);
    }
    if (
      !history.location.pathname.includes("my-order") ||
      history.location.pathname.includes("menu-card") ||
      history.location.pathname.includes("login")
    ) {
      setShowCartState(false);
    } else {
      setShowCartState(true);
    }
    if (!history.location.pathname.includes("menu-card")) {
      setShowSearchState(false);
    } else {
      setShowSearchState(true);
    }
    if (history.location.pathname.includes("menu-card")) {
      setPushBack(`/${restaurantId ?? storeId}/home`);
    } else {
      setPushBack("");
    }
  }, [history.location.pathname]);

  useEffect(() => {
    let localStorageOrderInfo = localStorage.getItem("orderInfo");
    // For old users
    if (
      localStorageOrderInfo &&
      localStorageOrderInfo.deliveryMode &&
      !orderInfo.deliveryMode
    ) {
      orderInfo.deliveryMode = JSON.parse(localStorageOrderInfo).deliveryMode;
      orderInfo.storeId = Number(restaurantId);
      dispatch(orderActions.updateOrderStore(orderInfo));
      return;
    }
    // First Appearance on website
    if (!orderInfo.deliveryMode) {
      let selectedDeliveryMode = "";
      if (store.pickupEnabled) {
        selectedDeliveryMode = "pickup";
      }else if (store.deliveryEnabled) {
        selectedDeliveryMode = "delivery";
      }
      orderInfo.deliveryMode = selectedDeliveryMode;
      orderInfo.storeId = Number(restaurantId);
      dispatch(orderActions.updateOrderStore(orderInfo));
    }
  }, [orderInfo, store]);

  let logoSection;

  if (PageName === "") {
    logoSection = (
      <p className="font-semibold font-open-sans text-white">{client.name}</p>
    );
  } else {
    if (PageName === "Track Order") {
      logoSection = (
        <div className="flex space-x-3 items-center">
          <i
            onClick={() => history.push(`/${restaurantId ?? storeId}/home`)}
            className="fas fa-chevron-left text-xl"
          ></i>
          <div className="font-semibold font-open-sans">Back To Menu</div>
        </div>
      );
    } else {
      logoSection = (
        <div className="flex space-x-3 items-center">
          <i
            onClick={() =>
              PageName == "Cart"
                ? orderInfo.orderType == "regular"
                  ? history.push(
                      `/${
                        restaurantId ?? storeId
                      }/menu-card/regular/all/${defaultCategory}`
                    )
                  : history.push(
                      `/${
                        restaurantId ?? storeId
                      }/menu-card/schedule/schedule/${defaultCategory}`
                    )
                // : pushBack
                // ? history.push(pushBack)
                : history.goBack()
            }
            className="fas fa-chevron-left text-xl"
          ></i>
          <div className="font-semibold font-open-sans">{PageName}</div>
        </div>
      );
    }
  }

  const textStyle = "text-black text-sm hidden lg:block";

  let address = store.address;
  if (store.address.length > 40) {
    address = store.address.slice(0, 40) + "...";
  }
  return (
    <div>
      <ComboModal clientType={client?.clientType} />
      <nav
        className={`hidden lg:flex py-4 px-7 bg-white justify-between fixed top-0 w-10/12 z-20`}
      >
        <div className=" flex space-x-4">
          <Link to="">
            <img src={client.logo} className="h-10 w-10" alt="logo" />
          </Link>
          {showAddress && store.address && (
            <>
              <img className="h-4 w-auto mt-1" src={LocationIcon} alt="logo" />
              <div>
                <p className="text-black text-sm">{address}</p>
                <div className="flex">
                  <p className="text-7FB069 text-xs capitalize pr-1">
                    {orderInfo.orderType == "regular"
                      ? client.clientType != "SCHOOL"
                        ? orderInfo.deliveryMode
                        : "SCHOOL"
                      : "Subscription"}
                  </p>
                  {client.clientType != "SCHOOL" && (
                    <p className="text-7FB069 text-xs capitalize pr-3">
                      {orderInfo.deliverNow
                        ? "NOW"
                        : orderInfo.orderType == "schedule"
                        ? `For ${orderInfo?.time}`
                        : `Later At ${
                            orderInfo.laterTime ?? orderInfo.startDate
                          }`}
                    </p>
                  )}
                  <p
                    onClick={() => {
                      history.push("/");
                      dispatch(orderActions.resetOrderStore(orderInfo));
                    }}
                    className={`text-xs text-8593FF cursor-pointer`}
                  >
                    change
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        {showSearchState && (
          <Search
            products={menu.products}
            availableProducts={menu.availableProductId}
            isCustomizationsVisible={isCustomizationsVisible}
            showCustomization={showCustomization}
            showSearch={showSearchNew}
            setShowSearch={setShowSearch}
            newQuery={query}
            setQuery={setQuery}
          />
        )}
        <ul className="flex space-x-8">
          {music && showMusic && (
            <li className="flex items-center space-x-2 cursor-pointer">
              <img className="h-4 w-auto" src={Music} alt="music" />
              <p className={textStyle}>Music On</p>
            </li>
          )}
          {showCartState && (
            <li
              onClick={() =>
                history.push(
                  `/${restaurantId ?? storeId}/menu-card/${
                    orderInfo.orderType === "bulk" ||
                    orderInfo.orderType === "schedule"
                      ? orderInfo.orderType + "/" + orderInfo.orderType
                      : "regular/all"
                  }/${defaultCategory}`
                )
              }
              className="flex items-center space-x-2.5 cursor-pointer"
            >
              <div className="relative">
                <img className="h-4 w-auto" src={Order} alt="order" />
                {cart.length > 0 && (
                  <span className="flex justify-center items-center absolute -top-2 -right-2 w-4 h-4 rounded-full text-white text-2xs bg-7FB069">
                    {cart.length}
                  </span>
                )}
              </div>
              <p className={textStyle}>My Cart</p>
            </li>
          )}
          {auth.auth
            ? null
            : showAuthState && (
                <li
                  className="flex items-center space-x-2.5 cursor-pointer"
                  onClick={() => setIsVisible(true)}
                >
                  <img className="h-auto w-auto" src={Profile} alt="profile" />
                  <p className={textStyle}>Sign In</p>
                </li>
              )}
          {auth.auth && (
            <li
              onClick={() => setShowMenu(true)}
              className="flex items-center cursor-pointer"
            >
              <img className="h-4 w-auto" src={More} alt="more" />
            </li>
          )}
        </ul>
      </nav>
      {/* Header For Mobile */}

      <div className=" block lg:hidden fixed top-0 w-full md:w-10/12 z-20">
        <nav className="flex py-1 pr-2 bg-white items-center justify-between">
          <div className="flex items-center justify-between">
            <Link to="">
              <img src={client.logo} className="h-10 w-10 ml-2" alt="logo" />
            </Link>
            {client && (
              <p
                className="font-semibold font-open-sans text-sm ml-2"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {client.name}
              </p>
            )}
          </div>
          {showAddress && store.address && (
            <div className="flex">
              <div>
                <p className="text-black text-sm">
                  {`${address.substr(0, 23)}...`}
                </p>
                <div className="flex">
                  <p className="text-7FB069 text-xs capitalize pr-1">
                    {orderInfo.orderType == "regular"
                      ? client.clientType != "SCHOOL"
                        ? orderInfo.deliveryMode
                        : "SCHOOL"
                      : "Subscription"}{" "}
                    {client?.clientType !== 'SCHOOL' &&
                      (orderInfo.deliverNow
                        ? "NOW"
                        : orderInfo.orderType == "schedule"
                        ? `For ${orderInfo.time}`
                        : `Later At ${moment(
                          orderInfo.laterTime ?? orderInfo.startDate
                        )?.format('DD/MM/YYYY')}`
                      )
                    }
                  </p>
                </div>
                <p
                  onClick={() => {
                    history.push("/");
                    dispatch(orderActions.resetOrderStore(orderInfo));
                  }}
                  className={`text-xs text-8593FF cursor-pointer`}
                >
                  change
                </p>
              </div>
              <img
                className="h-4 w-auto mt-1 ml-3"
                src={LocationIcon}
                alt="logo"
              />
            </div>
          )}
        </nav>
        {!showSearchNew ? (
          <nav className="flex justify-between items-center bg-white px-5 py-4 border-t-4 border-F5F5F5">
            <div>{logoSection}</div>
            <div>
              <ul className="flex space-x-6">
                {music && showMusic && (
                  <li className="flex items-center cursor-pointer">
                    <img className="h-4 w-auto" src={Music} alt="music" />
                  </li>
                )}
                {showCartState && (
                  <li className="flex items-center  cursor-pointer">
                    <Link to={`/${restaurantId ?? storeId}/my-order`}>
                      <div className="relative">
                        <img className="h-4 w-auto" src={Order} alt="order" />
                        {cart.length > 0 && (
                          <span className="flex justify-center items-center absolute -top-2 -right-2 w-4 h-4 rounded-full text-white text-2xs bg-7FB069">
                            {cart.length}
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>
                )}
                {auth.auth
                  ? null
                  : showAuthState && (
                      <li className="flex items-center space-x-2.5 cursor-pointer">
                        <Link
                          to={
                            restaurantId ?? storeId
                              ? `/${restaurantId ?? storeId}/login`
                              : `/login`
                          }
                        >
                          <img
                            className="h-auto w-auto"
                            src={Profile}
                            alt="profile"
                          />
                        </Link>
                      </li>
                    )}
                {auth.auth && (
                  <li
                    onClick={() => setShowMenu(true)}
                    className="flex items-center cursor-pointer"
                  >
                    <img className="h-4 w-auto" src={More} alt="more" />
                  </li>
                )}
              </ul>
            </div>
          </nav>
        ) : (
          <></>
        )}
      </div>

      <SideNav
        onClose={() => {
          setIsVisible(false);
          dispatch(modalActions.toggleLoginModal(false));
        }}
        visible={isVisible || modal.showLoginModal}
        mode="dark"
        type="login"
      >
        <Login
          closeLoginSlider={() => {
            setIsVisible(false);
            dispatch(modalActions.toggleLoginModal(false));
          }}
        />
      </SideNav>

      <SideBar onClose={() => setShowMenu(false)} visible={showMenu}>
        <SideMenu onClose={() => setShowMenu(false)} />
      </SideBar>

      {((orderInfo.deliverNow === true &&
        (store.isClosed || store.isInactive)) ||
        storeClosedOrNot(orderInfo, store)) && (
        <div className="fixed bg-gray-400 bottom-0 bg-opacity-10000 left-0 right-0 md:px-20 bg-no-repeat py-4 font-open-sans font-semibold text-center text-FFFFFF text-base uppercase z-10">
          <div className="font-open-sans text-white text-center h-8 py-1">
            {!store.isServisable
              ? "Store doesn't deliver here"
              : `Store is operational from ${store.openingTime} to ${store.closingTime}`}
          </div>
        </div>
      )}
    </div>
  );
}
