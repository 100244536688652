import React, { useState, useEffect } from "react";
import FireCalorie from "../../../Assets/Icons/FireCalorie.png";
import Veg from "../../../Assets/Images/Veg.svg";
import ChefChilli from "../../../Assets/Icons/ChefChilli.svg";
import RatingStar from "../../../Assets/Icons/RatingStar.svg";
import Nonveg from "../../../Assets/Images/Nonveg.png";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cartActions,
  orderActions,
  menuActions,
  modalActions,
} from "../../../Redux/Actions";
import Modal from "../../Modal/Modal";
import toast from "react-hot-toast";
import {
  addAccordingToType,
  removeFromCart,
} from "../../../Functions/CartFunctions";
import { cloneDeep } from "lodash";
import moment from "moment";
import {
  findIsDefaultCombo,
  findNextAvailableAt,
  getProcessObj,
  getSelectionCopy,
  handleAddCustomizationCopy,
  storeClosedOrNot,
} from "../../../Functions/helpersFunctions";
import { calculeteNoOfDays } from "../../../Functions/GetTime";
import SelectVariantPopup from "../../SelectVariantPopup/SelectVariantPopup";

const FinalFoodCard = ({
  key,
  productId,
  productType,
  description,
  discount,
  selectedCategory,
  subCategory,
  selectedVariant,
  displayVariants,
  variants,
  handleSubscriptionClick,
  image,
  isCustomizable,
  isVeg,
  menuTags,
  tags,
  name,
  ratings,
  tax,
  productAvailable,
  subscriptionTo,
  subscriptionFrom,
  type, // card opened in which ordertype -> BULK/SCHEDULE/REGULAR
  changeSizeHandler,
  isProductSpecial,
  showCustomization,
  foodItem,
}) => {
  const { restaurantId, orderType } = useParams();

  const { cart, orderInfo, menu, customization, store, client } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  // Handling the item counter for food item if added to cart
  const [itemCount, setItemCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // const [category, setCategory] = useState("regular");
  const localStoreOrdertype = JSON.parse(
    localStorage.getItem("orderInfo")
  ).orderType;
  useEffect(() => {
    // fetches count from cart of the specific food item
    let count = 0;
    if (localStoreOrdertype !== orderInfo.orderType) {
      setItemCount(0);
      return;
    }
    cart.forEach((item, index) => {
      if (item.productId === productId) {
        count = count + item.quantity;
      }
    });
    setItemCount(count);
  }, [cart, orderType]);

  // Handles addition to cart
  const modifiedTimeSlotAvailable = () => {
    if (product.modifiedTimeSlots) {
      return product.modifiedTimeSlots["slot0"].startAt ? true : false;
    }
    return false;
  };
  const timeSlotAvailable = () => {
    return product.timeSlots["slot0"].startAt ? true : false;
  };
  const product = menu.products[productId];
  findNextAvailableAt(orderInfo.time, product?.timeSlots);
  const currentTime = moment(new Date()).format("HH:mm");
  // const showCustomization = (productId, appliedCustomization, index) => {

  // };

  const addToCart = () => {
    // If cart length empty
    if (storeClosedOrNot(orderInfo, store)) {
      return;
    }
    if (
      (store.isClosed || store.isInactive) &&
      orderInfo.orderType === "regular" &&
      orderInfo.deliverNow === true
    ) {
      // if()
      // toast.error('Store is closed/inactive right now');
      return;
    }
    // debugger
    if (cart.length === 0) {
      // Update Order type in store
      // dispatch(
      //   orderActions.updateOrderStore({
      //     orderType: orderType,
      // 		noOfDays:
      // 		orderType === "schedule"
      // 			? calculeteNoOfDays(
      // 				new Date(orderInfo.startDate),
      // 				new Date(orderInfo.endDate),
      // 				orderInfo.frequencyDays
      // 			)
      // 			: 1,
      //     deliverNow:
      //       orderType === "schedule"
      //         ? false
      //         : true,
      //   })
      // );
    }
    // If cart not empty, check the order type
    else {
      // if order type is not same, then show warning modal
      // modal says -> your order type is different and will clear cart
      if (orderInfo.orderType !== localStoreOrdertype) {
        // Activate Modal
        // If user does not changes type, then do not add item to cart
        // If user changes type
        // 1. Empty Cart
        // 2. Update Order type
        // 3. Add Item to cart
        // debugger
        setIsOpen(true);
        return;
      }
    }
    if (productType === "COMBO") {
      if (product.variants.length === 1 && findIsDefaultCombo(foodItem)) {
        const selection = getSelectionCopy(foodItem, menu.products);
        const appliedCustomization = {};
        const removedProcessList = {};
        addAccordingToType(
          menu.products,
          product.productId,
          {
            ...customization,
            extraData: {
              selectedCategory,
              subCategory,
              cart,
            },
          },
          orderType,
          itemCount,
          setItemCount,
          dispatch,
          orderInfo,
          product.name,
          product.selectedVariant,
          handleAddCustomizationCopy(
            selection,
            product,
            appliedCustomization,
            removedProcessList,
            menu.products,
            getProcessObj
          ),
          1
        );
      } else dispatch(modalActions.toggleComboModal(true, { productId }));
      return;
    } else if (isCustomizable && showCustomization) {
      showCustomization(productId, {}, -1);
      return;
    }
    // if(isCustomizable){
    //   showCustomization(true)
    //   return
    // }
    // Add item to cart
    addAccordingToType(
      menu.products,
      productId,
      {
        ...customization,
        extraData: {
          selectedCategory,
          subCategory,
          cart,
        },
      },
      type,
      itemCount,
      setItemCount,
      dispatch,
      orderInfo,
      name,
      isProductSpecial
    );
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const submitModal = () => {
    // 1. Empty Cart
    dispatch(cartActions.clearCartStore());
    // 2. Update Order type -> store and local storage
    dispatch(
      orderActions.updateOrderStore({
        orderType: type,
        noOfDays:
          type == "schedule"
            ? calculeteNoOfDays(
                new Date(orderInfo.startDate),
                new Date(orderInfo.endDate),
                orderInfo.frequencyDays
              )
            : 1,
        deliverNow: type == "schedule" ? false : true,
      })
    );
    // 3. Add Item to cart only if it's a product
    if (productType === "PRODUCT") {
      addAccordingToType(
        menu.products,
        productId,
        {
          ...customization,
          extraData: {
            selectedCategory,
            subCategory,
            cart,
          },
        },
        type,
        itemCount,
        setItemCount,
        dispatch,
        orderInfo,
        name,
        selectedVariant
      );
    } else if (productType === "COMBO") {
      dispatch(modalActions.toggleComboModal(true, { productId }));
    }
    setIsOpen(false);
  };

  const onRemove = () => {
    removeFromCart(
      menu.products,
      productId,
      type,
      itemCount,
      setItemCount,
      dispatch
    );
  };

  const onSizeChange = (variant, productId) => {
    let tempMenu = cloneDeep(menu);
    tempMenu.products[productId].selectedVariant = { ...variant };
    dispatch(menuActions.updateMenu(tempMenu));
  };

  const onSelect = (variant) => {
    onSizeChange(variant, productId);
    addToCart();
  };

  const selectedSizeClass =
    "bg-7FB0692C hover:bg-7FB0692C bg-no-repeat border border-7FB0693B rounded-sm opacity-70 text-center not-italic font-semibold font-open-sans text-2xs xl:text-xs text-2F2F2F cursor-pointer py-1 px-1";
  const unSelectedSizeClass =
    "hover:bg-7FB0692C bg-no-repeat border border-7FB0693B rounded-sm opacity-70 text-center not-italic font-semibold font-open-sans text-2xs xl:text-xs text-2F2F2F cursor-pointer py-1 px-1";
  // const storeClosedOrNot = () => {
  // 	if (
  // 		(orderInfo.deliverNow === false &&
  // 			((orderInfo.time > store.closingTime &&
  // 				orderInfo.time > store.openingTime) ||
  // 				(orderInfo.time < store.closingTime &&
  // 					orderInfo.time < store.openingTime))) ||
  // 		(orderInfo.deliverNow && store.isClosed)
  // 	) {
  // 		return true;
  // 	}
  // 	return false;
  // };
  // console.log(storeClosedOrNot(), 'storeclosed');
  return (
    <div>
      <div className="bg-FFFFFF border border-EFEFEF pt-5 pl-5 pb-5 -space-y-1">
        <div className="grid grid-cols-10 gap-2">
          {/* Food card Content */}
          <div className="grid col-span-6">
            <div className="flex-col flex-grow  lg:space-y-2 xl:space-y-1">
              <div>
                {isCustomizable && (
                  <div className="font-open-sans not-italic text-8B8B8B text-left text-xs">
                    Customizable
                  </div>
                )}
              </div>
              <div className="flex items-start space-x-2">
                <div className="line-clamp-1 text-left not-italic font-bold font-open-sans text-sm sm:text-base lg:text-sm xl:text-base text-black capitalize">
                  {name}
                </div>
                {/* <div>
									<img
										src={ChefChilli}
										alt='chef chilli'
										className='h-5 w-7'
									/>
								</div> */}
              </div>
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <div className="flex items-center space-x-1">
                    <div className="text-center not-italic font-open-sans font-bold text-sm sm:text-base lg:text-xs xl:text-base text-181818 capitalize">
                      Rs.{" "}
                      {orderInfo.orderType !== "schedule"
                        ? discount && discount > 0
                          ? selectedVariant?.discountedPrice
                          : selectedVariant?.price
                        : selectedVariant?.discountedPrice}
                    </div>
                    {discount > 0 && (
                      <div className="text-left line-through not-italic font-open-sans text-xs lg:text-2xs xl:text-xs text-8B8B8B">
                        Rs. {selectedVariant?.price}
                      </div>
                    )}
                  </div>
                  {(discount || discount > 0) && (
                    <div className="text-left not-italic font-semibold font-open-sans text-xs lg:text-2xs xl:text-xs text-7FB069 capitalize">
                      {discount}% Discount
                    </div>
                  )}
                </div>
                <div>
                  {ratings && (
                    <div className="flex items-center bg-FFFFFF bg-no-repeat border border-7FB0693B rounded-sm opacity-70 px-2 lg:px-1 xl:px-2 py-1 space-x-1">
                      <div className="text-center not-italic font-semibold font-open-sans text-xs lg:text-2xs xl:text-xs text-2F2F2F">
                        4.2
                      </div>
                      <div>
                        <img
                          src={RatingStar}
                          alt="star"
                          className="h-3 w-3 lg:h-2 lg:w-2 xl:h-3 xl:w-3"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex space-x-1 mt-2">
                {tags?.map((tag, index) => (
                  <div
                    key={index}
                    className="bg-7FB0692C bg-no-repeat border border-7FB0693B opacity-70 rounded-sm text-center not-italic font-semibold font-open-sans text-2xs sm:text-2xs md:text-2xs lg:text-3xs xl:text-2xs 2xl:text-2xs px-0.5 xl:px-1 2xl:px-1 py-1 text-2F2F2F"
                  >
                    {tag}
                  </div>
                ))}
              </div>
              <div className="line-clamp-2 lg:h-auto xl:h-8 text-left not-italic font-open-sans text-xs lg:text-2xs xl:text-xs text-8B8B8B mt-1 xl:mt-2">
                {description}
              </div>
            </div>
          </div>
          {/* Food card Image */}
          <div className="relative grid col-span-4 place-self-end">
            <div className="relative bg-no-repeat rounded-r overflow-hidden pb-5 flex justify-center">
              <img
                className="h-36 w-44 object-cover"
                src={image}
                alt="food pic"
              />
              {/**Counter button for Bulk and Regular order */}
              {productAvailable &&
                (itemCount ? (
                  <div
                    className="bg-8593FF bg-no-repeat border border-8593FF rounded text-center font-semibold font-open-sans  cursor-pointer absolute bottom-1 right-5 sm:right-9 md:right-9 lg:right-5 xl:right-9 py-0.5  md:py-0"
                    aria-disabled={store.isClosed || store.isInactive}
                  >
                    <div className="flex items-center justify-between py-1.5 px-4 space-x-2">
                      <div
                        className="text-black bg-FFFFFF rounded-full text-xs sm:text-sm px-2 cursor-pointer"
                        onClick={onRemove}
                      >
                        -
                      </div>
                      <div className="italic text-xs sm:text-sm text-FFFFFF capitalize ">
                        {itemCount}
                      </div>
                      <div
                        className="text-black bg-FFFFFF rounded-full text-xs sm:text-sm px-1.5 cursor-pointer"
                        onClick={addToCart}
                      >
                        +
                      </div>
                    </div>
                  </div>
                ) : (
                  <div onClick={addToCart}>
                    {/* Add Button for bulk and regular order */}
                    {type === "bulk" ? (
                      <div
                        className="absolute bottom-1 right-4 sm:right-8 lg:right-5 xl:right-6 2xl:right-8 bg-8593FF bg-no-repeat border border-8593FF rounded text-center font-open-sans cursor-pointer  hover:bg-indigo-500 py-0.5 px-4"
                        aria-disabled={store.isClosed || store.isInactive}
                      >
                        <div className="font-semibold text-2xs sm:text-xs lg:text-2xs xl:text-xs text-FFFFFF capitalize ">
                          Add Item
                        </div>
                        <div className="text-3xs sm:text-2xs lg:text-3xs xl:text-2xs text-FFFFFF ">
                          Min: 10 quantity
                        </div>
                      </div>
                    ) : storeClosedOrNot(orderInfo, store) ? (
                      <div className="absolute bottom-1 right-5 sm:right-1/4 lg:right-6 xl:right-1/4 bg-707070 border-707070 bg-no-repeat border rounded text-center font-open-sans cursor-pointer py-2 px-4">
                        <div className="font-semibold text-2xs sm:text-xs lg:text-2xs xl:text-xs text-FFFFFF capitalize ">
                          Add Item
                        </div>
                      </div>
                    ) : (
                      <div className="absolute bottom-1 right-5 sm:right-1/4 lg:right-6 xl:right-1/4 bg-8593FF bg-no-repeat border border-8593FF rounded text-center font-open-sans cursor-pointer  hover:bg-indigo-500 py-2 px-4">
                        <div className="font-semibold text-xs text-FFFFFF capitalize ">
                          Add Item
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              {!productAvailable && (
                <div className="flex items-center justify-around lg:center text-center py-2 px-4 absolute bottom-1 bg-707070 bg-no-repeat border border-707070 rounded text-center font-open-sans cursor-pointer hover:bg-grey-500 py-0.5 px-4 w-4/5 m-auto">
                  <div className="font-semibold text-2xs sm:text-xs lg:text-2xs xl:text-xs text-FFFFFF capitalize ">
                    {orderInfo.orderType === "schedule" &&
                    product.subscriptionEnabled
                      ? `Available till ${subscriptionTo}`
                      : modifiedTimeSlotAvailable() || timeSlotAvailable()
                      ? `Next available at ${findNextAvailableAt(
                          orderInfo.deliverNow
                            ? moment(moment(new Date()).format("HH:mm"))
                            : orderInfo.time,
                          product
                        )}`
                      : "Item Out Of Stock"}
                  </div>
                </div>
              )}
              <div className="absolute top-2 left-2 h-5 w-5 xl:h-6 xl:w-6">
                <img
                  src={isVeg ? Veg : Nonveg}
                  alt="veg symbol"
                  className="w-full h-full"
                />
              </div>
              {selectedVariant.nutritionalFactsAvailable && (
                <div className="absolute top-3 right-0 flex items-center bg-D17B69 bg-no-repeat text-center font-open-sans text-xs lg:text-2xs xl:text-xs text-FFFFFF space-x-1 p-1">
                  <div>
                    <img
                      src={FireCalorie}
                      alt="calories count"
                      className="h-3 w-3 lg:h-2.5 lg:w-2.5 xl:h-3 xl:w-3"
                    />
                  </div>
                  <div>
                    {selectedVariant.nutritionalFacts["Calories"].value +
                      selectedVariant.nutritionalFacts["Calories"].unit}
                  </div>
                </div>
              )}
            </div>
            {showPopup && (
              <div className="flex justify-center w-full">
                <SelectVariantPopup
                  closePopup={() => setShowPopup(false)}
                  variants={product?.variants}
                  onSelect={onSelect}
                />
              </div>
            )}
          </div>
        </div>
        {/* Size Selection Column */}
        <div className="grid grid-cols-10 gap-2">
          <div className="grid col-span-6 space-y-1">
            {
              <div className="text-left font-open-sans text-xs xl:text-sm text-8B8B8B capitalize">
                {client.clientType === "SCHOOL"
                  ? "Confirm class for meal size"
                  : displayVariants
                  ? "Select Size "
                  : "Size "}
                <b className="text-black">
                  {client.clientType !== "SCHOOL" &&
                    selectedVariant.serves !== null &&
                    selectedVariant.serves !== "" &&
                    `Serves ${selectedVariant.serves}`}
                </b>
              </div>
            }
            <div className="flex space-x-2">
              {variants.map((size, index) => (
                <div
                  key={size.id}
                  className={`${
                    size.id === selectedVariant.id
                      ? selectedSizeClass
                      : unSelectedSizeClass
                  } ${variants.length > 1 ? "" : "opacity-0"} `}
                  onClick={
                    size.isDisabled ||
                    (orderInfo.orderType === "schedule" &&
                      !size.subscriptionEnabled)
                      ? () =>
                          toast.error("This size is currently not available")
                      : () => onSizeChange(size, productId)
                  }
                >
                  {size.displayName}
                </div>
              ))}
            </div>
          </div>
          <div className="grid col-span-4 place-self-end pr-2 sm:pr-5 md:pr-6 lg:pr-10 xl:pr-5 2xl:pr-3">
            <Link to={`/${restaurantId}/food-item/${type}/${productId}`}>
              <div className="text-center not-italic font-semibold font-open-sans text-xs md:text-xs lg:text-2xs xl:text-xs 2xl:text-sm text-8593FF cursor-pointer hover:text-indigo-500">
                {isCustomizable && orderInfo.orderType !== "schedule" && (
                  <div className="inline-block lg:block xl:inline-block">
                    Customize/
                  </div>
                )}
                <div
                  className={`text-center text inline-block lg:block xl:inline-block ${
                    !(isCustomizable && orderInfo.orderType !== "schedule") &&
                    "pr-9 sm:pr-8 md:pr-7 lg:pr-0 xl:pr-8 2xl:pr-9"
                  }`}
                >
                  View Details
                </div>
              </div>
            </Link>
          </div>
          {handleSubscriptionClick &&
            product?.productType === "SUBSCRIPTION" && (
              <div className="grid col-span-4">
                <div
                  onClick={() => {
                    handleSubscriptionClick({
                      subCategoryName: subCategory?.subCategoryName,
                    });
                  }}
                  className=" bg-D17B69 bg-no-repeat border border-D17B69  rounded text-center font-open-sans cursor-pointer  hover:opacity-70 py-2 px-4"
                >
                  <div className="font-semibold text-xs text-FFFFFF capitalize ">
                    View Schedule
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      <Modal
        visible={isOpen}
        title={`Do you want change your order type from ${localStoreOrdertype} to ${orderInfo.orderType} category`}
        text="This will delete your previous stored cart"
        submit={submitModal}
        close={closeModal}
        closingText="Cancel"
        submittingText="Delete"
      />
    </div>
  );
};
FinalFoodCard.defaultProps = {
  isProductSpecial: "menu",
};
export default FinalFoodCard;
