import { modalConstants } from '../Constants';

const initialState = {
	showLoginModal: false,
	showPaytmModal: false,
	combo: {
		show: false,
		data: {},
	},
	showChangeLocationModal:false,
	showChangePinModal: false,
	showEditProfile: false,
};

export function modal(state = initialState, action) {
	switch (action.type) {
		case modalConstants.SHOW_LOGIN:
			return {
				...state,
				showLoginModal: true,
			};
		case modalConstants.HIDE_LOGIN:
			return {
				...state,
				showLoginModal: false,
			};
		case modalConstants.SHOW_PAYTM:
			return {
				...state,
				showPaytmModal: true,
			};
		case modalConstants.HIDE_PAYTM:
			return {
				...state,
				showPaytmModal: false,
			};
		case modalConstants.SHOW_COMBO:
			return {
				...state,
				combo: {
					show: true,
					data: action.payload,
				},
			};
		case modalConstants.HIDE_COMBO:
			return {
				...state,
				combo: {
					show: false,
					data: action.payload,
				},
			};
		case modalConstants.SHOW_CHANGE_LOCATION:
			return {
				...state,
				showChangeLocationModal: true,
			};
		case modalConstants.HIDE_CHANGE_LOCATION:
			return {
				...state,
				showChangeLocationModal: false,
			};
		case modalConstants.SHOW_CHANGE_PIN:
			return {
				...state,
				showChangePinModal: true,
			};
		case modalConstants.HIDE_CHANGE_PIN:
			return {
				...state,
				showChangePinModal: false,
			};
		case modalConstants.SHOW_EDIT_PROFILE:
			return {
				...state,
				showEditProfile: true,
			};
		case modalConstants.HIDE_EDIT_PROFILE:
			return {
				...state,
				showEditProfile: false,
			};
		default:
			return state;
	}
}
