/** @format */

import { orderConstants, orderTrackConstants } from "../Constants";
import {
  getCustomizationTotal,
  orderService,
  subscriptionService,
} from "../Services";
import { authActions, cartActions } from "../Actions";
import toast from "react-hot-toast";
import { modalActions, feedbackActions } from "../../Redux/Actions";
import { startPayment } from "../../Containers/Pay/Paytm";
// import { useHistory } from "react-router-dom";
import { isEmpty } from "../../Functions/helpersFunctions";
import { cartService } from "../Services";
import { cloneDeep } from "lodash";
import moment from "moment";

export const orderActions = {
  updateOrderStore,
  resetOrderStore,
  PlaceOrder,
  setOrderId,
  startTracking,
  stopTracking,
  trackOrder,
  getPastOrders,
  cancelOrder,
  updatePaymentStatus,
  applyPromo,
  removePromo,
  setLoading,
  changeOrderStore,
  update,
};
let totalAmount = 0;

function update(payload) {
  return { type: orderConstants.ORDER_UPDATE, payload };
}

function setLoading(show) {
  const payload = {
    isLoading: false,
  };
  if (show) {
    payload.isLoading = true;
  }
  return { type: orderConstants.SET_LOADING, payload };
}

function updateOrderStore(order) {
  // console.log(order, 'orderer');
  return (dispatch, getState) => {
    // debugger
    dispatch(update(order));
  };
}
function changeOrderStore(order) {
  // console.log(order, 'orderer');
  return (dispatch, getState) => {
    // debugger
    dispatch(update(order));
    // updateLocalStorage('orderInfo', JSON.stringify(getState().orderInfo));
  };
}
// function updateOrderType(order){
// 	return (dispatch, getState)=>{
// 		dispatch(update(order))
// 	}
// 	function update(payload){
// 		return {
// 			type: orderConstants.UPDATE_ORDER_TYPE,
// 			payload
// 		}
// 	}
// }
function resetOrderStore() {
  return { type: orderConstants.ORDER_RESET };
}

const calculateTotalAmount = (value, qty, item) => {
  let amt = 0;
  amt = amt + value;
  let customizationTotal = getCustomizationTotal(item);
  totalAmount = totalAmount + amt + customizationTotal;
  return amt + customizationTotal;
};

const getPrice = (item) => {
  let calculatedTotalAmount = calculateTotalAmount(
    item.selectedVariant.discountedPrice
      ? item.selectedVariant.discountedPrice
      : item.selectedVariant.price,
    item.quantity,
    item
  );

  if (item.selectedVariant.discountedPrice) {
    return calculatedTotalAmount;
  } else {
    return null;
  }
};

const getOriginalPrice = (item) => {
  let calculatedTotalAmount = calculateTotalAmount(
    item.selectedVariant.discountedPrice
      ? item.selectedVariant.discountedPrice
      : item.selectedVariant.price,
    item.quantity,
    item
  );

  return calculatedTotalAmount;
};
const daysOfWeek = {
  sun: "SUNDAY",
  mon: "MONDAY",
  tue: "TUESDAY",
  wed: "WEDNESDAY",
  thu: "THURSDAY",
  fri: "FRIDAY",
  sat: "SATURDAY",
};
function PlaceOrder(orderDetails, selectedAddress) {
  return (dispatch, getState) => {
    const orderInfo = getState().orderInfo;
    dispatch(placementRequest());
    dispatch(orderActions.setLoading(true));
    const cartItems = { ...getState().cart };
    const { isPaymentCOD, paymentMode } = orderDetails;
    let scheduled = null;
    const orderType = getState().orderInfo.deliveryMode.toUpperCase();
    const clientType = getState().client.clientType;
    let items = [];
    let defaultItemCustomisation = {
      addedItems: {},
      removedItems: {},
      replacedItems: {},
      removedProcess: [],
    };
    for (const productId in cartItems) {
      // debugger;
      const productObj = cartItems[productId];
      if (orderInfo.orderType !== "schedule") {
        if (productObj.productType === "COMBO") {
          const finalComboCustomization = Object.keys(
            productObj.finalCustomisation
          ).reduce((acc, curr) => {
            const group = cloneDeep(productObj.finalCustomisation[curr]);

            group.itemCustomization =
              group.itemCustomization.finalCustomisation ??
              defaultItemCustomisation;
            delete group.name;
            acc[curr] = group;
            return acc;
          }, {});

          items.push({
            productId: productObj.productId,
            variantId: productObj.selectedVariant.id,
            productName: productObj.name,
            productImage: productObj.image,
            qty: productObj.quantity,
            price: getOriginalPrice(productObj),
            itemCustomization: finalComboCustomization,
            discountedPrice: getPrice(productObj),
            tax: productObj.tax,
            brandId: productObj.brandId,
            categoryId: productObj.categoryId,
            isCMSEnabled: productObj.isCMSEnabled,
            isCookingEnabled: productObj.isCookingEnabled,
            isIMSEnabled: productObj.isIMSEnabled,
            isQCEnabled: productObj.isQCEnabled,
          });
        } else {
          items.push({
            productId: productObj.productId,
            variantId: productObj.selectedVariant.id,
            productName: productObj.name,
            productImage: productObj.image,
            qty: productObj.quantity,
            price: getOriginalPrice(productObj),
            itemCustomization:
              productObj.finalCustomisation ?? defaultItemCustomisation,
            discountedPrice: getPrice(productObj),
            tax: productObj.tax,
            brandId: productObj.brandId,
            categoryId: productObj.categoryId,
            isCMSEnabled: productObj.isCMSEnabled,
            isCookingEnabled: productObj.isCookingEnabled,
            isIMSEnabled: productObj.isIMSEnabled,
            isQCEnabled: productObj.isQCEnabled,
          });
        }
      } else {
        if (productObj.productType === "COMBO") {
          const finalComboCustomization = Object.keys(
            productObj.finalCustomisation
          ).reduce((acc, curr) => {
            const group = cloneDeep(productObj.finalCustomisation[curr]);

            group.itemCustomization =
              group.itemCustomization.finalCustomisation ??
              defaultItemCustomisation;
            delete group.name;
            acc[curr] = group;
            return acc;
          }, {});

          items.push({
            productId: productObj.productId,
            variantId: productObj.selectedVariant.id,
            productName: productObj.name,
            productImage: productObj.image,
            qty: productObj.quantity,
            price: getOriginalPrice(productObj),
            // itemCustomization: finalComboCustomization,
            discountedPrice: getPrice(productObj),
            tax: productObj.tax,
            brandId: productObj.brandId,
            categoryId: productObj.categoryId,
            isCMSEnabled: productObj.isCMSEnabled,
            isCookingEnabled: productObj.isCookingEnabled,
            isIMSEnabled: productObj.isIMSEnabled,
            isQCEnabled: productObj.isQCEnabled,
          });
        } else {
          items.push({
            productId: productObj.productId,
            variantId: productObj.selectedVariant.id,
            productName: productObj.name,
            productImage: productObj.image,
            qty: productObj.quantity,
            price: getOriginalPrice(productObj),
            // itemCustomization:
            // 	productObj.finalCustomisation ??
            // 	defaultItemCustomisation,
            discountedPrice: getPrice(productObj),
            tax: productObj.tax,
            brandId: productObj.brandId,
            categoryId: productObj.categoryId,
            isCMSEnabled: productObj.isCMSEnabled,
            isCookingEnabled: productObj.isCookingEnabled,
            isIMSEnabled: productObj.isIMSEnabled,
            isQCEnabled: productObj.isQCEnabled,
          });
        }
      }
    }

    const auth = getState().auth;

    const userDetails =
      orderInfo.orderType !== "schedule"
        ? {
            user: auth.user,
            address:
            (clientType != "SCHOOL" && clientType != "STAFF")
            ? orderType === "PICKUP"
                  ? {}
                  : selectedAddress
                : { ...selectedAddress, lat: null, long: null },
            name: auth.name,
            email: auth.email,
          }
        : {
            user: auth.user,
            addressId: selectedAddress.id,
            name: auth.name,
            email: auth.email,
          };

          const couponDetails = isEmpty(orderInfo.selectedPromo)
      ? {
          couponCode: null,
          couponAmount: 0,
        }
      : {
          couponCode: orderInfo.selectedPromo.couponCode,
          couponAmount: orderInfo.couponAmount,
        };
    if (orderInfo.orderType === "schedule") {
      scheduled = {
        scheduledFrom: moment(orderInfo?.startDate).format("YYYY-MM-DD"),
        scheduledTo: moment(orderInfo?.endDate).format("YYYY-MM-DD"),
        time: '10:00',
        daysOfWeek: orderInfo?.frequencyDays
          .map((currentDay) => {
            return daysOfWeek[currentDay];
          })
          .join(","),
        noOfMeals: orderInfo?.noOfDays,
      };
    }
    if (orderInfo.orderType === "regular" && orderInfo.deliverNow === false) {
      scheduled = moment(orderInfo.startDate)
        .format("MM/DD/YYYY")
        .concat(
          ` ${moment(orderInfo.laterTime ?? "10:00:00", "HH:mm:ss").format(
            "HH:mm"
          )}`
        );
    }
    var req = {
      ...userDetails,
      ...orderDetails,
      ...couponDetails,
      scheduled: scheduled,
      store: getState().store.storeId,
      type:
        getState().orderInfo.orderType === "regular" &&
        getState().orderInfo.deliverNow === false
          ? "LATER"
          : getState().orderInfo.orderType.toUpperCase(),
      items,
      customization: orderInfo.instructions.comments,
      orderType,
    };
    if (req.type === "SCHEDULE") {
		console.log({paymentMode});
      subscriptionService
        .placeSubscription(req)
        .then(
          (data) => {
            if (paymentMode !== "DIGITAL") {
              dispatch(
                placementSuccess({
                  orderId: data?.subscriptionId,
                  isLoading: false,
                })
              );
              dispatch(cartActions.clearCartStore());
              dispatch(removePromo());
              toast.success(
                `Generated Subscription - Subscription Id ${data?.subscriptionId}`
              );
              dispatch(
                authActions.redirect(
                  `/${getState()?.store?.storeId}/track-subscription/${
                    data?.subscriptionId
                  }`
                )
              );
            } else {
              dispatch(modalActions.togglePaytmModal(true));
              dispatch(
                paymentSuccess({
                  subscriptionId: `SUBS_${data.subscriptionId}`,
                  isLoading: true,
                })
              );
              startPayment({
                orderId: `SUBS_${data.subscriptionId}`,
                txnToken: data.subsTxnToken,
                type: "SUBS",
              });
              dispatch(removePromo());
            }
            dispatch(orderActions.setLoading(false));
          },
          (error) => {
            dispatch(orderActions.setLoading(false));
            dispatch(cartActions.clearCartStore());
            dispatch(removePromo());
            if (paymentMode !== "DIGITAL") {
              toast.error(error);
              dispatch(placementFailure({ error }));
            } else {
              toast.error(error);
              dispatch(paymentFailure({ error }));
            }
            dispatch(authActions.redirect(`/${getState().store.storeId}/home`));
          }
        )
        .catch((error) => {
          toast.error(error);
          dispatch(orderActions.setLoading(false));
          dispatch(cartActions.clearCartStore());
          dispatch(removePromo());
        });
    } else {
      orderService.placeOrder(req).then(
        (data) => {
          console.log({data});
          if (paymentMode !== "DIGITAL") {
            dispatch(
              placementSuccess({
                orderId: data?.orderId,
                isLoading: false,
              })
            );
            dispatch(cartActions.clearCartStore());
            dispatch(removePromo());
            toast.success(data?.message);
            dispatch(
              authActions.redirect(
                `/${getState().store.storeId}/track-order/${data?.orderId}`
              )
            );
          } else {
            dispatch(modalActions.togglePaytmModal(true));
            dispatch(paymentSuccess({ ...data, isLoading: true }));
            startPayment(data);
            dispatch(removePromo());
          }
        },
        (error) => {
          if(error?.statusCode === 500){
            dispatch(authActions?.userLogout());
            dispatch(authActions.redirect('/'));
          }
          dispatch(orderActions.setLoading(false));
          dispatch(cartActions.clearCartStore());
          dispatch(removePromo());
          if (paymentMode !== "DIGITAL") {
            toast.error(error?.message);
            dispatch(placementFailure({ ...error?.message }));
          } else {
            toast.error(error?.message);
            dispatch(paymentFailure({ ...error?.message }));
          }
          error?.statusCode !== 500 && dispatch(authActions.redirect(`/${getState().store.storeId}/home`));
        }
      );
    }
  };
  function paymentSuccess(payload) {
    return { type: orderConstants.ORDER_PAYMENT_SUCCESS, payload };
  }
  function paymentFailure(payload) {
    return { type: orderConstants.ORDER_PAYMENT_FAILURE, payload };
  }

  function placementRequest() {
    return { type: orderConstants.ORDER_PLACEMENT_REQUEST };
  }
  function placementSuccess(payload) {
    return { type: orderConstants.ORDER_PLACEMENT_SUCCESS, payload };
  }
  function placementFailure(payload) {
    return { type: orderConstants.ORDER_PLACEMENT_FAILURE, payload };
  }
}

function applyPromo(orderDetails) {
  return (dispatch, getState) => {
    dispatch(request());
    const cartItems = getState().cart;
    const { paymentMode, couponCode, deliveryCharges, packagingCharges } =
      orderDetails;
    const orderType = getState().orderInfo.deliveryMode.toUpperCase();
    const { totalTax, totalAmount, subTotal, totalSavings } =
      cartService.calculateCart({
        deliveryCharges,
        packagingCharges,
        cart: cartItems,
        noOfDays: getState().orderInfo.noOfDays,
      });

    let items = [];
    for (const productId in cartItems) {
      const productObj = cartItems[productId];
      items.push({
        productId: productObj.productId,
        variantId: productObj.selectedVariant.id,
        productName: productObj.name,
        productImage: productObj.image,
        qty: productObj.quantity,
        price: productObj.selectedVariant.price,
        itemCustomization: productObj.finalCustomisation,
        discountedPrice: getPrice(productObj),
        tax: productObj.tax,
        brandId: productObj.brandId,
        categoryId: productObj.categoryId,
        isCMSEnabled: productObj.isCMSEnabled,
        isCookingEnabled: productObj.isCookingEnabled,
        isIMSEnabled: productObj.isIMSEnabled,
        isQCEnabled: productObj.isQCEnabled,
      });
    }
    const auth = getState().auth;
    const userDetails = {
      user: auth.user,
      address: orderType === "PICKUP" ? {} : orderDetails.selectedAddress,
      name: auth.name,
      email: auth.email,
    };

    const req = {
      ...userDetails,
      items,
      orderValue: totalAmount,
      deliveryCharges,
      packagingCharges,
      subTotal,
      gst: totalTax,
      totalDiscount: totalSavings,
      isPaymentCOD: true ? paymentMode === "cod" : false,
      couponCode: couponCode,
      store: getState().store.storeId,
      customization: getState().cart.customization,
      orderType,
    };

    orderService.applyPromo(req).then(
      (data) => {
        const payload = {
          couponAmount: data.couponAmount,
          selectedPromo:
            getState().store.promoCodes.find(
              (promo) => promo.couponCode === couponCode
            ) || {},
        };
        // dispatch(notificationActions.enqueueSnackbar({ message: data.message }));
        dispatch(success(payload));
        // dispatch(modalActions.togglePromosModal(false));
      },
      (error) => {
        const payload = {
          couponAmount: 0,
        };
        // dispatch(notificationActions.enqueueSnackbar({ message: error, variant: 'error' }));
        toast.error(error);
        dispatch(failure(payload));
        // dispatch(modalActions.togglePromosModal(false));
      }
    );
  };
  function request() {
    return { type: orderConstants.APPLY_PROMO_REQUEST };
  }
  function success(payload) {
    return { type: orderConstants.APPLY_PROMO_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: orderConstants.APPLY_PROMO_FAILURE, payload };
  }
}

function removePromo() {
  return {
    type: orderConstants.REMOVE_PROMO,
  };
}

function setOrderId(orderId) {
  const payload = {
    orderId,
  };
  return { type: orderTrackConstants.SET_ORDER_ID, payload };
}

function startTracking() {
  return (dispatch) => {
    dispatch(trackOrder());
    const intervalId = setInterval(() => {
      dispatch(trackOrder());
    }, 10000);
    dispatch({
      type: orderTrackConstants.START_TRACKING,
      payload: { intervalId },
    });
  };
}

function stopTracking() {
  return (dispatch, getState) => {
    const intervalId = getState().orderTrack.intervalId;
    clearInterval(intervalId);
    dispatch(feedbackActions.initOrderingExperience({ orderRating: 0 }));
    dispatch({
      type: orderTrackConstants.STOP_TRACKING,
      payload: { intervalId: null },
    });
  };
}

function trackOrder() {
  return (dispatch, getState) => {
    dispatch(request());
    const { orderId, orderStatus, items } = getState().orderTrack;
    const auth = getState().auth;
    const req = {
      orderId: orderId,
      userId: auth.user,
    };
    // const isFeedbackLoading = getState().feedback.isLoading;
    // const { orderRating } = getState().feedback;
    orderService.trackOrder(req).then(
      (data) => {
        const payload = { ...data };
        // payload.activeStep = orderService.getStepFromStatusCode('Order', data.orderStatus);
        // for (const item in data.items) {
        // payload.items[item].activeStep = orderService.getStepFromStatusCode('Item', data.items[item].statusCode);
        // payload.items[item].activeStepText = orderService.getStageFromStep(payload.items[item].activeStep);
        // }
        // if (isFeedbackLoading) {
        // payload['orderRating'] = orderRating;
        // } else if (orderRating !== data.orderRating) {
        dispatch(
          feedbackActions.initOrderingExperience({
            orderRating: data.orderRating,
          })
        );
        // }
        dispatch(success(payload));
        if (data.orderStatus === 700 || data.orderStatus === 800) {
          dispatch(stopTracking());
        }
        // dispatch(setLoading(false));
      },
      (error) => {
        toast.error("Some Error Occred");

        dispatch(authActions.redirect("/orderNotFound"));
        // dispatch(setLoading(true));
      }
    );
  };

  function request() {
    return { type: orderTrackConstants.ORDER_TRACKING_REQUEST };
  }
  function success(payload) {
    return { type: orderTrackConstants.ORDER_TRACKING_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: orderTrackConstants.ORDER_TRACKING_FAILURE, payload };
  }
}

function getPastOrders() {
  return (dispatch) => {
    dispatch(request());
    let redirectUrl = '';
    orderService.getPastOrders().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        if(error?.statusCode === 500){
          redirectUrl = '/';
          toast.error("Session expired");
          dispatch(authActions.userLogout());
          dispatch(authActions.redirect(redirectUrl));
        }else{
          toast.error(error?.errorMessage ?? "Some error occured");
        }
      }
    );
  };

  function request() {
    return { type: orderTrackConstants.PAST_ORDERS_REQUEST };
  }
  function success(payload) {
    return { type: orderTrackConstants.PAST_ORDERS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: orderTrackConstants.PAST_ORDERS_FAILURE, payload };
  }
}

function cancelOrder({ orderDetails, showSnackbars = true }) {
  return (dispatch, getState) => {
    const req = {
      ...orderDetails,
      store: getState().orderInfo.storeId,
    };
    dispatch(request());
    orderService.cancelOrder(req).then(
      (data) => {
        dispatch(success({}));
        dispatch(orderActions.getPastOrders());
        toast.success(data.message);
        dispatch(stopTracking());
        dispatch(authActions.redirect(`/${getState()?.orderInfo?.storeId}/home`));
      },
      (error) => {
        if(error?.statusCode === 500){
          toast.error("Session expired");
          dispatch(authActions.userLogout());
          dispatch(authActions.redirect("/"));
        } else{
          toast.error(error?.message ?? "Problem in cancelling order");
        }
        dispatch(failure({}));
      }
    );
  };
  function request() {
    return { type: orderConstants.CANCEL_ORDER_REQUEST };
  }
  function success(payload) {
    return { type: orderConstants.CANCEL_ORDER_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: orderConstants.CANCEL_ORDER_FAILURE, payload };
  }
}

function updatePaymentStatus(transactionDetails) {
  return (dispatch, getState) => {
    dispatch(request());
    orderService.updatePaymentStatus(transactionDetails).then(
      (data) => {
        let variant = "",
          redirectUrl = "";
        let message = data.message;
        if (data.paymentStatus === "TXN_SUCCESS") {
          // variant = 'success';
          toast.success(data?.message);
          redirectUrl = `/${getState().store.storeId}/track-order/${
            transactionDetails.orderId
          }`;
        } else if (
          data.paymentStatus === "USER_PENDING" ||
          data.paymentStatus === "PENDING"
        ) {
          // variant = 'warning';
          toast.error(data?.message);
          redirectUrl = `/${getState().store.storeId}/track-order/${
            transactionDetails.orderId
          }`;
        } else if (data.paymentStatus === "TXN_FAILURE") {
          // variant = 'error';
          redirectUrl = `/${getState().store.storeId}/home`;
          toast.error(data?.message);
        }
        dispatch(success());
        dispatch(cartActions.clearCartStore());
        // dispatch(promoActions.removePromo());
        // dispatch(notificationActions.enqueueSnackbar({ message, variant }));
        dispatch(authActions.redirect(redirectUrl));
      },
      (error) => {
        const redirectUrl = `/${getState().store.storeId}/home`;
        dispatch(failure());
        dispatch(cartActions.clearCartStore());
        // dispatch(promoActions.removePromo());
        // dispatch(notificationActions.enqueueSnackbar({ message: error, variant: 'error' }));
        dispatch(authActions.redirect(redirectUrl));
      }
    );
  };
  function request() {
    return { type: orderConstants.UPDATE_PAYMENT_STATUS_REQUEST };
  }
  function success(payload) {
    return { type: orderConstants.UPDATE_PAYMENT_STATUS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: orderConstants.UPDATE_PAYMENT_STATUS_FAILURE, payload };
  }
}
