export const userConstants = {
    USER_SET: "USER_SET",
    USER_REMOVE: "USER_REMOVE",
    USER_UPDATE:"USER_UPDATE",
    REDIRECT: 'REDIRECT',

    ADD_ADDRESS_REQUEST: 'ADD_ADDRESS_REQUEST',
    ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
    ADD_ADDRESS_FAILURE: 'ADD_ADDRESS_FAILURE',

    SET_USER_ORDER_ADDRESS : 'SET_USER_ORDER_ADDRESS',
};