import { userConstants } from "../Constants";

const initialState = {
  name: "",
  token: "",
  addresses: [
  ],
  selectedUserAddress : {
    address: '',
    location:{
      lat:'',
      lng: '',
    }
  },
  email: "",
  auth: false,
  redirectTo:null
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case userConstants.USER_SET:
      return {
        ...state,
        ...action.payload,
        auth: true,
      };
    case userConstants.USER_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case userConstants.USER_REMOVE:
      return {
        ...initialState,
        auth: false,
        selectedUserAddress:action.payload
      };
    case userConstants.REDIRECT:
      return {
          ...state,
          redirectTo: action.payload
      }
    case userConstants.SET_USER_ORDER_ADDRESS:
      return {
        ...state,
        selectedUserAddress: action.payload
      };
    case userConstants.ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses:[action.payload, ...state.addresses]
      };
    default:
      return state;
  }
}
