/** @format */

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { calculeteNoOfDays } from "../../Functions/GetTime";
import { orderActions } from "../../Redux/Actions/order.actions";
import toast from "react-hot-toast";
import Timekeeper from "react-timekeeper";
import { enGB } from "date-fns/locale";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@material-ui/core/TextField";
import StaticTimePicker from "@mui/lab/StaticTimePicker";
import ToggleDays from "../../Components/Modal/ToggleDays";
import { menuActions } from "../../Redux/Actions";
import { useParams } from "react-router";
import moment from "moment";
import { Box } from "@mui/system";
import { DateRangePicker, MobileDateRangePicker } from "@mui/lab";
import { compareDates } from "../../Functions/utils";

// default schedule preferences
const scheduleChoices = [
  {
    choiceId: 1000,
    title: "Which meal you want to schedule ?",
    type: "single",
    name: "type",
    defaultOptionId: 1001,
    options: [
      {
        optionId: 1001,
        name: "Now",
        selected: true,
      },
      {
        optionId: 1002,
        name: "Later",
        selected: false,
      },
      {
        optionId: 1003,
        name: "Schedule Your Meal",
        selected: false,
      },
    ],
  },
  // {
  //   choiceId: 2000,
  //   title: "How you want to schedule your meals?",
  //   type: "single",
  //   name: "frequency",
  //   defaultOptionId: 2001,
  //   options: [
  //     {
  //       optionId: 2001,
  //       name: "Monthly",
  //       selected: true,
  //     },
  //     {
  //       optionId: 2002,
  //       name: "Weekly(only Weekdays)",
  //       selected: false,
  //     },
  //     {
  //       optionId: 2003,
  //       name: "Only Weekends",
  //       selected: false,
  //     },
  //     {
  //       optionId: 2003,
  //       name: "Custom",
  //       selected: false,
  //     },
  //   ],
  // },
];

export default function ScheduleMeal({ close }) {
  const { orderInfo, store } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [dateValue, setDateValue] = useState([null, null]);

  const [choices, setChoices] = useState([]);
  const [value, setValue] = useState(
    orderInfo.time ? moment(orderInfo.time, "HH:mm:ss") : null
  );
  // console.log(typeof(value)=== typeof(new Date()), 'value');
  const [weekDays, setWeekDays] = useState([...orderInfo.frequencyDays]);
  // if preferences are pre stored then reserve them
  useEffect(() => {
    if (orderInfo.startDate && orderInfo.endDate) {
      setDateValue([
        new Date(orderInfo.startDate),
        new Date(orderInfo.endDate),
      ]);
    } else if (orderInfo.startDate) {
      setDateValue([new Date(orderInfo.startDate), null]);
    }
    setChoices(
      orderInfo.preferences["schedulePreferences"]?.length
        ? orderInfo.preferences["schedulePreferences"]
        : scheduleChoices
    );
  }, [orderInfo]);

  const selectOption = (choiceIndex, optionIndex) => {
    let updatedChoices = choices;
    updatedChoices[choiceIndex].options.forEach((option, index) => {
      index === optionIndex
        ? (option.selected = true)
        : (option.selected = false);
    });
    setChoices([...updatedChoices]);
  };
  const { restaurantId } = useParams();

  const onSubmit = () => {
    const format = "hh:mm:ss";
    const requiredTotalDates =
      (dateValue[1] - dateValue[0]) / (1000 * 3600 * 24) + 1;
    if (store?.askTimeForLater && !value) {
      toast.error("Please Select Valid Time");
      return;
    }
    if (dateValue[0] === null || dateValue[1] === null) {
      toast.error("Please Select Start and End Date");
      return;
    }
    if (requiredTotalDates < 3) {
      toast.error("Please Select Atleast 3 days to Continue");
      return;
    }
    if (!moment(dateValue[0]).isValid()) {
      toast.error("Please Select Start Date");
    }
    if (!moment(dateValue[1]).isValid()) {
      toast.error("Please Select End Date");
    }
    if (weekDays.length === 0) {
      toast.error("Please Select A day to schedule your meal");
      return;
    }
    var currDate = new Date();
    // debugger;
    const selectedTime = value && moment(value, format);
    if (
      currDate.toLocaleDateString("en-US") ===
        dateValue[0].toLocaleDateString("en-US") &&
      moment(currDate, "HH:mm:ss").format("HH:mm:ss") >
        selectedTime.format("HH:mm:ss")
    ) {
      // debugger;
      toast.error("Store Cannot Serve Today at this time");
      return;
    }
    orderInfo.deliverNow = false;
    orderInfo.orderType = "schedule";
    orderInfo.noOfDays  = 1;
    orderInfo.startDate = dateValue[0].toISOString();
    orderInfo.endDate = dateValue[1].toISOString();
    orderInfo.time = selectedTime && selectedTime.format("HH:mm:ss");
    // debugger;
    orderInfo.frequencyDays = weekDays;
    dispatch(orderActions.changeOrderStore(orderInfo));
    dispatch(
      menuActions.getMenu(restaurantId, {
        ...orderInfo,
      })
    );
    close();
    document.body.classList.remove("stop-scrolling");
  };

  const offDates = store?.offDates?.map(
    (date) => new Date(moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"))
  );

  const openOnWeekdays = store?.openOnWeekdays?.map((day) => {
    const x = day + 1;
    if (x === 7) {
      return 0;
    } else {
      return x;
    }
  });

  const disableDate = (day, dateArr) => {
    const boolArr = dateArr?.map((date) => compareDates(day, date)) ?? [];
    return (boolArr.length > 0 && boolArr.includes(true)) || (openOnWeekdays && !openOnWeekdays?.includes(day.getDay()));
  };

  return (
    <div className="font-open-sans  h-screen w-10/12 mt-12 sm:w-8/12 m-auto sm:mt-16 max-w-screen ">
      <div className="font-bold text-xl">Subscribe Your Meal</div>
      {store?.askTimeForLater && (
        <>
          <div className="mt-10 font-bold text-base">Choose Your Meal Time</div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticTimePicker
              // displayStaticWrapperAs='desktop'

              value={value}
              bool={false}
              orientation="portrait"
              onChange={(newValue) => {
                setValue(newValue);
              }}
              // ampmInClock
              ampm={false}
              renderInput={(params) => (
                <TextField className="max-w-xs w-full opacity-0 " {...params} />
              )}
              disableIgnoringDatePartForTimeValidation
            />
          </LocalizationProvider>
        </>
      )}
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
				<StaticTimePicker
					displayStaticWrapperAs='mobile'
					value={value}
					bool={false}
					ampm={false}
					// orientation='landscape'
					onChange={(newValue) => {
						setValue(newValue);
					}}
					renderInput={(params) => <TextField {...params} />}
				/>
			</LocalizationProvider> */}
      {/* {choices.map((choice, choiceIndex) => (
        <div key={choiceIndex}>
          <div className='mt-10 font-bold text-base'>{choice.title}</div>
          <div className='mt-1 text-xs text-gray-600'>Select Any One</div>
          {choice.options.map((option, optionIndex) => (
            <div key={optionIndex} className='mt-3 space-y-2'>
              <div>
                <input
                  onClick={() => selectOption(choiceIndex, optionIndex)}
                  type='radio'
                  id={choice.name}
                  name={choice.name}
                  value={option.name}
                  checked={option.selected}
                />
                <label className='ml-4' for='child'>
                  {option.name}
                </label>
              </div>
            </div>
          ))}
        </div>
      ))} */}
      <div>
        <div className="mt-4 font-bold text-base">
          How you want to subscribe your meals?
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateRangePicker
              minDate={new Date(Date.now() + 86400000)}
              displayStaticWrapperAs="mobile"
              value={dateValue}
              disableCloseOnSelect
              onChange={(newValue) => {
                setDateValue(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
			  shouldDisableDate={(day) => disableDate(day, offDates)}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="mt-4 font-bold text-base">Selected Days</div>
      <div>
        <ToggleDays days={weekDays} setDays={setWeekDays} openOnWeekdays={openOnWeekdays} />
      </div>
      <button
        onClick={onSubmit}
        className="max-w-sm mt-2 h-12 w-full bg-8593FF rounded   font-semibold font-open-sans text-xs text-FFFFFF capitalize  cursor-pointer  hover:bg-indigo-500"
      >
        Next
      </button>
      <br />
      <br />
    </div>
  );
}
