import React from "react";
import Chef from "../../Assets/Icons/Group 159198.svg";
import Namaste from "../../Assets/Icons/OBJECTS.svg";
import { formatAMPM, tConvert } from "../../Functions/GetTime";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuActions, orderActions } from "../../Redux/Actions";

export default function MenuJumbotron({ type }) {
  const { restaurantId } = useParams();
  const { openingTime, closingTime, customerSupport } = useSelector(
    (state) => state.store
  );
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const orderInfo = useSelector((state) => state.orderInfo);
  const defaultCategory = menu.menu[0]?.categoryId;

  // genetal type --> for general menu card
  // specefic type --> for specefic menu card(eg. redirection to specific category or menu ) based on some criteria(eg. day time or night time)

  let color = type === "general" ? "bg-23232C" : "bg-F8B363";
  let rowReverse = type === "general" ? "block" : "flex-row flex";
  const history = useHistory();
  return (
    <div
      className={`${color} ${rowReverse} sm:flex  h-full px-5  sm:flex-row  sm:px-5 md:px-10 xl:px-28 py-5 sm:py-16  rounded-xl items-center w-full`}
    >
      <div
        className={`flex-1 flex ${
          type === "general" ? "justify-center" : "sm:justify-end"
        } sm:block`}
      >
        <img
          className="h-24 sm:h-40 w-auto"
          src={type === "general" ? Chef : Namaste}
          alt="display"
        />
      </div>
      {type === "general" && (
        <div className="text-center flex-1">
          <div className="text-F8B363 mt-2 sm:mt-1 text-xs sm:text-E0E0E0 sm:text-sm font-open-sans font-normal">
            We are open {tConvert(openingTime)} - {tConvert(closingTime)}
          </div>
          <div className="mt-4 sm:mt-8 text-E0E0E0 text-xs font-open-sans font-normal">
            Call us to book your order
          </div>
          <div className="text-base text-white mt-2 font-open-sans font-bold">
            {customerSupport}
          </div>
          <div
            className="flex items-center justify-center"
            onClick={() => {
              // debugger;
              if (orderInfo.orderType != "regular") {
                dispatch(
                  menuActions.getMenu(restaurantId, {
                    ...orderInfo,
                    orderType: "regular",
                    deliverNow: true,
                  })
                );
                dispatch(orderActions.changeOrderStore({
                  ...orderInfo,
                  orderType: "regular",
                  deliverNow: true,
                }))
              }
              history.push(
                `/${restaurantId}/menu-card/regular/all/${defaultCategory}`
              );
            }}
          >
            <button
              className="font-open-sans font-semibold mt-10 sm:mt-4 h-12 text-white w-full  p-2 text-sm bg-8593FF rounded  hover:bg-gray-700 hover:text-white
            "
            >
              Open Menu Card
            </button>
          </div>
        </div>
      )}
      {type === "specefic" && (
        <div className="text-center  flex-1">
          <div className="text-xs text-white sm:text-lg sm:text-black font-open-sans font-normal">
            Namaste , It's {formatAMPM(new Date())}
          </div>
          <div className="text-base sm:text-2xl font-open-sans font-bold text-black mt-1">
            Would you like to place an order?
          </div>
          <Link
            to={
              orderInfo.orderType == "schedule"
                ? `/${restaurantId}/menu-card/schedule/schedule/${defaultCategory}`
                : `/${restaurantId}/menu-card/regular/all/${defaultCategory}`
            }
          >
            <button className="font-open-sans font-semibold sm:text-sm border-8593FF mt-2 h-7 sm:h-12 text-8593FF w-32 sm:w-full  sm:p-2 text-2xs bg-white rounded  hover:bg-gray-700 hover:text-white">
              {orderInfo.orderType == "schedule"
                ? "Subscribe your Meal"
                : orderInfo.orderType == "regular" &&
                  orderInfo.deliverNow == false
                ? `Order for ${orderInfo.laterTime ?? orderInfo.startDate}`
                : "Order Now"}
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}
