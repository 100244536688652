/** @format */

import { Redirect, Route, Switch } from "react-router-dom";
import FoodDetail from "./Containers/FoodDetail/FoodDetail";
import Restaurant from "./Containers/Restaurant/Restaurant";
import MenuCard from "./Containers/MenuCard/MenuCard";
import Home from "./Containers/Home/Home";
import Layout from "./Containers/Layout";
import ConfirmOrder from "./Containers/ConfirmOrder/ConfirmOrder";
import CheckOut from "./Containers/CheckOut/CheckOut";
import Pay from "./Containers/Pay/Pay";
import MyOrder from "./Containers/MyOrder/MyOrder";
import TrackOrder from "./Containers/TrackOrder/TrackOrder";
import PhoneLogin from "./Components/Login/PhoneLogin";
import MyOrders from "./Containers/MyOrders/MyOrders";
import Waiter from "./Components/Waiter/Waiter";
import Footer from "./Components/Footer/Footer";
import { Fragment, useState } from "react";
import Bill from "./Containers/Bill/Bill";
import { useSelector } from "react-redux";
import NotFound from "./Containers/NotFound/404";
import OrderNotExist from "./Containers/NotFound/OrderNotFound";
import Header from "./Components/Header/Header";
import Subscription from "./Containers/FoodDetail/subscription";
import ConfirmSubscription from "./Containers/ConfirmSubscription/ConfirmSubscription";
import SearchBarMobile from "./Components/SearchBarMobile/SearchBarMobile";
import { ProtectedRoute } from "./Components/ProtectedRoute";
import MyCredits from "./Containers/MyCredits/MyCredits";
import MyCreditsHistory from "./Containers/MyCreditsHistory/MyCreditsHistory";
import MyChild from "./Containers/MyChild/MyChild";
import { MyStaff } from "./Containers/MyStaff";

export const Routes = () => {
  const auth = useSelector((state) => state.auth);
  const isLoggedIn = auth?.auth ?? false;
  const [showSearch, setShowSearch] = useState(false);
  const [query, setQuery] = useState("");
  const client = useSelector((state) => state.client);

  const {
    address,
    email,
    gst,
    fssai,
    customerSupport,
    registrationDate,
    clientType,
  } = client;

  return (
    <Switch>
      <ProtectedRoute
        exact
        type='S'
        path='/:restaurantId/food-item/:orderType/:foodId'
        render={(props) => (
          <Layout {...props} PageName='Item Details'>
            <FoodDetail {...props} />
            <Waiter />
          </Layout>
        )}
      />
      <ProtectedRoute
        exact
        type='S'
        path='/:restaurantId/menu-card/:orderType/:category'
        render={(props) => <Subscription {...props} />}
      />
      <ProtectedRoute
        exact
        type='S'
        path='/:restaurantId/menu-card/:orderType/:category/:type'
        render={(props) => (
          <Layout
            {...props}
            PageName='Menu Card'
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            query={query}
            setQuery={setQuery}>
            <MenuCard
              {...props}
              query={query}
              showSearch={showSearch}
              setShowSearch={setShowSearch}
            />
            <Waiter menu />
            <SearchBarMobile
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              query={query}
              setQuery={setQuery}
            />
          </Layout>
        )}
      />
      <ProtectedRoute
        type='S'
        path='/my-order'
        render={(props) => (
          <Layout {...props} PageName='Cart'>
            <MyOrder {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        exact
        type='AS'
        path='/:restaurantId/bill/:orderId'
        render={(props) => (
          <Layout {...props} PageName='Your Bill'>
            <Bill {...props} />
          </Layout>
        )}
      />
      <Route
        exact
        path='/:restaurantId/login'
        render={(props) => {
          if (isLoggedIn) {
            return <Redirect to='/' />;
          }
          return (
            <Layout {...props} PageName='Login'>
              <PhoneLogin {...props} />
            </Layout>
          );
        }}
      />

      {/* Login Route for Landing Page in Phone */}
      <Route
        exact
        path='/login'
        render={(props) => {
          if (isLoggedIn) {
            return <Redirect to='/' />;
          }
          return (
            <div className='grid grid-cols-12 bg-F5F5F5'>
              <div className='col-span-12 col-start-1 md:col-start-2 md:col-span-10'>
                <Header history={props.history} PageName='Login' />
                <div className='mt-28 md:mt-20 mb-8 '>
                  <PhoneLogin {...props} />
                </div>
                <Footer
                  address={address}
                  email={email}
                  gst={gst}
                  fssai={fssai}
                  customerSupport={customerSupport}
                />
              </div>
            </div>
          );
        }}
      />
      <ProtectedRoute
        exact
        type='S'
        path='/:restaurantId/home'
        render={(props) => {
          console.log({ props }, "TEST2");
          return (
            <Layout {...props} PageName=''>
              <Restaurant {...props} />
            </Layout>
          );
        }}
      />
      <Route
        exact
        path='/'
        render={(props) => (
          <Fragment>
            <Home {...props} />
            <Footer
              address={address}
              email={email}
              gst={gst}
              fssai={fssai}
              customerSupport={customerSupport}
              registrationDate={registrationDate}
            />
          </Fragment>
        )}
      />
      <ProtectedRoute
        type='AS'
        path='/:restaurantId/my-order'
        render={(props) => (
          <Layout {...props} PageName='Cart'>
            <div className='mt-28 md:mt-20 mb-8 hidden'>
              <PhoneLogin {...props} />
            </div>
            <MyOrder {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        exact
        type='AS'
        path='/:restaurantId/track-subscription/:subscriptionId'
        render={(props) => (
          <Layout {...props} PageName='Track Order'>
            <ConfirmSubscription {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        exact
        type='AS'
        path='/:restaurantId/track-order/:orderId'
        render={(props) => (
          <Layout {...props} PageName='Track Order'>
            <ConfirmOrder {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        exact
        type='AS'
        path='/:restaurantId/track-order/:orderId/:foodItemSlug'
        render={(props) => (
          <Layout {...props} PageName='Track Item'>
            <TrackOrder {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        exact
        type='AS'
        path='/:restaurantId/check-out/pay'
        render={(props) => (
          <Layout {...props} PageName='Pay'>
            <Pay {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        exact
        type='AS'
        path='/:restaurantId/check-out'
        render={(props) => (
          <Layout {...props} PageName='Add Details'>
            <CheckOut {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        type='A'
        path='/my-orders/:type'
        render={(props) => (
          <Layout {...props} PageName='My Orders'>
            <MyOrders {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        type='AS'
        path='/:restaurantId/my-order'
        render={(props) => (
          <Layout {...props} PageName='Cart'>
            <MyOrder {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        type='A'
        path='/my-credits'
        render={(props) => (
          <Layout {...props} PageName='Credits-History'>
            <MyCredits {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        type='A'
        path='/my-credits-history'
        render={(props) => (
          <Layout {...props} PageName='Credits-History'>
            <MyCreditsHistory {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        type='A'
        path='/my-child'
        render={(props) => (
          <Layout {...props} PageName='My Child'>
            <MyChild {...props} />
          </Layout>
        )}
      />
      <ProtectedRoute
        type='A'
        path='/my-staff'
        render={(props) => (
          <Layout {...props} PageName='My Child'>
            <MyStaff {...props} />
          </Layout>
        )}
      />
      {/* <ProtectedRoute
        type='A'
        path='/my-addresses'
        render={(props) => (
          <Layout {...props} PageName='My Addresses'>
            <MyCreditsHistory {...props} />
          </Layout>
        )}
      /> */}
      <Route path='/orderNotFound' component={OrderNotExist} />
      <Route path='/404' component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  );
};
