import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import WaiterImage from "../../Assets/Icons/waiter.svg";
import { notificationActions } from "../../Redux/Actions/";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import Slide from "@mui/material/Slide";

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const Waiter = (props) => {
  const [open, setOpen] = React.useState(false);

  const notification = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  useEffect(() => {
    // setTimeout(() => {
    //   // TODO Stop second Call
    //   dispatch(notificationActions.createNotification(null));
    // }, 5000);
    if (notification) {
      setOpen(true);
    }
  }, [notification]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const { menu } = props;
  return (
    <div
      className={`fixed bottom-24 right-5 sm:right-8 md:right-24 z-20 flex items-end justify-end ${
        menu ? "invisible lg:visible" : "visible"
      }`}
    >
      {/* notification container  */}
      {/* {notification && (
        <div className="w-9/12 sm:w-96 bg-F4F4F4 py-5 px-6 relative mr-4 rounded-xl shadow-xl">
          <ion-icon
            onClick={() =>
              dispatch(notificationActions.createNotification(null))
            }
            name="close-outline"
          ></ion-icon>
          <div className="font-open-sans text-sm">{notification}</div>
        </div>
      )} */}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={notification}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        // action={action}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {notification}
        </Alert>
      </Snackbar>
      {/* notification icon  */}
      {/* <div>
        <img
          src={WaiterImage}
          alt="waiter"
          className=" w-12 h-12 sm:w-16 sm:h-16"
        />
      </div> */}
    </div>
  );
};
Waiter.defaultProps = {
  menu: false,
};
export default Waiter;
