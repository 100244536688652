import urls from '../../urls';

function toQueryStringURL(queryURL, params) {
	const url = new URL(queryURL);
	Object.keys(params).forEach((key) =>
		url.searchParams.append(key, params[key])
	);
	return url;
}

export const storeService = {
	getStore,
	getPromoCodes,
	getMealTime,
};

function getStore(storeId) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	};
	return fetch(urls['storeConfig'] + '/' + storeId, requestOptions).then(
		handleResponse
	);
}

function getPromoCodes() {
	const auth = JSON.parse(localStorage.getItem('auth'));
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', token: auth.token },
	};

	return fetch(urls['getPromoCodes'], requestOptions).then(handleResponse);
}

function getMealTime(req) {
	const auth = JSON.parse(localStorage.getItem('auth'));
	const params = {
		...(req?.params ?? {}),
	};

	const url = toQueryStringURL(urls['getMealTime'], params);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			token: auth?.token ?? '123456',
		},
		body: JSON.stringify({
			...(req?.data ?? {}),
		}),
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (data.status == 'Failed') {
			const error = data.message;
			return Promise.reject(error);
		}
		return data.data;
	});
}
