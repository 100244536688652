export const subscriptionConstants = {
  SUBSCRIPTION_SET: "SUBSCRIPTION_SET",

  SET_SUBSCRIPTION_ID: "SET_SUBSCRIPTION_ID",

  SUBSCRIPTION_TRACKING_REQUEST: "SUBSCRIPTION_TRACKING_REQUEST",
  SUBSCRIPTION_TRACKING_SUCCESS: "SUBSCRIPTION_TRACKING_SUCCESS",
  SUBSCRIPTION_TRACKING_FAILURE: "SUBSCRIPTION_TRACKING_FAILURE",

  PAST_SUBSCRIPTIONS_REQUEST: "PAST_SUBSCRIPTIONS_REQUEST",
  PAST_SUBSCRIPTIONS_SUCCESS: "PAST_SUBSCRIPTIONS_SUCCESS",
  PAST_SUBSCRIPTIONS_FAILURE: "PAST_SUBSCRIPTIONS_FAILURE",

  PAST_SUBSCRIPTION_DETAIL_REQUEST: "PAST_SUBSCRIPTION_DETAIL_REQUEST",
  PAST_SUBSCRIPTION_DETAIL_SUCCESS: "PAST_SUBSCRIPTION_DETAIL_SUCCESS",
  PAST_SUBSCRIPTION_DETAIL_FAILURE: "PAST_SUBSCRIPTION_DETAIL_FAILURE",
  SET_LOADING: "SET_LOADING",

  SUBSCRIPTION_UPDATE: "SUBSCRIPTION_UPDATE",
  SUBSCRIPTION_RESET: "SUBSCRIPTION_RESET",

  SUBSCRIPTION_PLACEMENT_REQUEST: "SUBSCRIPTION_PLACEMENT_REQUEST",
  SUBSCRIPTION_PLACEMENT_SUCCESS: "SUBSCRIPTION_PLACEMENT_SUCCESS",
  SUBSCRIPTION_PLACEMENT_FAILURE: "SUBSCRIPTION_PLACEMENT_FAILURE",

  CANCEL_SUBSCRIPTION_REQUEST: "CANCEL_SUBSCRIPTION_REQUEST",
  CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
  CANCEL_SUBSCRIPTION_FAILURE: "CANCEL_SUBSCRIPTION_FAILURE",

  SUBSCRIPTION_PAYMENT_REQUEST: "SUBSCRIPTION_PAYMENT_REQUEST",
  SUBSCRIPTION_PAYMENT_SUCCESS: "SUBSCRIPTION_PAYMENT_SUCCESS",
  SUBSCRIPTION_PAYMENT_FAILURE: "SUBSCRIPTION_PAYMENT_FAILURE",

  UPDATE_PAYMENT_STATUS_REQUEST: "UPDATE_PAYMENT_STATUS_REQUEST",
  UPDATE_PAYMENT_STATUS_SUCCESS: "UPDATE_PAYMENT_STATUS_SUCCESS",
  UPDATE_PAYMENT_STATUS_FAILURE: "UPDATE_PAYMENT_STATUS_FAILURE",

  APPLY_PROMO_REQUEST: "APPLY_PROMO_REQUEST",
  APPLY_PROMO_SUCCESS: "APPLY_PROMO_SUCCESS",
  APPLY_PROMO_FAILURE: "APPLY_PROMO_FAILURE",
  REMOVE_PROMO: "REMOVE_PROMO",

  MEAL_CANCELLATION_REQUEST: "MEAL_CANCELLATION_REQUEST",
  MEAL_CANCELLATION_SUCCESS: "MEAL_CANCELLATION_SUCCESS",
  MEAL_CANCELLATION_FAILURE: "MEAL_CANCELLATION_FAILURE",

  COMPLETED_MEALS_REQUEST: "COMPLETED_MEALS_REQUEST",
  COMPLETED_MEALS_SUCCESS: "COMPLETED_MEALS_SUCCESS",
  COMPLETED_MEALS_FAILURE: "COMPLETED_MEALS_FAILURE",

  SUBSCRIPTION_DETAIL: 'SUBSCRIPTION_DETAIL',
};
