import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formatAMPM, formatddmmyyyy } from "../../../Functions/GetTime";
import { cartActions } from "../../../Redux/Actions/cart.actions";
import { menuActions, modalActions } from "../../../Redux/Actions";
import { getCustomizationTotal } from "../../../Redux/Services";
import toast from "react-hot-toast";

const cellSno = "font-caveat col-span-1 border-r text-md font-semibold pt-4";
const cellOrderDetails =
  "font-caveat col-span-5 border-r text-md font-semibold pt-4  px-3";
const cellQty =
  "col-span-3 border-r text-md font-semibold pt-4  flex justify-center";
const cellAmount =
  "font-caveat col-span-3 text-md font-semibold pt-4 text-center ";

const customCellOrderDetails =
  "font-caveat col-start-2 col-span-5 border-r border-l text-md font-semibold  py-1 px-6 text-8B8B8B";
const customCellAmount =
  "font-caveat col-start-10 col-span-3   border-l text-md font-semibold  text-center ";

export default function MyOrderCard({ showCustomization }) {
  const { cart, orderInfo, menu } = useSelector((state) => state);
  const dispatch = useDispatch();

  let totalAmount = 0;

  const calculateTotalAmount = (value, qty, item) => {
    let amt = 0;
    amt = amt + value * qty;
    let customizationTotal = getCustomizationTotal(item) * qty;
    totalAmount = totalAmount + amt + customizationTotal;
    return amt + customizationTotal;
  };

  const removeItem = (index, qty) => {
    if (orderInfo.orderType === "bulk") {
      if (qty === 10) {
        dispatch(cartActions.removeFromCartByIndex(index, 10));
      } else {
        dispatch(cartActions.removeFromCartByIndex(index, 1));
      }
    } else if (orderInfo.orderType === "schedule") {
      dispatch(cartActions.removeFromCartByIndex(index, 1));
    } else {
      dispatch(cartActions.removeFromCartByIndex(index, 1));
    }
  };

  const getPrice = (item) => {
    let calculatedTotalAmount = calculateTotalAmount(
      item.selectedVariant.discountedPrice
        ? item.selectedVariant.discountedPrice
        : item.selectedVariant.price,
      item.quantity,
      item
    );
    return calculatedTotalAmount;
  };
  // const addItem = (index, id) => {
  //   if (orderInfo.orderType === "bulk") {
  //     dispatch(cartActions.addToCartByIndex(index, 1));
  //   } else if (orderInfo.orderType === "schedule") {
  //     dispatch(cartActions.addToCartByIndex(index, 1));
  //   } else {
  //     // if(menu.products[id].maxQty != null){
  //     dispatch(cartActions.addToCartByIndex(index, 1));
  //     // }
  //   } 
  // };

  const addItem = (index, item) => {
      if(!item?.maxQty || item?.maxQty > item.quantity){
        dispatch(cartActions.addToCartByIndex(index, 1));
      } else {
        toast.error(`You can add maximum ${item.maxQty}`);
      }
  };

  const handleCustomizeItem = (item, index) => {
    if (item.productType === "COMBO") {
      dispatch(
        modalActions.toggleComboModal(true, {
          isEdit: true,
          item,
          index,
          productId: item.productId,
        })
      );
    } else {
      showCustomization(item.productId, item.appliedCustomization, index);
    }
  };
  const localStorageOrder = JSON.parse(localStorage.getItem("orderInfo"));
  return (
    <div
      className="flex flex-col py-10 px-4 bg-white max-h- overflow-y-auto"
      style={{
        maxHeight: "450px",
      }}
      id="my-order-card"
    >
      <div className="uppercase text-7FB069 py-4 border-b-2 w-full text-center">
        {localStorageOrder.orderType === "schedule"
          ? "YOUR SUBSCRIPTION"
          : "YOUR ORDER"}
      </div>
      <div className="flex m-auto my-4 space-x-4 lg:space-x-6 text-center ">
        <div>
          <div className=" text-sm font-semibold">
            {localStorageOrder.orderType === "schedule"
              ? "Lunch Scheduled"
              : "Meal Consumption Date"}
          </div>
          <div className="font-caveat text-8B8B8B  text-sm">
            {localStorageOrder.orderType === "schedule"
              ? `${formatddmmyyyy(
                  new Date(localStorageOrder.startDate),
                  "/"
                )} - ${formatddmmyyyy(
                  new Date(localStorageOrder.endDate),
                  "/"
                )}`
              : localStorageOrder.deliverNow == false
              ? formatddmmyyyy(new Date(localStorageOrder.laterDate), "/")
              : formatddmmyyyy(new Date(), "/")}
          </div>
          <div className="font-caveat text-8B8B8B  text-sm">
            {localStorageOrder.orderType === "schedule"
              ? localStorageOrder.time && `${localStorageOrder.time}`
              : !localStorageOrder.deliverNow
              ? localStorageOrder.laterTime
              : ""}
          </div>
        </div>
        <div>
          <div className=" text-sm font-semibold">Order Time</div>
          <div className="font-caveat text-8B8B8B  text-sm">
            {formatAMPM(new Date())}
          </div>
        </div>
        <div>
          <div className=" text-sm font-semibold">
            {orderInfo.orderType === "bulk" ? "No of Guests" : ""}
          </div>
          <div className="font-caveat text-8B8B8B  text-sm">
            {orderInfo.orderType === "bulk"
              ? orderInfo.preferences[2]?.options.find(
                  (option) => option.selected === true
                ).name
              : ""}
          </div>
        </div>
        <div className=""></div>
        <div>
          {/* <div className=" text-sm font-semibold">Order Type</div>
          <div className="font-caveat text-8B8B8B  text-sm">
            {localStorageOrder.orderType}
          </div> */}
        </div>
      </div>
      <div className="grid grid-cols-12 overflow-y-auto max-h-64 lg:overflow-y-auto bgWhiteScrollbar">
        <div className="col-span-1 border-r border-b text-xs font-semibold text-center py-2">
          S no.
        </div>
        <div className="col-span-5 border-r border-b text-xs font-semibold text-center py-2">
          Order Details
        </div>
        <div className="col-span-3 border-r border-b text-xs font-semibold text-center py-2">
          Qty
        </div>
        <div className="col-span-3 border-b text-xs font-semibold text-center py-2">
          Amount
        </div>

        {cart.length > 0 &&
          cart.map((item, index) => (
            <Fragment key={index}>
              {/* Row with item data in each cell   */}
              <div className={cellSno}>
                {index < 10 ? "0" + Number(index + 1) : Number(index + 1)}
              </div>
              <div className={cellOrderDetails}>
                {item.name}({item.selectedVariant.displayName})
              </div>
              <div className={cellQty}>
                <div className="flex h-7 items-center border border-E6E6E6 space-x-1 px-1">
                  <ion-icon
                    onClick={() => removeItem(index, item.quantity)}
                    style={{
                      color: "#8593FF",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    name="remove"
                  ></ion-icon>
                  <div className="text-xs">{item.quantity}</div>
                  <ion-icon
                    style={{
                      color: "#8593FF",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => addItem(index, item)}
                    name="add"
                  ></ion-icon>
                </div>
              </div>
              <div
                style={{
                  color:
                    item.selectedVariant.discountedPrice !== "Free"
                      ? "black"
                      : "#7FB069",
                }}
                className={cellAmount}
              >
                &#x20B9; {getPrice(item)}
              </div>
              {/* Added Customizations  */}
              {item.productType === "COMBO"
                ? Object.keys(item.finalCustomisation).map((comboGroupId) => {
                    const comboGroupItem =
                      item.finalCustomisation[comboGroupId];
                    return (
                      <Fragment key={index}>
                        <div className={customCellOrderDetails}>
                          - {comboGroupItem.name}
                        </div>
                        {/* empty div to maintain the grid  */}

                        <div className={customCellAmount}></div>
                      </Fragment>
                    );
                  })
                : Object.keys(item.finalCustomisation.addedItems).map(
                    (itemkey) => {
                      return item.appliedCustomization[itemkey].map(
                        (option, index) => (
                          <Fragment key={index}>
                            <div className={customCellOrderDetails}>
                              - {option.name}
                            </div>
                            {/* empty div to maintain the grid  */}

                            <div className={customCellAmount}></div>
                          </Fragment>
                        )
                      );
                    }
                  )}
              {/* Replaced Customization  */}
              {item.productType !== "COMBO" &&
                Object.keys(item.finalCustomisation.replacedItems).map(
                  (itemkey) => {
                    return item.appliedCustomization[itemkey].to.map(
                      (option, index) => (
                        <Fragment key={index}>
                          <div className={customCellOrderDetails}>
                            - {option.name}
                          </div>
                          {/* empty div to maintain the grid  */}

                          <div className={customCellAmount}></div>
                        </Fragment>
                      )
                    );
                  }
                )}
              {menu.products[item.productId]?.isCustomizable && (
                <div
                  onClick={() => handleCustomizeItem(item, index)}
                  className="col-start-2 col-span-5 border-r border-l text-xs font-semibold  py-1 px-3 text-8593FF cursor-pointer"
                >
                  Customize Item
                </div>
              )}
              <div className="col-start-10 col-span-3   border-l text-xs font-semibold  py-1 px-6"></div>
            </Fragment>
          ))}
        <div className="col-span-12 border-t"></div>
      </div>
      <div className="grid grid-cols-12">
        <div className="font-caveat col-start-2 col-span-5  text-md font-semibold  py-4 px-6">
          Total Amount
        </div>
        <div className="font-caveat col-start-10 col-span-3  text-3xl font-semibold  py-3 text-center">
          &#x20B9; {totalAmount}
          <div className="font-caveat text-sm font-semibold">
          {orderInfo.orderType === 'schedule' && 'Per day'}
          </div>
        </div>
      </div>
    </div>
  );
}
