import { feedbackConstants } from "../Constants";
import { feedbackService } from "../Services";
import { orderActions } from "./order.actions";

export const feedbackActions = {
    setItem,
    initOrderingExperience,
    setOrderingExperience,
    setDeliveryExperience,
    setItemFeedback,
};

function setItem(selectedItem) {
    const payload = {
        selectedItem
    };
    return {
        type: feedbackConstants.SET_ITEM,
        payload
    }
}

function initOrderingExperience(payload) {
    return {
        type: feedbackConstants.INIT_ORDERING_EXPERIENCE,
        payload
    }
}

function setOrderingExperience(feedback) {
    return (dispatch, getState) => {
        const req = {
            ...feedback
        }
        dispatch(request());
        feedbackService.updateFeedback(req)
            .then(data => {
                const payload = {
                    orderRating: feedback.orderRating
                }
                dispatch(success(payload));
            }, error => {
                dispatch(failure({}));
            });
    }
    function request() { return { type: feedbackConstants.ORDERING_EXPERIENCE_REQUEST } }
    function success(payload) { return { type: feedbackConstants.ORDERING_EXPERIENCE_SUCCESS, payload } }
    function failure(payload) { return { type: feedbackConstants.ORDERING_EXPERIENCE_FAILURE, payload } }
}

function setDeliveryExperience(feedback) {
    return (dispatch, getState) => {
        const req = {
            orderId: getState().orderInfo.orderId,
            feedback
        }
        dispatch(request());
        feedbackService.updateFeedback(req)
            .then(data => {
                dispatch(success());
                // dispatch(notificationActions.enqueueSnackbar({ message: data.message }));
            }, error => {
                dispatch(failure());
                // dispatch(notificationActions.enqueueSnackbar({ message: error, variant: 'error' }));
            });
    }
    function request() { return { type: feedbackConstants.DELIVERY_EXPERIENCE_REQUEST } }
    function success() { return { type: feedbackConstants.DELIVERY_EXPERIENCE_SUCCESS } }
    function failure() { return { type: feedbackConstants.DELIVERY_EXPERIENCE_FAILURE } }
}

function setItemFeedback(feedback) {
    return (dispatch, getState) => {
        const req = {
            ...feedback
        };
        dispatch(request());
        feedbackService.updateFeedback(req)
            .then(data => {
                dispatch(success());
                // dispatch(notificationActions.enqueueSnackbar({ message: data.message }));
                dispatch(orderActions.trackOrder());
            }, error => {
                dispatch(failure());
                // dispatch(notificationActions.enqueueSnackbar({ message: error, variant: 'error' }));
            });
    }
    function request() { return { type: feedbackConstants.ITEM_FEEDBACK_REQUEST } }
    function success() { return { type: feedbackConstants.ITEM_FEEDBACK_SUCCESS } }
    function failure() { return { type: feedbackConstants.ITEM_FEEDBACK_FAILURE } }
}