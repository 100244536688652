import React, { useEffect } from 'react';

export default function SideNav({
	children,
	visible,
	onClose,
	mode,
	type = null,
	closable = true,
	isScrollable,
}) {
	const disableScroll = () => {
		document.body.classList.add('stop-scrolling');
	};
	useEffect(() => {
		if (visible) {
			window.scrollTo(0, 0);
			disableScroll();
		}
	}, [visible]);

	function close() {
		document.body.classList.remove('stop-scrolling');
		onClose();
	}

	return (
		<div>
			{/* Backdrop Component --> For Dimming the backgroung */}
			{visible && (
				<div
					onClick={closable ? close : () => {}}
					className='fixed w-full h-full z-30 left-0 top-0 bg-black opacity-50'
				></div>
			)}
			{/* SideNav Component  */}
			<div
				className={`${type === 'login' ? 'bg-FAFAFA' : 'bg-white'} ${
					visible
						? 'max-h-full  w-full sm:w-10/12 md:w-8/12  lg:w-1/2'
						: 'w-0'
				} absolute z-30 bottom-0 sm:top-0 right-0  transition delay-150 duration-300 ease-in-out ${
					isScrollable ? 'overflow-y-scroll' : 'overflow-hidden'
				} `}
				// style={{
				// 	zIndex: '1989856989'
				// }}
			>
				{closable && (
					<div className='block absolute top-7 right-7 sm:top-10 sm:right-10  text-4xl cursor-pointer z-30'>
						<ion-icon
							style={{
								color: mode === 'dark' ? 'black' : 'white',
							}}
							onClick={close}
							name='close-outline'
						></ion-icon>
					</div>
				)}
				{/* Side Nav Children */}
				<div
					className=''
					style={{
						top: '400px',
					}}
				>
					{children}
				</div>
			</div>
		</div>
	);
}

SideNav.defaultProps = {
	isScrollable: true,
};
