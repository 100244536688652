import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions, modalActions, orderActions } from "../../Redux/Actions";
import Modal from "../Modal/Modal";
import {
  addAccordingToType,
  removeFromCart,
} from "../../Functions/CartFunctions";

import {
  findIsDefaultCombo,
  findNextAvailableAt,
  getProcessObj,
  getSelectionCopy,
  handleAddCustomizationCopy,
  isEmpty,
  storeClosedOrNot,
} from "../../Functions/helpersFunctions";

import CookTime from "../../Assets/Images/CookTime.svg";
import Quality from "../../Assets/Icons/Quality.svg";
import Veg from "../../Assets/Images/Veg.svg";
import BannerCalorie from "../../Assets/Icons/BannerCalorie.svg";
import Nonveg from "../../Assets/Images/Nonveg.png";
import toast from "react-hot-toast";
import moment from "moment";
import { calculeteNoOfDays } from "../../Functions/GetTime";

const FoodItemBanner = ({
  displayVariants,
  isQCEnabled,
  qcData,
  orderType,
  productId,
  productType,
  image,
  isVeg,
  name,
  discount,
  cookingTime,
  variants,
  description,
  selectedVariant,
  isCustomizable,
  foodItem,
  changeSizeHandler,
  openCustomization,
  productAvailable,
  foodItemNotAvailableDueToDate,
  subscriptionTo,
  subscriptionFrom,
  type, // card opened in which ordertype -> BULK/SCHEDULE/REGULAR
}) => {
  const { cart, orderInfo, menu, customization, store, client } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const localStoreOrdertype = JSON.parse(
    localStorage.getItem("orderInfo")
  ).orderType;
  useEffect(() => {
    // fetches count from cart of the specific food item
    let count = 0;
    if (!orderType || localStoreOrdertype !== orderInfo.orderType) {
      setItemCount(0);
      return;
    }
    cart.forEach((item, index) => {
      if (item.productId === productId) {
        count = count + item.quantity;
      }
    });
    setItemCount(count);
  }, [cart, orderType]);
  const addToCart = () => {
    if (storeClosedOrNot(orderInfo, store)) {
      return;
    }
    // if (orderInfo.deliverNow === true) {
    // 	const currentTime = moment(new Date()).format('HH:mm');
    // 	if (
    // 		storeClosedOrNot({} ,store)
    // 	) {
    // 		return;
    // 	}
    // }
    if (cart.length === 0) {
      // dispatch(
      //   orderActions.updateOrderStore({
      //     noOfDays: orderInfo.noOfDays,
      //     deliverNow: orderInfo.deliverNow
      // 	}
      // ));
    } else {
      if (type !== localStoreOrdertype) {
        setIsOpen(true);
        return;
      }
    }
    if (isCustomizable) {
      openCustomization(true);
      return;
    }
    if (productType === "COMBO") {
      if (product.variants.length === 1 && findIsDefaultCombo(foodItem)) {
        const selection = getSelectionCopy(foodItem, menu.products);
        const appliedCustomization = {};
        const removedProcessList = {};
        addAccordingToType(
          menu.products,
          product.productId,
          customization,
          orderType,
          itemCount,
          setItemCount,
          dispatch,
          orderInfo,
          product.name,
          product.selectedVariant,
          handleAddCustomizationCopy(
            selection,
            product,
            appliedCustomization,
            removedProcessList,
            menu.products,
            getProcessObj
          ),
          1
        );
      } else dispatch(modalActions.toggleComboModal(true, { productId }));
      return;
    }
    // Add item to cart
    addAccordingToType(
      menu.products,
      productId,
      customization,
      orderType,
      itemCount,
      setItemCount,
      dispatch,
      orderInfo
    );
  };
  const product = menu.products[productId];
  // const subCateogryTimeSlotAvailable = () => {
  // 	return product.subCategoryTimeSlots['slot0'].startAt ? true : false;
  // };
  // const categoryTimeSlotAvailable = () => {
  // 	return product.categoryTimeSlots['slot0'].startAt ? true : false;
  // };
  // const nextAvailableSubcategory = () => {
  // 	const prod = product.subCategoryTimeSlots['slot0'].startAt;
  // 	// const endsAt = menu.products[key].timeSlots['slot0'].endsAt
  // 	return `${prod}`;
  // };
  // const nextAvailableAt = () => {
  // 	const prod = product.timeSlots['slot0'].startAt;
  // 	// const endsAt = menu.products[key].timeSlots['slot0'].endsAt
  // 	return `${prod}`;
  // };
  // const nextAvailableCategory = () => {
  // 	const prod = product.categoryTimeSlots['slot0'].startAt;
  // 	// const endsAt = menu.products[key].timeSlots['slot0'].endsAt
  // 	return `${prod}`;
  // };
  const onRemove = () => {
    removeFromCart(
      menu.products,
      productId,
      orderType,
      itemCount,
      setItemCount,
      dispatch
    );
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const modifiedTimeSlotAvailable = () => {
    if (product.modifiedTimeSlots) {
      return product.modifiedTimeSlots["slot0"].startAt ? true : false;
    }
    return false;
  };
  const timeSlotAvailable = () => {
    return product.timeSlots["slot0"].startAt ? true : false;
  };
  const submitModal = () => {
    // 1. Empty Cart
    dispatch(cartActions.clearCartStore());
    // 2. Update Order type -> store and local storage
    dispatch(
      orderActions.updateOrderStore({
        noOfDays:
          orderInfo.orderType === "schedule"
            ? 1 : 1,
        deliverNow: orderType === "schedule" ? false : true,
      })
    );
    // 3. Add Item to cart
    if (productType === "PRODUCT") {
      addAccordingToType(
        menu.products,
        productId,
        customization,
        type,
        itemCount,
        setItemCount,
        dispatch,
        orderInfo,
        name,
        selectedVariant
      );
    } else if (productType === "COMBO") {
      dispatch(modalActions.toggleComboModal(true, { productId }));
    }
    setIsOpen(false);
  };

  // Classes for selected, not selected sizes and hover effects
  const selectedSizeClass =
    "bg-7FB0692C hover:bg-7FB0692C bg-no-repeat border border-7FB0693B rounded-sm opacity-70 text-center not-italic font-semibold font-open-sans text-sm xl:text-base text-2F2F2F cursor-pointer py-2 px-1";
  const unSelectedSizeClass =
    "hover:bg-7FB0692C bg-no-repeat border border-7FB0693B rounded-sm opacity-70 text-center not-italic font-semibold font-open-sans text-sm xl:text-base text-2F2F2F cursor-pointer py-2 px-1";

  let cookingDIV = "hidden",
    qcDIV = "hidden",
    calorieDIV = "hidden";

  const isCalories = selectedVariant.nutritionalFactsAvailable
    ? selectedVariant.nutritionalFacts["Calories"]
    : null;

  const getStatsLayout = () => {
    if (cookingTime && isQCEnabled && isCalories) {
      cookingDIV = "col-span-2";
      qcDIV = "col-span-2";
      calorieDIV = "col-span-2";
    } else if (cookingTime && isQCEnabled) {
      cookingDIV = "col-span-3";
      qcDIV = "col-span-3";
    } else if (isCalories && isQCEnabled) {
      calorieDIV = "col-span-3";
      qcDIV = "col-span-3";
    } else if (cookingTime && isCalories) {
      cookingDIV = "col-span-3";
      calorieDIV = "col-span-3";
    } else if (cookingTime) {
      cookingDIV = "col-span-6";
    } else if (isQCEnabled) {
      qcDIV = "col-span-6";
    } else if (isCalories) {
      calorieDIV = "col-span-6";
    }
    return {
      cookingDIV,
      qcDIV,
      calorieDIV,
    };
  };

  return (
    <div className="grid grid-rows-6 grid-cols-10 gap-4">
      {/* Image Section in food Banner */}
      <div className="row-span-4 col-span-4">
        <div className="bg-no-repeat shadow-sm relative">
          <img
            src={image}
            alt="foodItem"
            className={"object-cover h-60 xl:h-72 2xl:h-80 w-full"}
          />
          <div className="flex absolute bottom-4 left-4 space-x-2 z-20 items-center">
            <div className="h-5 w-5">
              <img
                src={isVeg ? Veg : Nonveg}
                alt="veg symbol"
                className="w-full h-full"
              />
            </div>
            <div className="text-left font-bold font-open-sans text-lg xl:text-xl text-FFFFFF capitalize">
              {name}
            </div>
          </div>
          <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
        </div>
      </div>
      {/* Stats Card section*/}
      <div
        className={`row-span-2 ${
          getStatsLayout().cookingDIV
        } bg-FFFFFF bg-no-repeat rounded`}
      >
        <div className="flex flex-row px-4 py-12 xl:py-10 2xl:px-7 2xl:py-12 justify-around items-center">
          <div>
            <img src={CookTime} alt="cup" className="w-10 xl:w-14 h-7" />
          </div>
          <div>
            <div className="text-center not-italic font-bold font-open-sans text-xs xl:text-sm 2xl:text-base">
              {cookingTime}
            </div>
            <div className="text-center not-italic font-open-sans text-xs xl:text-sm 2xl:text-base capitalize">
              Cooking Time
            </div>
          </div>
        </div>
      </div>
      <div
        className={`row-span-2 ${
          getStatsLayout().qcDIV
        } bg-FFFFFF bg-no-repeat rounded`}
      >
        {isQCEnabled && (
          <div className="flex flex-row px-5 py-10 2xl:px-7 2xl:py-12 justify-around items-center">
            <div>
              <img src={Quality} alt="cup" className="w-5 xl:w-7 h-7" />
            </div>
            <div>
              <div className="text-center not-italic font-bold font-open-sans text-xs xl:text-sm 2xl:text-base capitalize">
                Quality Check
              </div>
              {Object.keys(selectedVariant.qcData).map((itemKey, index) => (
                <div
                  key={index}
                  className="text-center not-italic font-open-sans text-xs  text-black"
                >
                  {itemKey + ": " + selectedVariant.qcData[itemKey]}
                  {itemKey === "Temp" ? "\u00b0C" : " gm"}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {selectedVariant.nutritionalFactsAvailable && (
        <div
          className={`row-span-2 ${
            getStatsLayout().calorieDIV
          } bg-FFFFFF bg-no-repeat rounded`}
        >
          <div className="flex flex-row px-7 py-12 xl:py-10 2xl:px-7 2xl:py-12 justify-evenly items-center">
            <div>
              <img src={BannerCalorie} alt="cup" className="w-5 xl:w-6 h-8" />
            </div>
            <div>
              <div className="text-center not-italic font-bold font-open-sans text-xs xl:text-sm 2xl:text-base">
                {selectedVariant.nutritionalFacts["Calories"].value +
                  " " +
                  selectedVariant.nutritionalFacts["Calories"].unit}
              </div>
              <div className="text-center not-italic font-open-sans text-xs xl:text-sm 2xl:text-base capitalize">
                Calories
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Food Item detail and sizes select section */}
      <div className="row-span-4 col-span-6 relative bg-FFFFFF bg-no-repeat py-6 xl:py-8 px-7 rounded">
        <div className="absolute top-16 right-6 2xl:right-10">
          {productAvailable &&
            (itemCount ? (
              <div className="bg-8593FF bg-no-repeat border border-8593FF rounded text-center font-semibold font-open-sans  cursor-pointer">
                <div className="flex items-center justify-between py-2 xl:py-2.5 px-3 xl:px-10 space-x-4">
                  <div
                    className="text-black bg-FFFFFF rounded-full text-sm xl:text-base h-5 w-5 xl:h-6 xl:w-6 cursor-pointer"
                    onClick={onRemove}
                  >
                    -
                  </div>
                  <div className="italic text-sm xl:text-lg text-FFFFFF capitalize ">
                    {itemCount}
                  </div>
                  <div
                    className="text-black bg-FFFFFF rounded-full text-sm xl:text-base h-5 w-5 cursor-pointer"
                    onClick={addToCart}
                  >
                    +
                  </div>
                </div>
              </div>
            ) : orderType === "bulk" ? (
              <div
                className="bg-no-repeat bg-8593FF rounded text-center not-italic font-semibold font-open-sans text-xs xl:text-sm text-FFFFFF py-3 px-5 xl:px-8 cursor-pointer  hover:bg-indigo-500"
                onClick={addToCart}
              >
                <div className="font-semibold text-xs text-FFFFFF capitalize ">
                  Add Item
                </div>
                <div className="text-2xs text-FFFFFF ">
                  min order: 10 quantity
                </div>
              </div>
            ) : (
              <div
                className={`bg-no-repeat  rounded text-center not-italic font-semibold font-open-sans text-xs xl:text-sm text-FFFFFF py-3 xl:py-4 px-5 xl:px-12 cursor-pointer  ${
                  storeClosedOrNot(orderInfo, store) ||
                  (orderInfo.deliverNow && store.isClosed)
                    ? "font-semibold bg-707070 text-xs text-FFFFFF capitalize"
                    : "hover:bg-indigo-500 bg-8593FF"
                }`}
                onClick={addToCart}
              >
                Add Item
              </div>
            ))}
          {!productAvailable && (
            <div className="bg-no-repeat bg-707070 rounded text-center not-italic font-semibold font-open-sans text-xs xl:text-sm text-FFFFFF py-3 px-5 xl:px-8">
              <div className="font-semibold text-xs text-FFFFFF capitalize">
                {orderInfo.orderType === "schedule" &&
                product.subscriptionEnabled
                  ? `Available till ${subscriptionTo}`
                  : modifiedTimeSlotAvailable() || timeSlotAvailable()
                  ? `Next available at ${findNextAvailableAt(
                      orderInfo.deliverNow
                        ? moment(moment(new Date()).format("HH:mm"))
                        : orderInfo.time,
                      product
                    )}`
                  : "Item Out Of Stock"}
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col space-y-4 xl:space-y-2 2xl:space-y-4">
          <div className="text-left not-italic font-semibold font-open-sans text-lg xl:text-xl text-464646 capitalize">
            {client.clientType === "SCHOOL" ? "Confirm class for meal size" : displayVariants ? "Select Size" : "Size"}
          </div>
          <div className="flex space-x-6">
            {displayVariants ? (
              <div className="flex space-x-2">
                {variants.map((size, index) => (
                  <div
                    key={size.id}
                    className={
                      size.id === selectedVariant.id
                        ? selectedSizeClass
                        : unSelectedSizeClass
                    }
                    onClick={
                      size.isDisabled ||
                      (orderInfo.orderType === "schedule" &&
                        !size.subscriptionEnabled)
                        ? () =>
                            toast.error("This size is currently not available")
                        : () => changeSizeHandler(size, productId)
                    }
                  >
                    {size.displayName}
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex space-x-2">
                <div className={selectedSizeClass}>
                  {selectedVariant.displayName}
                </div>
              </div>
            )}
            <div className="flex bg-FFFFFF bg-no-repeat border border-7FB0693B rounded-sm opacity-70 items-center justify-between px-2 space-x-2">
              <div className="text-center not-italic font-bold font-open-sans text-base xl:text-xl text-7FB069 capitalize ">
                Rs.{" "}
                {discount && discount > 0
                  ? selectedVariant.discountedPrice
                  : selectedVariant.price}
              </div>
              {discount > 0 && (
                <div className="text-left line-through not-italic font-open-sans text-2xs xl:text-xs text-8B8B8B">
                  Rs. {selectedVariant.price}
                </div>
              )}
            </div>
          </div>

          <div className="font-semibold text-left not-italic font-roboto text-xs text-D17B69">
            {client.clientType !== "SCHOOL" &&
              selectedVariant?.serves !== null &&
              selectedVariant?.serves !== "" &&
              `Serves ${selectedVariant?.serves}`}
          </div>

          <div
            className="text-left not-italic font-semibold font-open-sans
          text-lg xl:text-xl text-464646"
          >
            About the Dish
          </div>
          <div className="text-left not-italic font-open-sans text-xs xl:text-sm text-8B8B8B">
            {description}
          </div>
          {isCustomizable &&
            productType !== "COMBO" &&
            // productAvailable &&
            orderInfo.orderType !== "schedule" && (
              <div
                className="text-right font-semibold font-open-sans text-8593FF opacity-100 cursor-pointer text-sm xl:text-base"
                onClick={openCustomization}
              >
                Customise Recipe
              </div>
            )}
        </div>
      </div>
      {selectedVariant.nutritionalFactsAvailable && (
        <div className="row-span-2 col-span-4 bg-D17B69 bg-no-repeat py-8 px-11 rounded">
          <div className="space-y-2">
            <div className="text-left not-italic font-semibold font-open-sans text-xl text-FFFFFF">
              Nutritional facts
            </div>
            <div className="flex justify-between">
              {Object.keys(selectedVariant.nutritionalFacts).map(
                (fact, index) => (
                  <div key={index}>
                    <div className="text-center not-italic font-semibold font-open-sans text-base text-FFFFFF">
                      {selectedVariant.nutritionalFacts[fact].value}
                      {selectedVariant.nutritionalFacts[fact].unit}
                    </div>
                    <div className="text-center not-italic font-open-sans text-xs text-D0D0D0">
                      {fact}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        visible={isOpen}
        title={`Do you want change your order type from ${localStoreOrdertype} to ${orderInfo.orderType} category`}
        text="This will delete your previous stored cart"
        submit={submitModal}
        close={closeModal}
        closingText="Cancel"
        submittingText="Delete"
      />
    </div>
  );
};

export default FoodItemBanner;
