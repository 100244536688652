import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function PhoneBanner() {
  const { restaurantId } = useParams()
  const menu = useSelector((state) => state.menu);
  const defaultCategory = menu.menu[0]?.categoryId;

  return (
    <div className='rounded-md  px-4 py-6 relative overflow-hidden'>

      {/* Content */}
      <div className="w-8/12">
        <div className='relative text-white mt-5 font-bold font-open-sans text-base text-left z-20'>
          Tired of ordering lunch every day on time?
        </div>
        <Link to={`/${restaurantId}/menu-card/schedule/schedule/${defaultCategory}`}>
          <button className='relative mt-4 h-8 sm:h-12 font-semibold text-8593FF w-full sm:w-full  sm:p-2 text-2xs bg-white rounded  hover:bg-gray-700 hover:text-white z-20'>
            Explore Meal Plans
          </button>
        </Link>
      </div>
      {/*backeground Image*/}
      <img
        src="https://dam.abbott.com/en-in/articles/india/_604811.jpg"
        alt='offer'
        className='h-full w-full object-cover absolute inset-0'
      />
      {/* Image Opacity  */}
      <div className='h-full w-full  absolute inset-0  bg-gray-900 opacity-40'></div>
    </div>
  );
}




