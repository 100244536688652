import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { loginService } from "../../Redux/Services";
import OtpCard from "../OtpCard/OtpCard";

const emailOtpInpId = [
  "emailOtpInput1",
  "emailOtpInput2",
  "emailOtpInput3",
  "emailOtpInput4",
  "emailOtpInput5",
  "emailOtpInput6",
];

let countdown;

function SignUpUser(props) {
  const {
    verify,
    phone,
    setPhone,
    mobileVerified,
    emailVerified,
    checkOtp,
    name,
    setName,
    email,
    setEmail,
    otpInputId,
    setVerify,
    token,
    setToken,
    pin,
    setPin,
    handleCancelSignUp,
  } = props;
  const { client } = useSelector((state) => state);
  const [mobResendTime, setMobResendTime] = useState(60);
  const [emailResendTime, setEmailResendTime] = useState(60);

  const startCounter = (type) => {
    let leftTime = 60;
    countdown = setInterval(() => {
      leftTime = leftTime - 1;
      if (type) {
        // eslint-disable-next-line eqeqeq
        type == "email" && setEmailResendTime(leftTime);
        // eslint-disable-next-line eqeqeq
        type == "mobile" && setMobResendTime(leftTime);
      }
      if (!type) {
        setMobResendTime(leftTime);
        setEmailResendTime(leftTime);
      }
      if (leftTime <= 0) {
        clearInterval(countdown);
        setMobResendTime(60);
        setEmailResendTime(60);
        return;
      }
    }, 1000);
  };

  const signUpUser = async () => {
    let phoneRegx = /^\d{10}$/;
    let emailRegx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!name) toast.error("Not a valid name");
    if (name && !phone.match(phoneRegx))
      toast.error("Phone number is not valid");
    if (
      phone.match(phoneRegx) &&
      (!email || (email && !email.match(emailRegx)))
    )
      toast.error("Email address not Valid");

    if (!pin) toast.error("Invalid Passcode");
    if (
      name &&
      phone.match(phoneRegx) &&
      email &&
      email.match(emailRegx) &&
      pin
    ) {
      try {
        const response = await loginService.signUpUser(name, phone, email, pin);
        if (response?.statusCode === 200) {
          toast.success(response?.message ?? "Success");
          setVerify(true);
          startCounter();
          setToken(response?.signUpToken);
        } else {
          toast.error(response?.errorMessage ?? "Some error occured");
        }
      } catch (error) {
        toast.error(error?.message ?? error);
      }
    }
  };

  const resendOtp = (type) => {
    loginService
      .resendOtp(type, token)
      .then(
        (res) => {
          console.log({ res });
          if (res?.statusCode === 200) {
            toast.success(res?.message ?? "Success");
            startCounter(type);
          } else {
            console.log({ res });
            toast.error(
              res?.errorMessage ?? res?.message ?? "Some error occured"
            );
          }
        },
        (err) => {
          toast.error(err);
        }
      )
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div className="flex flex-col mx-auto space-y-4">
      <div className="space-y-4 font-open-sans">
        <div className="text-left text-xs text-D17B69 capitalize">
          {!verify && "Enter your details to proceed"}
        </div>
        <div className="font-bold text-left text-base text-212121 capitalize">
          {verify && !mobileVerified && "Enter Mobile OTP"}
        </div>
        <div className="">
          {verify ? (
            <div>
              {mobileVerified ? (
                <div className="font-bold text-center text-lg text-green-600 capitalize">
                  {emailVerified && mobileVerified
                    ? "Mobile and Email Verified Successfully"
                    : "Mobile Number verified"}
                </div>
              ) : (
                <>
                  <OtpCard
                    checkOtpHandler={checkOtp}
                    otpType={"Mobile"}
                    inputId={otpInputId}
                  />
                  {mobResendTime !== 60 ? (
                    <div className="text-center text-base text-D17B69 mt-3">
                      Resend in {mobResendTime} sec
                    </div>
                  ) : (
                    <div
                      className="text-center text-base text-0372BC cursor-pointer mt-3"
                      onClick={() => {
                        resendOtp("mobile");
                      }}
                    >
                      Resend OTP
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="flex items-center gap-6">
              <div className="font-bold text-left text-base text-212121 capitalize">
                Name:
              </div>
              <div className="flex border-b-4 border-7FB069 w-full">
                <input
                  className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-14 w-full px-4 py-3 focus:outline-none"
                  value={name}
                  maxLength={50}
                  onChange={(e) => {
                    if (e.target.value.length <= 50) setName(e.target.value);
                  }}
                  placeholder="Enter Your Name "
                />
              </div>
            </div>
          )}
        </div>
        <div className="font-bold text-left text-base text-212121 capitalize">
          {verify && !emailVerified && "Enter Email OTP "}
        </div>
        {verify ? (
          <div>
            {emailVerified ? (
              !mobileVerified && (
                <div className="font-bold text-center text-lg text-green-600 capitalize">
                  Email Address verified
                </div>
              )
            ) : (
              <>
                <OtpCard
                  checkOtpHandler={checkOtp}
                  inputId={emailOtpInpId}
                  otpType={"Email"}
                />
                {emailResendTime !== 60 ? (
                  <div className="text-center text-base text-D17B69 mt-3">
                    Resend in {emailResendTime} sec
                  </div>
                ) : (
                  <div
                    className="text-center text-base text-0372BC cursor-pointer mt-3"
                    onClick={() => {
                      resendOtp("email");
                    }}
                  >
                    Resend OTP
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div>
            <div className="flex items-center gap-4">
              <div className="font-bold text-left text-base text-212121 capitalize">
                Mobile:
              </div>
              <div className="flex border-b-4 border-7FB069 w-full">
                <input
                  type="text"
                  value="+91"
                  disabled
                  className="bg-FFFFFFAD bg-no-repeat border-t border-l border-gray-300 rounded-l h-14 w-16 px-4 py-3"
                />
                <input
                  type="number"
                  className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-14 w-full px-4 py-3 focus:outline-none"
                  value={phone}
                  maxLength={10}
                  onChange={(e) => {
                    if (e.target.value.length < 11) setPhone(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {!verify && (
          <div className="space-y-4">
            <div className="flex items-center gap-8">
              <div className="font-bold text-left text-base text-212121">
                Email
              </div>
              <div className="flex border-b-4 border-7FB069 w-full">
                <input
                  type="email"
                  className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-14 w-full px-4 py-3 focus:outline-none"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) => e.code === "Enter" && signUpUser()}
                  placeholder="Enter Your Email "
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="font-bold text-left text-base text-212121">
                Setup a Passcode
              </div>
              <div className="flex justify-center">
                <OtpCard checkOtpHandler={setPin} hideConfirm />
              </div>
            </div>
          </div>
        )}
      </div>
      {!verify && (
        <button
          className="bg-8593FF bg-no-repeat rounded py-4 font-semibold text-center text-white text-base cursor-pointer hover:bg-indigo-500"
          onClick={signUpUser}
        >
          {client.clientType != "SCHOOL" ? "Send OTP" : "SIGN UP"}
        </button>
      )}
      {verify && (
        <div
          className="font-semibold text-center text-red-500 text-base cursor-pointer hover:bg-[#fffffff]"
          onClick={handleCancelSignUp}
        >
          Cancel SignUp
        </div>
      )}
    </div>
  );
}

export default SignUpUser;
