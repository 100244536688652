import { storeConstants } from '../Constants';
import { clientService, storeService } from '../Services';
import toast from 'react-hot-toast';
import { orderActions } from '.';
import { cloneDeep, get } from 'lodash';
import { history } from '../../Functions/helpersFunctions';

const checkServiceablity = (address) => {
	return clientService
		.checkServiceAbility({
			lat: address.lat,
			long: address.lng ?? address.long,
		})
		.then(
			(res) => {
				return new Promise((resolve) => {
					resolve(res);
				});
			},
			(error) => {
				toast.error(error);
			}
		);
};

function getStore(storeId, PageName = '') {
	return (dispatch, getState) => {
		dispatch(request([]));
		storeService.getStore(storeId).then(
			(store) => {
				const orderInfo = cloneDeep(getState().orderInfo);
				if (
					PageName == 'Pay' &&
					orderInfo.orderType == 'regular' &&
					orderInfo.deliveryMode == 'delivery'
				) {
					checkServiceablity(orderInfo.selectedAddress).then(
						(res) => {
							// console.log(res);
							if (get(res, 'servicable')) {
								// console.log(1);
								dispatch(
									storeActions.setStoreDeliveryCharges({
										deliveryCharges: get(
											res,
											'deliveryCharges'
										),
									})
								);
							} else {
								// console.log(2);
								orderInfo.selectedAddress = {};
								dispatch(orderActions.updateOrderStore({}));
							}
						}
					);
				}
				if (orderInfo.deliveryMode == null || orderInfo.deliveryMode == '') {
					let selectedDeliveryMode = '';
					if (store.pickupEnabled) {
						selectedDeliveryMode = 'pickup';
					}else if (store.deliveryEnabled) {
						selectedDeliveryMode = "delivery";
						// console.log("ran here in store action");
					} else {
						history.push("/");
					}
					orderInfo.deliveryMode = selectedDeliveryMode;
				}
				dispatch(orderActions.updateOrderStore(orderInfo));
				dispatch(success(store));
			},
			(error) => {
				dispatch(failure(error));
				history.push('/');
				toast.error(error);
			}
		);
	};
	function request(payload) {
		return { type: storeConstants.STORE_REQUEST, payload };
	}
	function success(payload) {
		return { type: storeConstants.STORE_SUCCESS, payload };
	}
	function failure(payload) {
		return { type: storeConstants.STORE_FAILURE, payload };
	}
}

function setStore(storeData) {
	return (dispatch) => {
		dispatch(update(storeData));
	};
	function update(payload) {
		return { type: storeConstants.STORE_SUCCESS, payload };
	}
}

function getPromoCodes() {
	return (dispatch) => {
		dispatch(request());
		storeService.getPromoCodes().then(
			(data) => {
				const payload = {
					promoCodes: data.promoCodes,
				};
				dispatch(success(payload));
			},
			(error) => {
				const payload = {
					promoCodes: [],
				};
				dispatch(failure(payload));
			}
		);
	};
	function request() {
		return { type: storeConstants.GET_PROMO_CODES_REQUEST };
	}
	function success(payload) {
		return { type: storeConstants.GET_PROMO_CODES_SUCCESS, payload };
	}
	function failure(payload) {
		return { type: storeConstants.GET_PROMO_CODES_FAILURE, payload };
	}
}
const setStoreData = (storeData) => {
	return (dispatch) => {
		dispatch(set(storeData));
	};
	function set(payload) {
		return { type: storeConstants.SET_STORE_AVILABILITY, payload };
	}
};
const setStoreDeliveryCharges = (storeData) => {
	return (dispatch) => {
		dispatch(set(storeData));
	};
	function set(payload) {
		return { type: storeConstants.SET_STORE_DELIVERY_CHARGES, payload };
	}
};

const getPickupStores = (req) => {
	return async (dispatch) => {
		try {
			const response = await clientService.getPickupStores(req);
			dispatch({
				type: storeConstants.SET_PICKUP_STORES,
				payload: response,
			});
		} catch {}
	};
};

export const storeActions = {
	getStore,
	setStore,
	getPromoCodes,
	setStoreData,
	setStoreDeliveryCharges,
	getPickupStores,
};
