export const modalConstants = {

    SHOW_PAYTM: 'SHOW_PAYTM',
    HIDE_PAYTM: 'HIDE_PAYTM',

    SHOW_LOGIN: 'SHOW_LOGIN',
    HIDE_LOGIN: 'HIDE_LOGIN',

    SHOW_COMBO: 'SHOW_COMBO',
    HIDE_COMBO: 'HIDE_COMBO',

    SHOW_CHANGE_LOCATION: 'SHOW_CHANGE_LOCATION',
    HIDE_CHANGE_LOCATION: 'HIDE_CHANGE_LOCATION',

    SHOW_CHANGE_PIN: 'SHOW_CHANGE_PIN',
    HIDE_CHANGE_PIN: 'HIDE_CHANGE_PIN',

    SHOW_EDIT_PROFILE: 'SHOW_EDIT_PROFILE',
    HIDE_EDIT_PROFILE: 'HIDE_EDIT_PROFILE',
}