import { createBrowserHistory } from "history";
import moment from "moment";
import toast from "react-hot-toast";
import { cloneDeep } from "lodash";

function isObject(object) {
  return object != null && typeof object === "object";
}

export function isEmpty(obj) {
  if (!isObject(obj)) return true;
  if (obj.length === 0) return true;
  for (var prop in obj) {
    if (obj?.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export function isProductAvailable(obj, time) {
  var finalResult = false;
  var finalList = [];
  if (!isObject(obj)) return false;
  if (obj.length === 0) return false;
  for (var prop in obj) {
    finalList.push(isSlotAvailable(obj[prop], time));
  }

  let result = finalList.every(function (e) {
    return e === null;
  });
  if (result) {
    finalResult = true;
  } else {
    for (const val of finalList) {
      if (val) {
        finalResult = finalResult || val;
      }
    }
  }
  return finalResult;
}

function isSlotAvailable(obj, time) {
  var format = "HH:mm:ss";
  time = moment(time, format);
  var startTime = null;
  var endTime = null;

  Object.keys(obj).map((time0, index) => {
    if (time0.includes("start")) {
      startTime = moment(obj[time0], format);
    } else {
      endTime = moment(obj[time0], format);
    }
  });
  // debugger;
  if (startTime.isValid() && endTime.isValid()) {
    // const dateFormat = 'YYYY-MM-DD'
    // const today = moment(new Date()).format(dateFormat)
    startTime = startTime.format(format);
    endTime = endTime.format(format);
    time = time.format(format);
    // debugger
    if (startTime > endTime) {
      return time >= startTime || time <= endTime;
    }
    return startTime <= time && time <= endTime;
    // if (time.isBetween(startTime, endTime)) {
    //     return true;
    // } else {
    //     return false;
    // }
  } else {
    return null;
  }
}

export const findNextAvailableAt = (time, product = {}) => {
  // const timeSlot = { ...product.modifiedTimeSlots, ...product.timeSlots };
  let timeSlotArr = Object.keys(product?.modifiedTimeSlots ?? {}).reduce(
    (accSlots, currentSlot) => {
      if (product?.modifiedTimeSlots[currentSlot].startAt) {
        accSlots.push({
          startAt: product?.modifiedTimeSlots[currentSlot].startAt,
          endAt: product?.modifiedTimeSlots[currentSlot].endAt,
        });
      }
      return accSlots;
    },
    []
  );
  const tempTimeSlots = Object.keys(product.timeSlots ?? {}).reduce(
    (accSlots, currentSlot) => {
      if (product.timeSlots[currentSlot].startAt) {
        accSlots.push({
          startAt: product.timeSlots[currentSlot].startAt,
          endAt: product.timeSlots[currentSlot].endAt,
        });
      }
      return accSlots;
    },
    []
  );
  timeSlotArr.push(...tempTimeSlots);
  timeSlotArr.sort(function (a, b) {
    // console.log(parseInt(b.startAt.substr(0,2))-parseInt(a.startAt.substr(0,2)), 'a b')
    return parseInt(a.startAt.substr(0, 2)) - parseInt(b.startAt.substr(0, 2));
  });
  let ans = "",
    delta = 99999;
  for (let i = 0; i < timeSlotArr.length; i++) {
    if (
      (timeSlotArr[i].startAt > time && timeSlotArr[i].endAt > time) ||
      (timeSlotArr[i].startAt < time && timeSlotArr[i].endAt < time)
    ) {
      let convertToDate = moment(new Date());
      let nextDay = moment(new Date());
      nextDay.set("hour", timeSlotArr[i].startAt.substr(0, 2));
      nextDay.set("minutes", timeSlotArr[i].startAt.substr(3, 5));
      convertToDate.set("hour", time.substr(0, 2));
      convertToDate.set("minutes", time.substr(3, 5));
      if (timeSlotArr[i].startAt < time) {
        nextDay.set("date", nextDay.get("date") + 1);
      }
      const tempDelta = nextDay.diff(convertToDate, "minutes");
      if (tempDelta < delta) {
        delta = tempDelta;
        ans = timeSlotArr[i].startAt;
      }
    }
  }
  // debugger;
  if (delta === 99999 && timeSlotArr.length >= 1) {
    console.log(timeSlotArr[0]);
    ans = timeSlotArr[0].startAt;
  }
  return ans;
};

export const storeClosedOrNot = (orderInfo, store) => {
  if (orderInfo.deliverNow === false) {
    // if (store.openingTime <= store.closingTime) {
    // 	if (orderInfo.time < store.openingTime) {
    // 		return true;
    // 	} else if (orderInfo.time > store.closingTime) {
    // 		return true;
    // 	}
    // } else {
    // 	if (
    // 		orderInfo.time > store.closingTime &&
    // 		orderInfo.time < store.openingTime
    // 	)
    // 		return true;
    // }
    return false;
  } else {
    if (orderInfo.deliverNow && store.isClosed) return true;
  }
  return false;
};

export const isStoreOpen = (
  openingTime,
  closingTime,
  increase2HoursForClose = false
) => {
  let currentTime = new Date().toTimeString();
//   const currentDate = new Date();

//   // Add 4 hours to the current date
//   currentDate.setHours(currentDate.getHours() + 4);
//   currentTime = currentDate?.toTimeString();
//   console.log({
//     currentTime,
//     closingTime,
// 	openingTime,
// 	currentDate,
//   });
  let btnOpenTime = currentTime >= openingTime && currentTime <= closingTime;
  if (increase2HoursForClose) {
    const closingTimeDate = new Date(`2000-01-01T${closingTime}`);
    closingTimeDate.setHours(closingTimeDate.getHours() + 2);
    const newClosingTime = closingTimeDate.toTimeString().slice(0, 8);
    btnOpenTime = currentTime >= openingTime && currentTime <= newClosingTime;
  }
  if (btnOpenTime) {
    return true;
  } else {
    return false;
  }
};

export const handleAddCustomizationCopy = (
  selection,
  product,
  appliedCustomization = {},
  removedProcessList = {},
  products,
  getProcessObj = () => {},
  customizationState
) => {
  const selectionClone = cloneDeep(selection);
  for (const group of Object.keys(
    product.customisation[product.selectedVariant.id]
  )) {
    let finalItemCustomisation = {
      addedItems: {},
      removedItems: {},
      replacedItems: {},
      removedProcess: [],
    };

    if (!appliedCustomization[group]) {
      continue;
    }

    if (Object.prototype?.hasOwnProperty?.call(removedProcessList, group)) {
      finalItemCustomisation["removedProcess"] = removedProcessList[group];
    }
    const product = products[selection[group].productId];
    for (let processId in appliedCustomization[group]) {
      // if productId key is found, skip it by continuing
      if (processId === "productId") {
        // and if selection productId and appliedCustomization productId don't match,
        // add empty customization and break out
        if (appliedCustomization[group][processId] !== product.productId) {
          break;
        }
        continue;
      }
      let processObj = getProcessObj(group, processId, customizationState);
      if (!processObj) continue;
      if (processObj && processObj.processType === "REPLACEABLE") {
        let remainingItemsToAdd =
          appliedCustomization[group][processId]["from"].length -
          appliedCustomization[group][processId]["to"].length;
        if (remainingItemsToAdd !== 0) {
          toast.error(
            `Add ${remainingItemsToAdd} more items in process: ${processObj.processName} for ${product.name}`
          );
          return;
        }
      }
      if (processObj.processType === "ADDON") {
        if (appliedCustomization[group][processId].length > 0) {
          finalItemCustomisation["addedItems"][processId] =
            appliedCustomization[group][processId].map((item) => item.id);
        }
      } else if (processObj.processType === "REMOVABLE") {
        if (appliedCustomization[group][processId].length > 0) {
          finalItemCustomisation["removedItems"][processId] =
            appliedCustomization[group][processId].map((item) => item.id);
        }
      } else if (processObj.processType === "REPLACEABLE") {
        finalItemCustomisation["replacedItems"][processId] = {
          to: [],
          from: [],
        };
        if (
          appliedCustomization[group][processId]["to"].length > 0 &&
          appliedCustomization[group][processId]["from"].length > 0
        ) {
          finalItemCustomisation["replacedItems"][processId]["to"] =
            appliedCustomization[group][processId]["to"].map((item) => item.id);
          finalItemCustomisation["replacedItems"][processId]["from"] =
            appliedCustomization[group][processId]["from"].map(
              (item) => item.id
            );
        } else {
          delete finalItemCustomisation["replacedItems"][processId];
        }
      }
    }
    selectionClone[group].itemCustomization = {
      finalCustomisation: cloneDeep(finalItemCustomisation),
      appliedCustomization: cloneDeep(appliedCustomization[group]),
    };
  }
  return selectionClone;
};

export const findIsDefaultCombo = (product) => {
  if (product.variants.length > 1) {
    return false;
  }
  let res = true;
  const currentVariantItemsObj =
    product.customisation[product.selectedVariant.id];
  const currentVariantItems = Object.keys(currentVariantItemsObj);
  currentVariantItems.forEach((comboGroupItem) => {
    const comboGroupItems =
      currentVariantItemsObj[comboGroupItem]["comboGroupItem"];
    const comboGroupItemsObj = Object.keys(comboGroupItems);
    comboGroupItemsObj.forEach((currentItem) => {
      if (!comboGroupItems[currentItem].isDefault) {
        res = false;
      }
    });
  });
  // debugger
  return res;
};

export const getProcessObj = (group, processId, customisationState) => {
  for (let pId in customisationState[group]) {
    const process = customisationState[group][pId];
    if (pId === processId) return process;
  }
  return null;
};

export const getSelectionCopy = (product, products) => {
  const tempSelection = {};
  let fixedCombo = true;
  if (product.variants.length > 1) {
    fixedCombo = false;
  }
  const currentVariantItemsObj =
    product.customisation[product.selectedVariant.id];
  const currentVariantItems = Object.keys(currentVariantItemsObj);
  currentVariantItems.forEach((comboGroupItem) => {
    const comboGroupItems =
      currentVariantItemsObj[comboGroupItem]["comboGroupItem"];
    const comboGroupItemsObj = Object.keys(comboGroupItems);
    comboGroupItemsObj.forEach((currentItem) => {
      if (comboGroupItems[currentItem].isDefault) {
        tempSelection[comboGroupItem] = {
          ...comboGroupItems[currentItem],
          comboGroupItemId: currentItem,
          itemCustomization: {},
          name: products[comboGroupItems[currentItem].productId].name,
        };
      } else if (!comboGroupItems[currentItem].isDefault && fixedCombo) {
        fixedCombo = false;
      }
    });
  });
  return tempSelection;
};

export const checkIfAlreadyAdded = (menu, productId, cart) => {
  let result = false;
  let cartQty = 0;

  cart?.forEach((ele) => {
    cartQty += ele?.quantity;
  });

  console.log({
    cartQty,
  });

  // menu?.menu?.map((menuItem) => {
  // 	menuItem?.subCategory?.map((subCat) => {
  // 		if(subCat?.products?.includes(productId) &&  && alreadyAdded !== -1){
  // 			result = true;
  // 		}
  // 	})
  // })
  return result;
};

export const history = createBrowserHistory();
