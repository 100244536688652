
// main filter function --> filter according to type (spice , price etc )
export const Filter = (products, FoodMenu, type, value) => {
  switch (type) {
    case "price":
      return FilterByPrice(products, FoodMenu, value);
    case "spice":
      return FilterBySpiceLevel(products, FoodMenu, value);
    default:
      return FoodMenu;
  }
};
// filter by price 
export const FilterByPrice = (products, FoodMenu, value) => {
  // value = 0 --> high to low
  // value = 1 --> low to high
  FoodMenu.subCategory.forEach((subCategory) => {
    subCategory.products.sort((item1, item2) =>
      priceSort(products, item1, item2, value)
    );
  });
  return FoodMenu;
};

// sort accordingly 
const priceSort = (products, item1, item2, value) => {
  const firstItemPrice = products[item1].selectedVariant.discountedPrice;
  const secondItemPrice = products[item2].selectedVariant.discountedPrice;
  if (value) {
    // value is 1 --> assending
    return firstItemPrice - secondItemPrice;
  } else {
    // value is 0 --> decending
    return secondItemPrice - firstItemPrice;
  }
};
// filter by spice level 
export const FilterBySpiceLevel = (products, FoodMenu, value) => {};
