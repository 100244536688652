import toast from "react-hot-toast";
import { authActions, cartActions } from ".";
import { subscriptionConstants } from "../Constants";
import { subscriptionService } from "../Services";

function cancelSubscription({ subscriptionId, showSnackbars = true }) {
  return (dispatch, getState) => {
    const req = {
      subscriptionId,
    };
    dispatch(request());
    subscriptionService.cancelSubscription(req).then(
      (data) => {
        dispatch(success({}));
        toast.success(data.message);
        dispatch(authActions.redirect(`/${getState().orderInfo.storeId}/home`));
      },
      (error) => {
        toast.error("Problem in cancelling subscription");
        dispatch(failure({}));
      }
    );
  };
  function request() {
    return { type: subscriptionConstants.CANCEL_SUBSCRIPTION_REQUEST };
  }
  function success(payload) {
    return {
      type: subscriptionConstants.CANCEL_SUBSCRIPTION_SUCCESS,
      payload,
    };
  }
  function failure(payload) {
    return {
      type: subscriptionConstants.CANCEL_SUBSCRIPTION_FAILURE,
      payload,
    };
  }
}

function updatePayment(transaction) {
  // { subscriptionId, showSnackbars = true }
  return (dispatch, getState) => {
    const req = {
      ...transaction,
    };
    dispatch(request());
    subscriptionService.paymentUpdate(req).then(
      (data) => {
        dispatch(success({}));
        if (transaction && transaction.status === "FAILURE") {
          toast.error("cancelled");
          dispatch(cartActions.clearCartStore());
          dispatch(
            authActions.redirect(`/${getState().orderInfo.storeId}/home`)
          );
        } else {
          toast.success(data.responseMessage);
          dispatch(cartActions.clearCartStore());
          dispatch(
            authActions.redirect(
              `/${getState().orderInfo.storeId}/track-subscription/${
                transaction.subscriptionId
              }`
            )
          );
        }
      },
      (error) => {
        toast.error("Problem in updating payment of subscription");
        dispatch(failure({}));
      }
    );
  };
  function request() {
    return { type: subscriptionConstants.CANCEL_SUBSCRIPTION_REQUEST };
  }
  function success(payload) {
    return {
      type: subscriptionConstants.CANCEL_SUBSCRIPTION_SUCCESS,
      payload,
    };
  }
  function failure(payload) {
    return {
      type: subscriptionConstants.CANCEL_SUBSCRIPTION_FAILURE,
      payload,
    };
  }
}

function getPastSubscription() {
  return (dispatch) => {
    dispatch(request());
    subscriptionService.getPastSubscription().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: subscriptionConstants.PAST_SUBSCRIPTIONS_REQUEST };
  }
  function success(payload) {
    return {
      type: subscriptionConstants.PAST_SUBSCRIPTIONS_SUCCESS,
      payload,
    };
  }
  function failure(payload) {
    return {
      type: subscriptionConstants.PAST_SUBSCRIPTIONS_FAILURE,
      payload,
    };
  }
}
function getCompletedMeals(req) {
  return (dispatch) => {
    dispatch(request());
    subscriptionService.getCompletedMeals(req).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: subscriptionConstants.COMPLETED_MEALS_REQUEST };
  }
  function success(payload) {
    return {
      type: subscriptionConstants.COMPLETED_MEALS_SUCCESS,
      payload,
    };
  }
  function failure(payload) {
    return {
      type: subscriptionConstants.COMPLETED_MEALS_FAILURE,
      payload,
    };
  }
}

function mealCancellation({ subscriptionDetails, showSnackbars = true }) {
  return (dispatch, getState) => {
    const req = {
      meals: [
        {
          mealDate: subscriptionDetails.mealDate,
        },
      ],
      subscriptionId: subscriptionDetails.subscriptionId,
      store: getState().orderInfo.storeId,
    };
    dispatch(request());
    subscriptionService.cancelMeal(req).then(
      (data) => {
        dispatch(success({}));
        toast.success(data.message);
      },
      (error) => {
        toast.error(`Problem in meal cancellation ${error}`);
        dispatch(failure({}));
      }
    );
  };
  function request() {
    return { type: subscriptionConstants.MEAL_CANCELLATION_REQUEST };
  }
  function success(payload) {
    return {
      type: subscriptionConstants.MEAL_CANCELLATION_SUCCESS,
      payload,
    };
  }
  function failure(payload) {
    return {
      type: subscriptionConstants.MEAL_CANCELLATION_FAILURE,
      payload,
    };
  }
}

function SubscriptionDetailRequest(subscriptionId) {
  return (dispatch, getState) => {
    dispatch(request());
    // const { orderId, orderStatus, items } = getState().orderTrack;
    const auth = getState().auth;
    const req = {
      // orderId: orderId,
      userId: auth.user,
      subscriptionId,
    };
    dispatch(request());
    subscriptionService.getSubscription(req).then(
      (data) => {
        dispatch(success(data));
        toast.success(data.message);
      },
      (error) => {
        toast.error(`Problem in meal cancellation ${error}`);
        dispatch(failure({}));
      }
    );
  };
  // const isFeedbackLoading = getState().feedback.isLoading;
  // const { orderRating } = getState().feedback;
  // subscriptionService.trackOrder(req).then(
  // 	(data) => {
  // 		const payload = { ...data };
  // 		dispatch(success(payload));
  // 	},
  // 	(error) => {
  // 		toast.error('Some Error Occred');
  // 		dispatch(failure());
  // 		dispatch(authActions.redirect('/orderNotFound'));
  // 		// dispatch(setLoading(true));
  // 	}
  // );
  // dispatch(success({ subscriptionDetail }));

  function request() {
    return { type: subscriptionConstants.PAST_SUBSCRIPTION_DETAIL_REQUEST };
  }
  function success(payload) {
    return {
      type: subscriptionConstants.PAST_SUBSCRIPTION_DETAIL_SUCCESS,
      payload,
    };
  }
  function failure(payload) {
    return {
      type: subscriptionConstants.PAST_SUBSCRIPTION_DETAIL_FAILURE,
      payload,
    };
  }
}

export const subscriptionActions = {
  cancelSubscription,
  getPastSubscription,
  SubscriptionDetailRequest,
  mealCancellation,
  updatePayment,
  getCompletedMeals,
};
