import { clientConstants } from "../Constants";

const initialState = {
  promotions: [],
  homeImage: [],
  music: "", // either a Url | None
  name: "", // Client Display Naame
  logo: "", // Client Logo
  orderPlacementNumber: "", // Client Online Ordering Number
  customerSupport: "",
  email: "",
  address: "",
  gst: "", // Client GST Number
  fssai: "", // Client fssai Number
  primaryColor: "", // Client theme color
  secondaryColor: "", // Client theme color
  privacyPolicy: "", // Client privacy Policy
  terms: "", // Client Terms
  refundPolicy: "",
  companyName: "", // Client Registered Company Name
  facebookUrl: "",
  instagramUrl: "",
  twitterUrl: "",
  tagline: "",
  deliveryEnabled: true,
  pickupEnabled: true,
  isLoading: true,
};

export function client(state = initialState, action) {
  switch (action.type) {
    case clientConstants.CLIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case clientConstants.CLIENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case clientConstants.CLIENT_FAILURE:
      return {
        isLoading: true,
      };
    default:
      return state;
  }
}
