import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { authActions, modalActions } from "../../Redux/Actions";
import closeIcon from "../../Assets/Icons/closeIcon.svg";
import toast from "react-hot-toast";
import styles from "./EditProfileModal.module.css";
import { loginService } from "../../Redux/Services";

function EditProfileModal(props) {
  const { shouldShow, toggleEditProfileModal } = props;
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState("");

  useEffect(() => {
    setNewName(auth?.name);
  }, [auth?.name]);

  const handleClose = () => {
    dispatch(toggleEditProfileModal(false));
    setNewEmail("");
    document.body.classList.remove("stop-scrolling");
  };

  const handleEditProfile = async () => {
    const req = {
      data: {
        name: newName,
        email: newEmail,
      },
    };
    if(!newName) toast.error("Please enter name");
    if(newName){
        try {
          const response = await loginService.editProfile(req);
          if (response?.statusCode === 200) {
            toast.success(response?.message ?? "Profile edited Successfully");
            dispatch(authActions.updateUser({name: newName, email: auth?.email ? auth?.email : newEmail}));
            handleClose();
          } else {
            toast.error(response?.errorMessage ?? "Some error occured");
          }
        } catch (error) {
          toast.error(error.message ?? error ?? "Some Error occured");
        }
    }
  };

  return (
    <div className={`${styles.root} ${shouldShow ? "flex" : "hidden"}`}>
      <div className={`space-y-4 ${styles["modal-wrapper"]} `}>
        <div className={styles.close} onClick={handleClose}>
          <img src={closeIcon} alt="closeModal" />
        </div>
        <h1>EDIT PROFILE </h1>
        <div
          className={`flex items-center justify-between gap-1 ${styles["pin-input"]}`}
        >
          <div className={`font-bold text-left text-base text-212121 ${styles.label}`}>
            Name:
          </div>
          <div className="flex border-b-4 border-7FB069 w-full">
            <input
              type="text"
              className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-14 w-full px-4 py-3 focus:outline-none"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
        </div>

        <div
          className={`flex items-center justify-between gap-1 ${styles["pin-input"]}`}
        >
          <div className={`font-bold text-left text-base text-212121 ${styles.label}`}>
            Email:
          </div>
          {auth?.email ? (
            <div className="bg-FFFFFFAD bg-no-repeat rounded-r w-full focus:outline-none ">
              {auth?.email}
            </div>
          ) : (
            <div className="border-b-4 border-7FB069 w-full">
              <input
                type="email"
                className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-14 w-full px-4 py-3 focus:outline-none"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Enter Your Email "
                disabled={auth?.email}
              />
            </div>
          )}
        </div>

        <div
          className={`flex items-center justify-between gap-1 ${styles["pin-input"]}`}
        >
          <div className={`font-bold text-left text-base text-212121 ${styles.label}`}>
            Number:
          </div>
          {auth?.user ? (
            <div className="bg-FFFFFFAD bg-no-repeat rounded-r w-full focus:outline-none ">
              {auth?.user}
            </div>
          ) : (
            <div className="border-b-4 border-7FB069 w-full">
              <input
                type="number"
                className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-14 w-full px-4 py-3 focus:outline-none"
                value={auth?.user}
                disabled={auth?.user}
              />
            </div>
          )}
        </div>

        <button
          className="bg-8593FF bg-no-repeat rounded py-4 font-semibold text-center text-white text-base cursor-pointer hover:bg-indigo-500"
          onClick={handleEditProfile}
        >
          CONFIRM
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const shouldShow = state?.modal?.showEditProfile;
  return {
    shouldShow,
  };
};

const mapDispatchToProps = {
    toggleEditProfileModal: modalActions?.toggleEditProfileModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModal);
