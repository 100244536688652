import React from 'react';

const MapComponent = (props) => {
	const { data } = props;
	return (
		<div>
			<iframe
				className='object-cover w-112 h-80 max-w-full'
				loading='lazy'
				src={data}
			></iframe>
		</div>
	);
};

export default React.memo(MapComponent);
