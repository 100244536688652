/** @format */

import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  cartActions,
  customizationActions,
  orderActions,
  notificationActions,
} from "../../Redux/Actions";
import Veg from "../../Assets/Images/Veg.svg";
import Nonveg from "../../Assets/Images/Nonveg.png";
import SelectedTick from "../../Assets/Icons/SelectedTick.svg";
import Modal from "../../Components/Modal/Modal";
import toast from "react-hot-toast";
import { cloneDeep } from "lodash";
import { storeClosedOrNot } from "../../Functions/helpersFunctions";
import { calculeteNoOfDays } from "../../Functions/GetTime";

function CustomizationSlider(props) {
  const [product, setProduct] = useState({});
  const [customisationState, setCustomisation] = useState([]);
  const [appliedCustomization, setAppliedCustomization] = useState();
  const [removedProcessList, setRemovedProcessList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const { menu, customization, cart, orderInfo, store, client } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  const { displayVariants, selectedVariant, variants, productId } =
    props.product;

  useEffect(() => {
    setProduct(props.product);
    setCustomisation(props.product.customisation);
    setAppliedCustomization(initCustomization());
    return;
  }, [props.selectedVariant, customization]);

  const initCustomization = (reset = false) => {
    // if  reset is false then update the applied customization from props
    // else it will be reset to default customization
    if (!reset) {
      if (props.type === "SAVE" && customization[props.product.productId]) {
        return customization[props.product.productId].appliedCustomization;
      }
      if (props.type === "EDIT" && props.appliedCustomization) {
        return props.appliedCustomization;
      }
    }
    let selectedVariant = props.selectedVariant;
    let appliedCustomization = {};
    Object.keys(props.product.customisation).forEach((processId) => {
      const process = props.product.customisation[processId];
      if (
        process.variantIdApplicableOn.includes(selectedVariant.id.toString())
      ) {
        let processData = [];
        if (process.processType === "REPLACEABLE") {
          processData = {
            to: processData,
            from: [],
          };
        }
        if (!process.processRemovable || process.processType !== "ADDON") {
          appliedCustomization = {
            ...appliedCustomization,
            ...{ [process.processId]: processData },
          };
        }
      }
    });
    return appliedCustomization;
  };

  const getDefaultItems = (process) => {
    const defaultItems = Object.keys(process.items).reduce((acc, curr) => {
      const item = process.items[curr];
      if (item.isDefault) {
        return [...acc, item];
      } else {
        return acc;
      }
    }, []);
    return {
      defaultItems,
      totalDefaultItems: defaultItems.length,
    };
  };

  const getNonDefaultItems = (process) => {
    const nonDefaultItems = Object.keys(process.items).reduce((acc, curr) => {
      const item = process.items[curr];
      if (item.isDefault) {
        return acc;
      } else {
        return [...acc, item];
      }
    }, []);

    return {
      nonDefaultItems,
      totalNonDefaultItems: nonDefaultItems.length,
    };
  };

  const itemClickHandler = (process, item, isItemSelected) => {
    // console.log(cart, 'cart add to cart1')
    let processData = cloneDeep(appliedCustomization[process.processId]);
    let { defaultItems, totalDefaultItems } = getDefaultItems(process);
    if (process.processType === "REPLACEABLE") {
      // if only one is replaceable, acts as selection
      if (process.maxItemCustomizableLimit === 1 && totalDefaultItems === 1) {
        // if item is not selected only then the selection needs to be changed
        if (!isItemSelected) {
          // if default item is clicked, clear 'to' and 'from' to reset to default
          if (item.id === defaultItems[0].id) {
            processData["to"] = [];
            processData["from"] = [];
          } else {
            // if 'from' is empty, add default item - because customization is made and default is removed
            if (processData["from"].length === 0) {
              processData["from"] = [defaultItems[0]];
            }
            // add newly selected item
            processData["to"] = [item];
          }
        }
        // multi-item replacement logic
      } else {
        if (isItemSelected) {
          const index = processData["to"].findIndex(
            (processItem) => processItem.id === item.id
          );
          if (index > -1) {
            processData["to"].splice(index, 1);
          }
          if (item.isDefault) processData["from"].push(item);
        } else {
          const index = processData["from"].findIndex(
            (processItem) => processItem.id === item.id
          );
          if (index > -1) {
            processData["from"].splice(index, 1);
          }
          if (item.isDefault) {
            processData["to"].splice(index, 1);
          } else {
            // if number items added are greater than or equal to removed items, it is an invalid action to add another item without 'unselecting' its replacement
            if (processData["to"].length >= processData["from"].length) {
              toast.error("First remove an item to add its replacement");
              return;
            }
            processData["to"].push(item);
          }
        }
      }
    } else {
      // if addon limit is exceeded display error message
      if (
        process.processType === "ADDON" &&
        process.maxItemCustomizableLimit <=
          appliedCustomization[process.processId].length &&
        !isItemSelected
      ) {
        toast.error(
          `Cannot add more than ${process.maxItemCustomizableLimit} items.`
        );
        return;
      }
      // if removeable limit is exceeded display error message
      if (
        process.processType === "REMOVABLE" &&
        process.maxItemCustomizableLimit <=
          appliedCustomization[process.processId].length &&
        isItemSelected
      ) {
        toast.error(
          `Cannot remove more than ${process.maxItemCustomizableLimit} items.`
        );
        return;
      }
      if (
        (process.processType === "ADDON" && isItemSelected) ||
        (process.processType === "REMOVABLE" && !isItemSelected)
      ) {
        const index = processData.findIndex(
          (processItem) => processItem.id === item.id
        );
        if (index > -1) {
          processData.splice(index, 1);
        }
      } else processData.push(item);
    }
    // console.log(cart, 'cart add to cart1')
    let updatedAppliedCustomization = {
      ...appliedCustomization,
      ...{ [process.processId]: processData },
    };
    setAppliedCustomization(updatedAppliedCustomization);
  };

  const getStatus = (process, item) => {
    let isItemSelected = false;
    let showRemoveBtn = true;
    let showAddBtn = true;

    if (!(process.processId in appliedCustomization))
      return {
        isItemSelected: false,
        showRemoveBtn: false,
        showAddBtn: false,
      };

    if (process.processType === "REPLACEABLE") {
      if (
        appliedCustomization[process.processId]["to"].find(
          (processItem) => processItem.id === item.id
        ) ||
        (!appliedCustomization[process.processId]["from"].find(
          (processItem) => processItem.id === item.id
        ) &&
          item.isDefault)
      )
        isItemSelected = true;
      if (
        appliedCustomization[process.processId]["from"].length <=
        appliedCustomization[process.processId]["to"].length
      )
        showAddBtn = false;
      if (
        item.isDefault &&
        process.maxItemCustomizableLimit <=
          appliedCustomization[process.processId]["from"].length
      )
        showRemoveBtn = false;
    } else {
      if (
        process.processType === "REMOVABLE" &&
        !appliedCustomization[process.processId].find(
          (processItem) => processItem.id === item.id
        )
      )
        isItemSelected = true;
      else if (
        process.processType === "ADDON" &&
        appliedCustomization[process.processId].find(
          (processItem) => processItem.id === item.id
        )
      ) {
        isItemSelected = true;
      }
    }

    if (
      process.processType === "ADDON" &&
      process.maxItemCustomizableLimit <=
        appliedCustomization[process.processId].length
    )
      showAddBtn = false;

    showAddBtn = showAddBtn && !isItemSelected;
    showRemoveBtn = showRemoveBtn && isItemSelected;

    return { isItemSelected, showRemoveBtn, showAddBtn };
  };

  let finalCustomisation = {
    addedItems: {},
    removedItems: {},
    replacedItems: {},
    removedProcess: [],
  };

  const handleAddCart = () => {
    if (storeClosedOrNot(orderInfo, store)) {
      return;
    }
    finalCustomisation["removedProcess"] = removedProcessList;
    for (let processId in appliedCustomization) {
      let processObj = getProcessObj(processId);
      if (processObj && processObj.processType === "REPLACEABLE") {
        let remainingItemsToAdd =
          appliedCustomization[processId]["from"].length -
          appliedCustomization[processId]["to"].length;
        if (remainingItemsToAdd !== 0) {
          toast.error(
            `Add ${remainingItemsToAdd} more items in process: ${processObj.processName}`
          );
          return;
        }
      }
      if (processObj.processType === "ADDON") {
        if (appliedCustomization[processId].length > 0) {
          finalCustomisation["addedItems"][processId] = appliedCustomization[
            processId
          ].map((item) => item.id);
        }
      } else if (processObj.processType === "REMOVABLE") {
        if (appliedCustomization[processId].length > 0) {
          finalCustomisation["removedItems"][processId] = appliedCustomization[
            processId
          ].map((item) => item.id);
        }
      } else if (processObj.processType === "REPLACEABLE") {
        finalCustomisation["replacedItems"][processId] = {
          to: [],
          from: [],
        };
        if (
          appliedCustomization[processId]["to"].length > 0 &&
          appliedCustomization[processId]["from"].length > 0
        ) {
          finalCustomisation["replacedItems"][processId]["to"] =
            appliedCustomization[processId]["to"].map((item) => item.id);
          finalCustomisation["replacedItems"][processId]["from"] =
            appliedCustomization[processId]["from"].map((item) => item.id);
        } else {
          delete finalCustomisation["replacedItems"][processId];
        }
      }
    }

    // Saving customization to store
    dispatch(
      customizationActions.updateCustomizationStore({
        [productId]: {
          appliedCustomization,
          finalCustomisation,
        },
      })
    );

    // Adding item to cart
    if (props.type === "SAVE") {
      addToCart();
    } else if (props.type === "EDIT") {
      editCart();
    }
    props.handleClose();
  };

  const editCart = () => {
    cart[props.index].appliedCustomization = appliedCustomization;
    cart[props.index].finalCustomisation = finalCustomisation;
    cart[props.index].selectedVariant = selectedVariant;

    dispatch(cartActions.updateCartStore([...cart]));
  };

  // SECTION START LOGIC FOR ADDING IN CART
  // Handles addition to cart
  const addToCart = () => {
    // console.log(cart, 'cart add to cart')
    // If cart length empty
    if (cart.length === 0) {
      // Update Order type in store
      // dispatch(
      //   orderActions.updateOrderStore({
      //     orderType: props.orderType,
      //     noOfDays:
      //       props.orderType === "schedule"
      //         ? calculeteNoOfDays(
      //             new Date(orderInfo.startDate),
      //             new Date(orderInfo.endDate),
      //             orderInfo.frequencyDays
      //           )
      //         : 1,
      //     deliverNow: props.orderType === "schedule" ? false : true,
      //   })
      // );
    }
    // If cart not empty, check the order type
    else {
      // if order type is not same, then show warning modal
      // modal says -> your order type is different and will clear cart
      const localStoreOrdertype = JSON.parse(
        localStorage.getItem("orderInfo")
      ).orderType;
      if (props.orderType !== localStoreOrdertype) {
        // Activate Modal
        // If user does not changes type, then do not add item to cart
        // If user changes type
        // 1. Empty Cart
        // 2. Update Order type
        // 3. Add Item to cart
        setIsOpen(true);
        return;
      }
    }
    // Add item to cart
    addAccordingToType();
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const submitModal = () => {
    // 1. Empty Cart
    dispatch(cartActions.clearCartStore());
    // 2. Update Order type -> store and local storage
    dispatch(
      orderActions.updateOrderStore({
        orderType: props.orderType,
        noOfDays:
          props.orderType === "schedule"
            ? calculeteNoOfDays(
                new Date(orderInfo.startDate),
                new Date(orderInfo.endDate),
                orderInfo.frequencyDays
              )
            : 1,
        deliverNow:
          orderInfo.type == "later" || props.orderType == "schedule"
            ? false
            : true,
      })
    );
    // 3. Add Item to cart
    addAccordingToType();
    setIsOpen(false);
  };

  // Add According to type selected
  const addAccordingToType = () => {
    let foodItem = cloneDeep(menu.products[productId]);
    // console.log(foodItem);
    // adding applied customization to food object before adding to cart
    foodItem.appliedCustomization = appliedCustomization;
    foodItem.finalCustomisation = finalCustomisation;
    let notificationText;

    if (props.orderType === "bulk") {
      // function checks the food item already present or not
      dispatch(cartActions.compareAndAddToCart(foodItem, 10, props.orderType));
      notificationText = (
        <Fragment>
          Hi, You Have Selected{" "}
          <b>
            {foodItem.name} for &#x20B9;{" "}
            {foodItem.selectedVariant.discountedPrice}/meal with customization.
          </b>
        </Fragment>
      );
    } else if (props.orderType === "schedule") {
      dispatch(cartActions.compareAndAddToCart(foodItem, 1, props.orderType));
      notificationText = (
        <Fragment>
          Hi, You Have Selected{" "}
          <b>
            {foodItem.name}
            {/* for &#x20B9;{" "}
            {foodItem.selectedVariant.discountedPrice}/meal with customization , */}
          </b>{" "}
          for <b>{orderInfo.noOfDays} days</b>
        </Fragment>
      );
    } else {
      dispatch(cartActions.compareAndAddToCart(foodItem, 1, props.orderType));
      notificationText = (
        <Fragment>
          Hi, You Have Selected{" "}
          <b>
            {foodItem.name}
            {/* for &#x20B9;{" "}
            {foodItem.selectedVariant.discountedPrice}/meal with customization. */}
          </b>
        </Fragment>
      );
    }
    dispatch(notificationActions.createNotification(notificationText));
  };

  // SECTION END

  const getProcessObj = (processId) => {
    for (let pId in customisationState) {
      const process = customisationState[pId];
      if (pId === processId) return process;
    }
    return null;
  };

  // function to display the details and action items for a process
  const getProcessDetails = (process) => {
    let displayText = "";

    switch (process.processType) {
      case "REPLACEABLE":
        displayText = `(You can replace any ${process.maxItemCustomizableLimit} ingredient(s))`;
        break;
      case "ADDON":
        displayText = `(You can add upto ${process.maxItemCustomizableLimit} ingredients(s))`;
        break;
      case "REMOVABLE":
        displayText = `(You can remove any ${process.maxItemCustomizableLimit} ingredient(s))`;
        break;
      default:
        displayText = "";
    }
    return displayText;
  };

  const processClickHandler = (process) => {
    let updatedAppliedCustomization = { ...appliedCustomization };
    let updateRemovedProcessList = [...removedProcessList];
    if (process.processId in updatedAppliedCustomization) {
      delete updatedAppliedCustomization[process.processId];
      updateRemovedProcessList.push(process.processId);
    } else {
      let processData = [];
      if (process.processType === "REPLACEABLE")
        processData = { to: processData, from: [] };
      updatedAppliedCustomization = {
        ...updatedAppliedCustomization,
        ...{ [process.processId]: processData },
      };
      const index = updateRemovedProcessList.indexOf(process.processId);
      if (index > -1) {
        updateRemovedProcessList.splice(index, 1);
      }
    }
    setAppliedCustomization(updatedAppliedCustomization);
    setRemovedProcessList(updateRemovedProcessList);
  };

  const resetCustomization = () => {
    setAppliedCustomization(initCustomization(true));
    setRemovedProcessList([]);
  };

  // Classes for selected, not selected sizes and hover effects
  const selectedSizeClass =
    "bg-7FB0692C hover:bg-7FB0692C bg-no-repeat border border-7FB0693B rounded-sm opacity-70 text-center not-italic font-semibold font-open-sans text-xs sm:text-sm xl:text-base text-2F2F2F cursor-pointer py-2 px-1";
  const unSelectedSizeClass =
    "hover:bg-7FB0692C bg-no-repeat border border-7FB0693B rounded-sm opacity-70 text-center not-italic font-semibold font-open-sans text-xs sm:text-sm xl:text-base text-2F2F2F cursor-pointer py-2 px-1";
  return (
    <div>
      <div className="space-y-4 sm:space-y-8 h-full">
        {/* Image banner section */}
        <div className="relative">
          <img
            src={props.image}
            alt="customize pizza"
            className="object-cover w-full h-44 sm:h-60 lg:h-52 xl:h-60"
          />
          <div className="flex items-center absolute bottom-4 left-8 space-x-4 sm:space-x-8 z-20">
            <div>
              <img
                src={props.isVeg ? Veg : Nonveg}
                alt="food type"
                className="w-4 h-4 sm:w-5 sm:h-5"
              />
            </div>
            <div className="font-open-sans font-semibold text-left text-base sm:text-lg text-white">
              Customize {props.name}
            </div>
          </div>
          <div className="absolute bg-FFFFFF bg-no-repeat border border-FFFFFFAD rounded-sm text-center font-semibold font-open-sans text-2xs xl:text-xs text-black p-1 bottom-4 right-8 z-20">
            {client.clientType === "SCHOOL"
              ? "Confirm class for meal size"
              : props?.selectedVariant?.serves !== null &&
                props?.selectedVariant?.serves !== "" &&
                `Serves ${props?.selectedVariant?.serves}`}
          </div>
          <div className="absolute bg-black opacity-50 inset-0"></div>
        </div>
        {/* Size seletion section */}
        <div
          className={`flex px-5 sm:px-20 lg:px-14 xl:px-20 space-x-8 items-center ${
            displayVariants ? "block" : "hidden"
          }`}
        >
          <div className="font-open-sans font-semibold text-xs sm:text-sm xl:text-base text-black text-left capitalize">
            {/* {props.type !== "EDIT" && displayVariants ? "Choose a Variant" : "Size"} */}
            Size
          </div>
          {/* {props.type !== "EDIT" &&( displayVariants ? (
            <div className="flex space-x-2">
              {variants.map((size, index) => (
                <div
                  key={size.id}
                  className={
                    size.id === selectedVariant.id
                      ? selectedSizeClass
                      : unSelectedSizeClass
                  }
                  onClick={
                    size.isDisabled
                      ? () =>
                          toast.error("This size is currently not available")
                      : () => props.changeSizeHandler(size, productId)
                  }
                >
                  {size.displayName}
                </div>
              ))}
            </div>
          ) : (
            <div className="flex space-x-2">
              <div className={selectedSizeClass}>
                {selectedVariant.displayName}
              </div>
            </div>
          ))} */}
          <div className="flex space-x-2">
            <div className={selectedSizeClass}>
              {selectedVariant.displayName}
            </div>
          </div>
          {/*   <select
            name='variants'
            onChange={(e) => {
              let variant = variants.find(
                (variant) => variant.id === Number(e.target.value)
              );
              props.changeSizeHandler(variant, productId);
            }}
            className='border border-8B8B8B'>
            {variants.map((variant, index) => {
              return (
                <option
                  key={index}
                  value={variant.id}
                  selected={props.selectedVariant.id === variant.id}>
                  {variant.displayName}
                </option>
              );
            })}
          </select> */}
        </div>
        {/* Customizations Section */}
        <div className="px-5 sm:px-20 lg:px-14 xl:px-20 py-6 space-y-8">
          {customisationState &&
            Object.keys(customisationState).map((processId, idx) => {
              const process = customisationState[processId];
              const { defaultItems, totalDefaultItems } =
                getDefaultItems(process);
              const { nonDefaultItems, totalNonDefaultItems } =
                getNonDefaultItems(process);
              const isCollapsed =
                process.processRemovable &&
                !(process.processId in appliedCustomization); // added or removed
              if (
                !process.variantIdApplicableOn.includes(
                  props.selectedVariant.id.toString()
                )
              )
                return;
              return (
                <div className="space-y-4" key={idx}>
                  <div className="space-y-2">
                    <div className="flex justify-between font-open-sans">
                      <div>
                        <div className="font-semibold text-left text-xs sm:text-sm xl:text-base text-black">
                          {process.processName}
                        </div>
                        <div className="text-left text-2xs sm:text-xs text-black">
                          {getProcessDetails(process)}
                        </div>
                      </div>
                      {process.processRemovable ? (
                        process.processId in appliedCustomization ? (
                          <div
                            className="text-left text-xs text-8593FF cursor-pointer"
                            onClick={() => processClickHandler(process)}
                          >
                            Remove
                          </div>
                        ) : (
                          <div
                            className="text-left text-xs text-8593FF cursor-pointer"
                            onClick={() => processClickHandler(process)}
                          >
                            Add
                          </div>
                        )
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                  <div>
                    {!isCollapsed && (
                      <div>
                        {
                          // if REPLACEABLE && default, limit are more than 1 create two lists - 1 default and 1 for new items
                          process.processType === "REPLACEABLE" &&
                          process.maxItemCustomizableLimit >= 1 &&
                          totalDefaultItems > 1 ? (
                            <div className="space-y-2 border-b-2 pb-2 sm:border-0 sm:pb-0">
                              {/* default items list showing '-' sign on click, meaning it is being removed */}
                              <p className="text-left text-2xs sm:text-xs text-black">
                                Unselect the item to be replaced
                              </p>
                              <div className="flex space-x-8 overflow-x-auto hide-scroll-bar-phone bgWhiteScrollbar py-2 ">
                                {defaultItems.map((item, index) => {
                                  let status = getStatus(process, item);
                                  return (
                                    <div
                                      key={index}
                                      className="flex flex-col space-y-1 font-open-sans cursor-pointer"
                                      onClick={() =>
                                        itemClickHandler(
                                          process,
                                          item,
                                          status["isItemSelected"]
                                        )
                                      }
                                    >
                                      <div className="relative rounded h-24 w-24 sm:w-28 sm:h-28 border-2 border-grey-50 hover:border-gray-300">
                                        <img
                                          src={item.image}
                                          alt="customized logo"
                                          className={`w-full h-full ${
                                            status["isItemSelected"]
                                              ? ""
                                              : "filter grayscale"
                                          }`}
                                        />
                                        {status["isItemSelected"] && (
                                          <div className="absolute -right-2 -top-2">
                                            <img
                                              src={SelectedTick}
                                              alt="selected option"
                                              className="h-5 w-5 sm:h-6 sm:w-6"
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="text-center text-2xs sm:text-xs text-8B8B8B">
                                        {item.name}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              {/* non default items list showing '+' sign on click, meaning it is being added */}
                              <p className="text-left text-2xs sm:text-xs text-black">
                                Select a replacement item
                              </p>
                              <div className="flex space-x-8 overflow-x-auto  hide-scroll-bar-phone bgWhiteScrollbar py-2 ">
                                {nonDefaultItems.map((item, index) => {
                                  let status = getStatus(process, item);
                                  return (
                                    <div
                                      key={index}
                                      className="flex flex-col space-y-1 font-open-sans cursor-pointer"
                                      onClick={() =>
                                        itemClickHandler(
                                          process,
                                          item,
                                          status["isItemSelected"]
                                        )
                                      }
                                    >
                                      <div className="relative rounded h-24 w-24 sm:w-28 sm:h-28 border-2 border-grey-50 hover:border-gray-300 ">
                                        <img
                                          src={item.image}
                                          alt="customized logo"
                                          className={`w-full h-full ${
                                            status["isItemSelected"]
                                              ? ""
                                              : "filter grayscale"
                                          }`}
                                        />
                                        {status["isItemSelected"] && (
                                          <div className="absolute -right-2 -top-2 border-2 border-grey-50 hover:border-gray-300">
                                            <img
                                              src={SelectedTick}
                                              alt="selected option"
                                              className="h-5 w-5 sm:h-6 sm:w-6"
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="text-center text-2xs sm:text-xs text-8B8B8B">
                                        {item.name}
                                      </div>
                                      {process.deltaPricing && (
                                        <div className="font-bold text-center text-2xs sm:text-xs text-black">
                                          Rs.{" "}
                                          {item.price[props.selectedVariant.id]}
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            <div className="border-b-2 pb-2 sm:border-0 sm:pb-0">
                              <div className="flex space-x-8 overflow-x-auto  hide-scroll-bar-phone bgWhiteScrollbar py-2">
                                {Object.keys(process.items).map(
                                  (itemId, itemIdx) => {
                                    const item = process.items[itemId];
                                    let status = getStatus(process, item);
                                    return (
                                      <div
                                        key={itemIdx}
                                        className="flex flex-col space-y-1 font-open-sans cursor-pointer"
                                        onClick={() =>
                                          itemClickHandler(
                                            process,
                                            item,
                                            status["isItemSelected"]
                                          )
                                        }
                                      >
                                        <div className="relative rounded h-24 w-24 sm:w-28 sm:h-28 border-2 border-grey-50 hover:border-gray-300">
                                          <img
                                            src={item.image}
                                            alt="customized logo"
                                            className={`w-full h-full ${
                                              status["isItemSelected"]
                                                ? ""
                                                : "filter grayscale"
                                            }`}
                                          />
                                          {status["isItemSelected"] && (
                                            <div className="absolute -right-2 -top-2">
                                              <img
                                                src={SelectedTick}
                                                alt="selected option"
                                                className="h-5 w-5 sm:h-6 sm:w-6  "
                                              />
                                            </div>
                                          )}
                                        </div>
                                        <div className="text-center text-2xs sm:text-xs text-8B8B8B">
                                          {item.name}
                                        </div>
                                        {process.deltaPricing &&
                                          process.processType !==
                                            "REMOVABLE" && (
                                            <div className="font-bold text-center text-2xs sm:text-xs text-black">
                                              Rs.
                                              {
                                                item.price[
                                                  props.selectedVariant.id
                                                ]
                                              }
                                            </div>
                                          )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          )
                        }
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          <div className="flex space-x-12 font-open-sans items-end">
            <div
              className={`${
                storeClosedOrNot(orderInfo, store)
                  ? "bg-707070"
                  : "bg-8593FF hover:bg-indigo-500"
              } bg-no-repeat rounded font-semibold text-center text-xs text-white py-5 sm:py-4 px-5 xl:px-10 cursor-pointer w-full`}
              onClick={() => resetCustomization()}
            >
              Reset Customization
            </div>
            <div
              className={`${
                storeClosedOrNot(orderInfo, store)
                  ? "bg-707070"
                  : "bg-8593FF hover:bg-indigo-500"
              } bg-no-repeat rounded font-semibold text-center text-xs text-white py-5 sm:py-4 px-5 xl:px-10 cursor-pointer w-full`}
              onClick={() => handleAddCart()}
            >
              {props.type === "EDIT" ? "Save Item" : "Save and Add item"}
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={isOpen}
        title={`Do you want change your order type from ${orderInfo.orderType} to ${props.orderType} category`}
        text="This will delete your previous stored cart"
        submit={submitModal}
        close={closeModal}
        closingText="Cancel"
        submittingText="Delete"
      />

      <div
        className={`opacity-100 flex items-center justify-center fixed top-7 right-7 sm:top-10 sm:right-10  bg-121216 rounded-full h-10 w-10 z-50 transition-opacity duration-500`}
        onClick={() => props.handleClose()}
      >
        <ion-icon
          style={{
            color: "#FFFFFF",
            fontSize: "24px",
            cursor: "pointer",
          }}
          name="close"
        ></ion-icon>
      </div>
    </div>
  );
}

export default CustomizationSlider;
