import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import DownArrowBlack from '../../Assets/Icons/DownArrowBlack.png';

const selected =
	'food-items p-6  h-10 w-24 sm:w-40  col-span-1 border border-8593FF text-xs rounded text-8593FF cursor-pointer flex justify-center items-center';
const unSelected =
	'food-items p-6 h-10 w-24 sm:w-40  col-span-1 border border-gray-500 text-xs rounded text-gray-500 cursor-pointer flex justify-center items-center';

export default function SelectItem({ items, item }) {
	const { restaurantId, orderId } = useParams();
	const [counter, setCounter] = useState(0);
	const [isOpen, setIsOpen] = useState(false);

	const scrollLeft = () => {
		// console.log(
		// 	'clicked Left',
		// 	document.getElementsByClassName('food-items-container')[0],
		// 	counter
		// );

		let items = document.getElementsByClassName('food-items');
		if (counter < items.length) {
			document.getElementsByClassName(
				'food-items-container'
			)[0].scrollLeft += items[counter].offsetWidth;
			if (counter + 1 < items.length) setCounter(counter + 1);
		}
	};
	const scrollRight = () => {
		// console.log(
		// 	'clicked',
		// 	document.getElementsByClassName('food-items-container')[0],
		// 	counter
		// );
		let items = document.getElementsByClassName('food-items');
		if (counter > 0) {
			document.getElementsByClassName(
				'food-items-container'
			)[0].scrollLeft -= items[counter].offsetWidth;
			setCounter(counter - 1);
		}
	};

	return (
		<div>
			{/* for desktop */}
			<div className='hidden lg:block bg-white p-4  sm:px-6 sm:py-5'>
				<div className='relative items-end'>
					<div className='absolute bottom-0'>
						<i
							onClick={scrollRight}
							className='fas fa-chevron-left'
						></i>
					</div>
					<div className='flex-grow px-4 sm:px-2 md:px-6'>
						<div className='text-8B8B8B text-2xs uppercase'>
							Select item which you wish to track
						</div>
						<div className='food-items-container flex overflow-x-scroll  hide-scroll-bar mb-6'>
							<div className='flex mt-4 space-x-2  sm:space-x-6'>
								{Object.keys(items).map((key, index) => {
									let item = items[key];
									if (item.statusCode != 800) {
										return (
											<NavLink
												activeClassName={selected}
												className={unSelected}
												to={`/${restaurantId}/track-order/${orderId}/${item.itemId}`}
											>
												{item.productName}
											</NavLink>
										);
									}
								})}
							</div>
						</div>
					</div>
					<div className='absolute right-1 bottom-0'>
						<i
							onClick={scrollLeft}
							className='fas fa-chevron-right'
						></i>
					</div>
				</div>
			</div>
			{/* for mobile screen  */}
			<div className='block lg:hidden mt-4 md:mt-12'>
				<div
					onClick={() => setIsOpen(!isOpen)}
					className='flex px-4 py-4 justify-between font-open-sans bg-white shadow mx-4 md:mx-0 rounded'
				>
					<div>
						<div className='text-2xs text-8B8B8B mb-1'>
							Track Your Item
						</div>
						<div className='text-xs'>{item.productName}</div>
					</div>
					<img
						src={DownArrowBlack}
						alt='down/up arrow'
						className='w-4 h-4'
					/>
				</div>
			</div>
			{isOpen && (
				<div className='block lg:hidden p-4 bg-white mx-4 md:mx-0 shadow max-h-52 overflow-y-auto'>
					<div className='flex justify-between text-2xs font-semibold'>
						<div className=''>All Items</div>
						<div>Status</div>
					</div>
					{Object.keys(items).map((key, index) => {
						let item = items[key];
						if (item.statusCode != 800) {
							return (
								<div key={key}>
									<NavLink
										onClick={() => setIsOpen(false)}
										activeClassName='flex justify-between border-b border-8593FF py-2 text-sm text-8593FF'
										className='flex justify-between border-b py-2 text-sm'
										to={`/${restaurantId}/track-order/${orderId}/${item.itemId}`}
									>
										<div>{item.productName}</div>
										<div className='text-7FB069'>
											{item.orderStatus}
										</div>
									</NavLink>
								</div>
							);
						}
					})}
				</div>
			)}
		</div>
	);
}
