import React from "react";
import BackArrow from "../../Assets/Icons/BackArrow.svg";
import { useHistory } from "react-router-dom";

const SubHeader = ({ title = "", link = "" }) => {
  const history = useHistory();

  return (
    <div className='flex flex-row items-center h-16 border border-solid border-B7B7B7 bg-white p-4 mt-4'>
      <div
        onClick={!link ? () => history.goBack() : () => history.push(link)}
        className='cursor-pointer'>
        <img src={BackArrow} alt='Back button' height='22px' width='22px' />
      </div>
      <div className='titlecase flex-grow text-center text-464646 capitalize not-italic font-open-sans font-semibold text-xl'>
        {title}
      </div>
    </div>
  );
};

export default SubHeader;
