import { creditConstants } from "../Constants";
import { creditService } from "../Services";
import toast from "react-hot-toast";
import { startPayment } from "../../Containers/Pay/Paytm";
import { modalActions, authActions } from "../../Redux/Actions";

export const creditActions = {
  getCreditDetails,
  initiateCredits,
  fullFillCredits,
  updateCreditStatus,
  fetchCreditsHistory,
};

function getCreditDetails() {
  return (dispatch) => {
    creditService.fetchCreditDetails().then(
      (credit) => {
        dispatch(success(credit));
      },
      (error) => {
        dispatch(failure(false));
        if (error && error?.responseCode === "401") {
          dispatch(authActions.userLogout());
          toast.error("Session expired");
        } else {
          toast.error(error?.message);
        }
      }
    );
  };
  // function request(payload) { return { type: creditConstants.CREDIT_REQUEST, payload } }
  function success(payload) {
    return { type: creditConstants.CREDIT_SET, payload };
  }
  function failure(payload) {
    return { type: creditConstants.CREDIT_FAILURE, payload };
  }
}

function initiateCredits(req) {
  return (dispatch) => {
    dispatch(request(true));
    creditService.intiateCredits(req).then(
      (credit) => {
        dispatch(modalActions.togglePaytmModal(true));
        dispatch(success(credit));
        startPayment({
          txnToken: credit.txnToken,
          type: "TOPUP",
          orderId: credit.orderId,
        });
      },
      (error) => {
        dispatch(failure());
        if(error?.responseCode){
            toast.error("Session expired");
            dispatch(authActions.userLogout());
            dispatch(authActions.redirect("/"));
        }else{
            toast.error(error?.error)
        }
      }
    );
  };

  function request(payload) {
    return { type: creditConstants.CREDIT_PAYMENT_REQUEST, payload };
  }
  function success(payload) {
    return { type: creditConstants.CREDIT_PAYMENT_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: creditConstants.CREDIT_PAYMENT_FAILURE, payload };
  }
}

function fullFillCredits(req) {
  return (dispatch) => {
    creditService.fullFillCredits(req).then(
      (credit) => {
        dispatch(success(credit));
        dispatch(modalActions.togglePaytmModal(false));
        toast.success("Credits added Succesfully");
      },
      (error) => {
        toast.error(error);
      }
    );
  };
  function request(payload) {
    return { type: creditConstants.CREDIT_REQUEST, payload };
  }
  function success(payload) {
    return { type: creditConstants.CREDIT_SET, payload };
  }
  function failure(payload) {
    return { type: creditConstants.CREDIT_FAILURE, payload };
  }
}

function updateCreditStatus(req) {
  return (dispatch) => {
    creditService.updateCreditStatus(req).then(
      (credit) => {
        toast.error("Transcation Failed !!!");
      },
      (error) => {
        toast.error(error);
      }
    );
  };
  // function request(payload) { return { type: creditConstants.CREDIT_REQUEST, payload } }
  // function success(payload) { return { type: creditConstants.CREDIT_SET, payload } }
  // function failure(payload) { return { type: creditConstants.CREDIT_FAILURE, payload } }
}

function fetchCreditsHistory(req) {
  return (dispatch) => {
    dispatch(request(true));
    creditService.fetchTranscationHistory(req).then(
      (credit) => {
        dispatch(success(credit));
      },
      (error) => {
        dispatch(failure());
        toast.error(error);
      }
    );
  };
  function request(payload) {
    return { type: creditConstants.CREDIT_HISTORY_REQUEST, payload };
  }
  function success(payload) {
    return { type: creditConstants.CREDIT_HISTORY_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: creditConstants.CREDIT_HISTORY_FAILURE, payload };
  }
}
