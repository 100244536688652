import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { useEffect, useState } from "react";
import { menuActions, storeActions } from "../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import urls from "../urls";
import toast from "react-hot-toast";
import "./Layout.css";
import CustomizationSlider from "./FoodDetail/CustomizationSlider";
import SideNav from "../Components/SideNav/SideNav";
import ChangePinModal from "../Components/ChangePinModal/ChangePinModal";
import EditProfileModal from "../Components/EditProfileModal/EditProfileModal";

export default function Layout({
  children,
  history,
  PageName,
  showSearch,
  setShowSearch,
  query,
  setQuery,
}) {
  const dispatch = useDispatch();
  const { restaurantId } = useParams();
  const { store, orderInfo, auth } = useSelector((state) => state);
  const {
    address,
    email,
    gst,
    fssaiNumber,
    customerSupport,
    registrationDate,
  } = store;
  const { menu } = useSelector((store) => store);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  useEffect(() => {
    const orderDetails = {
      orderType: orderInfo.orderType,
      deliverNow: orderInfo.deliverNow,
      time: orderInfo.time,
    };
    if (restaurantId || orderInfo.storeId) {
      dispatch(storeActions.getStore(restaurantId ?? orderInfo.storeId, PageName));
      if(PageName === '' || PageName === "Menu Card"){
        dispatch(menuActions.getMenu(restaurantId ?? orderInfo.storeId, orderInfo));
      }
    }
  }, [PageName, dispatch, restaurantId]);


  const [selectFoodItem, setSelectFoodItem] = useState({
    foodItem: {},
    appliedCustomization: {},
    index: 0,
  });
  const [isCustomizationsVisible, setIsCustomizationsVisible] = useState(false);
  const showCustomization = (productId, appliedCustomization, index) => {
    setSelectFoodItem({
      foodItem: menu.products[productId],
      appliedCustomization: JSON.parse(JSON.stringify(appliedCustomization)),
      index,
    });
    setIsCustomizationsVisible(true);
  };
  return (
    <div>
      <ChangePinModal />
      <EditProfileModal />
      <div className="grid grid-cols-12 bg-F5F5F5">
        <div className="col-span-12 col-start-1 md:col-start-2 md:col-span-10 ">
          <Header
            history={history}
            PageName={PageName}
            isCustomizationsVisible={isCustomizationsVisible}
            showCustomization={showCustomization}
            showSearchNew={showSearch}
            setShowSearch={setShowSearch}
            query={query}
            setQuery={setQuery}
          />
          <div
            className={`${showSearch ? "mt-12" : "mt-28"} md:mt-20 mb-8`}
            style={{
              minHeight: "calc(100vh - 260px)",
            }}
          >
            {children}
          </div>
          <Footer
            address={address}
            email={email}
            gst={gst}
            fssai={fssaiNumber}
            customerSupport={customerSupport}
          />
          <SideNav
            onClose={() => setIsCustomizationsVisible(false)}
            visible={isCustomizationsVisible}
            closable={false}
            mode="light"
          >
            {isCustomizationsVisible && (
              <CustomizationSlider
                type="SAVE"
                index={selectFoodItem.index}
                appliedCustomization={selectFoodItem.appliedCustomization}
                image={selectFoodItem.foodItem.image}
                name={selectFoodItem.foodItem.name}
                isVeg={selectFoodItem.foodItem.isVeg}
                selectedVariant={selectFoodItem.foodItem.selectedVariant}
                variants={selectFoodItem.foodItem.variants}
                customization={selectFoodItem.foodItem.customisation}
                product={selectFoodItem.foodItem}
                handleClose={() => {
                  setIsCustomizationsVisible(false);
                  document.body.classList.remove("stop-scrolling");
                }}
                orderType={orderInfo.orderType}
              />
            )}
          </SideNav>
        </div>
      </div>
    </div>
  );
}
