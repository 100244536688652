import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Tooltip } from '@mui/material';

const DAYS = [
	{
		dayname: 'Sunday',
		key: 'sun',
		label: 'S',
		id: 0,
	},
	{
		dayname: 'Monday',
		key: 'mon',
		label: 'M',
		id: 1,
	},
	{
		dayname: 'Tuesday',
		key: 'tue',
		label: 'T',
		id: 2,
	},
	{
		dayname: 'Wednesday',
		key: 'wed',
		label: 'W',
		id: 3,
	},
	{
		dayname: 'Thursday',
		key: 'thu',
		label: 'T',
		id: 4,
	},
	{
		dayname: 'Friday',
		key: 'fri',
		label: 'F',
		id: 5,
	},
	{
		dayname: 'Saturday',
		key: 'sat',
		label: 'S',
		id: 6,
	},
];

const StyledToggleButtonGroup = withStyles((theme) => ({
	grouped: {
		// margin: theme.spacing(1),
		// padding: theme.spacing(0, 1),
		'&:not(:first-child)': {
			border: '1px solid',
			borderColor: '#692B7C',
			borderRadius: '50%',
		},
		'&:first-child': {
			border: '1px solid',
			borderColor: '#692B7C',
			borderRadius: '50%',
		},
		maxWidth: '95%',
	},
}))(ToggleButtonGroup);

const StyledToggle = withStyles({
	root: {
		color: '#692B7C',
		'&$selected': {
			color: 'white',
			background: '#8593FF',
		},
		'&:disabled': {
			color: 'black',
			background: '#e2e2e2',
			borderColor: '#e2e2e2',
			textDecoration: 'line-through'
		},
		'&:hover': {
			borderColor: '#BA9BC3',
			background: '#BA9BC3',
		},
		'&:hover$selected': {
			borderColor: '#BA9BC3',
			background: '#BA9BC3',
		},
		minWidth: 32,
		maxWidth: 32,
		height: 32,
		textTransform: 'unset',
		fontSize: '0.75rem',
	},
	selected: {},
})(ToggleButton);

const ToggleDays = (props) => {
	const { days, setDays, openOnWeekdays } = props;
	// <div className='flex w-full max-w-full justify-between	'>
	return (
		<div>
			<StyledToggleButtonGroup
				size='small'
				arial-label='Days of the week'
				value={days}
				onChange={(event, value) => setDays(value)}
				style={{
					display: 'flex',
					maxWidth: '400px',
					justifyContent: 'space-between',
					marginTop: '0.5rem',
					marginBottom : '0.5rem',
					width: '100%'
				}}
			>
				{DAYS.map((day) => (
					// <Tooltip title={day.dayname}>
					<StyledToggle
						key={day.key}
						value={day.key}
						aria-label={day.key}
						disabled={openOnWeekdays && !openOnWeekdays.includes(day?.id)}
					>
						{day.label}
					</StyledToggle>
					// </Tooltip>
				))}
			</StyledToggleButtonGroup>
		</div>
	);
};

export default ToggleDays;
