import { Link, useParams } from "react-router-dom";
import Forward from "../../../Assets/Icons/Icon ionic-ios-arrow-dropright.svg";
export default function MenuCard({ image, heading, para, link }) {
  const { restaurantId } = useParams();
  return (
    <div className='bg-white flex p-6 xl:p-8 rounded-md space-x-8 shadow-sm  '>
      <div className='flex-none self-center '>
        <img className='w-auto h-auto' src={image} alt='menu-icon' />
      </div>
      <div className='flex-grow '>
        <div className='text-lg font-open-sans font-normal'>{heading}</div>
        <div className='lowercase font-open-sans font-light text-sm'>{para}</div>
      </div>
      <div className='flex-none cursor-pointer'>
        <Link to={`/${restaurantId}/menu-card${link}`}>
          <img src={Forward} alt='menu card' />
        </Link>
      </div>
    </div>
  );
}
