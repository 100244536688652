import { toQueryStringURL } from "../../Functions/utils";
import urls from "../../urls";

export const menuService = {
  getMenu,
};

export function getMenuUrl(storeId, isUserBased, req = {}) {
  const { params = {} } = req;
  const url = toQueryStringURL(`${urls.Menu}/${storeId}/menu/subs/schedule`, params);
  return url;
}

function getMenu(storeId, isUserBased, req = {}) {
  const { data = {}, params = {} } = req;
  const url = isUserBased
    ? toQueryStringURL(`${urls.userMenu}/${storeId}/menu`, params)
    : toQueryStringURL(`${urls.Menu}/${storeId}/menu`, params);
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", token: auth?.token },
    body: JSON.stringify({
      ...data,
    }),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data.status === "Failed") {
      const error = data.message;
      return Promise.reject(error);
    }
    if (data?.errorMessage === "Auth_Invalid") {
      return Promise.reject("Auth Invalid, please login again");
    }
    return data.data;
  });
}
