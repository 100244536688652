export const orderTrackConstants = {
    ORDER_SET: "ORDER_SET",
    
    SET_ORDER_ID: 'SET_ORDER_ID',

    START_TRACKING: 'START_TRACKING',
    STOP_TRACKING: 'STOP_TRACKING',

    ORDER_TRACKING_REQUEST: 'ORDER_TRACKING_REQUEST',
    ORDER_TRACKING_SUCCESS: 'ORDER_TRACKING_SUCCESS',
    ORDER_TRACKING_FAILURE: 'ORDER_TRACKING_FAILURE',

    PAST_ORDERS_REQUEST: 'PAST_ORDERS_REQUEST',
    PAST_ORDERS_SUCCESS: 'PAST_ORDERS_SUCCESS',
    PAST_ORDERS_FAILURE: 'PAST_ORDERS_FAILURE',
  
    PAST_ORDER_DETAIL_REQUEST: 'PAST_ORDER_DETAIL_REQUEST',
    PAST_ORDER_DETAIL_SUCCESS: 'PAST_ORDER_DETAIL_SUCCESS',
    PAST_ORDER_DETAIL_FAILURE: 'PAST_ORDER_DETAIL_FAILURE',
};