import urls from "../../urls";

export const loginService = {
  getOTP,
  verifyOTP,
  addAddress,
  signUpUser,
  signInUser,
  resendOtp,
  resendPin,
  changePin,
  editProfile,
  verifyToken,
  signOut,
};

function getOTP(phone ,email='', type) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ phone, email, type }),
  };
  return fetch(urls["generateOTP"], requestOptions).then(handleResponse1);
}

function verifyOTP(OTP, type, token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ otp: OTP, type, token }),
  };
  return fetch(urls["verifyOTP"], requestOptions).then(handleAuthResponse);
}

function signUpUser(name, phone, email, pin) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ name, mobile: phone, email, pin }),
  };

  return fetch(urls.signUp, requestOptions).then(handleAuthResponse);
};

function resendOtp(type, token){
  const requestOptions = {
    method: 'POST',
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({type, token})
  };

  return fetch(urls.resendOtp, requestOptions).then(handleAuthResponse);
}

function signInUser(pin, email, mobile = ''){
  const requestOptions = {
    method: 'POST',
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(email !== '' ? {pin, email} : {pin, mobile})
  };

  return fetch(urls.signIn, requestOptions).then(handleAuthResponse);
}

function resendPin(email, mobile = ''){
  const requestOptions = {
    method: 'POST',
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(email !== '' ? {email} : {mobile})
  };

  return fetch(urls.resendPin, requestOptions).then(handleAuthResponse);
}

function changePin(req = {}){
  const {data = {}} = req;
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: 'POST',
    headers: {"Content-Type": "application/json", token: auth?.token},
    body: JSON.stringify(data)
  };

  return fetch(urls.changepin, requestOptions).then(handleAuthResponse);
}

function editProfile(req = {}){
  const {data = {}} = req;
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: 'POST',
    headers: {"Content-Type": "application/json", token: auth?.token},
    body: JSON.stringify(data)
  };

  return fetch(urls.updateprofile, requestOptions).then(handleAuthResponse);
}

function addAddress(req) {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
      method: 'POST',
      headers: { token :auth.token,'Content-Type': 'application/json' },
      body: JSON.stringify({ ...req, user:auth.user })
  }

  return fetch(urls['address'], requestOptions)
      .then(handleResponse);
}

function verifyToken(){
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: 'GET',
    headers: { token :auth?.token,'Content-Type': 'application/json' },
  };
  return fetch(urls.verifyToken, requestOptions).then(handleVerifyResponse);
}

function signOut(){
  const auth = JSON.parse(localStorage.getItem("auth"));
  const requestOptions = {
    method: 'GET',
    headers: { token :auth?.token,'Content-Type': 'application/json' },
  };
  return fetch(urls.signOut, requestOptions).then(handleAuthResponse);  
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data.errorMessage) {
      const error = {error: data?.errorMessage, statusCode: data?.statusCode};
      return Promise.reject(error);
    }
    return data.data ?? data;
  });
}

function handleAuthResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data?.errorMessage) {
      const error = data.errorMessage;
      return Promise.reject(error);
    }
    return data;
  });
}
function handleVerifyResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data?.errorMessage) {
      const error = {errorMessage: data.errorMessage, statusCode: data?.statusCode};
      return Promise.reject(error);
    }
    return data;
  });
}

function handleResponse1(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data.status=='Failed') {
      // debugger
      const error = data.message;
      return Promise.reject(error);
    }
    return data.data;
  });
}