import { useEffect } from "react";

const SideBar = ({
    children,
    visible,
    onClose,

}) => {
    function close() {
        document.body.classList.remove("stop-scrolling");
        onClose();
    }
    const disableScroll = () => {
        document.body.classList.add("stop-scrolling");
      };
      useEffect(() => {
        if (visible) {
          window.scrollTo(0, 0);
          disableScroll();
        }
      }, [visible]);
    return (
        <div>
            {/* Backdrop Component --> For Dimming the backgroung */}
            {visible && (
                <div
                    onClick={close}
                    className='fixed w-full h-full z-30 left-0 top-0 bg-black opacity-50'></div>
            )}
            {/* SideNav Component  */}
            <div
                className={`bg-white ${visible ? "h-full w-9/12 sm:w-6/12 md:w-4/12 xl:w-3/12" : "w-0"
                    } absolute z-30 bottom-0 sm:top-0 right-0  transition delay-150 duration-300 ease-in-out overflow-y-scroll`}>
                <div className='block absolute top-10 right-10 text-4xl cursor-pointer z-30'>
                    <ion-icon
                        style={{ color: "black" }}
                        onClick={close}
                        name='close-outline'></ion-icon>
                </div>
                {/* Side Nav Children */}
                {children}
            </div>
        </div>
    )
}

export default SideBar
