import { menuConstants } from "../Constants";

const initialState = {
  addOn: [],
  products: {},
  menu: [],
  specialMenu: { name: "", products: [] },
  loading: false,
  menuTags: [],
  categoryAvailableId: {
    kids: [],
    healthy: [],
    schedule: [],
  },
  availableProductId: {},
};

export function menu(state = initialState, action) {
  switch (action.type) {
    case menuConstants.MENU_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case menuConstants.MENU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case menuConstants.MENU_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case menuConstants.MENU_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
