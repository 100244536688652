import React from "react";
import { CircularProgress, Modal as MaterialUiModal } from "@mui/material";
import {  formatddmmyyyy } from "../../Functions/GetTime";
import SubscriptionCard from "../../Containers/MenuCard/SubscriptionCard";


function SubscriptionModal({ modalState,setModalState, orderInfo }) {
  return (
    <MaterialUiModal
      open={modalState?.show}
      onClose={() => {
        setModalState((prev) => {
          return { ...prev, show: false };
        });
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div
        onClick={() => {
          setModalState((prev) => {
            return { ...prev, show: false };
          });
        }}
        className="w-full h-full items-center justify-center flex"
      >
        <div
          onClick={(eve) => {
            eve?.stopPropagation();
          }}
          className="w-full relative md:w-1/2 p-5 bg-white rounded-lg"
        >
          <div className="w-full text-center text-xl">
            Viewing Schedule for{" "}
            <span className="font-bold text-3xl">
              {" "}
              {modalState?.subCategoryName}
            </span>
          </div>
          <div className="w-full border-b-2 mb-2 text-center">
            From
            <span className="font-bold">
              {" "}
              {formatddmmyyyy(new Date(orderInfo?.startDate), "/")}{" "}
            </span>
            to
            <span className="font-bold">
              {" "}
              {formatddmmyyyy(new Date(orderInfo?.endDate), "/")}{" "}
            </span>
          </div>
          <div
            onClick={() => {
              setModalState((prev) => {
                return { ...prev, show: false };
              });
            }}
            className="cursor-pointer absolute text-xl text-center w-10  h-10 rounded-full bg-gray-200 font-bold right-0 flex items-center justify-center top-0 z-10"
          >
            X
          </div>
          {modalState?.loading ? (
            <>
              <div
                style={{
                  height: "80vh",
                  minHeight: "400px",
                  maxHeight: "500px",
                  overflowY: "auto",
                }}
                className=" p-2 flex items-center justify-center  "
              >
                <CircularProgress />
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  height: "60vh",
                  minHeight: "500px",
                  overflowY: "auto",
                }}
                className=" p-2  "
              >
                {modalState?.data?.map((ele) => {
                  return <SubscriptionCard foodItem={ele} />;
                })}
                {modalState?.data?.length == 0 && "No data to show"}
              </div>
            </>
          )}
        </div>
      </div>
    </MaterialUiModal>
  );
}

export default SubscriptionModal;
