import { isEmpty } from "lodash";
import { store } from "../../Functions/store";

export const cartService = {
  calculateCart,
  // getPrice
};

export const getCustomizationTotal = (item) => {
  let total = 0;

  if (item.productType === "COMBO") {
    Object.keys(item.finalCustomisation).forEach((comboGroupId) => {
      const comboGroup = item.finalCustomisation[comboGroupId];

      total +=
        item.appliedCustomization[item.selectedVariant.id][comboGroupId]
          .comboGroupItem[comboGroup.comboGroupItemId].price;

      if (comboGroup.itemCustomization.finalCustomisation) {
        const { addedItems, removedItems, removedProcess, replacedItems } =
          comboGroup.itemCustomization.finalCustomisation;

        Object.keys(addedItems).forEach((itemkey) => {
          comboGroup.itemCustomization.appliedCustomization[itemkey].forEach(
            (option) => {
              total = total + option.price[comboGroup.variantId];
            }
          );
        });

        Object.keys(replacedItems).forEach((processId) => {
          comboGroup.itemCustomization.appliedCustomization[processId][
            "to"
          ].forEach((option) => {
            for (const items of comboGroup.itemCustomization.finalCustomisation
              .replacedItems[processId]["to"]) {
              if (option.id == items) {
                total = total + option.price[comboGroup.variantId];
              }
            }
          });
        });

        Object.keys(replacedItems).forEach((processId) => {
          comboGroup.itemCustomization.appliedCustomization[processId][
            "from"
          ].forEach((option) => {
            for (const items of comboGroup.itemCustomization.finalCustomisation
              .replacedItems[processId]["from"]) {
              if (option.id == items) {
                total = total - option.price[comboGroup.variantId];
              }
            }
          });
        });
      }
    });
  } else {
    Object.keys(item.finalCustomisation.addedItems).forEach((itemkey) => {
      item.appliedCustomization[itemkey].forEach((option) => {
        total = total + option.price[item.selectedVariant.id];
      });
    });

    Object.keys(item.finalCustomisation.replacedItems).forEach((processId) => {
      item.appliedCustomization[processId]["to"].forEach((option) => {
        for (const items of item.finalCustomisation.replacedItems[processId][
          "to"
        ]) {
          if (option.id == items) {
            total = total + option.price[item.selectedVariant.id];
            // console.log(total, 'from total');
          }
        }
      });
    });

    Object.keys(item.finalCustomisation.replacedItems).forEach((processId) => {
      item.appliedCustomization[processId]["from"].forEach((option) => {
        for (const items of item.finalCustomisation.replacedItems[processId][
          "from"
        ]) {
          if (option.id == items) {
            total = total - option.price[item.selectedVariant.id];
          }
        }
      });
    });
  }
  return total;
};

function calculateCart(cartDetails) {
  // console.log("cartDetails",cartDetails)
  let { packagingCharges, deliveryCharges, cart, noOfDays } = cartDetails;
  deliveryCharges = parseFloat(deliveryCharges);
  const { orderInfo } = store.getState();
  let totalTax = 0,
    ogTax = 0,
    totalAmount = 0,
    subTotal = 0,
    ogTotal = 0,
    totalSavings = 0,
    payableTax = 0;
  packagingCharges = orderInfo.orderType === "scheduled" ? 0 : packagingCharges;
  for (const productId in cart) {
    const product = cart[productId];
    totalTax +=
      (product.tax / 100) *
      ((product.selectedVariant.discountedPrice !== null
        ? product.selectedVariant.discountedPrice
        : product.selectedVariant.price) +
        getCustomizationTotal(product)) *
      product.quantity *
      noOfDays;
    // console.log(
    // 	'totalTax',
    // 	// totalTax,product.discountedPrice,
    // 	getCustomizationTotal(product)
    // 	// (product.tax / 100) *
    // 	// 	((product.discountedPrice !== null
    // 	// 		? product.discountedPrice
    // 	// 		: product.selectedVariant.price) +
    // 	// 		getCustomizationTotal(product) * product.quantity)
    // );
    ogTax +=
      (product.tax / 100) *
      (product.selectedVariant.price + getCustomizationTotal(product)) *
      product.quantity *
      noOfDays;

    subTotal +=
      ((product.discountedPrice !== null
        ? product.selectedVariant.discountedPrice
        : product.selectedVariant.price) +
        getCustomizationTotal(product)) *
        product.quantity *
        noOfDays -
      (product?.taxIncluded
        ? (product.tax / 100) *
          (product.selectedVariant.price + getCustomizationTotal(product)) *
          product.quantity *
          noOfDays
        : 0);
    ogTotal +=
      (product.selectedVariant.price + getCustomizationTotal(product)) *
      product.quantity *
      noOfDays;
  }

  payableTax = totalTax + deliveryCharges + packagingCharges;
  ogTax += deliveryCharges + packagingCharges;
  totalAmount = subTotal + payableTax;
  totalSavings = ogTotal + ogTax - totalAmount;
  const res = {
    totalTax,
    ogTax,
    totalAmount,
    subTotal,
    ogTotal,
    totalSavings,
    payableTax,
    packagingCharges,
    deliveryCharges,
  };

  // console.log(res, cartDetails, 'res');
  for (const prop in res) {
    // console.log(prop,res[prop], 'res prop')

    res[prop] = parseFloat(res[prop].toFixed(2));
  }
  return res;
}
