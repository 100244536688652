import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "../../Redux/Actions/order.actions";


// default bulk preferences
const bulkChoices = [
  {
    choiceId: 1000,
    title: "For what you are ordering for?",
    type: "single",
    defaultOptionId: 1001, // default selectd
    options: [
      {
        optionId: 1001,
        name: "anniversary",
        selected: true,
      },
      {
        optionId: 1002,
        name: "corporate event",
        selected: false,
      },
      {
        optionId: 1003,
        name: "BirthDay Party",
        selected: false,
      },
    ],
  },
  {
    choiceId: 2000,
    title: "How much spice would you like?",
    type: "single",
    defaultOptionId: 2001,
    options: [
      {
        optionId: 2001,
        name: "low",
        selected: true,
      },
      {
        optionId: 2002,
        name: "med",
        selected: false,
      },
      {
        optionId: 2003,
        name: "high",
        selected: false,
      },
    ],
  },
  {
    choiceId: 3000,
    title: "Ordering for how many people approx ?",
    type: "single",
    defaultOptionId: 3001,
    options: [
      {
        optionId: 3001,
        name: "10-20",
        selected: true,
      },
      {
        optionId: 3002,
        name: "20-50",
        selected: false,
      },
      {
        optionId: 3003,
        name: ">50",
        selected: false,
      },
    ],
  },
  {
    choiceId: 4000,
    title: "Ordering for ?",
    type: "single",
    defaultOptionId: 4001,
    options: [
      {
        optionId: 4001,
        name: "Breakfast/ Brunch",
        selected: true,
      },
      {
        optionId: 4002,
        name: "Lunch",
        selected: false,
      },
      {
        optionId: 4003,
        name: "Dinner",
        selected: false,
      },
    ],
  },
];

const selected =
  "flex justify-center items-center text-white bg-D17B69  h-24 rounded cursor-pointer p-8 text-center";
const unSelected =
  "flex justify-center items-center border border-gray-800 h-24  rounded cursor-pointer p-8 text-center";

export default function BulkMenuSideNav({ close }) {
  const orderInfo = useSelector((state) => state.orderInfo);
  const dispatch = useDispatch();
  const [choices, setChoices] = useState([]);

  // if preferences are pre stored then reserve them 
  useEffect(() => {
    setChoices(
      orderInfo.preferences["bulkPreferences"].length
        ? orderInfo.preferences["bulkPreferences"]
        : bulkChoices
    );
  }, [orderInfo]);

  const selectOption = (choiceIndex, optionIndex) => {
    let updatedChoices = choices;
    updatedChoices[choiceIndex].options.forEach((option, index) => {
      index === optionIndex
        ? (option.selected = true)
        : (option.selected = false);
    });

    setChoices([...updatedChoices]);
  };

  const onSubmit = () => {
    close();
    orderInfo.preferences["bulkPreferences"] = choices;
    dispatch(orderActions.updateOrderStore(orderInfo));
    document.body.classList.remove("stop-scrolling");
  };

  return (
    <div className='font-open-sans w-9/12 md:w-8/12 lg:w-7/12 m-auto my-28'>
      <div className='font-bold text-xl'>Quick Questions... to get started</div>
      {choices.map((choice, choiceIndex) => (
        <div key={choiceIndex} className='mt-10'>
          <div className=''>{choice.title}</div>
          <div className='grid grid-cols-3 gap-4 mt-2'>
            {choice.options.map((option, optionIndex) => (
              <div
                key={optionIndex}
                onClick={
                  option.selected
                    ? () => { }
                    : () => selectOption(choiceIndex, optionIndex)
                }
                className={option.selected ? selected : unSelected}>
                {option.name}
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className='flex justify-center'>
        <button
          onClick={onSubmit}
          className='mt-10 h-12 w-full bg-8593FF rounded   font-semibold font-open-sans text-xs text-FFFFFF capitalize  cursor-pointer  hover:bg-indigo-500'>
          Next
        </button>
      </div>
    </div>
  );
}
