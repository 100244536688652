import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions(config) {
  const { onResize = null } = config ?? {};

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const handleResize = useCallback(
    debounce(() => {
      const currentDimensions = getWindowDimensions();
      setWindowDimensions(currentDimensions);
      onResize?.(currentDimensions);
    }, 250),
    [onResize]
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  return windowDimensions;
}

export function safeJSONParse(obj) {
  try {
    return JSON.parse(obj);
  } catch (error) {
    console.log(error, obj);
    return null;
  }
}

export function useAccess(params) {
  const client = useSelector((state) => {
    return state.client;
  });

  const store = useSelector((state) => {
    return state.store;
  });

  const auth = useSelector((state) => {
    return state.auth;
  });

  const { storeAccessType, userStoreAccessType } = client;

  const { storeId } = store;

  const { auth: loggedIn } = auth;

  const access = useMemo(() => {
    const res = {
      allowed: true,
      loginFirst: false,
      sendMobile: false,
      configFetched: storeAccessType != null && userStoreAccessType != null,
    };
    if (storeAccessType === "PROTECTED") {
      res.loginFirst = !loggedIn;
      res.allowed =
        (storeId !== "" || params?.restaurantId != null) && loggedIn;
      if (userStoreAccessType === "RESTRICTED") {
        res.sendMobile = true;
      }
    }
    return res;
  }, [storeAccessType, userStoreAccessType, loggedIn, storeId, params]);

  return access;
}

export const formatTimeDate = (date, time) => {
  date = moment(date).format("DD/MM/YYYY");
  if (time === null) {
    return `${date}`;
  }
  return `${date} ${time}`;
};

export const compareDates = (date1, date2) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const isOrderAllowed = (laterDate, isStoreClossed, openTime, closeTime) => {
  const currentTime = new Date().toTimeString();
  const btnOpenTime = currentTime >= openTime && currentTime <= closeTime;  
  // console.log({btnOpenTime, currentTime, openTime, closeTime});  
  const futureDate = laterDate && (new Date(laterDate) > new Date() && new Date(laterDate) != new Date());
  if(futureDate && btnOpenTime && !isStoreClossed){
    return true;
  }else{
    return false;
  }
}

export function toQueryStringURL(queryURL, params) {
	const url = new URL(queryURL);
	Object.keys(params).forEach((key) =>
		url.searchParams.append(key, params[key])
	);
	return url;
}

export function checkStoreId(history){
  const restaurantId = Number(history?.location?.pathname?.split("/")[1]);
  const { storeId } = JSON.parse(localStorage.getItem("orderInfo"));
  if (storeId !== restaurantId) {
    // history?.push("/");
    toast.error("Please reselect the store!");
    return false;
  }
  return true;
}