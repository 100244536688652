/** @format */

import React from "react";

const PhoneRecipeSection = ({ foodItem }) => {
  return (
    <div className='space-y-4'>
      <div className='font-open-sans font-bold text-left text-base text-464646'>
        How we make your {foodItem.name}?
      </div>
      <div className=''>
        {Object.keys(foodItem.recipe).map((stepId, index) => {
          const step = foodItem.recipe[stepId];
          return (
            <div
              key={stepId}
              className="relative pb-2 ">
              <div className='bg-FFFFFF bg-no-repeat rounded px-4 py-3 space-y-2 overflow-x-scroll'>
                {/* <div className='font-open-sans font-semibold text-left text-xs sm:text-sm text-181818'>
                  {step.description}
                </div> */}
                <div className='text-left not-italic font-semibold font-open-sans text-xs max-w-screen'>
                Step {index + 1} :{`${step.name}`}{step.type
                    ? step.type == "REPLACEABLE"
                      ? "(Replaceble)"
                      : "(Removable)"
                    : ""}
                </div>
                <div className='grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 gap-2 bgWhiteScrollbar'>
                  {Object.keys(step.items).map((itemId, index) => {
                    const img = step.items[itemId].image;
                    return (
                      <div key={itemId} className='overflow-hidden rounded'>
                        <img
                          src={img}
                          alt='recipe'
                          className='object-cover w-full h-full'
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <div className='absolute top-0 -left-4 z-10 bg-F5F5F5 border-2 border-gray-300 text-gray-300 text-2xs rounded-full px-2.5 py-1.5'>
                {index + 1}
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PhoneRecipeSection;
