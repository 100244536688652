import { cartConstants } from '../Constants';

const initialState = [];

export function cart(state = initialState, action) {
	switch (action.type) {
		case cartConstants.CART_UPDATE:
			return [...action.payload];
		case cartConstants.CART_CLEAR:
			return [];
		default:
			return state;
	}
}
