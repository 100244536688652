export const creditConstants = {
    CREDIT_SET: "CREDIT_SET",
    CREDIT_PAYMENT_REQUEST: 'CREDIT_PAYMENT_REQUEST',
    CREDIT_PAYMENT_SUCCESS: 'CREDIT_PAYMENT_SUCCESS',
    CREDIT_PAYMENT_FAILURE: 'CREDIT_PAYMENT_FAILURE',
    CREDIT_FAILURE : 'CREDIT_FAILURE',
    CREDIT_REQUEST : 'CREDIT_REQUEST',
    CREDIT_HISTORY_REQUEST: 'CREDIT_HISTORY_REQUEST',
    CREDIT_HISTORY_SUCCESS: 'CREDIT_HISTORY_SUCCESS',
    CREDIT_HISTORY_FAILURE: 'CREDIT_HISTORY_FAILURE',
};