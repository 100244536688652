import React, { useEffect, useState } from "react";
import ChatShef from "../../Assets/Icons/chat-chef.svg";
import chef from "../../Assets/Icons/Chef(black).svg";

const chatsData = [
  {
    message:
      "Good Afternoon, i will be your chef for today,my name is Mr. Rajeev Singha",
    sender: "chef",
  },
  {
    message: "Chef, i want my food to be less oily",
    sender: "self",
  },
  {
    message: "we will be try our best.",
    sender: "chef",
  },
];

export default function Chat() {
  const [chats, setChats] = useState([...chatsData]);
  const [stopSending, setStopSending] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const chatContainer = document.getElementById("chat-component");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  }, [chats]);

  const sendChat = () => {
    chats.push({ sender: "self", message: message });
    setChats([...chats]);
    setStopSending(true);
  };
  return (
    <div
      id='chat-component'
      className='h-full flex flex-col justify-between relative font-open-sans'>
      <div className='flex justify-center my-6 items-center'>
        <div>TALK WITH CHEF</div>
        <img className='h-10 w-auto ml-4' src={chef} alt="chef"/>
      </div>
      <div className='flex flex-col justify-end'>
        <div className='w-11/12 sm:w-8/12 mx-auto'>
          {chats.map((chat) => {
            if (chat.sender === "chef") return <Chef message={chat.message} />;
            if (chat.sender === "self") return <User message={chat.message} />;
          })}
        </div>
        <div className='pb-8 pt-4 bg-gray-200 w-full sticky bottom-0 left-0'>
          <div className='flex justify-between items-center w-11/12 mx-auto'>
            {stopSending ? (
              <div className='ml-0  sm:ml-16'>
                <div className='font-semibold'>
                  Thank You , Your request is given to chef
                </div>
                <div className='text-sm'>
                  sorry you can't ask for more request
                </div>
              </div>
            ) : (
              <input
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && sendChat()}
                className='bg-gray-200 ml-0 sm:ml-16 outline-none w-10/12'
                placeholder='One request to chef is allowed...'
              />
            )}
            <ion-icon
              onClick={() => sendChat(message)}
              style={{ fontSize: "35px", cursor: "pointer" }}
              name='arrow-forward'></ion-icon>
          </div>
        </div>
      </div>
    </div>
  );
}

const Chef = ({ message }) => (
  <div className='flex items-end space-x-4 my-6'>
    <div
      style={{ minHeight: "70px", backgroundColor:"#d9deff" }}
      className='flex-grow pointer relative  p-3 rounded-sm'>
      <div>{message}</div>
    </div>
    <img src={ChatShef} className='h-24 w-auto' alt="chef"/>
  </div>
);

const User = ({ message }) => (
  <div className='flex items-end space-x-4 my-6'>
    <div 
    className='h-16 w-16 rounded-full flex justify-center  items-center bg-D17B69 text-white'>
      You
    </div>
    <div
      style={{ minHeight: "70px", backgroundColor:"#e7bcb3" }}
      className='flex-grow pointer2 relative  p-3 rounded-sm'>
      <div>{message}</div>
    </div>
  </div>
);
