export const orderConstants = {
  SET_LOADING: 'SET_LOADING',
  
  ORDER_UPDATE: "ORDER_UPDATE",
  ORDER_RESET: "ORDER_RESET",

  ORDER_PLACEMENT_REQUEST: 'ORDER_PLACEMENT_REQUEST',
  ORDER_PLACEMENT_SUCCESS: 'ORDER_PLACEMENT_SUCCESS',
  ORDER_PLACEMENT_FAILURE: 'ORDER_PLACEMENT_FAILURE',

  CANCEL_ORDER_REQUEST: 'CANCEL_ORDER_REQUEST',
  CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_FAILURE: 'CANCEL_ORDER_FAILURE',

  ORDER_PAYMENT_REQUEST: 'ORDER_PAYMENT_REQUEST',
  ORDER_PAYMENT_SUCCESS: 'ORDER_PAYMENT_SUCCESS',
  ORDER_PAYMENT_FAILURE: 'ORDER_PAYMENT_FAILURE',

  UPDATE_PAYMENT_STATUS_REQUEST: 'UPDATE_PAYMENT_STATUS_REQUEST',
  UPDATE_PAYMENT_STATUS_SUCCESS: 'UPDATE_PAYMENT_STATUS_SUCCESS',
  UPDATE_PAYMENT_STATUS_FAILURE: 'UPDATE_PAYMENT_STATUS_FAILURE',

  APPLY_PROMO_REQUEST: 'APPLY_PROMO_REQUEST',
  APPLY_PROMO_SUCCESS: 'APPLY_PROMO_SUCCESS',
  APPLY_PROMO_FAILURE: 'APPLY_PROMO_FAILURE',
  REMOVE_PROMO:"REMOVE_PROMO" ,
  
};
