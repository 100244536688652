import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SubHeader from "../../Components/Header/SubHeader";
import ScrollableFoodList from "./ScrollableFoodList";
import ScrollableRecipeList from "./ScrollableRecipeList";
import FoodItemBanner from "../../Components/Banner/FoodItemBanner";
import PhoneImageBanner from "./PhoneImageBanner";
import PhoneFoodDetailBanner from "./PhoneFoodDetailBanner";
import PhoneRecipeSection from "./PhoneRecipeSection";
import PhoneNutritionalFacts from "./PhoneNutritionalFacts";
import PhoneFoodList from "./PhoneFoodList";
import SideNav from "../../Components/SideNav/SideNav";
import CustomizationSlider from "./CustomizationSlider";
import {
  menuActions,
  orderActions,
  cartActions,
  modalActions,
} from "../../Redux/Actions";
import Modal from "../../Components/Modal/Modal";
import { useHistory } from "react-router-dom";
import {
  addAccordingToType,
  removeFromCart,
} from "../../Functions/CartFunctions";
import { cloneDeep } from "lodash";
import { findIsDefaultCombo, getProcessObj, getSelectionCopy, handleAddCustomizationCopy, storeClosedOrNot } from "../../Functions/helpersFunctions";
import { calculeteNoOfDays } from "../../Functions/GetTime";

export default function FoodDetail() {
  const { foodId, orderType, restaurantId } = useParams();
  const { cart, orderInfo, menu, customization, store, client } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const history = useHistory();

  // Food items shown in suggestions sections
  const [suggestedFoodItems, setSuggestedFoodItems] = useState({});

  const [itemCount, setItemCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // Open and closes the slider for customization
  const [isCustomizationsVisible, setIsCustomizationsVisible] = useState(false);
  // Category user will land on after clicking "View Cart" button (only in desktop version)
  const defaultCategory = menu.menu[0]?.categoryId;

  useEffect(() => {
    window.scrollTo(0, 0);
    setSuggestedFoodItems(menu.products);
  }, [menu.products]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [foodId]);

  //  Useeffect prevent from loading any invalid urls
  // Eg: user wants to open bulk/schedule food detail page
  // but restaurant does not support them
  // user will be redirected to home page
  useEffect(() => {
    if (orderType === "bulk" && !store.bulkOrdering) {
      history.push(`/${restaurantId}/home`);
    }
    if (orderType === "schedule" && !store.mealSubscription) {
      history.push(`/${restaurantId}/home`);
    }
  }, [store, orderType]);

  useEffect(() => {
    // fetches count from cart of the specific food item
    let count = 0;
    if (orderType !== orderInfo.orderType) {
      setItemCount(0);
      return;
    }
    cart.forEach((item, index) => {
      if (item.productId === Number(foodId)) {
        count = count + item.quantity;
      }
    });
    setItemCount(count);
  }, [cart, orderType]);

  const foodItem = menu.products[foodId];

  const addToCart = () => {
    // If cart length empty
    if (storeClosedOrNot(orderInfo, store)) return;
    if (cart.length === 0) {
      // Update Order type in store
      // dispatch(
      //   orderActions.updateOrderStore({
      //     orderType: orderType,
			// 		noOfDays:
			// 		orderType === "schedule"
			// 			? calculeteNoOfDays(
			// 				new Date(orderInfo.startDate),
			// 				new Date(orderInfo.endDate),
			// 				orderInfo.frequencyDays
			// 			)
			// 			: 1,
      //     deliverNow:
      //       orderType === "schedule" ||
      //       (orderType === "regular" && orderInfo.type == 'later')
      //         ? false
      //         : true,
      //   })
      // );
    }
    // If cart not empty, check the order type
    else {
      // if order type is not same, then show warning modal
      // modal says -> your order type is different and will clear cart
			const localStoreOrdertype = JSON.parse(localStorage.getItem('orderInfo')).orderType
      if (orderType !== localStoreOrdertype) {
      // if (orderType !== orderInfo.orderType) {
        // Activate Modal
        // If user does not changes type, then do not add item to cart
        // If user changes type
        // 1. Empty Cart
        // 2. Update Order type
        // 3. Add Item to cart
        setIsOpen(true);
        return;
      }
    }
    if (foodItem.productType === "COMBO") {
      if (menu.products[foodId].variants.length === 1 && findIsDefaultCombo(foodItem)) {
        const selection = getSelectionCopy(foodItem, menu.products)
        const appliedCustomization = {}
        const removedProcessList = {}
        addAccordingToType(
          menu.products,
          foodId,
          customization,
          orderType,
          itemCount,
          setItemCount,
          dispatch,
          orderInfo,
          menu.products[foodId].name,
          menu.products[foodId].selectedVariant,
          handleAddCustomizationCopy(selection, menu.products[foodId],appliedCustomization, removedProcessList, menu.products, getProcessObj),
          1
        );
			} else dispatch(modalActions.toggleComboModal(true, { productId : foodId}));
      return;
    }else if(foodItem.isCustomizable){
      setIsCustomizationsVisible(true)
      return
    }
    // Add item to cart
    addAccordingToType(
      menu.products,
      foodId,
      customization,
      orderType,
      itemCount,
      setItemCount,
      dispatch,
      orderInfo
    );
  };

  const onRemove = () => {
    removeFromCart(
      menu.products,
      foodId,
      orderType,
      itemCount,
      setItemCount,
      dispatch
    );
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const submitModal = () => {
    // 1. Empty Cart
    dispatch(cartActions.clearCartStore());
    // 2. Update Order type -> store and local storage
    // debugger
    dispatch(
      orderActions.updateOrderStore({
        orderType: orderType,
        noOfDays:
          orderType === "schedule"
            ? calculeteNoOfDays(
                new Date(orderInfo.startDate),
                new Date(orderInfo.endDate),
                orderInfo.frequencyDays
              ) : 1,
        deliverNow: orderType == "schedule" ? false : true,
      })
    );
    // 3. Add Item to cart
    if (foodItem.productType === "PRODUCT") {
      addAccordingToType(
        menu.products,
        foodId,
        customization,
        orderType,
        itemCount,
        setItemCount,
        dispatch,
        orderInfo,
        foodItem.productname,
        foodItem.selectedVariant
      );
    } else if (foodItem.productType === "COMBO") {
      dispatch(modalActions.toggleComboModal(true, { productId: foodId }));
    }
    setIsOpen(false);
  };

  // Handles the size change in food item
  const onSizeChange = (variant, productId) => {
    let tempMenu = cloneDeep(menu);
    // debugger
    tempMenu.products[productId].selectedVariant = { ...variant };
    dispatch(menuActions.updateMenu(tempMenu));
  };

  return (
    menu.menu.length > 0 && (
      <div className="mb-8">
        {/* Phone View */}
        <div className="relative block lg:hidden">
          <PhoneImageBanner
            image={foodItem.image}
            isVeg={foodItem.isVeg}
            name={foodItem.name}
          />
          {/* Phone nutritionalFacts section */}
          {foodItem.selectedVariant.nutritionalFactsAvailable && (
            <PhoneNutritionalFacts
              nutritionalFacts={foodItem.selectedVariant.nutritionalFacts}
            />
          )}
          <div className="bg-F5F5F5 bg-no-repeat px-4 md:px-0 py-6 space-y-6">
            {/* Phone Food banner*/}
            <PhoneFoodDetailBanner
            clientType={client?.clientType}
              displayVariants={foodItem.displayVariants}
              isQCEnabled={foodItem.isQCEnabled}
              qcData={foodItem.qcData}
              productId={foodItem.productId}
              productType={foodItem.productType}
              image={foodItem.image}
              isVeg={foodItem.isVeg}
              name={foodItem.name}
              cookingTime={foodItem.cookingTime}
              variants={foodItem.variants}
              description={foodItem.description}
              discount={foodItem.discount}
              selectedVariant={foodItem.selectedVariant}
              foodItem={foodItem}
              changeSizeHandler={onSizeChange}
              type={orderType}
            />
            {foodItem.isCustomizable && foodItem.productType !== "COMBO" && (
              <div className="px-8">
                <div
                  className="bg-FFFFFF bg-no-repeat rounded border border-8593FF font-open-sans font-semibold text-center text-sm text-8593FF mx-auto max-w-sm py-5 hover:bg-8593FF hover:text-FFFFFF cursor-pointer"
                  onClick={() => setIsCustomizationsVisible(true)}
                >
                  Customize Recipe
                </div>
              </div>
            )}
            {foodItem.recipe != null &&
              Object.keys(foodItem.recipe).length !== 0 && (
                <PhoneRecipeSection foodItem={foodItem} />
              )}

            {/* Phone Suggestions List */}
            <PhoneFoodList
              // this category id will help to get same category food cards in similar results section
              categoryId={foodItem.categoryId}
              productId={foodItem.productId}
              foodItems={suggestedFoodItems}
              orderType={orderType}
            />
          </div>
          <div className="fixed bottom-0 left-0 right-0 flex justify-between items-center bg-121216 bg-no-repeat font-open-sans h-20 w-full z-20 p-5 md:px-10">
            <div className="flex flex-col">
              <div className="font-semibold text-sm text-FFFFFF capitalize">
                {foodItem.name}
              </div>
              <div>
                <div className="inline-block text-center text-xs text-FFFFFF capitalize">
                  1 Item |
                </div>
                <div className="inline-block font-bold text-center text-xs text-FFFFFF capitalize">
                  Rs.{" "}
                  {foodItem.selectedVariant.discountedPrice
                    ? foodItem.selectedVariant.discountedPrice
                    : foodItem.selectedVariant.price}
                </div>
              </div>
            </div>
            {itemCount ? (
              <div className="flex space-x-2 items-center">
                <Link to={`/${restaurantId}/my-order`}>
                  <div className="bg-8593FF bg-no-repeat border border-8593FF rounded font-semibold text-sm text-FFFFFF capitalize py-2 px-2 cursor-pointer">
                    View Cart
                  </div>
                </Link>
                <div className="bg-8593FF bg-no-repeat border border-8593FF rounded text-center font-semibold font-open-sans  cursor-pointer py-0.5">
                  <div className="flex items-center justify-between py-1.5 px-2 space-x-3">
                    <div
                      className="text-black bg-FFFFFF rounded-full text-sm px-2 cursor-pointer"
                      onClick={onRemove}
                    >
                      -
                    </div>
                    <div className="italic text-sm text-FFFFFF capitalize ">
                      {itemCount}
                    </div>
                    <div
                      className="text-black bg-FFFFFF rounded-full text-sm px-1.5 cursor-pointer"
                      onClick={addToCart}
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>
            ) : orderType === "bulk" ? (
              <div
                className="bg-8593FF bg-no-repeat border border-8593FF rounded font-semibold text-sm text-center text-FFFFFF capitalize py-2 px-4 cursor-pointer"
                onClick={addToCart}
              >
                <div className="font-semibold text-xs text-FFFFFF capitalize ">
                  Add Item
                </div>
                <div className="text-2xs text-FFFFFF ">
                  min order: 10 quantity
                </div>
              </div>
            ) : (
              foodItem.isProductAvailable &&
              !storeClosedOrNot(orderInfo, store) && (
                <div
                  className={
                    "bg-8593FF bg-no-repeat border border-8593FF rounded font-semibold text-sm text-FFFFFF capitalize py-2 px-4 cursor-pointer"
                  }
                  onClick={addToCart}
                >
                  Add item{" "}
                </div>
              )
            )}
          </div>
        </div>
        {/* Desktop View */}
        <div className="hidden lg:block space-y-6">
          <SubHeader title={foodItem.name} />
          {/* Food Banner Section */}
          <FoodItemBanner
            displayVariants={foodItem.displayVariants}
            isQCEnabled={foodItem.isQCEnabled}
            productAvailable={foodItem.isProductAvailable}
            qcData={foodItem.qcData}
            orderType={orderType}
            productId={foodItem.productId}
            productType={foodItem.productType}
            image={foodItem.image}
            isVeg={foodItem.isVeg}
            name={foodItem.name}
            cookingTime={foodItem.cookingTime}
            variants={foodItem.variants}
            description={foodItem.description}
            selectedVariant={foodItem.selectedVariant}
            isCustomizable={foodItem.isCustomizable}
            discount={foodItem.discount}
            foodItem={foodItem}
            changeSizeHandler={onSizeChange}
            openCustomization={() => setIsCustomizationsVisible(true)}
            type={orderType}
            foodItemNotAvailableDueToTime={
              foodItem.foodItemNotAvailableDueToTime
            }
            foodItemNotAvailableDueToDate={
              foodItem.foodItemNotAvailableDueToDate
            }
            subscriptionFrom={foodItem.subscriptionFrom}
            subscriptionTo={foodItem.subscriptionTo}
          />
          {/* Recipe Section */}
          {foodItem.recipe != null &&
            Object.keys(foodItem.recipe).length !== 0 && (
              <ScrollableRecipeList foodItem={foodItem} />
            )}
          <ScrollableFoodList
            // this category id will help to get same category food cards in similar results section
            categoryId={foodItem.categoryId}
            productId={foodItem.productId}
            foodItems={suggestedFoodItems}
            orderType={orderType}
          />
          <div
            className={`hidden ${
              itemCount > 0 ? "opacity-100" : "opacity-0"
            } transition-opacity duration-500`}
          >
            <div className="fixed bottom-0 left-0 right-0 bg-transparent px-20 xl:px-24 2xl:px-28 2xl:z-20">
              <div className="flex justify-between items-center p-4 bg-121216 bg-no-repeat font-open-sans rounded-t-md">
                <div className="flex flex-col">
                  <div className="font-semibold text-base text-FFFFFF capitalize">
                    {foodItem.name}
                  </div>
                  <div>
                    <div className="inline-block text-center text-sm text-FFFFFF capitalize">
                      1 Item |
                    </div>
                    <div className="inline-block font-bold text-center text-sm text-FFFFFF capitalize">
                      Rs.{" "}
                      {foodItem.selectedVariant.discountedPrice
                        ? foodItem.selectedVariant.discountedPrice
                        : foodItem.selectedVariant.price}
                    </div>
                  </div>
                </div>
                <Link
                  to={`/${restaurantId}/menu-card/${
                    orderInfo.orderType === "bulk" ||
                    orderInfo.orderType === "schedule"
                      ? orderInfo.orderType + "/" + orderInfo.orderType
                      : "regular/all"
                  }/${defaultCategory}`}
                >
                  <div className="bg-8593FF bg-no-repeat border border-8593FF rounded font-semibold text-sm text-FFFFFF capitalize py-2 px-4 cursor-pointer">
                    View Cart
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Customizaton Slider */}
        <SideNav
          onClose={() => setIsCustomizationsVisible(false)}
          visible={isCustomizationsVisible}
          closable={false}
          mode="light"
        >
          {isCustomizationsVisible && (
            <CustomizationSlider
              type="SAVE"
              image={foodItem.image}
              name={foodItem.name}
              isVeg={foodItem.isVeg}
              selectedVariant={foodItem.selectedVariant}
              variants={foodItem.variants}
              customization={foodItem.customisation}
              product={foodItem}
              handleClose={() => {
                setIsCustomizationsVisible(false);
                document.body.classList.remove("stop-scrolling");
              }}
              orderType={orderType}
              changeSizeHandler={onSizeChange}
            />
          )}
        </SideNav>
        <Modal
          visible={isOpen}
          title={`Do you want change your order type from ${orderInfo.orderType} to ${orderType} category`}
          text="This will delete your previous stored cart"
          submit={submitModal}
          close={closeModal}
          closingText="Cancel"
          submittingText="Delete"
        />
      </div>
    )
  );
}
