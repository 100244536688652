import React, { useEffect, useState } from "react";
import SubHeader from "../../Components/Header/SubHeader";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions, orderActions } from "../../Redux/Actions";
import OrderCard from "../../Components/Cards/OrderCard/OrderCard";
import { useHistory } from "react-router-dom";
import { subscriptionActions } from "../../Redux/Actions";
import ScheduledOrderCard from "../../Components/Cards/ScheduledOrderCard/ScheduledOrderCard";
import toast from "react-hot-toast";
const unSelected = "text-gray-400 py-2 text-sm";
const selected = "border-b-4 border-8593FF text-8593FF text-sm";

const orderTypeMap = {
  current: "activeOrders",
  cancelled: "cancelledOrders",
  past: "deliveredOrders",
  scheduled: "scheduledOrders",
};

export default function MyOrders() {
  const dispatch = useDispatch();
  const { type } = useParams();
  const { orderInfo, orderTrack, subscription } = useSelector((state) => state);
  // const history = useHistory();

  useEffect(() => {
    dispatch(orderActions.getPastOrders());
    if (type === "scheduled") {
      dispatch(subscriptionActions.getPastSubscription());
    }
  }, [type]);

  let subscriptionDOM = [];
  let ordersDOM = {
    activeOrders: {},
    deliveredOrders: {},
    cancelledOrders: {},
  };

  const handleCancelOrder = (orderId) => {
    dispatch(
      orderActions.cancelOrder({
        orderDetails: {
          orderId,
        },
        showSnackbars: false,
        showSnackbars: false,
      })
    );
  };

  if (type === "scheduled") {
    subscriptionDOM = subscription.pastOrders.map((currentOrder, idx) => {
      return (
        <div className="my-3">
          <ScheduledOrderCard order={currentOrder} index={idx} />
        </div>
      );
    });
  } else {
    ordersDOM = Object.keys(orderTrack.pastOrders[orderTypeMap[type]] ?? {})
      .reverse()
      .map((orderId) => {
        const order = orderTrack.pastOrders[orderTypeMap[type]][orderId];
        return (
          <div className="my-3">
            <OrderCard
              order={order}
              type={type}
              cancelOrder={handleCancelOrder}
            />
          </div>
        );
      });
  }

  if (orderInfo.storeId) {
    var linkadd = `/${orderInfo.storeId}/home`;
  } else {
    var linkadd = `/home`;
  };

  return (
    <div>
      <div className="hidden lg:block">
        <SubHeader title="My Orders" link={linkadd} />
      </div>

      <nav className="cssflex px-4 sm:px-10 bg-white border-b border-gray-400 font-open-sans space-x-6 sm:space-x-8 pt-6 mt-4 md:mt-32 lg:mt-4">
        <NavLink
          activeClassName={selected}
          className={unSelected}
          to={`/my-orders/current`}
        >
          Current
        </NavLink>
        <NavLink
          activeClassName={selected}
          className={unSelected}
          to={`/my-orders/past`}
        >
          Past
        </NavLink>
        <NavLink
          activeClassName={selected}
          className={unSelected}
          to={`/my-orders/cancelled`}
        >
          Cancelled
        </NavLink>
        <NavLink
          activeClassName={selected}
          className={unSelected}
          to={`/my-orders/scheduled`}
        >
          Subscriptions
        </NavLink>
      </nav>
      <div
        className="items-center justify-center sm:mt-6 px-6 sm:px-12 sm:py-4 bg-white"
        style={{
          minHeight: "calc(100vh - 450px)",
        }}
      >
        {type != "scheduled" && orderTrack?.isLoading && (
          <p className="no-orders-text">Loading, Please wait.</p>
        )}
        {type != "scheduled" &&
          !orderTrack?.isLoading &&
          (ordersDOM.length > 0 ? (
            ordersDOM
          ) : (
            <p className="no-orders-text">No orders to display</p>
          ))}
        {type === "scheduled" &&
          (subscriptionDOM.length > 0 ? (
            subscriptionDOM
          ) : (
            <p className="no-orders-text">No orders to display</p>
          ))}
      </div>
    </div>
  );
}
