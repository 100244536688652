import React from 'react'
import { Link, useParams } from 'react-router-dom'

export default function PhoneMenuCard({ image, heading, para, link }) {
    const { restaurantId } = useParams()

    return (
        <Link to={`/${restaurantId}/menu-card${link}`}>
            <div className="h-full bg-white  p-2 rounded-md  shadow-sm flex items-center justify-center ">
                <div>
                    <img className="w-auto h-12 m-auto" src={image} alt="menu-icon" />
                    <div className="text-xs font-open-sans mt-2 font-normal text-center md:text-xs ">{heading}</div>
                </div>
            </div>
        </Link>
    )
}
