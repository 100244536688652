import React from "react";
import { useSelector } from "react-redux";
import OrderDetailsDone from "../../Assets/Icons/OrderDetailsDone.svg";
import CookingDetails from "../../Assets/Icons/CookingDetails.svg";
import CookingDetailsDone from "../../Assets/Icons/CookingDetailsDone.svg";
import QualityCheck from "../../Assets/Icons/QualityCheck.svg";
import QualityCheckDone from "../../Assets/Icons/QualityCheckDone.svg";
import OrderDispatch from "../../Assets/Icons/OrderDispatch.svg";
import OrderDispatchDone from "../../Assets/Icons/OrderDispatchDone.svg";
import { useParams } from "react-router-dom";

//  !IMPORTANT : This array must contain only available stages, all isDisabled: true should be not sent
// ! this array can be passed as props or stores in redux store
// ! this array should be updated once get details of supported stages by restaurant
const stages = {
  100: {
    stageName: "Order Accepted",
    stageId: 100,
    stageImages: {
      completedImage: OrderDetailsDone,
      pendingImage: OrderDetailsDone,
    },
    isDisabled: false,
  },
  200: {
    stageName: "Cooking",
    stageId: 200,
    stageImages: {
      completedImage: CookingDetailsDone,
      pendingImage: CookingDetails,
    },
    isDisabled: false,
  },
  300: {
    stageName: "Quality Check",
    stageId: 300,
    stageImages: {
      completedImage: QualityCheckDone,
      pendingImage: QualityCheck,
    },
    isDisabled: false,
  },
  500: {
    stageName: "Order Dispatch",
    stageId: 500,
    stageImages: {
      completedImage: OrderDispatchDone,
      pendingImage: OrderDispatch,
    },
    isDisabled: false,
  },
  // 500: {
  //   stageName: "Delivered",
  //   stageId: 500,
  //   stageImages: {
  //     completedImage: "",
  //     pendingImage: "",
  //   },
  //   isDisabled: true,
  // },
};

export default function TrackingStatus({ type }) {
  const orderTrack = useSelector((state) => state.orderTrack);
  const { foodItemSlug } = useParams();

  return (
    type === "ITEM_LEVEL" ? orderTrack.items[foodItemSlug] : orderTrack
  ) ? (
    <div
      className={`${
        type === "ITEM_LEVEL"
          ? "py-8 xl:py-9 px-4 sm:px-10 xl:px-16 2xl:px-20"
          : ""
      } text-center font-open-sans bg-FFFFFF`}>
      <div className='flex justify-around'>
        {Object.keys(stages).map((stageId, index) => {
          return (
            !stages[stageId].isDisabled && (
              <div className='flex w-full relative'>
                <div className='flex flex-col space-y-1'>
                  <div className='relative z-10  w-8 h-8 sm:w-8 sm:h-8 mx-auto'>
                    <img
                      src={
                        (
                          type === "ITEM_LEVEL"
                            ? orderTrack.items[foodItemSlug].statusCode >=
                              stages[stageId].stageId
                            : orderTrack.orderStatus >= stages[stageId].stageId
                        )
                          ? stages[stageId].stageImages.completedImage
                          : stages[stageId].stageImages.pendingImage
                      }
                      alt='progress'
                      className='h-full w-full'
                    />
                  </div>

                  <div
                    className={`font-semibold ${
                      type === "ITEM_LEVEL"
                        ? "text-2xs sm:text-sm xl:text-lg"
                        : "text-sm"
                    } text-2F2F2F`}>
                    {stages[stageId].stageName}
                  </div>
                  {/* <div
                    className={`${
                      type === "ITEM_LEVEL"
                        ? "text-2xs sm:text-xs xl:text-sm"
                        : "text-xs"
                    } text-8B8B8B`}>
                    10:45pm
                  </div> */}
                </div>
                {index !== Object.keys(stages).length - 1 && (
                  <div
                    className={`absolute ${
                      type === "ITEM_LEVEL"
                        ? "top-4 -right-10 sm:-right-16"
                        : "top-4 -right-10 sm:-right-16 lg:-right-12 xl:-right-14 2xl:-right-16"
                    }  w-full border-b-2 border-dashed border-gray-300`}></div>
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  ) : null;
}
