import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

const inputs = {
  input1: "",
  input2: "",
  input3: "",
  input4: "",
};

const stateValue = (otpType, empty) => {
  const newObj = {};
  if (otpType) {
    Object.keys(inputs).forEach((key) => {
      newObj[key + otpType] = !empty ? inputs[key] : "";
    });
    return { ...newObj };
  } else {
    return { ...inputs };
  }
};

const OtpCard = ({
  checkOtpHandler,
  otpType,
  inputId,
  btnText = "",
  shouldEmpty = false,
  hideConfirm = false,
}) => {
  const [inputName, setInputName] = useState(stateValue(otpType));

  useEffect(() => {
    if(shouldEmpty){
      setInputName(stateValue(otpType, shouldEmpty))
    }
  }, [shouldEmpty, otpType])

  useEffect(() => {
    const allValues = Object.keys(inputName).every(
      (eve) => inputName[eve] !== ""
    );
    if (allValues && hideConfirm) {
      let pin = "";

      inputName &&
        Object.keys(inputName).forEach((key) => {
          pin += inputName[key];
        });

      checkOtpHandler(pin);
    }
  }, [checkOtpHandler, hideConfirm, inputName, otpType]);

  const focusHandler = (current, type) => {
    const keys = Object.keys(inputName);
    const nextItem = document.getElementsByName(
      keys[keys.indexOf(current) + 1]
    )[0];
    const prevItem = document.getElementsByName(
      keys[keys.indexOf(current) - 1]
    )[0];
    if (type === "next" && nextItem) {
      nextItem.focus();
    }
    if (type === "prev" && prevItem) {
      prevItem.focus();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.length === 1) {
      setInputName({
        ...inputName,
        [name]: value,
      });
      focusHandler(name, "next");
    }
    if (value.length === 0) {
      setInputName({
        ...inputName,
        [name]: value,
      });
      focusHandler(name, "prev");
    }
  };

  const submitOtp = () => {
    let otp = "";

    inputName &&
      Object.keys(inputName).forEach((key) => {
        otp += inputName[key];
      });

    // debugger
    if (otp.length === 4) {
      checkOtpHandler(otp, otpType.toLowerCase());
    } else {
      toast.error("OTP not correct");
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-2 justify-center">
        {inputName &&
          Object.keys(inputName).map((name, idx) => (
            <input
              className="w-10 h-10 md:w-12 md:h-12 lg:w-10 lg:h-10 2xl:w-14 2xl:h-14 border border-8593FF rounded-sm text-center text-lg  focus:border-indigo-400"
              type="number"
              name={name}
              value={inputName[name]}
              onChange={(e) => {
                if(inputName[name] !== ''){
                  setInputName({
                    ...inputName,
                    [name]: '',
                  });
                }
                handleChange(e);
              }}
            />
          ))}
      </div>
      {!hideConfirm && (
        <div
          className="bg-8593FF bg-no-repeat rounded py-4 font-semibold text-center text-white text-base cursor-pointer hover:bg-indigo-500"
          onClick={submitOtp}
        >
          {btnText ? btnText : `Confirm ${otpType} OTP/PIN`}
        </div>
      )}
    </div>
  );
};

export default OtpCard;
