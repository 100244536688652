import { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { isObject, random } from 'lodash';
import './Maps.css';
function deepEqual(object1, object2) {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if (
			(areObjects && !deepEqual(val1, val2)) ||
			(!areObjects && val1 !== val2)
		) {
			return false;
		}
	}

	return true;
}

const getLatLng = (map) => {
	const currLocation = map.getCenter();
	const lat = currLocation?.lat();
	const lng = currLocation?.lng();
	return {
		lat,
		lng,
	};
};
const Maps = (props) => {
	const { width, height, handleMarkerDrag, coords, mapId } = props;
	const [map, setMap] = useState(null);
	const [googleObj, setGoogleObj] = useState(null);
	function initMap(google) {
		const marker = new google.maps.Marker({
			position: coords,
			map,
			title: '',
		});

		map.addListener('dragend', () => {
			handleMarkerDrag(getLatLng(map));
		});
		map.addListener('center_changed', () => {
			const pos = getLatLng(map);
			marker.setPosition(pos);
		});
		const defaultCoords = {
			lat: 28.6523392,
			lng: 77.185024,
		};
		if (navigator.geolocation && deepEqual(defaultCoords, coords)) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};
					marker.setPosition(pos);
					map.setCenter(pos);
					handleMarkerDrag(getLatLng(map));
				},
				() => {
				}
			);
		} else handleMarkerDrag(getLatLng(map));
	}

	useEffect(() => {
		const loader = new Loader({
			apiKey: 'AIzaSyDSFW1Q6S3ghhiuDbhCLgaFcVuSfGr05GQ',
			version: 'weekly',
			libraries: ['places'],
		});
		loader
			.load()
			.then((google) => {
				setMap(
					new google.maps.Map(document.getElementById(mapId), {
						center: coords,
						zoom: 14,
						draggable: true,
					})
				);
				setGoogleObj(google);
				// initMap(google);
			})
			.catch((e) => {
			});
	}, []);
	useEffect(() => {
		if (googleObj) {
			initMap(googleObj);
		}
	}, [googleObj]);
	useEffect(() => {
		if (map && window.google) {
			map.setCenter(coords);
		}
	}, [coords]);

	return <div id={mapId} className='map' style={{ width, height }}></div>;
};

Maps.defaultProps = {
	width: 300,
	height: 300,
	coords: {
		lat: 28.6523392,
		lng: 77.185024,
	},
	mapId: 'map',
};

export default Maps;
