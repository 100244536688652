import React from 'react';
import Star from '../../Components/Star/Star';

export default function RatingCard({
	itemName,
	tasteRating,
	packageRating,
	ItemImage,
	location,
	qualityRating,
	setQualityRating,
	setTasteRating,
	setPackageRating,
    handleChange
}) {
	return (
		<div className='bg-white p-5 font-open-sans rounded'>
			<div className='flex'>
				<div className='flex-grow'>
					<div className='flex flex-col space-y-4'>
						<div className='font-semibold text-sm'>{itemName}</div>
						<div className='text-xs'>Taste</div>
						<div className='flex space-x-8'>
							<Star
								number={tasteRating}
								onSelect={(number) => setTasteRating(number)}
								handleChange={(number)=>handleChange(number, 'taste')}
							/>
						</div>
						<div className='text-xs'>Quality</div>
						<div className='flex space-x-8'>
							<Star
								number={qualityRating}
								onSelect={(number) => setQualityRating(number)}
								handleChange={(number)=>handleChange(number, 'quality')}
							/>
						</div>
						<div className='text-xs'>Packaging</div>
						<div className='flex space-x-8'>
							<Star
								number={packageRating}
								onSelect={(number) => setPackageRating(number)}
								handleChange={(number)=>handleChange(number, 'packaging')}
							/>
						</div>
					</div>
				</div>
				<div>
					<img
						src={ItemImage}
						className='w-12 h-12 rounded  sm:w-32 sm:h-32'
						alt='item'
					/>
				</div>
			</div>
		</div>
	);
}
