import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import SubHeader from "../../Components/Header/SubHeader";
import MyOrderCard from "../../Components/Cards/MyOrderCard/MyOrderCard";
import filter from "../../Assets/Icons/filter.svg";
import SideNav from "../../Components/SideNav/SideNav";
import Filters from "./Filters";
import SpiralBorder from "../../Assets/Icons/SpiralCover.svg";
import EmptyOrder from "./EmptyOrder";
import ScheduleMeal from "./ScheduleMeal";
import BulkMenuSideNav from "./BulkMenuSideNav";
import Modal from "../../Components/Modal/Modal";
import AddInstructions from "./AddInstructions";
import toast from "react-hot-toast";
import styles from "./MenuCard.module.css";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  menuActions,
  modalActions,
  authActions,
  notificationActions,
  orderActions,
  storeActions,
} from "../../Redux/Actions";
import {
  isProductAvailable,
  storeClosedOrNot,
} from "../../Functions/helpersFunctions";
import FinalFoodCard from "../../Components/Cards/FoodCard/FinalFoodCard";
import { Filter } from "../../Functions/filterFunctions";
import ScrollToTop from "../../Components/Button/FloatingOnScrollBtn";
import CustomizationSlider from "../FoodDetail/CustomizationSlider";
import { cloneDeep } from "lodash";
import { useLocation, useParams } from "react-router";
import { calculeteNoOfDays, formatddmmyyyy } from "../../Functions/GetTime";
import moment from "moment";
import {
  isOrderAllowed,
  useAccess,
  useWindowDimensions,
} from "../../Functions/utils";
import {
  clientService,
  storeService,
  subscriptionService,
} from "../../Redux/Services";
import ChangeLocationModal from "../../Components/Change Location Modal/ChangeLocationModal";
import SubscriptionModal from "../../Components/SubscriptionModal/SubscriptionModal";

const filters = [
  {
    name: "Filters",
    type: "multiple",
    filtersOptions: [
      {
        name: "Must Try",
        selected: true,
      },
      {
        name: "rating 4.0",
        selected: false,
      },
      {
        name: "offers",
        selected: false,
      },
      {
        name: "under 300 calories",
        selected: false,
      },
      {
        name: "under 500 calories",
        selected: false,
      },
      {
        name: "Fast cooking",
        selected: false,
      },
    ],
  },
  {
    name: "Spice Level",
    tagName: "spice-level",
    type: "single",
    filtersOptions: [
      {
        name: "low",
        selected: false,
        id: 0,
      },
      {
        name: "mid",
        selected: false,
        id: 1,
      },
      {
        name: "high",
        selected: false,
        id: 2,
      },
    ],
  },
  {
    name: "Price",
    tagName: "price",
    type: "single",

    filtersOptions: [
      {
        name: "high to low",
        selected: false,
        id: 0,
      },
      {
        name: "low to high",
        selected: false,
        id: 1,
      },
    ],
  },
];

const DEFAULT_COORDS = {
  lat: 28.6523392,
  lng: 77.185024,
};
export default function MenuCard(props) {
  const [modalState, setModalState] = useState({
    show: false,
    data: [],
    loading: false,
    subCategoryName: "",
  });
  const {
    match: {
      params: { orderType, category, type, restaurantId },
    },
    location: { search },
    query,
    showSearch,
  } = props;
  useEffect(() => {
    return () => {
      if (props.setShowSearch) {
        props.setShowSearch(false);
      }
    };
  }, []);
  const { cart, menu, auth, orderInfo, store, modal, client } = useSelector(
    (state) => state
  );

  const { showChangeLocationModal } = modal;

  const localStorageAuth = JSON.parse(localStorage.getItem("auth"));

  const params = useParams();

  const access = useAccess(params);

  const { bypass = true } = client;

  const { deliverNow } = orderInfo;
  const dispatch = useDispatch();
  const history = useHistory();

  const [isVisible, setIsVisible] = useState(false);
  const [isBulkMenuVisible, setIsBulkMenuVisible] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterstate, setFilterstate] = useState([
    ...JSON.parse(JSON.stringify(filters)),
  ]);
  const [customizationType, setCustomisationType] = useState("edit");
  const [errorModal, setErrorModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [filterRoute, setFilterRoute] = useState({});
  const [filterCount, setFilterCount] = useState(0);

  // Open and closes the slider for customization
  const [isCustomizationsVisible, setIsCustomizationsVisible] = useState(false);
  const [selectFoodItem, setSelectFoodItem] = useState({
    foodItem: {},
    appliedCustomization: {},
    index: 0,
  });

  //  Useeffect prevent from loading any invalid urls
  // Eg: user wants to open bulk/schedule menu card options
  // but restaurant does not support them
  // user will be redirected to home page

  useEffect(() => {
    if (auth?.redirectTo) {
      history.push(auth?.redirectTo);
    }
  }, [auth?.redirectTo]);

  useEffect(() => {
    if (!showSearch) {
      if (orderType === "bulk" && !store.bulkOrdering) {
        history.push(`/${restaurantId}/home`);
      }
      if (orderType === "schedule" && !store.mealSubscription) {
        history.push(`/${restaurantId}/home`);
      }
    }
  }, [store, orderType]);

  useEffect(() => {
    if (!showSearch) {
      if (orderInfo.orderType == "schedule" && orderType != "schedule") {
        menuActions.getMenu(restaurantId, {
          ...orderInfo,
          orderType: "regular",
          deliverNow: true,
        });
      }
    }
  }, [type]);

  useEffect(() => {
    if (!type || auth?.redirectTo?.includes(type)) {
      dispatch(authActions.redirect(null));
    }
    if (!showSearch) {
      if (!menu.menu.length) return;
      let selectedMenu;
      menu.menu.find((category) => {
        if (category.categoryId === Number(type)) {
          selectedMenu = category;
        }
      });
      let selectedMenuTag;
      if (category === "all") {
        setSelectedCategory(selectedMenu);
        applyFilters(selectedMenu);
        return;
      } else if (category === "kids") selectedMenuTag = "Kids Menu";
      else if (category === "healthy") selectedMenuTag = "Healthy Menu";
      else if (category === "bulk") selectedMenuTag = "Bulk Order Menu";
      else if (category === "schedule") selectedMenuTag = "Subscription Menu";

      let updatedSelectedMenu =
        selectedMenu && JSON.parse(JSON.stringify(selectedMenu));

      if (category !== "schedule") {
        selectedMenu.subCategory.forEach((subcategory, index) => {
          updatedSelectedMenu.subCategory[index].products =
            subcategory.products.filter((productId) => {
              let foodItem = menu.products[productId];
              if (foodItem.menuTags.includes(selectedMenuTag)) {
                return productId;
              }
            });
        });
      } else {
        selectedMenu?.subCategory?.forEach((subcategory, index) => {
          updatedSelectedMenu.subCategory[index].products =
            subcategory?.products?.filter((productId) => {
              let foodItem = menu?.products[productId];
              if (foodItem?.menuTags?.includes(selectedMenuTag)) {
                return productId;
              }
            });
        });
      }

      // Categories available in restaurant, and selected one
      setSelectedCategory({ ...updatedSelectedMenu });
      applyFilters(updatedSelectedMenu);
      // menu comes empty, to update componenet
    }
  }, [category, dispatch, menu.menu, menu.products, type]);

  // Auto Scroll Menus
  useEffect(() => {
    if (!showSearch) {
      const navMenu = document.getElementsByClassName("nav-menu")?.[0];
      if (navMenu) {
        navMenu.scrollLeft = 0;
      }
      const subNavMenu = document.getElementsByClassName("sub_nav-menu")?.[0];
      if (subNavMenu) {
        subNavMenu.scrollLeft = 0;
      }
      let menus = document.getElementsByClassName("nav-menu__item");
      let subMenus = document.getElementsByClassName("nav-sub-menu__item");
      for (let i = 0; i < menus.length; i++) {
        // debugger
        if (
          menus &&
          menus[i] &&
          menus[i].classList.contains("active-category")
        ) {
          if (menus[i].offsetLeft) {
            document.getElementsByClassName("nav-menu")[0].scrollLeft =
              menus[i].offsetLeft;
          }
        }
      }
      for (let i = 0; i < subMenus.length; i++) {
        if (
          subMenus &&
          subMenus[i] &&
          subMenus[i].classList.contains("selected-menu")
        ) {
          // debugger
          if (subMenus[i].offsetLeft && i > 9) {
            document.getElementsByClassName("sub_nav-menu")[0].scrollLeft =
              subMenus[i].offsetLeft;
          }
        }
      }
    }
  }, []);

  const localStoreOrdertype = JSON.parse(localStorage.getItem("orderInfo"));

  useEffect(() => {
    applyFilters(selectedCategory);
    if (!showSearch) {
      if (orderInfo.orderType === "schedule" && type != "schedule") {
        menuActions.getMenu(restaurantId, {
          ...orderInfo,
          orderType: "schedule",
          deliverNow: false,
        });
      } else if (orderInfo.orderType != "schedule" && type == "schedule") {
        menuActions.getMenu(restaurantId, {
          ...orderInfo,
          orderType: "regular",
          deliverNow: true,
        });
      }
      if (params.orderType === "schedule" && params.category === "schedule") {
        if (type !== "schedule") {
          if (
            orderInfo.startDate &&
            orderInfo.endDate &&
            (!store?.askTimeForLater || orderInfo.time) &&
            orderInfo.frequencyDays
          ) {
            const noOfDays = calculeteNoOfDays(
              new Date(orderInfo.startDate),
              new Date(orderInfo.endDate),
              orderInfo.frequencyDays
            );
            // dispatch(
            //   orderActions.updateOrderStore({
            //     ...orderInfo,
            //     orderType: "schedule",
            //     noOfDays,
            //     deliverNow: false,
            //   })
            // );
            dispatch(
              menuActions.getMenu(restaurantId, {
                ...orderInfo,
                orderType: "schedule",
                noOfDays,
                deliverNow: false,
              })
            );
          } else {
            setIsVisible(true);
          }
        }
      }
      if (orderInfo.deliveryMode !== "pickup") {
        if (
          localStorageAuth.selectedUserAddress &&
          (localStorageAuth.selectedUserAddress.location["lat"] === "" ||
            localStorageAuth.selectedUserAddress.location["lng"] === null ||
            localStorageAuth.selectedUserAddress.location["lng"] === "" ||
            localStorageAuth.selectedUserAddress.location["lng"] === null)
        ) {
          dispatch(modalActions.toggleChangeLocationModal(true));
        } else {
          // console.log(localStorageAuth, 'localstorageauth');
          findDeliveryStores(
            localStorageAuth.selectedUserAddress.location,
            "useEffect"
          );
        }
      }
    }
  }, [search]);

  useEffect(() => {
    if (orderType === "regular" && deliverNow === true) {
      (async () => {
        try {
          const response = await storeService.getMealTime({
            data: {
              store: parseInt(restaurantId),
            },
          });

          const { mealType: type = "REGULAR", time } = response ?? {};

          if (type !== "REGULAR") {
            const formattedTime = moment(time, "DD-MM-YYYY").format(
              "MM/DD/YYYY"
            );
            dispatch(
              orderActions.update({
                deliverNow: false,
                startDate: formattedTime,
                laterDate: formattedTime,
                time: null,
                laterTime: null,
              })
            );
          }
        } catch {}
      })();
    }
  }, [restaurantId, dispatch, orderType, deliverNow]);

  const applyFilters = (updatedSelectedMenu) => {
    if (search && menu.products && updatedSelectedMenu) {
      const params = new URLSearchParams(search);

      // TODO update filter state
      let updatedfilterCount = 0;
      params.forEach((value, type) => {
        const updatedCategory = Filter(
          menu.products,
          updatedSelectedMenu,
          type,
          parseInt(value)
        );
        setSelectedCategory({ ...updatedCategory });
        updatedfilterCount++;
        filterstate.forEach((filter) => {
          if (filter.tagName === type) {
            filter.filtersOptions.forEach((option) => {
              if (option.id === Number(value)) {
                option.selected = true;
              }
            });
          }
        });
        setFilterstate([...filterstate]);
      });
      setFilterCount(updatedfilterCount);
    }
  };
  // console.log(useParams(), 'useParams');
  const closeSlider = (popType) => {
    if (popType === "bulk") {
      setIsBulkMenuVisible(false);
    }
    if (popType === "schedule") {
      if (orderInfo.orderType !== "schedule") {
        history.push(`/${restaurantId}/menu-card/regular/all/${type}`);
      }
      setIsVisible(false);
    }
  };

  const findDeliveryStores = (location, type = "map") => {
    var changedLocation = {
      lat: "",
      lng: "",
    };
    if (type !== "map") {
      changedLocation = location;
    } else {
      changedLocation = {
        lat: location.geometry.location.lat,
        lng: location.geometry.location.lng,
      };
    }
    clientService
      .getDeliveryStore({
        params: {
          lat: changedLocation.lat,
          long: changedLocation.lng,
          ...(access?.sendMobile ? { mobile: localStorageAuth?.user } : {}),
        },
      })
      .then((stores) => {
        let exist = false;
        if (stores.length) {
          stores.forEach((currentStore) => {
            if (restaurantId == currentStore.storeId) {
              dispatch(
                storeActions.setStoreData(storeClosedOrNot(orderInfo, store))
              );
              exist = true;
              return;
            }
          });
        }
        if (!exist) {
          dispatch(
            storeActions.setStoreData({
              isClosed: true,
              isServisable: false,
            })
          );
          toast.error("Store Doesn't deliver here!");
        }
      })
      .catch((err) => {
        dispatch(
          storeActions.setStoreData({
            isClosed: true,
            isServisable: false,
          })
        );
        toast.error(err);
      });
  };

  const menus = [
    {
      name: "A'la Carte",
      id: 0,
      link: `/${restaurantId}/menu-card/regular/all/${type}`,
      onClick: () => {
        if (orderInfo.orderType === "schedule") {
          // dispatch(
          //   orderActions.updateOrderStore({
          //     ...orderInfo,
          //     orderType: "regular",
          //     noOfDays: 1,
          //     deliverNow: true,
          //   })
          // );
          dispatch(
            orderActions.changeOrderStore({
              ...orderInfo,
              orderType: "regular",
              noOfDays: 1,
              deliverNow: true,
            })
          );
          dispatch(
            menuActions.getMenu(restaurantId, {
              ...orderInfo,
              orderType: "regular",
              noOfDays: 1,
              deliverNow: true,
            })
          );
        }
      },
    },
    {
      name: "Kids Menu",
      id: 1,

      link: `/${restaurantId}/menu-card/regular/kids/${type}`,
      onClick: () => {
        if (orderInfo.orderType === "schedule") {
          // dispatch(
          //   orderActions.updateOrderStore({
          //     ...orderInfo,
          //     orderType: "regular",
          //     noOfDays: 1,
          //     deliverNow: true,
          //   })
          // );
          dispatch(
            menuActions.getMenu(restaurantId, {
              ...orderInfo,
              orderType: "regular",
              noOfDays: 1,
              deliverNow: true,
            })
          );
          dispatch(
            orderActions.changeOrderStore({
              ...orderInfo,
              orderType: "regular",
              noOfDays: 1,
              deliverNow: true,
            })
          );
        }
      },
    },
    {
      name: "Healthy Menu",
      id: 2,

      link: `/${restaurantId}/menu-card/regular/healthy/${type}`,
      onClick: () => {
        if (orderInfo.orderType === "schedule") {
          // dispatch(
          //   orderActions.updateOrderStore({
          //     ...orderInfo,
          //     orderType: "regular",
          //     noOfDays: 1,
          //     deliverNow: true,
          //   })
          // );
          dispatch(
            orderActions.changeOrderStore({
              ...orderInfo,
              orderType: "regular",
              noOfDays: 1,
              deliverNow: true,
            })
          );
          dispatch(
            menuActions.getMenu(restaurantId, {
              ...orderInfo,
              orderType: "regular",
              noOfDays: 1,
              deliverNow: true,
            })
          );
        }
      },
    },
    {
      name: "Bulk Menu",
      id: 3,

      link: `/${restaurantId}/menu-card/bulk/bulk/${type}`,
      onClick: () => setIsBulkMenuVisible(true),
    },

    {
      name: "Subscription Menu",
      id: 4,

      link: `/${restaurantId}/menu-card/schedule/schedule/${type}`,
      onClick: () => {
        if (orderInfo.orderType !== "schedule") {
          if (
            orderInfo.startDate &&
            orderInfo.endDate &&
            orderInfo.time &&
            orderInfo.frequencyDays
          ) {
            const noOfDays = calculeteNoOfDays(
              new Date(orderInfo.startDate),
              new Date(orderInfo.endDate),
              orderInfo.frequencyDays
            );
            dispatch(
              orderActions.changeOrderStore({
                ...orderInfo,
                orderType: "schedule",
                noOfDays,
                deliverNow: false,
              })
            );

            // dispatch(
            //   orderActions.updateOrderStore({
            //     ...orderInfo,
            //     orderType: "schedule",
            //     noOfDays,
            //     deliverNow: false,
            //   })
            // );
            dispatch(
              menuActions.getMenu(restaurantId, {
                ...orderInfo,
                orderType: "schedule",
                noOfDays,
                deliverNow: false,
              })
            );
          } else {
            setIsVisible(true);
          }
        } else {
          setIsVisible(true);
        }
      },
    },
  ];

  const save = () => {
    let paramsRoute = "";
    // console.log(filterRoute, 'filterRoute');
    Object.keys(filterRoute).forEach((key) => {
      if (paramsRoute) {
        paramsRoute = `${paramsRoute}&&${key}=${filterRoute[key]}`;
      } else {
        paramsRoute = `${key}=${filterRoute[key]}`;
      }
    });
    history.push(`?${paramsRoute}`);
  };

  const addOrRemoveFilter = (level, index, status, type) => {
    let updatedFilters = filterstate;
    // console.log(level, index, status, type, 'heree');
    if (type === "multiple") {
      updatedFilters[level].filtersOptions[index].selected = status;
    } else {
      updatedFilters[level].filtersOptions.forEach((item, ind) => {
        ind === index ? (item.selected = true) : (item.selected = false);
      });
    }
    filterRoute[updatedFilters[level].tagName] =
      updatedFilters[level].filtersOptions[index].id;
    // console.log(filterRoute);
    setFilterRoute(filterRoute);
    setFilterstate([...updatedFilters]);
  };

  const clear = () => {
    setFilterstate([...JSON.parse(JSON.stringify(filters))]);
    setFilterRoute({});
  };

  const onVegSelected = () => {
    dispatch(
      notificationActions.createNotification(
        "Hi, You Have Selected Veg Meal Preference"
      )
    );
  };
  const checkTime = () => {
    const laterTime = cloneDeep(orderInfo.time);
    const endsOn = moment(menu.specialMenu.endsOn, "DD-MM-YYYY HH:mm").format(
      "YYYY-MM-DD HH:mm"
    );
    // debugger;
    const laterDate = orderInfo.startDate;
    let laterDateCopy = cloneDeep(laterDate);
    laterDateCopy = moment(laterDateCopy, "YYYY-MM-DD")
      .format("YYYY-MM-DD")
      .concat(` ${laterTime}`);
    if (moment(laterDateCopy).isBefore(endsOn)) {
      return true;
    } else {
      return false;
    }
  };
  const checkNormalTime = () => {
    const endsOn = moment(menu.specialMenu.endsOn, "DD-MM-YYYY HH:mm").format(
      "YYYY-MM-DD HH:mm"
    );
    let currTime = moment(new Date(), "DD-MM-YYYY HH:mm").format(
      "YYYY-MM-DD HH:mm"
    );
    // console.log(moment(currTime).isBefore(endsOn), 'endsOnnnnn');
    if (moment(currTime).isBefore(endsOn)) {
      return true;
    } else {
      return false;
    }
  };

  const handleVerification = async () => {
    const isVerified = await authActions.verifyAuth();
    if (auth?.auth && !isVerified) {
      toast.error("Session expired");
      history.push("/");
      dispatch(authActions.userLogout());
      return;
    }
  };

  const handleCheckout = async () => {
    handleVerification();
    if (orderInfo.noOfDays === 0) {
      toast.error("No Of Days is equal to Zero");
      return;
    }
    if (store.isClosed || store.isInactive) {
      toast.error("Store is closed right now");
      return;
    }
    if (orderInfo.orderType === "bulk") {
      if (orderInfo.preferences.bulkPreferences.length === 0) {
        toast.error("Please Select Bulk Preferences");
        setIsBulkMenuVisible(true);
        return;
      }
    }

    if (!auth.auth) {
      dispatch(modalActions.toggleLoginModal(true));
      toast.error("Login To Continue");
      return;
    }
    const localOrderInfo = JSON.parse(localStorage.getItem("orderInfo"));

    if (orderInfo.orderType != localOrderInfo.orderType) {
      dispatch(
        orderActions.updateOrderStore({
          ...orderInfo,
          orderType: localOrderInfo.orderType,
          deliverNow: localOrderInfo.deliverNow,
          noOfDays: localOrderInfo.noOfDays,
          startDate:
            localOrderInfo.orderType == "regular" &&
            localOrderInfo.deliverNow == false
              ? localOrderInfo.laterDate
              : localOrderInfo.startDate,
        })
      );
    }
    if (
      orderInfo?.type === "later" &&
      !isOrderAllowed(
        orderInfo?.orderType === "schedule"
          ? formatddmmyyyy(new Date(orderInfo.startDate), "/")
          : orderInfo?.laterDate,
        store?.isClosed,
        store?.openingTime,
        store?.closingTime
      )
    ) {
      history.push(`/`);
      toast.error("Store is closed.");
    } else {
      history.push(`/${restaurantId}/check-out`);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  const handlePushingToCart = () => {
    if (moment().diff(moment([2024, 2, 29]), "d") >= 0) {
      return;
    }
    if (!auth.auth) {
      toast.error("Login To Continue");
      history.push(`/login`);
      return;
    }

    if (
      orderInfo?.type === "later" &&
      !isOrderAllowed(
        orderInfo?.orderType === "schedule"
          ? formatddmmyyyy(new Date(orderInfo.startDate), "/")
          : orderInfo?.laterDate,
        store?.isClosed,
        store?.openingTime,
        store?.closingTime
      )
    ) {
      history.push(`/`);
    } else {
      history.push(`/${restaurantId}/my-order`);
    }
  };
  const FilterSection = () => (
    <Fragment>
      {/* <Switch onSubmit={onVegSelected} />
      <div className='ml-2'>Veg</div>
      <div className='flex-grow ml-6'>
        <div className='flex space-x-2 items-center'>
          <i className='far fa-clock text-xl text-gray-500'></i>
          <div>30 mins</div>
        </div>
      </div> */}
      <div
        onClick={() => setShowFilter(true)}
        className="flex space-x-2 cursor-pointer"
      >
        <div className="relative">
          <img src={filter} alt="filter" />
          <span className="flex justify-center items-center absolute -top-2 -right-2 w-4 h-4 rounded-full text-white bg-7FB069">
            {filterCount}
          </span>
        </div>
        <div>Filters</div>
      </div>
    </Fragment>
  );

  const showCustomization = (productId, appliedCustomization, index) => {
    if (index == -1) {
      setCustomisationType("save");
    } else {
      setCustomisationType("edit");
    }
    setSelectFoodItem({
      foodItem: menu.products[productId],
      appliedCustomization: JSON.parse(JSON.stringify(appliedCustomization)),
      index,
    });
    setIsCustomizationsVisible(true);
  };

  // Handles the size change in food item
  const onSizeChange = (variant, productId) => {
    let tempMenu = cloneDeep(menu);
    tempMenu.products[productId].selectedVariant = { ...variant };
    dispatch(menuActions.updateMenu(tempMenu));
  };
  // console.log(selectedCategory, 'selectedCategory');
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (category === "kids") {
      if (
        menu.categoryAvailableId[category].length &&
        !menu.categoryAvailableId[category].includes(parseInt(type, 10))
      ) {
        history.push(
          `/${restaurantId}/menu-card/${orderType}/${category}/${menu.categoryAvailableId[category][0]}`
        );
      }
    } else if (category === "healthy") {
      if (
        menu.categoryAvailableId[category].length &&
        !menu.categoryAvailableId[category].includes(parseInt(type, 10))
      ) {
        history.push(
          `/${restaurantId}/menu-card/${orderType}/${category}/${menu.categoryAvailableId[category][0]}`
        );
      }
    } else if (category === "schedule") {
      if (
        menu.categoryAvailableId[category].length &&
        !menu.categoryAvailableId[category].includes(parseInt(type, 10))
      ) {
        history.push(
          `/${restaurantId}/menu-card/${orderType}/${category}/${menu.categoryAvailableId[category][0]}`
        );
      }
    }
  }, [category]);
  const SearchProducts = () => {
    let found = 0;
    const result = Object.keys(menu.availableProductId).map(
      (foodItemId, index) => {
        const foodItem = menu.products[foodItemId];
        if (!foodItem.name.toLowerCase().includes(query?.toLowerCase())) return;
        found++;
        return (
          <div className={"my-6"} key={index}>
            <div className="">
              <FinalFoodCard
                key={foodItemId}
                productAvailable={foodItem.isProductAvailable}
                productId={foodItem.productId}
                productType={foodItem.productType}
                description={foodItem.description}
                discount={foodItem.discount}
                selectedVariant={foodItem.selectedVariant}
                displayVariants={foodItem.displayVariants} // TRUE | FALSE (Sizes)
                variants={foodItem.variants} // Sizes
                image={foodItem.image}
                isCustomizable={foodItem.isCustomizable}
                isVeg={foodItem.isVeg}
                menuTags={foodItem.menuTags}
                tags={foodItem.tags}
                name={foodItem.name}
                ratings={foodItem.ratings}
                tax={foodItem.tax}
                type={orderType}
                showCustomization={showCustomization}
                foodItemNotAvailableDueToTime={foodItem.notAvailableDueToTime}
                foodItem={foodItem}
                foodItemNotAvailableDueToDate={foodItem.notAvailableDueToDate}
                subscriptionFrom={foodItem.subscriptionFrom}
                subscriptionTo={foodItem.subscriptionTo}
              />
            </div>
          </div>
        );
      }
    );
    if (found) {
      return result;
    }
    return (
      <div className="flex items-center justify-center lg:col-span-2">
        <div className="flex items-center justify-center flex-col h-full">
          <div className={styles["img-container"]}></div>
          <h2 className={styles["not-much-container"]}>
            Oh! There’s not much left
          </h2>
          <p className={styles["cant-find"]}>
            We can’t find anything related to your search.
          </p>
          {/* No Results Found For "{query}"  */}
          <p className={styles["try-diff"]}>Try a different search.</p>
        </div>
      </div>
    );
  };

  const menuCategories = menu.menu
    .filter((categoryObj) => {
      if (categoryObj.categoryId === -1 && orderInfo.orderType === "schedule") {
        if (!checkTime()) {
          return false;
        }
      } else if (
        categoryObj.categoryId === -1 &&
        orderInfo.deliverNow === true
      ) {
        if (!checkNormalTime()) {
          return false;
        }
      }
      if (category === "kids" && !categoryObj.kidsMenuAvailable) {
        return false;
      }
      if (category === "healthy" && !categoryObj.healthyMenuAvailable) {
        return false;
      }
      if (category === "schedule" && !categoryObj.subscriptionMenuAvailable) {
        return false;
      }
      return true;
    })
    .map((categoryObj) => {
      return (
        <NavLink
          key={categoryObj.categoryId}
          activeClassName="selected-menu"
          to={`/${restaurantId}/menu-card/${orderType}/${category}/${
            categoryObj.categoryId
          }${search ? `${search}` : ``}`}
          className="nav-sub-menu__item unselected-menu"
        >
          {categoryObj.categoryName}
        </NavLink>
      );
    });

  const handleSubscription = ({
    subCategoryName,
    from = new Date(orderInfo.startDate),
    to = new Date(orderInfo.endDate),
  }) => {
    (async () => {
      setModalState((prev) => {
        return { ...prev, show: true, loading: true };
      });
      try {
        const reqOptions = {
          data: {
            subCategoryName,
            from: moment(from).format("MM/DD/YYYY"),
            to: moment(to).format("MM/DD/YYYY"),
          },
          params: {
            context: "SUBSCRIPTION",
          },
        };

        const response =
          await subscriptionService.getSubscriptionLisitingService(
            restaurantId,
            client?.userBasedMenu,
            reqOptions
          );
        setModalState({
          show: true,
          loading: false,
          data: response,
          subCategoryName,
        });
        toast.success(response?.message ?? "Fetched successfully");
      } catch (error) {
        toast.error(error ?? "Some Error Occured");
        setModalState({
          data: [],
          show: false,
          loading: false,
          subCategoryName: "",
        });
      }
    })();
  };

  return (
    <div>
      {params.orderType === "schedule" && (
        <SubscriptionModal
          modalState={modalState}
          setModalState={setModalState}
          orderInfo={orderInfo}
        />
      )}

      <SideNav
        onClose={() => {
          dispatch(modalActions.toggleChangeLocationModal(false));
          // dispatch(
          //   storeActions.setStoreData({
          //     isClosed: true,
          //     isServisable: false,
          //   })
          // );
        }}
        visible={showChangeLocationModal}
        mode="dark"
      >
        <ChangeLocationModal
          findDeliveryStores={findDeliveryStores}
          DEFAULT_COORDS={DEFAULT_COORDS}
          restaurantId={restaurantId}
        />
      </SideNav>
      {/* Desktop section  */}
      {!showSearch && (
        <>
          <div className="hidden lg:block">
            <SubHeader
              title="Menu Card"
              link={bypass ? `/` : `/${restaurantId}/home`}
            />
            {/* {
              <div
                className={`${
                  menu.menuTags.length > 1 || store.mealSubscription
                    ? "block"
                    : "hidden"
                } nav-menu bg-black flex justify-evenly text-white bgGrayScrollbar p-4 mt-6 overflow-y-auto space-x-8 whitespace-nowrap`}
              >
                {menus.map((tempMenu) => {
                  if (
                    !menu.menuTags.includes(tempMenu.name) &&
                    tempMenu.name != "A'la Carte" &&
                    tempMenu.name != "Subscription Menu"
                  ) {
                    return;
                  }
                  return (
                    <span
                      key={tempMenu.id}
                      onClick={tempMenu.onClick}
                      className={`${
                        (tempMenu.id === 3 && store.bulkOrdering) ||
                        (tempMenu.id === 4 && store.mealSubscription) ||
                        (tempMenu.id !== 3 && tempMenu.id !== 4)
                          ? "block"
                          : "hidden"
                      }`}
                    >
                      <NavLink
                        activeClassName="active-category"
                        to={tempMenu.link + `${search && search}`}
                        className="nav-menu__item inline-block relative"
                      >
                        {tempMenu.name}
                      </NavLink>
                    </span>
                  );
                })}
              </div>
            } */}
            {/* {menuCategories.length > 1 && (
              <div
                className={`sub_nav-menu bg-white flex ${
                  menu.menu.length > 5 ? "" : "justify-center"
                } overflow-y-auto bgWhiteScrollbar whitespace-nowrap space-x-20 p-4`}
              >
                {menuCategories}
              </div>
            )} */}
            {params.orderType === "schedule" && (
              <div className="flex items-center justify-between  p-4 mb-4 mt-2 bg-white">
                <div
                  className="flex justify-between items-center text-black bg-white bgGrayScrollbar overflow-y-auto space-x-8"
                  style={{
                    maxWidth: width,
                  }}
                >
                  <div>
                    Order To Be Scheduled From
                    <span className="font-bold">
                      {" "}
                      {formatddmmyyyy(new Date(orderInfo.startDate), "/")}{" "}
                    </span>
                    to
                    <span className="font-bold">
                      {" "}
                      {formatddmmyyyy(new Date(orderInfo.endDate), "/")}{" "}
                    </span>
                    {store?.askTimeForLater && (
                      <span> at {orderInfo.time} </span>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    className="bg-black text-white p-2"
                    onClick={() => setIsVisible(true)}
                  >
                    Change
                  </button>
                </div>
              </div>
            )}
            {menuCategories.length > 1 && (
              <div
                className={`sub_nav-menu bg-white flex ${
                  menu.menu.length > 5 ? "" : "justify-center"
                } overflow-y-auto bgWhiteScrollbar whitespace-nowrap space-x-20 p-4`}
              >
                {menuCategories}
              </div>
            )}
            <div className="flex gap-4 mt-4">
              <div className="bg-white px-12 xl:px-16 2xl:px-20 py-10 space-y-6 w-7/12 relative overflow-hidden">
                {selectedCategory && selectedCategory.description && (
                  <div className="flex flex-col items-center text-center">
                    <p className="col-span-5 text-base md:text-lg pt-2 px-3">
                      {selectedCategory.description}
                    </p>
                  </div>
                )}
                {/* <div className='flex font-open-sans font-semibold text-xs items-center'>
							<FilterSection />
						</div> */}
                <img
                  src={SpiralBorder}
                  className="absolute left-0 top-0 h-auto w-14 my-6"
                  alt="spiral notebook"
                />
                {selectedCategory &&
                  selectedCategory.subCategory.map(
                    (subCategory, index) =>
                      subCategory.products.length > 0 && (
                        <div key={index}>
                          <div
                            key={index}
                            className="flex flex-col items-center justify-center text-center"
                          >
                            {subCategory.subCategoryName.length && (
                              <h1 className="text-D17B69 font-bold text-xl">
                                {subCategory.subCategoryName}
                              </h1>
                            )}
                            {subCategory.description.length && (
                              <p className="text-gray-400  text-sm">
                                {subCategory.description}
                              </p>
                            )}
                          </div>
                          {/* {console.log(
												'subCategory',
												subCategory
											)} */}
                          {subCategory.products.map((foodItemId, index) => {
                            const foodItem = menu.products[foodItemId];
                            return (
                              foodItem && (
                                <div className="my-6" key={index}>
                                  <div className="hidden sm:block">
                                    <FinalFoodCard
                                      key={foodItemId}
                                      handleSubscriptionClick={
                                        params.orderType === "schedule"
                                          ? handleSubscription
                                          : null
                                      }
                                      productAvailable={
                                        foodItem.isProductAvailable
                                      }
                                      selectedCategory={selectedCategory}
                                      subCategory={subCategory}
                                      productId={foodItem.productId}
                                      productType={foodItem.productType}
                                      description={foodItem.description}
                                      discount={foodItem.discount}
                                      selectedVariant={foodItem.selectedVariant}
                                      displayVariants={foodItem.displayVariants} // TRUE | FALSE (Sizes)
                                      variants={foodItem.variants} // Sizes
                                      image={foodItem.image}
                                      isCustomizable={foodItem.isCustomizable}
                                      isVeg={foodItem.isVeg}
                                      menuTags={foodItem.menuTags}
                                      tags={foodItem.tags}
                                      name={foodItem.name}
                                      ratings={foodItem.ratings}
                                      tax={foodItem.tax}
                                      type={orderType}
                                      showCustomization={showCustomization}
                                      foodItemNotAvailableDueToTime={
                                        foodItem.notAvailableDueToTime
                                      }
                                      foodItem={foodItem}
                                      foodItemNotAvailableDueToDate={
                                        foodItem.notAvailableDueToDate
                                      }
                                      subscriptionFrom={
                                        foodItem.subscriptionFrom
                                      }
                                      subscriptionTo={foodItem?.subscriptionTo}
                                    />
                                  </div>
                                </div>
                              )
                            );
                          })}
                        </div>
                      )
                  )}
              </div>
              {cart.length > 0 ? (
                <div className="flex flex-col  w-5/12">
                  <div className="sticky top-20">
                    <MyOrderCard
                      showCustomization={showCustomization}
                      type="menu"
                      setIsVisible={setIsVisible}
                    />
                    <button
                      onClick={() => {
                        if (moment().diff(moment([2024, 2, 29]), "d") >= 0) {
                          return;
                        }

                        if (
                          orderInfo.orderType == "regular" &&
                          localStoreOrdertype.orderType == "regular"
                        ) {
                          if (
                            orderInfo.deliverNow ==
                            localStoreOrdertype.deliverNow
                          ) {
                            handleCheckout();
                          } else {
                            setIsOpen(true);
                          }
                        } else if (
                          orderInfo.orderType != localStoreOrdertype.orderType
                        ) {
                          setIsOpen(true);
                        } else if (
                          orderInfo.orderType == "schedule" &&
                          localStoreOrdertype.orderType == "schedule"
                        ) {
                          handleCheckout();
                        }
                      }}
                      className={
                        "mt-2 h-12   rounded   font-semibold font-open-sans text-xs text-FFFFFF capitalize  cursor-pointer  w-full " +
                        (moment().diff(moment([2024, 2, 29]), "d") >= 0
                          ? " bg-gray-300 "
                          : " bg-8593FF  hover:bg-indigo-500 ")
                      }
                      disabled={
                        ((store.isClosed || store.isInactive) &&
                          orderInfo.orderType === "regular" &&
                          orderInfo.deliverNow === true) ||
                        storeClosedOrNot(orderInfo, store) ||
                        moment().diff(moment([2024, 2, 29]), "d") >= 0
                      }
                    >
                      Proceed To Check Out
                    </button>
                    {client?.clientType != "SCHOOL" &&
                      client?.clientType != "STAFF" && <AddInstructions />}
                  </div>
                </div>
              ) : (
                <div className="w-5/12">
                  <EmptyOrder />
                </div>
              )}
            </div>
          </div>
          <div
            className="block lg:hidden"
            style={{
              minHeight: "calc(100vh - 450px)",
            }}
          >
            {/* Mobile Section  */}
            {/* <div className='flex font-open-sans font-semibold text-xs items-center mt-36 mx-4 '>
					<FilterSection />
				</div> */}
            {
              <div
                className={`${
                  menu.menuTags.length > 1 || store.mealSubscription
                    ? "block"
                    : "hidden"
                }  nav-menu bg-black text-white py-4 mt-32 overflow-y-auto hide-scroll-bar space-x-8 whitespace-nowrap px-4 flex items-center justify-start`}
              >
                {menus.map((tempMenu) => {
                  if (
                    !menu.menuTags.includes(tempMenu.name) &&
                    tempMenu.name != "A'la Carte" &&
                    tempMenu.name != "Subscription Menu"
                  ) {
                    return;
                  }
                  return (
                    <span
                      key={tempMenu.id}
                      onClick={tempMenu.onClick}
                      className={`${
                        (tempMenu.id === 3 && store.bulkOrdering) ||
                        (tempMenu.id === 4 && store.mealSubscription) ||
                        (tempMenu.id !== 3 && tempMenu.id !== 4)
                          ? "inline-block"
                          : "hidden"
                      }`}
                    >
                      <NavLink
                        activeClassName="active-category"
                        to={tempMenu.link + `${search && search}`}
                        className="nav-menu__item inline-block relative"
                      >
                        {tempMenu.name}
                      </NavLink>
                    </span>
                  );
                })}
              </div>
            }
            {/* {orderInfo.orderType === 'schedule' && (
					<div className='text-black bg-white bgGrayScrollbar p-4 mt-6 overflow-y-auto space-x-8 whitespace-nowrap'>
						<div>
							Order Scheduled From
							<span>
								{' '}
								{formatddmmyyyy(
									new Date(orderInfo.startDate),
									'/'
								)}{' '}
							</span>
						</div>
						<div className='flex justify-between items-center'>
							to
							<span>
								{' '}
								{formatddmmyyyy(
									new Date(orderInfo.endDate),
									'/'
								)}{' '}
							</span>
							at
							<span> {orderInfo.time} </span>
							<div>
								<button
									className='bg-black text-white p-2'
									onClick={() => setIsVisible(true)}
								>
									Change
								</button>
							</div>
						</div>
					</div>
				)} */}
            {params.orderType === "schedule" && (
              <div className="flex justify-between text-sm items-center text-black bg-white bgGrayScrollbar p-2 mt-2 overflow-y-auto space-x-8 text-center">
                <div>
                  Order To Be Scheduled From
                  <span>
                    {" "}
                    {formatddmmyyyy(new Date(orderInfo.startDate), "/")}{" "}
                  </span>
                  to
                  <span>
                    {" "}
                    {formatddmmyyyy(new Date(orderInfo.endDate), "/")}{" "}
                  </span>
                  {orderInfo.time && (
                    <div>
                      at
                      <span> {orderInfo.time} </span>
                    </div>
                  )}
                </div>
                <div>
                  <button
                    className="bg-black text-white p-2"
                    onClick={() => setIsVisible(true)}
                  >
                    Change
                  </button>
                </div>
              </div>
            )}
            {menuCategories.length > 1 && (
              <div
                className={`sub_nav-menu space-x-4 py-6 px-4 overflow-y-auto hide-scroll-bar whitespace-nowrap ${
                  menu.menuTags.length > 1 || store.mealSubscription
                    ? ""
                    : "mt-28"
                }`}
              >
                {menuCategories}
              </div>
            )}
            <div className="bg-white px-2 py-4 relative overflow-hidden">
              {/* <img
            src={SpiralBorder}
            className='absolute left-0 top-0 h-full w-auto my-6'
            alt='spiral border'
          /> */}
              {selectedCategory && (
                <div className="flex flex-col items-center text-center">
                  <p className="col-span-5 text-sm md:text-lg pt-2 px-3">
                    {selectedCategory.description}
                  </p>
                </div>
              )}
              {selectedCategory &&
                selectedCategory.subCategory.map(
                  (subCategory, index) =>
                    subCategory.products.length > 0 && (
                      <div key={index}>
                        <div className="flex flex-col items-center ">
                          {subCategory.subCategoryName.length && (
                            <h1 className="text-D17B69 font-bold text-lg text-center">
                              {subCategory.subCategoryName}
                            </h1>
                          )}
                          {subCategory.description.length && (
                            <p className="text-gray-400  text-sm text-center">
                              {subCategory.description}
                            </p>
                          )}
                        </div>
                        {subCategory.products.map((foodItemId, index) => {
                          const foodItem = menu.products[foodItemId];
                          // if (
                          //   !foodItem?.name
                          //     .toLowerCase()
                          //     .includes(query?.toLowerCase())
                          // )
                          //   return;\
                          return (
                            foodItem && (
                              <div className="my-6" key={index}>
                                <FinalFoodCard
                                  handleSubscriptionClick={
                                    params.orderType === "schedule"
                                      ? handleSubscription
                                      : null
                                  }
                                  key={foodItemId}
                                  selectedCategory={selectedCategory}
                                  subCategory={subCategory}
                                  productId={foodItem.productId}
                                  productType={foodItem.productType}
                                  productAvailable={foodItem.isProductAvailable}
                                  description={foodItem.description}
                                  discount={foodItem.discount}
                                  selectedVariant={foodItem.selectedVariant}
                                  displayVariants={foodItem.displayVariants} // TRUE | FALSE (Sizes)
                                  variants={foodItem.variants} // Sizes
                                  image={foodItem.image}
                                  isCustomizable={foodItem.isCustomizable}
                                  isVeg={foodItem.isVeg}
                                  menuTags={foodItem.menuTags}
                                  tags={foodItem.tags}
                                  name={foodItem.name}
                                  ratings={foodItem.ratings}
                                  showCustomization={showCustomization}
                                  tax={foodItem.tax}
                                  type={orderType}
                                  // foodItemNotAvailableDueToTime={
                                  // 	foodItem.notAvailableDueToTime
                                  //   }
                                  //   foodItemNotAvailableDueToDate={
                                  // 	foodItem.notAvailableDueToDate
                                  //   }
                                  subscriptionFrom={foodItem?.subscriptionFrom}
                                  subscriptionTo={foodItem?.subscriptionTo}
                                  foodItem={foodItem}
                                />
                              </div>
                            )
                          );
                        })}
                      </div>
                    )
                )}
            </div>
            {cart.length > 0 && (
              <div
                className="fixed bottom-0 left-0 h-12 right-0 bg-transparent z-10 md:px-16 w-full "
                // disabled={
                // 	((store.isClosed || store.isInactive) &&
                // 		orderInfo.orderType === 'regular' &&
                // 		orderInfo.deliverNow === true) ||
                // 	(orderInfo.orderType === 'regular' &&
                // 		orderInfo.deliverNow === false &&
                // 		(orderInfo.time >
                // 			store.closingTime ||
                // 			orderInfo.time <
                // 				orderInfo.openingTime))
                // }
                onClick={handlePushingToCart}
              >
                {/* to={`/${restaurantId}/my-order`} */}
                <button className={"font-open-sans  text-white text-center h-12 py- w-full " +
              (moment().diff(moment([2024,2,29]),"d")>=0?"bg-gray-300":" bg-7FB069")
              }>
                  {/* <div className=''> */}
                  Proceed To Cart
                  {/* </div> */}
                </button>
              </div>
            )}
          </div>
        </>
      )}
      {/* Search Component */}
      <div
        style={{
          minHeight: showSearch ? "calc(100vh - 72px)" : "",
          background: "white",
          display: showSearch ? "" : "none",
        }}
        className="flex justify-center flex-col lg:grid lg:grid-cols-2 lg:gap-4"
      >
        {SearchProducts()}

        {/* Mobile */}
        {/* {Object.keys(menu.availableProductId).map((foodItemId, index) => {
          const foodItem = menu.products[foodItemId];
          if (!foodItem.name.toLowerCase().includes(query.toLowerCase()))
            return;
          return (
            <div className="my-6" key={index}>
              <FinalFoodCard
                key={foodItemId}
                productId={foodItem.productId}
                productType={foodItem.productType}
                productAvailable={foodItem.isProductAvailable}
                description={foodItem.description}
                discount={foodItem.discount}
                selectedVariant={foodItem.selectedVariant}
                displayVariants={foodItem.displayVariants} // TRUE | FALSE (Sizes)
                variants={foodItem.variants} // Sizes
                image={foodItem.image}
                isCustomizable={foodItem.isCustomizable}
                isVeg={foodItem.isVeg}
                menuTags={foodItem.menuTags}
                tags={foodItem.tags}
                name={foodItem.name}
                ratings={foodItem.ratings}
                showCustomization={showCustomization}
                tax={foodItem.tax}
                type={orderType}
                // foodItemNotAvailableDueToTime={
                // 	foodItem.notAvailableDueToTime
                //   }
                //   foodItemNotAvailableDueToDate={
                // 	foodItem.notAvailableDueToDate
                //   }
                subscriptionFrom={foodItem.subscriptionFrom}
                subscriptionTo={foodItem.subscriptionTo}
                foodItem={foodItem}
              />
            </div>
          );
        })} */}
      </div>
      <SideNav
        onClose={() => closeSlider("schedule")}
        visible={isVisible}
        mode="dark"
      >
        <ScheduleMeal close={() => setIsVisible(false)} />
      </SideNav>
      <SideNav
        onClose={() => closeSlider("bulk")}
        visible={isBulkMenuVisible}
        mode="dark"
      >
        <BulkMenuSideNav close={() => setIsBulkMenuVisible(false)} />
      </SideNav>
      <SideNav
        mode="dark"
        visible={showFilter}
        onClose={() => setShowFilter(false)}
      >
        <Filters
          filters={filterstate}
          addOrRemoveFilter={addOrRemoveFilter}
          clear={clear}
          cancel={() => setShowFilter(false)}
          save={save}
        />
      </SideNav>
      <Modal
        visible={errorModal}
        title={`Choose your preferences!`}
        text="We cannot create your order without preferences"
        close={() => setErrorModal(false)}
        closingText="OK"
      />
      <ScrollToTop
        scrollYOffset={150}
        icon="arrow-up"
        onClickHandler={() => window.scrollTo(0, 0)}
      />
      <Modal
        visible={isOpen}
        title={`Your Selected Category is ${
          orderInfo.orderType == "regular"
            ? orderInfo.deliverNow == false
              ? "later"
              : "regular"
            : "subscribe"
        } it will be changed to ${
          localStoreOrdertype.orderType == "regular"
            ? localStoreOrdertype.deliverNow == false
              ? "later"
              : "regular"
            : "subscribe"
        } category`}
        text=""
        submit={() => {
          dispatch(
            menuActions.getMenu(restaurantId, {
              ...orderInfo,
              orderType: localStoreOrdertype.orderType,
              deliverNow: localStoreOrdertype.deliverNow,
              time: localStoreOrdertype.time,
            })
          );
          handleCheckout();
        }}
        close={closeModal}
        closingText="Cancel"
        submittingText="Proceed"
      />
      {/* To show customization for an item in cart  */}
      <SideNav
        onClose={() => setIsCustomizationsVisible(false)}
        visible={isCustomizationsVisible}
        closable={false}
        mode="light"
      >
        {isCustomizationsVisible && (
          <CustomizationSlider
            type={customizationType == "save" ? "SAVE" : "EDIT"}
            index={selectFoodItem.index}
            appliedCustomization={selectFoodItem.appliedCustomization}
            image={selectFoodItem.foodItem.image}
            name={selectFoodItem.foodItem.name}
            isVeg={selectFoodItem.foodItem.isVeg}
            selectedVariant={selectFoodItem.foodItem.selectedVariant}
            variants={selectFoodItem.foodItem.variants}
            customization={selectFoodItem.foodItem.customisation}
            product={selectFoodItem.foodItem}
            handleClose={() => {
              setIsCustomizationsVisible(false);
              document.body.classList.remove("stop-scrolling");
            }}
            orderType={orderInfo.orderType}
            changeSizeHandler={onSizeChange}
          />
        )}
      </SideNav>

      {/* <SideNav
          onClose={() => setIsCustomizationsVisible(false)}
          visible={isCustomizationsVisible}
          closable={false}
          mode="light"
        >
          {isCustomizationsVisible && (
            <CustomizationSlider
              type="SAVE"
              image={foodItem.image}
              name={foodItem.name}
              isVeg={foodItem.isVeg}
              selectedVariant={foodItem.selectedVariant}
              variants={foodItem.variants}
              customization={foodItem.customisation}
              product={foodItem}
              handleClose={() => {
                setIsCustomizationsVisible(false);
                document.body.classList.remove("stop-scrolling");
              }}
              orderType={orderType}
              changeSizeHandler={onSizeChange}
            />
          )}
        </SideNav> */}
    </div>
  );
}
MenuCard.defaultProps = {
  // showSearch: true,
};
