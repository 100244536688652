/** @format */

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Cash from "../../Assets/Icons/Cash.svg";
import deliveryMan from "../../Assets/Icons/delivery-man.svg";
import DownArrow from "../../Assets/Icons/DownArrow.png";
import Home from "../../Assets/Icons/Home.svg";
import MarkedRadio from "../../Assets/Icons/MarkedRadio.svg";
import CreditCard from "../../Assets/Icons/material-payment.svg";
import Paytm from "../../Assets/Icons/md-wallet.svg";
import Notes from "../../Assets/Icons/payment-cash.svg";
import UnMarkedRadio from "../../Assets/Icons/UnMarkedRadio.svg";
import DisabledRadio from "../../Assets/Icons/disabled-radio.svg";
import UpArrow from "../../Assets/Icons/UpArrow.png";
import Wallet from "../../Assets/Icons/Wallet.svg";
import BillCard from "../../Components/Cards/BillCard/BillCard";
import PromoCodeCard from "../../Components/Cards/PromoCodeCard/PromoCodeCard";
import SubHeader from "../../Components/Header/SubHeader";
import { Loader } from "../../Components/Spinner/Loader";
import { formatddmmyyyy } from "../../Functions/GetTime";
import { creditActions, modalActions, orderActions } from "../../Redux/Actions";
import { cartService } from "../../Redux/Services";
import Address from "../CheckOut/Address";
import DeliverySlots from "../CheckOut/DeliverySlots";
import MoneyBag from "../../Assets/Icons/moneyBag.png";
import { isOrderAllowed } from "../../Functions/utils";
import moment from "moment";

const Pay = () => {
  const dispatch = useDispatch();
  const { restaurantId } = useParams();
  const { orderInfo, store, cart, menu, auth, client, credit } = useSelector(
    (state) => state
  );
  const { deliverNow, startDate, endDate, time, orderType, selectedAddress } =
    orderInfo;
  const [showDeliverySlots, setShowDeliverySlots] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [selectedMode, setSelectedMode] = useState("cash");
  const [paymentMode, setPaymentMode] = useState("cod");
  const [, setSelectedSlot] = useState(null);
  const history = useHistory();
  const {
    paymentDigital,
    paymentCOD,
    deliveryCharges,
    packagingCharges,
    minCartValue,
    maxCartValue,
  } = store;
  const { addressLine1, addressLine2, pincode, state, city } =
    orderInfo.selectedAddress;
  const defaultCategory = menu.menu[0]?.categoryId;
  useEffect(() => {
    if (!paymentCOD) {
      setPaymentMode("paytm");
      setSelectedMode("digital");
    }
    if (orderInfo.orderType == "schedule") {
      setPaymentMode("wallet");
      setSelectedMode("wallet");
    }
  }, [store]);

  useEffect(() => {
    dispatch(creditActions.getCreditDetails());
  }, []);

  const { totalTax, totalAmount, subTotal, totalSavings } =
    cartService.calculateCart({
      deliveryCharges:
        orderInfo.deliveryMode === "pickup" ||
        orderInfo.orderType === "scheduled"
          ? 0
          : parseFloat(deliveryCharges),
      packagingCharges,
      cart,
      noOfDays: orderInfo.noOfDays,
    });

  const handleOrderPlacement = () => {
    if (
      orderInfo.deliveryMode != "pickup" &&
      orderInfo.orderType !== "schedule"
    ) {
      if (maxCartValue - minCartValue > 0) {
        if (subTotal < minCartValue) {
          toast.error(`Minimum order value is ${minCartValue}`);
          return;
        } else if (subTotal > maxCartValue) {
          toast.error(`Maximum order value is ${maxCartValue}`);
          return;
        }
      }
    }

    if (
      orderInfo?.type === "later" &&
      !isOrderAllowed(
        orderInfo?.orderType === "schedule"
          ? formatddmmyyyy(new Date(orderInfo.startDate), "/")
          : orderInfo?.laterDate,
        store?.isClosed,
        store?.openingTime,
        store?.closingTime
      )
    ) {
      history.push("/");
    } else if (
      orderInfo?.orderType === "schedule" &&
      credit?.balance === 0 &&
      credit?.balance < (totalAmount * 2)
    ) {
      toast.error("Low balance, please add credits first");
    } else {
      dispatch(
        orderActions.PlaceOrder(
          {
            isPaymentCOD: selectedMode === "cash",
            orderValue: totalAmount,
            totalDiscount: totalSavings,
            deliveryCharges:
              orderInfo.deliveryMode === "pickup" ||
              orderInfo.orderType === "schedule"
                ? 0
                : parseFloat(deliveryCharges),
            packagingCharges:
              orderInfo.orderType === "schedule" ? 0 : packagingCharges,
            gst: totalTax,
            paymentMode: selectedMode?.toUpperCase(),
          },
          selectedAddress
        )
      );
    }
    if (selectedMode === "digital") {
      dispatch(modalActions.togglePaytmModal(true));
      // toast.error("We are working on it..")
    }
  };

  const closePaytm = () => {
    if (
      window.Paytm &&
      window.Paytm.CheckoutJS &&
      window.Paytm.CheckoutJS.close
    ) {
      window.Paytm.CheckoutJS.close();
    }
    modalActions.togglePaytmModal(false);
  };

  if (auth.redirectTo) {
    history.push(auth.redirectTo);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    closePaytm();
  }, []);

  useEffect(() => {
    if (orderInfo.deliveryMode === "delivery") {
      if (Object.keys(selectedAddress).length <= 0) {
        toast.error("Address not selected");
        history.replace(`/${restaurantId}/check-out`);
        return;
      }
    }
    if (orderInfo.orderType == "schedule") setSelectedMode("wallet");
    if (cart.length <= 0) {
      toast.error("Cart is Empty");
      history.replace(
        `/${restaurantId}/menu-card/${
          orderInfo.orderType === "bulk" || orderInfo.orderType === "schedule"
            ? orderInfo.orderType + "/" + orderInfo.orderType
            : "regular/all"
        }/${defaultCategory}`
      );
      return;
    }
    if (!deliverNow) {
      if (
        (orderType === "regular" || orderType === "bulk") &&
        startDate
        // && time
      ) {
        return;
      } else if (startDate && time && endDate) {
        return;
      }
    } else if (deliverNow) {
      return;
    }
    if (orderType !== "schedule") {
      toast.error("Select your time/slot");
      history.replace(`/${restaurantId}/check-out`);
    }
  }, []);

  if (orderInfo.isLoading) {
    return <Loader />;
  }
  let toastId = null;
  const handleMealCreditSelect = () => {
    if (orderInfo?.orderType !== "schedule" && credit?.balance < totalAmount) {
      if(toastId) { toast.remove(toastId); };
      toastId = toast.error("Low balance, please select other payment method", {
        duration: 1000,

      });
    } else if (
      orderInfo?.orderType === "schedule" &&
      credit?.balance &&
      credit?.balance < totalAmount * 2
    ) {
      toast.error("Low balance, please add credits first");
    } else {
      setSelectedMode("wallet");
      setPaymentMode("wallet");
    }
  };

  const isDisabled = () => {
    if (
      orderInfo?.orderType === "schedule" &&
      (credit?.balance === 0 ||
      credit?.balance < (totalAmount * 2))
    ) {
      return true;
    }

    if (
      orderInfo?.orderType !== "schedule" &&
      credit?.balance &&
      credit?.balance < totalAmount &&
      (paymentMode === "wallet" &&
      selectedMode === "wallet")
    ) {
      return true;
    }

    return false;
  };

  return (
    <div>
      {/*Small screen -> phone version */}
      <div className="relative lg:hidden">
        <div className="block p-5 md:px-0 space-y-4 mb-28 md:mt-28">
          <div className="bg-FFFFFF bg-no-repeat rounded p-4 font-open-sans space-y-4">
            <div className="text-left text-xs text-black uppercase">
              {store.storeName}
            </div>
            <div
              className={`text-left text-black uppercase ${
                orderInfo?.orderType === "schedule" ? "text-2xs" : "text-xs"
              }`}
            >
              {orderInfo?.orderType !== "schedule"
                ? "Select Payment Mode"
                : `Please ensure minimum Rs. ${totalAmount} in the wallet everyday, the day before the order.`}
            </div>
            {/*Payment modes -> cash on delivery , paytm */}
            <div className=" space-y-2">
              {paymentCOD && orderInfo.orderType != "schedule" && (
                <div
                  className="flex items-center border-2 border-gray-200 px-2 py-4 space-x-4 cursor-pointer"
                  onClick={() => setSelectedMode("cash")}
                >
                  <div className="w-4 h-4">
                    <img
                      src={
                        selectedMode === "cash" ? MarkedRadio : UnMarkedRadio
                      }
                      alt="radio"
                      className="h-full w-full"
                    />
                  </div>
                  <div className="flex-grow font-semibold text-left text-xs text-black capitalize">
                    {orderInfo.deliveryMode == "delivery"
                      ? "CASH ON DELIVERY"
                      : "CASH ON PICKUP"}
                  </div>
                  <div className="w-6 h-4">
                    <img src={Cash} alt="cash" className="h-full w-full" />
                  </div>
                </div>
              )}

              {orderInfo.orderType !== "schedule" && paymentDigital && (
                <div
                  className="flex items-center border-2 border-gray-200 px-2 py-4 space-x-4 cursor-pointer"
                  onClick={() => setSelectedMode("digital")}
                >
                  <div className="w-4 h-4">
                    <img
                      src={
                        selectedMode === "digital" ? MarkedRadio : UnMarkedRadio
                      }
                      alt="radio"
                      className="h-full w-full"
                    />
                  </div>
                  <div className="flex-grow font-semibold text-left text-xs text-black capitalize">
                    PAY DIGITALLY
                  </div>
                  <div className="w-5 h-4">
                    <img src={Wallet} alt="wallet" className="h-full w-full" />
                  </div>
                </div>
              )}

              {client?.walletEnabled && (
                <div
                  className="flex items-center border-2 border-gray-200 px-2 py-4 space-x-4"
                  onClick={() => handleMealCreditSelect()}
                >
                  <div className="w-4 h-4">
                    <img
                      src={
                        isDisabled()
                          ? DisabledRadio
                          : paymentMode === "wallet"
                          ? MarkedRadio
                          : UnMarkedRadio
                      }
                      alt="radio"
                      className="h-full w-full"
                    />
                  </div>
                  <div className="flex-grow font-semibold text-left text-xs text-black capitalize">
                    <div>MEAL CREDITS</div>
                    <div
                      style={{
                        color:
                          orderInfo?.orderType === "schedule" &&
                          credit?.balance < totalAmount * 2
                            ? "red"
                            : "",
                      }}
                      className="text-2xs italic"
                    >
                      {orderInfo?.orderType === "schedule" &&
                        credit?.balance < (totalAmount * 2 )&& (
                          <span>
                            Balance required to start Subscription:
                            <br />
                            {`(${totalAmount} X 2 (days) = ${totalAmount * 2})`}
                          </span>
                        )}
                    </div>
                    <div
                      style={{
                        color:
                          ((
                            orderInfo?.orderType !== "schedule" &&
                            credit?.balance < totalAmount) ||
                          (orderInfo?.orderType === "schedule" &&
                            credit?.balance < (totalAmount * 2)))
                            ? "red"
                            : "",
                      }}
                      className="text-2xs"
                    >
                      Balance: {credit?.balance}{" "}
                      {((
                        orderInfo?.orderType !== "schedule" &&
                        credit?.balance < totalAmount) ||
                      (orderInfo?.orderType === "schedule" &&
                        credit?.balance < totalAmount * 2))
                        ? "(Low balance)"
                        : ""}
                    </div>
                  </div>
                  <div className="w-5 h-4">
                    <img src={MoneyBag} alt="wallet" className="h-full" />
                  </div>
                </div>
              )}
              {/* 
							{client.walletEnabled && (
								<div
									className='flex items-center border-2 border-gray-200 px-2 py-4 space-x-4 cursor-pointer'
									onClick={() => setSelectedMode('wallet')}
								>
									<div className='w-4 h-4'>
										<img
											src={
												selectedMode === 'wallet'
													? MarkedRadio
													: UnMarkedRadio
											}
											alt='radio'
											className='h-full w-full'
										/>
									</div>
									<div className='flex-grow font-semibold text-left text-xs text-black capitalize'>
										Meal Credits
									</div>
									<div className='w-5 h-4'>
										<img
											src={credit}
											alt='wallet'
											className='h-full w-full'
										/>
									</div>
								</div>
							)} */}
            </div>
          </div>
          {/* Selected address card 
          here change address option takes back to previous page*/}
          {orderInfo.deliveryMode === "delivery" && (
            <div className="flex bg-white  bg-no-repeat rounded p-5 space-x-4">
              <div className="h-4 w-4">
                <img src={Home} alt="Home" className="h-full w-full" />
              </div>
              <div className="flex-grow">
                <div className="font-open-sans font-semibold text-left text-sm text-black capitalize">
                  {addressLine1} , {addressLine2}
                </div>
                {/* <div className='font-open-sans text-left text-2xs text-8B8B8B capitalize'>
									{`${city} , ${state}-${pincode}`}
								</div> */}
                <div className="font-open-sans font-semibold text-left text-2xs text-black capitalize pt-2">
                  Delivery is possible here
                </div>
              </div>
              <div
                onClick={() => setShowAddress(!showAddress)}
                className="space-x-2 cursor-pointer"
              >
                <Link to={`/${restaurantId}/check-out`}>
                  <div className="font-open-sans font-semibold text-left text-xs text-8593FF capitalize ">
                    Change
                  </div>
                </Link>
              </div>
            </div>
          )}
          <PromoCodeCard
            paymentMode={paymentMode}
            orderValue={totalAmount}
            totalDiscount={totalSavings}
            deliveryCharges={deliveryCharges}
            packagingCharges={packagingCharges}
            selectedAddress={selectedAddress}
            gst={totalTax}
          />
          <BillCard />
        </div>
        <div
          className="bg-transparent fixed left-0 right-0 bottom-0 md:px-16"
          style={{
            zIndex: "15",
          }}
        >
          <div
            className="bg-8593FF bg-no-repeat py-4 font-open-sans font-semibold text-center text-FFFFFF text-base uppercase z-10"
            onClick={handleOrderPlacement}
            disabled={isDisabled()}
          >
            {selectedMode === "cash"
              ? "PAY WITH CASH"
              : selectedMode === "wallet"
              ? "PAY WITH MEAL CREDITS"
              : "PAY DIGITALLY"}
          </div>
        </div>
      </div>

      {/* ============================================================================================ */}
      {/*Desktop Version */}
      {/* ============================================================================================ */}

      <div className="py-2 hidden lg:block">
        <div className="hidden lg:block">
          <SubHeader title="Pay" />
        </div>
        <div className="grid grid-cols-5 gap-6 mt-10">
          <div className="col-span-5 lg:col-span-3 space-y-4">
            {/*Payment Options component ->  cash on delivery and  paytm option */}
            {/* <PaymentOptions /> */}
            <div className="space-y-2">
              <div className="flex space-x-8 bg-FFFFFF bg-no-repeat rounded items-center p-9">
                <div className="h-7 w-7">
                  <img
                    src={CreditCard}
                    alt="Credit card"
                    className="h-full w-full"
                  />
                </div>
                <div
                  className={`text-left font-semibold font-open-sans text-black ${
                    orderInfo?.orderType === "schedule"
                      ? "text-sm"
                      : "text-base"
                  }`}
                >
                  {orderInfo?.orderType !== "schedule"
                    ? "Select Payment Mode"
                    : `Please ensure minimum Rs. ${totalAmount} in the wallet everyday, the day before the order.`}
                </div>
              </div>
              {paymentCOD && orderInfo.orderType !== "schedule" && (
                <div className="flex space-x-8 bg-FFFFFF bg-no-repeat rounded items-center p-9">
                  <div
                    className="h-5 w-5 cursor-pointer"
                    onClick={() => {
                      setPaymentMode("cod");
                      setSelectedMode("cash");
                    }}
                  >
                    <img
                      src={paymentMode === "cod" ? MarkedRadio : UnMarkedRadio}
                      alt="Marked"
                      className="h-full w-full"
                    />
                  </div>
                  <div className="flex-grow text-left font-open-sans text-black text-sm capitalize">
                    {orderInfo.deliveryMode == "delivery"
                      ? "CASH ON DELIVERY"
                      : "CASH ON PICKUP"}
                  </div>
                  <div className="h-7 w-7">
                    <img
                      src={Notes}
                      alt="Notes card"
                      className="h-full w-full"
                    />
                  </div>
                </div>
              )}
              {orderInfo.orderType !== "schedule" && paymentDigital && (
                <div className="flex space-x-8 bg-FFFFFF bg-no-repeat rounded items-center p-9">
                  <div
                    className="h-5 w-5 cursor-pointer"
                    onClick={() => {
                      setPaymentMode("paytm");
                      setSelectedMode("digital");
                    }}
                  >
                    <img
                      src={
                        paymentMode === "paytm" ? MarkedRadio : UnMarkedRadio
                      }
                      alt="Marked"
                      className="h-full w-full"
                    />
                  </div>
                  <div className="flex-grow text-left font-open-sans text-black text-sm capitalize">
                    PAY DIGITALLY
                  </div>
                  <div className="h-7 w-7">
                    <img
                      src={Paytm}
                      alt="Notes card"
                      className="h-full w-full"
                    />
                  </div>
                </div>
              )}
              {client?.walletEnabled && (
                <div className="flex space-x-8 bg-FFFFFF bg-no-repeat rounded items-center p-9">
                  <div
                    className="h-5 w-5 cursor-pointer"
                    onClick={handleMealCreditSelect}
                  >
                    <img
                      src={
                        isDisabled()
                          ? DisabledRadio
                          : paymentMode === "wallet"
                          ? MarkedRadio
                          : UnMarkedRadio
                      }
                      alt="Marked"
                      className="h-full w-full"
                    />
                  </div>
                  <div className="flex-grow text-left font-open-sans text-black text-sm capitalize">
                    <div>MEAL CREDITS</div>
                    <div
                      style={{
                        color:
                          orderInfo?.orderType === "schedule" &&
                          credit?.balance < totalAmount * 2
                            ? "red"
                            : "",
                      }}
                      className="text-xs italic"
                    >
                      {orderInfo?.orderType === "schedule" &&
                        credit?.balance < totalAmount * 2 &&
                        `Balance required to start Subscription: (${totalAmount} X 2 (days) = Rs. ${
                          totalAmount * 2
                        })`}
                    </div>
                    <div
                      style={{
                        color:
                          (
                            orderInfo?.orderType !== "schedule" &&
                            credit?.balance < totalAmount) ||
                          (orderInfo?.orderType === "schedule" &&
                            credit?.balance < totalAmount * 2)
                            ? "red"
                            : "",
                      }}
                      className="text-xs"
                    >
                      Balance: {credit?.balance}{" "}
                      {(
                        orderInfo?.orderType !== "schedule" &&
                        credit?.balance < totalAmount) ||
                      (orderInfo?.orderType === "schedule" &&
                        credit?.balance < totalAmount * 2)
                        ? "(Low balance)"
                        : ""}
                    </div>
                  </div>
                  <div className="h-7 w-7">
                    <img
                      src={MoneyBag}
                      alt="Notes card"
                      className="h-full w-full"
                    />
                  </div>
                </div>
              )}
              {/* {client.walletEnabled && (
								<div className='flex space-x-8 bg-FFFFFF bg-no-repeat rounded items-center p-9 '>
									<div
										className='h-5 w-5 cursor-pointer'
										onClick={() =>{ setPaymentMode('wallet')
										setSelectedMode('paytm')}}
									>
										<img
											src={
												paymentMode === 'wallet'
													? MarkedRadio
													: UnMarkedRadio
											}
											alt='Marked'
											className='h-full w-full'
										/>
									</div>
									<div className='flex-grow text-left font-open-sans text-black text-sm capitalize'>
									Meal Credits
									</div>
									<div className='h-7 w-7'>
										<img
											src={credit}
											alt='Notes card'
											className='h-full w-full'
										/>
									</div>
								</div>
							)} */}
            </div>
            {/* Selected address card -> allows to change the addres
            here address component shown on same page */}
            <div className="space-y-2">
              {orderInfo.deliveryMode === "delivery" && (
                <div className="flex bg-white  bg-no-repeat rounded p-9 space-x-8">
                  <div className="h-5 w-5">
                    <img src={Home} alt="Home" className="h-full w-full" />
                  </div>
                  <div className="flex-grow">
                    <div className="font-open-sans font-semibold text-left text-base text-black capitalize">
                      {addressLine1} , {addressLine2}
                    </div>
                    {/* <div className='font-open-sans text-left text-xs text-8B8B8B capitalize'>
											{`${city} , ${state}-${pincode}`}
										</div> */}
                    <div className="font-open-sans font-semibold text-left text-xs text-black capitalize pt-2">
                      Delivery is possible here
                    </div>
                  </div>
                  <div
                    onClick={() => setShowAddress(!showAddress)}
                    className="flex space-x-2 cursor-pointer"
                  >
                    <div className="font-open-sans font-semibold text-left text-xs text-8593FF capitalize ">
                      Edit
                    </div>
                    <div className="h-3.5 w-3.5">
                      <img
                        src={showAddress ? UpArrow : DownArrow}
                        alt="Up"
                        className="h-full w-full"
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* Show all address saved by user and option to edit them */}
              {showAddress && <Address />}
              {/* {client.clientType != "SCHOOL" && (
                <div className="flex px-9 py-7 bg-white mt-2 items-center relative">
                  <div>
                    <img src={deliveryMan} alt="delivery Man icon" />
                  </div>
                  <div className="flex-grow ml-8 font-open-sans">
                    <div className="text-base font-semibold">
                      {orderInfo.deliveryMode === "delivery"
                        ? "Your Food will be Delivered"
                        : "Your Food can be Picked"}
                    </div>
                    {orderInfo.orderType === "schedule" ? (
                      <div>
                        from{" "}
                        {formatddmmyyyy(new Date(orderInfo.startDate), "/")} to{" "}
                        {formatddmmyyyy(new Date(orderInfo.endDate), "/")} at{" "}
                        <span className="text-D17B69">{orderInfo.time}</span>
                      </div>
                    ) : (
                      <div>
                        {orderInfo.deliverNow ? (
                          <span className="text-D17B69">Today</span>
                        ) : (
                          <>
                            {formatddmmyyyy(new Date(orderInfo.startDate), "/")}{" "}
                            <span className="text-D17B69">
                              {orderInfo.time != null
                                ? `at ${orderInfo.time}`
                                : ""}
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="absolute -bottom-4 -right-96 w-96 z-30">
                    {showDeliverySlots && (
                      <DeliverySlots
                        close={setShowDeliverySlots}
                        setSelectedSlot={setSelectedSlot}
                      />
                    )}
                  </div>
                </div>
              )} */}
              <button
                className="block sm:hidden lg:block mt-2 h-12 w-full bg-8593FF rounded font-semibold font-open-sans text-xs text-FFFFFF capitalize  cursor-pointer  hover:bg-indigo-500"
                onClick={handleOrderPlacement}
                style={{
                  zIndex: "100",
                }}
                disabled={isDisabled()}
              >
                {selectedMode === "cash"
                  ? "PAY WITH CASH"
                  : selectedMode === "wallet"
                  ? "PAY WITH MEAL CREDITS"
                  : "PAY DIGITALLY"}
              </button>
            </div>
          </div>
          {/* Right side of page 
          Bill Card and promo code card */}
          <div className="col-span-5 lg:col-span-2 space-y-8">
            <div>
              <BillCard />
            </div>
            <div>
              <PromoCodeCard />
            </div>
            {/* <Link to={`/${restaurantId}/confirm-order`}>
              <button className='hidden md:block lg:hidden mt-2 h-12 w-full bg-8593FF rounded   font-semibold font-open-sans text-xs text-FFFFFF capitalize  cursor-pointer  hover:bg-indigo-500'>
                PROCEED TO PAY
              </button>
            </Link> */}
            {/* <Link to={`/${restaurantId}/confirm-order`}>
              <div className='fixed lg:hidden  left-0 bottom-0 w-full bg-8593FF bg-no-repeat py-4 font-open-sans font-semibold text-center text-FFFFFF text-base uppercase z-10'>
                PLACE ORDER
              </div>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pay;
