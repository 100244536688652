/** @format */

import React, { useEffect, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@material-ui/core/TextField";
import StaticTimePicker from "@mui/lab/StaticTimePicker";
import { calculeteNoOfDays } from "../../Functions/GetTime";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import "./OrderModal.css";
import {
  cartActions,
  customizationActions,
  orderActions,
  storeActions,
} from "../../Redux/Actions";
import ToggleDays from "./ToggleDays";
import {
  DatePicker,
  MobileDateRangePicker,
  StaticDateRangePicker,
} from "@mui/lab";
import { Box } from "@mui/system";
import { cloneDeep } from "lodash";
import { compareDates } from "../../Functions/utils";

const options = [
  {
    optionId: 1001,
    name: "Now",
    selected: true,
  },
  {
    optionId: 1002,
    name: "Later",
    selected: false,
  },
  {
    optionId: 1003,
    name: "Subscribe Your Meal",
    selected: false,
  },
];
export default function OrderModal({
  storeId,
  close,
  visible,
  selectedBranch,
  showMealScheduling,
  showMealSubscription,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderInfo, store , client } = useSelector((state) => state);
  const [selectedOption, setSelectedOption] = useState({ optionid: 1001 });
  const [orderButton, setOrderButton] = useState(true);
  const [value, setValue] = useState("");
  // orderInfo.time ? moment(orderInfo.time, 'hh:mm:ss') : new Date()
  let [startDate, setStartDate] = useState( new Date(new Date().setDate(new Date().getDate() + 1)));
  const [dateValue, setDateValue] = useState([new Date(new Date().setDate(new Date().getDate() + 1)), null]);
  const [weekDays, setWeekDays] = useState([
    "sun",
    "mon",
    "tue",
    "wed",
    "thu",
    "fri",
    "sat",
  ]);
  const format = "hh:mm:ss";

  useEffect(() => {
    if (orderInfo.startDate && orderInfo.endDate) {
      setDateValue([
        new Date(orderInfo.startDate),
        new Date(orderInfo.endDate),
      ]);
    }
    setValue("00:00");
  }, []);

  useEffect(() => {
    if(!store?.now && (store?.mealScheduling && store?.mealSubscription)){
      setSelectedOption({ optionid: 1002 });
    }else{
      setSelectedOption({ optionid: 1001 })
    }
  }, [store?.now, store?.mealScheduling, store?.mealSubscription])

  const selectOption = (optionid) => {
    options.map((option, optionIndex) =>
      option.optionId == optionid
        ? (option.selected = true)
        : (option.selected = false)
    );
    setValue(null);
    setSelectedOption({ optionid });
    if (optionid == 1001) {
      setOrderButton(true);
    }
  };

  // const handleClose = () => {
  // 	setSelectedOption({ optionid: 1001 });
  // };
  // const [open, setOpen] = React.useState(false);
  // const toggle = () => setOpen(!open);

  const openOnWeekdays = store?.openOnWeekdays?.map((day) => {
    const x = day + 1;
    if (x === 7) {
      return 0;
    } else {
      return x;
    }
  });

  const handleSelectedDays = () => {
    const days = [{day: "sun", id: 0}, {day: "mon", id: 1}, {day: "tue", id:2}, {day: "wed", id: 3}, {day: "thu", id: 4}, {day: "fri", id: 5}, {day: "sat", id: 6}];
    const selectedDays = days?.filter(el => {
      return openOnWeekdays?.includes(el?.id) && weekDays?.includes(el?.day);
    });
    const filterSelectedDay = selectedDays?.map(el => el?.day);
    return filterSelectedDay;
  }

  const enterStore = () => {
    if (storeId !== orderInfo.storeId) {
      // if storeId does not match with orderInfo.storeId then -> Clear cart, orderInfo, customization
      dispatch(cartActions.clearCartStore());
      dispatch(orderActions.resetOrderStore());
      dispatch(customizationActions.clearCustomizationStore());
      orderInfo.storeId = store.storeId;
    }
    document.body.classList.remove("stop-scrolling");
    orderInfo.preferences["schedulePreferences"] = [];
    orderInfo.deliverNow = null;
    orderInfo.startDate = null;
    orderInfo.noOfDays = 1;
    orderInfo.endDate = null;
    orderInfo.time = null;
    orderInfo.laterTime = null;
    orderInfo.frequencyDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

    if (selectedOption.optionid == 1001 || selectedOption.optionid == 1002) {
      if (selectedOption.optionid == 1001 && store.now) {
        orderInfo.deliverNow = true;
        orderInfo.time = null;
        orderInfo.type = "regular";
      } else {
        if (!startDate) {
          toast.error("Please Enter a Valid Date");
          return;
        }
        if (store?.askTimeForLater && !value) {
          toast.error("Please Enter a Valid Time");
          return;
        }
        // debugger;
        let tempState = cloneDeep(startDate);
        tempState = moment(tempState, "YYYY-MM-DD");
        if (!moment(tempState).isValid()) {
          toast.error("Please Enter a Valid Date");
          return;
        }
        // debugger
        startDate = tempState.toDate();
        const openingTime = moment(store.openingTime, format);
        const closingTime = moment(store.closingTime, format);
        console.log("--------------------",value)

        var selectedTime = value && moment(value, format);
        var selectedDate = startDate.toISOString();
        selectedDate = moment(selectedDate, 'YYYY-MM-DD').format(
          'MM/DD/YYYY'
        );
        var currTime = moment(new Date(), format);
        var currDate = new Date();
        if (
          currDate.toLocaleDateString("en-US") ===
            startDate.toLocaleDateString("en-US") &&
          moment(new Date()).format("HH:mm:ss") >
            selectedTime.format("HH:mm:ss")
        ) {
          toast.error("Store Cannot Serve Today at this time");
          return;
        }
        if (client?.clientType === "STAFF" || client?.clientType === "SCHOOL") {
          orderInfo.deliveryMode = "pickup"
        }
        orderInfo.deliverNow = false;
        orderInfo.time = selectedTime && selectedTime.format("HH:mm");
        orderInfo.startDate = selectedDate;
        // console.log(orderInfo.time);
        orderInfo.storeId = store.storeId;
        orderInfo.type = "later";
        orderInfo.laterTime = store?.askTimeForLater ? selectedTime?.format("HH:mm") : null;
        orderInfo.laterDate = selectedDate;
      }
      orderInfo.orderType = "regular";
      orderInfo.storeId = store.storeId;
      dispatch(orderActions.updateOrderStore(orderInfo));
      history.push(`${store.storeId}/home`);
    } else {
      var selectedTime = value && moment(value, format);
      const openingTime = moment(store?.openingTime, format);
      const closingTime = moment(store?.closingTime, format);
      var currDate = new Date();
      const requiredTotalDates = ((dateValue[1] - dateValue[0]) / (1000 * 3600 * 24)) + 1;
      if (dateValue[0] === null || dateValue[1] === null) {
        toast.error("Please Select Start and End Date");
        return;
      }
      if (requiredTotalDates < 3){
        toast.error("Please Select Atleast 3 days to Continue");
        return;
      }
        if (store?.askTimeForLater && !value) {
          toast.error("Please Enter a Valid Time");
          return;
        }
      
      if (weekDays.length === 0) {
        toast.error("Please Select A day to schedule your meal");
        return;
      }
      if (store?.askTimeForLater && !selectedTime?.isBetween(openingTime, closingTime)) {
        toast.error("Store Cannot Serve In Selected Time");
        return;
      }
      // console.log(
      // 	currDate.toLocaleDateString('en-US') ===
      // 		dateValue[0].toLocaleDateString('en-US'),
      // 	selectedTime.format('HH:mm:ss'),
      // 	moment(currDate,format).format('HH:mm:ss'),
      // 	'true true'
      // );
      if (
        currDate.toLocaleDateString("en-US") ===
          dateValue[0].toLocaleDateString("en-US") &&
        moment(currDate, format).format("HH:mm:ss") >
          selectedTime.format("HH:mm:ss")
      ) {
        toast.error("Store Cannot Serve Today at this time");
        return;
      }
      // var selectedTime = moment(value, format);
      orderInfo.orderType = "schedule";
      orderInfo.startDate = dateValue[0].toISOString();
      orderInfo.endDate = dateValue[1].toISOString();
      orderInfo.time = selectedTime && selectedTime.format("HH:mm");
      orderInfo.storeId = store.storeId;
      orderInfo.frequencyDays = handleSelectedDays();
      orderInfo.noOfDays = 1;
      orderInfo.type = "later";
      console.log({value});
      orderInfo.preferences["schedulePreferences"] = value && value.toLocaleTimeString();
      dispatch(orderActions.updateOrderStore(orderInfo));
      history.push(`${store.storeId}/menu-card/schedule/schedule`);
    }
    setSelectedOption({ optionid: 1001 });

    options.map((option, optionIndex) =>
      option.optionId == 1001
        ? (option.selected = true)
        : (option.selected = false)
    );
  };

  const offDates = store?.offDates?.map(
    (date) => new Date(moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"))
  );


  const disableDate = (day, dateArr) => {
    const boolArr = dateArr?.map((date) => compareDates(day, date)) ?? [];
    return (boolArr.length > 0 && boolArr.includes(true)) || (openOnWeekdays && !openOnWeekdays?.includes(day.getDay()));
  };

  return (
    <div
      className={`font-open-sans  mt-12 mb-4 sm:w-8/12 m-auto sm:mt-16 ${
        selectedOption.optionId === 1001 ? "h-2/6" : ""
      }`}
    >
      <div
        className={`max-h-full mt-20 bg-FFFFFF py-5 px-5 rounded text-gray-800 w-fit `}
      >
        {((store.now && store.mealScheduling) || store.mealSubscription) && (
          <div className="flex justify-between items-start text-center">
            <h4 className="text-sm font-bold text-center w-full">
              {store?.now
                ? "Choose Your Order Type...!!!"
                : "Select Delivery Time and Date"}
            </h4>
          </div>
        )}
        <div
          className={`${
            selectedOption.optionId === 1001 ? "" : "h-2/6 max-h-5/6"
          }`}
        >
          <div className="flex space-x-8 bg-no-repeat rounded items-center p-2 order-modal-container justify-evenly">
            {(store?.now || store?.mealSubscription) &&
              options.map((option, optionIndex) => {
                if (
                  (optionIndex === 0 && store?.now) ||
                  (optionIndex === 1 && store?.mealScheduling) ||
                  (optionIndex === 2 && store?.mealSubscription)
                ) {
                  return (
                    <div className="space-y-2 text-sm">
                      <div key={optionIndex}>
                        <div className="flex items-center">
                          <input
                            onChange={() => selectOption(option.optionId)}
                            type="radio"
                            id={option.name}
                            name="options"
                            value={option.selected}
                            defaultChecked={option.selected}
                            checked={selectedOption.optionid == option.optionId}
                          />
                          <label className="ml-4" htmlFor={option.name}>
                            {option.name}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>

          {store?.askTimeForLater &&
            (!store.now ||
              selectedOption.optionid == 1002 ||
              selectedOption.optionid == 1003) && (
              <div
                className="max-w-xs outline-1"
                style={{
                  maxWidth: "90%",
                  maxHeight: "35%",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticTimePicker
                    value={value}
                    bool={false}
                    onChange={(newValue) => {
                      if (moment(newValue).isValid()) {
                        setValue(newValue);
                      }
                    }}
                    ampm={false}
                    renderInput={(params) => (
                      <TextField
                        className="max-w-xs w-full opacity-0 "
                        {...params}
                      />
                    )}
                    disableIgnoringDatePartForTimeValidation
                  />
                </LocalizationProvider>
              </div>
            )}
          {(!store.now || selectedOption.optionid == 1002) &&
            selectedOption?.optionid !== 1003 && (
              <div>
                <div className="mt-4 font-bold justify-between items-start text-center">
                  Select day for your order?
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: "25rem",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={startDate}
                      label="Select Date"
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      minDate={
                        new Date(new Date().setDate(new Date().getDate() + 1))
                      }
                      maxDate={
                        store?.orderLaterMaxDays &&
                        new Date(
                          new Date().setDate(
                            new Date().getDate() + store?.orderLaterMaxDays
                          )
                        )
                      }
                      ampm={true}
                      ampmInClock={true}
                      renderInput={(params) => (
                        <TextField {...params} className="max-w-sm" />
                      )}
                      disableIgnoringDatePartForTimeValidation
                      shouldDisableDate={(day) => disableDate(day, offDates)}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            )}
          {selectedOption.optionid == 1003 && (
            <div>
              <div className="mt-4 font-bold text-base">
                How you want to subscribe your meals?
              </div>
              <div
                style={{
                  maxWidth: "25rem",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDateRangePicker
                    minDate={new Date(Date.now() + 86400000)}
                    displayStaticWrapperAs="desktop"
                    value={dateValue}
                    // calenders={1}
                    // disableOpenPicker
                    onChange={(newValue) => {
                      setDateValue(newValue);
                    }}
                    shouldDisableDate={(day) => disableDate(day, offDates)}
                    renderInput={(startProps, endProps) => (
                      <React.Fragment>
                        <TextField {...startProps} className="max-w-sm" />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <TextField {...endProps} className="max-w-sm" />
                      </React.Fragment>
                    )}
                  />
                </LocalizationProvider>
                <div className="mt-4 font-bold text-base">Selected Days</div>
              </div>
              <div className="max-w-sm max-w-sm order-modal-container">
                <ToggleDays
                  days={weekDays}
                  setDays={setWeekDays}
                  openOnWeekdays={openOnWeekdays}
                />
              </div>
            </div>
          )}
        </div>
        <div className="pb-4">
          {orderButton && (
            <button
              className="block lg:block mt-8 mb-8 h-12 w-full bg-8593FF rounded font-semibold font-open-sans text-m text-FFFFFF capitalize  cursor-pointer order-modal-container hover:bg-indigo-500 max-w-full"
              onClick={enterStore}
            >
              Continue Ordering
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
