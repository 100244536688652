import React from "react";

export default function Modal({
  visible,
  submit,
  close,
  title,
  text,
  closingText,
  submittingText,
  image
}) {
  return (
    <div className={`${visible ? "block" : "hidden"}`}>
      <div className='fixed w-full h-full z-50 left-0 top-0 bg-black opacity-50'></div>
      <div className='fixed inset-0 flex justify-center items-center z-50'>
        <div className='bg-white max-w-sm py-5 px-5 rounded shadow-xl text-gray-800'>
          <div className='flex justify-between items-start'>
            <h4 className='text-lg font-bold'>{title}</h4>
            <svg
              onClick={close}
              className='h-6 w-8 cursor-pointer px-0.5 py-1 hover:bg-gray-300 rounded-full'
              id='close-modal'
              fill='currentColor'
              viewBox='0 0 20 20'>
              <path
                fill-rule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clip-rule='evenodd'></path>
            </svg>
          </div>
          <div className='mt-2 text-sm'>
            <p>{text}</p>
          </div>
          <div className='mt-2 text-sm'>
            {image && <img className='mt-2' src={image} alt='qrcode'/>}
          </div>
          <div className='mt-3 flex justify-end space-x-3'>
            {close && (
              <button
                onClick={close}
                className='px-3 py-1 rounded hover:bg-red-300 hover:bg-opacity-50 hover:text-red-900'>
                {closingText}
              </button>
            )}
            {submit && (
              <button
                onClick={submit}
                className='px-3 py-1 bg-red-600 text-gray-200 hover:bg-red-600 rounded'>
                {submittingText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
