import React from "react";

export default function Filters({
  filters,
  addOrRemoveFilter,
  clear,
  cancel,
  save,
}) {
  const close = () => {
    document.body.classList.remove("stop-scrolling");
    cancel();
  };

  const saveFilter = () => {
    save();
    document.body.classList.remove("stop-scrolling");
    cancel();
  };
  return (
    <div className='flex flex-col h-full'>
      <div className='p-6 font-open-sans w-full sm:w-9/12 m-auto mt-8'>
        {filters.map((filter, level) => (
          <div key={level}>
            <div className='flex justify-between mx-1 my-3 items-center'>
              <div className='text-base font-semibold'>{filter.name}</div>
              {level === 0 && (
                <div
                  onClick={clear}
                  className='text-xs text-7FB069 cursor-pointer'>
                  clear
                </div>
              )}
            </div>
            <div className='flex flex-wrap justify-between'>
              {filter.filtersOptions.map((option, index) => (
                <div
                key={index}
                  onClick={() =>
                    addOrRemoveFilter(
                      level,
                      index,
                      !option.selected,
                      filter.type
                    )
                  }
                  className={`flex-grow px-5 py-3 border-2 text-center ${
                    option.selected ? "border-7FB069" : "border-D0D0D0"
                  } rounded-full m-1  ${
                    option.selected ? "text-7FB069 font-semibold" : "text-black"
                  } cursor-pointer hover:text-7FB069 hover:border-D0D0D0`}>
                  {option.name}
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className='flex mx-1 my-4 space-x-4'>
          <button
            onClick={saveFilter}
            className='bg-8593FF rounded-full h-10 flex-1 text-white outline-none'>
            Save
          </button>
          <button
            onClick={close}
            className='border-2 font-semibold text-8593FF border-8593FF rounded-full h-10 flex-1 outline-none'>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
