/** @format */

import React from "react";

const RecipeCard = ({ step: { description, items ,name,type}, index }) => {
  return (
    <div className='flex flex-col space-y-1'>
      <div>
        <div className='text-left not-italic font-semibold font-open-sans text-sm max-w-screen'>
          Step {index + 1} : {`${name}`}{type?(type=="REPLACEABLE"?"(Ingredients Replacement Possible)":"(Removing Ingredients Possible)"):""}
        </div>
      </div>
      <div className='flex flex-row space-x-2 max-w-screen'>
        {Object.keys(items).length !== 0 ? (
          Object.keys(items)
            .map((item, index) => (
              <div key={index} className='bg-no-repeat overflow-hidden rounded border-2 border-grey-100 hover:border-gray-300'>
                <img
                  src={items[item].image}
                  alt='food items'
                  className='object-cover w-32 h-32 '
                />
              </div>
            ))
        ) : (
          <div className='h-32'></div>
        )}
      </div>
    </div>
  );
};

export default RecipeCard;
