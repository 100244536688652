import React, { useState, useEffect } from "react";
import SubHeader from "../../Components/Header/SubHeader";
import MyOrderCard from "../../Components/Cards/MyOrderCard/MyOrderCard";
import Address from "./Address";
import Student from "./Student";
import pickupimage from "../../Assets/Images/pickup.png";
import DeliverySlots from "./DeliverySlots";
import deliveryMan from "../../Assets/Icons/delivery-man.svg";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SmallFoodCart from "../../Components/Cards/SmallFoodCard/SmallFoodCart";
import { authActions, menuActions, orderActions } from "../../Redux/Actions";
import toast from "react-hot-toast";
import SideNav from "../../Components/SideNav/SideNav";
import CustomizationSlider from "../FoodDetail/CustomizationSlider";
import { cloneDeep } from "lodash";
import Staff from "./Staff";

const unSelected = "border border-8593FF rounded h-10 w-full sm:w-40 ";
const selected = "bg-8593FF rounded h-10 w-full sm:w-40 text-white";

export default function CheckOut() {
  const { orderInfo, menu, cart, auth, client } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDeliverySlots, setShowDeliverySlots] = useState(false);
  const [, setSelectedSlot] = useState(null);
  const [divHeight, setDivHeight] = useState(510);
  const { restaurantId } = useParams();
  // Open and closes the slider for customization
  const [isCustomizationsVisible, setIsCustomizationsVisible] = useState(false);
  const [selectFoodItem, setSelectFoodItem] = useState({
    foodItem: {},
    appliedCustomization: {},
    index: 0,
  });
  const defaultCategory = menu.menu[0]?.categoryId;

  const { deliverNow, startDate, endDate, time, orderType } = orderInfo;

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("IN Check oUt page");
  }, []);

  useEffect(() => {
    if(auth.redirectTo){
      dispatch(authActions.redirect(null))
    };
  }, [auth.redirectTo])

  const onSelectNow = () => {
    orderInfo.time = "";
    orderInfo.startDate = "";
    orderInfo.endDate = "";
    orderInfo.deliverNow = true;
    setShowDeliverySlots(false);
    dispatch(orderActions.updateOrderStore(orderInfo));
  };
  const onSelectLater = () => {
    orderInfo.time = "";
    orderInfo.startDate = "";
    orderInfo.endDate = "";
    orderInfo.deliverNow = false;
    setShowDeliverySlots(false);
    dispatch(orderActions.updateOrderStore(orderInfo));
  };

  const handleVerification = async () => {
    const isVerified = await authActions.verifyAuth();
    if(auth?.auth && !isVerified){
        toast.error("Session expired");
       history.push("/");
       dispatch(authActions.userLogout());
       return;
    }
  };

  const proceedToPay = () => {
    handleVerification();
    if (
      orderInfo.deliveryMode === "delivery" ||
      client.clientType == "SCHOOL" ||
      client.clientType == "STAFF"
    ) {
      if (Object.keys(orderInfo.selectedAddress).length <= 0) {
        if (client.clientType == "SCHOOL") {
          toast.error("Select a child");
        } else if (client.clientType == "STAFF") {
          toast.error("Select a staff");
        } else {
          toast.error("Select an address");
        }
        return;
      }
      if (!auth?.addresses || auth.addresses.length === 0) {
        if (client.clientType == "SCHOOL") {
          toast.error("Please add a child first ");
        }else if (client.clientType == "STAFF") {
          toast.error("Please add a staff first");
        } else {
          toast.error("Please add an address first");
        }
        return;
      }
    }


    if (cart.length <= 0) {
      toast.error("Cart is Empty");
      history.replace(
        `/${restaurantId}/menu-card/${
          orderInfo.orderType === "bulk" || orderInfo.orderType === "schedule"
            ? orderInfo.orderType + "/" + orderInfo.orderType
            : "regular/all"
        }/${defaultCategory}`
      );
      return;
    }
    if (!deliverNow) {
      if ((orderType === "regular" || orderType === "bulk") && startDate) {
        history.push(`/${restaurantId}/check-out/pay`);
      } else if (orderType === "schedule" && startDate && endDate) {
        history.push(`/${restaurantId}/check-out/pay`);
      } else {
        toast.error("Incomplete Time/Slot Details");
      }
      return;
    } else if (deliverNow) {
      history.push(`/${restaurantId}/check-out/pay`);
    }
    return;
  };
  const DeliverySlot = () => (
    <>
      <div className="block px-4 sm:px-9 py-7 bg-white mt-2 rounded">
        <div className="flex items-center">
          <div>
            <img src={deliveryMan} alt="delivery icon" />
          </div>
          <div className="ml-4 sm:ml-8 flex-grow text-base">
            {orderInfo.deliveryMode === "delivery"
              ? "Select Your Delivery Slot"
              : "Select Your Pickup Slot"}
          </div>
        </div>
        <div className="flex space-x-3 mt-3">
          {orderInfo.orderType !== "schedule" && (
            <button
              onClick={() => onSelectNow()}
              className={orderInfo.deliverNow ? selected : unSelected}
            >
              {orderInfo.deliveryMode === "delivery"
                ? "Deliver Now"
                : "Pickup Now"}
            </button>
          )}
          <button
            onClick={() => onSelectLater()}
            className={!orderInfo.deliverNow ? selected : unSelected}
          >
            {orderInfo.deliveryMode === "delivery"
              ? "Deliver Later"
              : "Pickup Later"}
          </button>
        </div>
      </div>
    </>
  );
  const showCustomization = (productId, appliedCustomization, index) => {
    setSelectFoodItem({
      foodItem: menu.products[productId],
      appliedCustomization: JSON.parse(JSON.stringify(appliedCustomization)),
      index,
    });
    setIsCustomizationsVisible(true);
  };
  // Handles the size change in food item
  const onSizeChange = (variant, productId) => {
    let tempMenu = cloneDeep(menu);
    tempMenu.products[productId].selectedVariant = { ...variant };
    dispatch(menuActions.updateMenu(tempMenu));
  };
  useEffect(() => {
    if (document.getElementById("my-order-card")) {
      setDivHeight(document.getElementById("my-order-card").offsetHeight);
    }
  }, [document.getElementById("my-order-card")]);
  return (
    <div className="p-4 sm:p-0 md:pt-1">
      <div className="hidden lg:block">
        <SubHeader
          title="Check-Out"
          link={
            orderInfo.orderType == "regular"
              ? `/${restaurantId}/menu-card/regular/all/${defaultCategory}`
              : ""
          }
        />
      </div>
      <div className="grid lg:grid-cols-5 sm:mt-32 md:mt-12 lg:mt-6 gap-6 mb-24">
        <div className="col-span-3 font-open-sans">
          {orderInfo.deliveryMode === "delivery" && <Address />}
          {orderInfo.deliveryMode === "pickup" &&
            client.clientType != "SCHOOL" && client.clientType != "STAFF" && (
              <div
                className="flex items-center justify-center"
                style={{
                  maxHeight: "510px",
                  height: `${divHeight + 50}px`,
                }}
              >
                <img
                  src={pickupimage}
                  alt="pick-up"
                  className="h-5/6"
                  style={{
                    maxHeight: "510px",
                    height: `${divHeight + 50}px`,
                  }}
                ></img>{" "}
              </div>
            )}
          {client.clientType == "SCHOOL" && <Student props={{ hide: false }} />}
          {client.clientType == "STAFF" && <Staff props={{ hide: false }} />}
        </div>
        {/* {
        orderInfo.deliveryMode !== "delivery" && 
        <div className='pt-20'/>

        } */}
        <div className="hidden lg:block col-span-2 ">
          <div className="sticky top-20">
            <MyOrderCard showCustomization={showCustomization} />
            {orderInfo.orderType !== "bulk" &&
              orderInfo.orderType !== "schedule" &&
              menu.addOn.length > 0 && (
                <div className="py-6">
                  <div className="font-open-sans font-bold">
                    Do you want these items in the cart
                  </div>
                  <div className="mt-4 flex  space-x-3 overflow-x-auto pb-4">
                    {menu.addOn.map((item) => {
                      const foodItem = menu.products[item];
                      if (foodItem.isProductAvailable) {
                        return (
                          <div>
                            <div className="w-32">
                              <SmallFoodCart
                                name={foodItem.name}
                                image={foodItem.image}
                                price={foodItem.selectedVariant.price}
                                productId={foodItem.productId}
                                productType={foodItem.productType}
                                selectedVariant={foodItem.selectedVariant}
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
            <button
              onClick={proceedToPay}
              className="mt-2 h-12 w-full bg-8593FF rounded   font-semibold font-open-sans text-xs text-FFFFFF capitalize  cursor-pointer  hover:bg-indigo-500"
            >
              PROCEED TO PAY
            </button>
          </div>
        </div>
      </div>
      <div
        className="block lg:hidden fixed bottom-0 left-0 right-0 z-10 bg-transparent md:px-20"
        style={{
          zIndex: "15",
        }}
      >
        <div
          onClick={proceedToPay}
          className="bg-8593FF py-3 font-open-sans h-12 text-white text-center text-base"
        >
          PROCEED TO PAY
        </div>
        {/* <div
          onClick={handleCheckout}
          className='font-open-sans bg-8593FF h-12 text-white text-center py-3 cursor-pointer'>
          Proceed To Pay
        </div> */}
      </div>
      {/* To show customization for an item in cart  */}
      <SideNav
        onClose={() => setIsCustomizationsVisible(false)}
        visible={isCustomizationsVisible}
        closable={false}
        mode="light"
      >
        {isCustomizationsVisible && (
          <CustomizationSlider
            type="EDIT"
            index={selectFoodItem.index}
            appliedCustomization={selectFoodItem.appliedCustomization}
            image={selectFoodItem.foodItem.image}
            name={selectFoodItem.foodItem.name}
            isVeg={selectFoodItem.foodItem.isVeg}
            selectedVariant={selectFoodItem.foodItem.selectedVariant}
            variants={selectFoodItem.foodItem.variants}
            customization={selectFoodItem.foodItem.customisation}
            product={selectFoodItem.foodItem}
            handleClose={() => {
              setIsCustomizationsVisible(false);
              document.body.classList.remove("stop-scrolling");
            }}
            orderType={orderInfo.orderType}
            changeSizeHandler={onSizeChange}
          />
        )}
      </SideNav>
    </div>
  );
}
