/** @format */

import { cloneDeep, min } from "lodash";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useWindowDimensions } from "../../Functions/utils";
import { authActions } from "../../Redux/Actions/auth.actions";

export default function StaffSlider(props) {
  const { auth, orderInfo, store } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [addressLine1, setAddressLine1] = useState(() => {
    return props.type === "EDIT" ? props.addressLine1 : "";
  });
  const [addressLine2, setAddressLine2] = useState(() => {
    return props.type === "EDIT" ? props.addressLine2 : "";
  });
  const [email, setEmail] = useState(() => {
    return props.type === "EDIT" ? props.email : auth.email;
  });
  const [userName, setUserName] = useState(() => {
    return props.type === "EDIT" ? props.addressLine1 : "";
  });

  const [city, setCity] = useState(() => {
    return props.type === "EDIT" ? "" : "";
  });

  const clearState = () => {
    setAddressLine1("");
    setAddressLine2("");
    setEmail("");
    setUserName("");
  };

  const changeHandler = (name, value) => {
    if (name === "addressLine1") setAddressLine1(value);
    else if (name === "addressLine2") setAddressLine2(value);
    else if (name === "userName") setUserName(value);
    else if (name === "email") setEmail(value);
    else if (name === "city") setCity(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!addressLine1 || !email) {
      toast.error("Fill all the fields");
      return;
    }

    const emailRegax =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegax.test(String(email).toLowerCase())) {
      toast.error("Enter a valid Email");
      return;
    }
    console.log("before ===", { auth });
    dispatch(
      authActions.addAddress({
        name: addressLine1,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        email: email,
        lat: null,
        long: null,
        storeId: store.storeId,
      })
    );
    const tempAddress = cloneDeep(auth.addresses);

    dispatch(authActions.updateUser({ address: [...tempAddress] }));
    console.log("after ===", auth.addresses);

    // cleaning up state
    clearState();
    document.body.classList.remove("stop-scrolling");
    props.close();
  };
  const { width, height } = useWindowDimensions();

  return (
    <div className="font-open-sans w-9/12 sm:w-7/12 m-auto my-4">
      <form>
        <div className="flex justify-between mt-16">
          <div className="font-bold text-xl">Add a Staff</div>
        </div>
        <p className="pt-2">
          School: <span className="font-semibold">{store.storeName}</span>
        </p>
        <div className="mt-5 h-20 py-2 px-6 border border-8593FF rounded">
          <div className="text-8B8B8B text-xs">Staff Name</div>
          <input
            className="focus:outline-none mt-2 text-base font-semibold w-full"
            onChange={(e) => changeHandler("addressLine1", e.target.value)}
            value={addressLine1}
            type="text"
            name="addressLine1"
          />
        </div>
        <div className="mt-5 h-20 py-2 px-6 border border-8593FF rounded">
          <div className="text-8B8B8B text-xs">Email</div>
          <input
            className="focus:outline-none mt-2 text-base font-semibold w-full"
            onChange={(e) => changeHandler("email", e.target.value)}
            value={email}
            type="text"
            name="email"
          />
        </div>
        {/* <div className='mt-5 h-20 py-2 px-6 border border-8593FF rounded'>
					<div className='text-8B8B8B text-xs'>
						Class
					</div>
					<input
						className='focus:outline-none mt-2 text-base font-semibold w-full'
						onChange={(e) =>
							changeHandler('addressLine1', e.target.value)
						}
						value={addressLine1}
						type='text'
						name='addressLine1'
					/>
				</div> */}
        {/* <Box sx={{ minWidth: 120, marginTop: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="addressLine2-Label">Class</InputLabel>
            <Select
              labelId="addressLine2-Label"
              id="addressLine2"
              value={addressLine2}
              label="Class"
              onChange={(e) => changeHandler("addressLine2", e.target.value)}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
              <MenuItem value={12}>12</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 120, marginTop: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="city-Label">Section</InputLabel>
            <Select
              labelId="city-Label"
              id="city"
              value={city}
              label="Section"
              onChange={(e) => changeHandler("city", e.target.value)}
            >
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
              <MenuItem value="C">C</MenuItem>
              <MenuItem value="D">D</MenuItem>
              <MenuItem value="E">E</MenuItem>
              <MenuItem value="F">F</MenuItem>
              <MenuItem value="G">G</MenuItem>
              <MenuItem value="H">H</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
        {/* <div className='mt-5 h-20 py-2 px-6 border border-8593FF rounded'>
					<div className='text-8B8B8B text-xs'>Section</div>
					<input
						className='focus:outline-none mt-2 text-base font-semibold w-full'
						onChange={(e) =>
							changeHandler('addressLine2', e.target.value)
						}
						value={addressLine2}
						type='text'
						name='addressLine2'
						disabled
					/>
				</div> */}
        <button
          onClick={onSubmit}
          className="mt-10 h-16 w-full bg-8593FF rounded font-semibold font-open-sans text-xs text-FFFFFF capitalize  cursor-pointer  hover:bg-indigo-500"
        >
          {props.type !== "EDIT" ? "ADD AS NEW STAFF" : "SAVE STAFF"}
        </button>
      </form>
    </div>
  );
}
