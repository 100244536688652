/** @format */

import { useCallback, useEffect } from "react";
import { BrowserRouter, Router } from "react-router-dom";
import { Routes } from "./Routes";
import { Fragment, useState } from "react";
import "react-nice-dates/build/style.css";
import { authActions, clientActions } from "./Redux/Actions";
import {
  cartActions,
  orderActions,
  customizationActions,
} from "./Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { updateLocalStorage } from "./Functions/CartFunctions";
import { Toaster } from "react-hot-toast";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Loader } from "./Components/Spinner/Loader";
import { history } from "./Functions/helpersFunctions";
import { useWindowDimensions } from "./Functions/utils";
import ReactGA from "react-ga";

function App() {
  const TRACKING_ID = "G-L7GJ3D7TQF";
  ReactGA.initialize(TRACKING_ID);

  const dispatch = useDispatch();
  const { orderInfo, client, auth } = useSelector((state) => state);
  const [changeInDimenesion, setChangeInDimenesion] = useState(null);
  var device = null;
  var preDevice = null;
  const onResize = useCallback((dimensions) => {
    console.log("changeInDimenesion", changeInDimenesion, "device", device);
    if (dimensions?.width > 576) {
      device = "desktop";
    } else {
      device = "mobile";
    }

    if (preDevice !== device || preDevice == null) {
      preDevice = device;
      dispatch(
        clientActions.getClientDetails({
          params: { screenWidth: dimensions?.width },
        })
      );
    }
  }, []);

  const dimensions = useWindowDimensions({ onResize });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (auth.token) dispatch(authActions.userLogin({ ...auth }));
  }, [auth.addresses]);

  useEffect(() => {
    let cart = localStorage.getItem("cart");
    if(cart === '{}'){
      updateLocalStorage("cart", []);
    }
  }, []);
  
  useEffect(() => {
    // Updating Cart, Customizations, OrderInfo of user from local storage
    // fetch cart
    try {
      let cart = localStorage.getItem("cart");
      let localStorageOrderInfo = localStorage.getItem("orderInfo");
      let customization = localStorage.getItem("customization");
      let auth = localStorage.getItem("auth");

      if (cart) {
        dispatch(cartActions.updateCartStore([...JSON.parse(cart)]));
      } else {
        updateLocalStorage("cart", JSON.stringify([]));
      }
      if (localStorageOrderInfo) {
        dispatch(
          orderActions.updateOrderStore({
            ...JSON.parse(localStorageOrderInfo),
            isLoading: false,
          })
        );
      } else {
        updateLocalStorage("orderInfo", JSON.stringify(orderInfo));
      }
      if (customization) {
        dispatch(
          customizationActions.updateCustomizationStore({
            ...JSON.parse(customization),
          })
        );
      } else {
        updateLocalStorage("customization", JSON.stringify({}));
      }
      if (auth) {
        let authObj = JSON.parse(auth);
        // debugger;
        if (authObj.token) dispatch(authActions.userLogin({ ...authObj }));
        else dispatch(authActions.userLogout());
      } else {
        updateLocalStorage(
          "auth",
          JSON.stringify({
            selectedUserAddress: { location: { lat: "", lng: "" } },
          })
        );
        dispatch(
          authActions.updateUser({
            selectedUserAddress: { location: { lat: "", lng: "" } },
          })
        );
      }
    } catch (error) {
      console.log({ error });
    }

    // calling client details
    dispatch(
      clientActions.getClientDetails({
        params: { screenWidth: dimensions?.width },
      })
    );
  }, []);

  return (
    <Router history={history}>
      {client.isLoading ? (
        <Loader />
      ) : (
        <>
          <Routes />
          <Toaster />
        </>
      )}
    </Router>
  );
}

export default App;
