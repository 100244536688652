import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PhoneLoginImage from "../../Assets/Images/LoginImage.svg";
import Google from "../../Assets/Icons/Google.png";
import OtpCard from "../OtpCard/OtpCard";
import { authActions, creditActions } from "../../Redux/Actions";
import { loginService } from "../../Redux/Services";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

let timeOut;
let otpCountdown;
let countdown;

const otpInputId = [
  "firstOtpInput",
  "secondOtpInput",
  "thirdOtpInput",
  "fourthOtpInput",
  "fifthOtpInput",
  "sixthOtpInput",
];

const emailOtpInpId = [
  "emailOtpInput1",
  "emailOtpInput2",
  "emailOtpInput3",
  "emailOtpInput4",
  "emailOtpInput5",
  "emailOtpInput6",
];

const PhoneLogin = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [verify, setVerify] = useState(false);
  const [email, setEmail] = useState("");
  const [authType, setAuthType] = useState("signIn");
  const [mobResendTime, setMobResendTime] = useState(60);
  const [emailResendTime, setEmailResendTime] = useState(60);
  const [token, setToken] = useState("");
  const [pin, setPin] = useState("");
  const [resendPin, setResendPin] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  // Send Otp function
  const { client, auth, modal } = useSelector((state) => state);

  const startCounter = (type) => {
    let leftTime = 60;
    countdown = setInterval(() => {
      leftTime = leftTime - 1;
      if (type) {
        // eslint-disable-next-line eqeqeq
        type == "email" && setEmailResendTime(leftTime);
        // eslint-disable-next-line eqeqeq
        type == "mobile" && setMobResendTime(leftTime);
      }
      if (!type) {
        setMobResendTime(leftTime);
        setEmailResendTime(leftTime);
      }
      if (leftTime <= 0) {
        clearInterval(countdown);
        setMobResendTime(60);
        setEmailResendTime(60);
        return;
      }
    }, 1000);
  };

  const checkOtp = (OTP, type) => {
    loginService
      .verifyOTP(OTP, type, token)
      .then(
        (res) => {
          if (res?.statusCode === 200) {
            type === "mobile" && setMobileVerified(true);
            type === "email" && setEmailVerified(true);
            toast.success(res?.message ?? "Success");
          } else if (res?.statusCode === 201) {
            setEmailVerified(true);
            setMobileVerified(true);
            toast.success(res?.message ?? "Success");
            setVerify(false);
            handleTabChange("signIn");
          } else {
            console.log({ res });
            toast.error(
              res?.errorMessage ?? res?.message ?? "Some error occured"
            );
          }
        },
        (err) => {
          // debugger
          toast.error(err);
        }
      )
      .catch((err) => {
        otpInputId.forEach((id) => {
          document.getElementById(id).value = "";
        });
        toast.error(err.message);
      });
  };

  const signInUser = async () => {
    let phoneRegx = /^\d{10}$/;
    let emailRegx =
      /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;

    if (phone && !phone.match(phoneRegx))
      toast.error("Phone number is not valid");

    if (email && !email.match(emailRegx))
      toast.error("Email address not Valid");

    if (!email && !phone)
      toast.error("Please Enter Number or Email to Sign In");

    if (email && phone)
      toast.error("Please enter either of mobile or email to Login");

    if ((phone.match(phoneRegx) || email.match(emailRegx)) && !pin)
      toast.error("Please Enter Passcode");

    if (
      (phone.match(phoneRegx) || email.match(emailRegx)) &&
      !(email && phone) &&
      pin
    ) {
      try {
        const response = await loginService.signInUser(
          pin,
          email && email,
          phone && phone
        );
        if (response?.statusCode === 200) {
          toast.success(response?.message ?? "Successfully Signed In");
          handleTabChange();
          dispatch(
            authActions.userLogin({
              token: response?.data?.token,
              ...response?.data?.userDetails,
              addresses: response?.data?.userDetails?.addresses?.reverse(),
              user: response?.data?.userDetails?.mobile,
            })
          );
          history.goBack();
        } else {
          toast.error(response?.errorMessage ?? "Some Error Occured");
        }
      } catch (error) {
        toast.error(error?.message ?? error ?? "Some error occured");
      }
    }
  };

  const signUpUser = async () => {
    let phoneRegx = /^\d{10}$/;
    let emailRegx =
      /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;

    if (!name) toast.error("Not a valid name");
    if (name && !phone.match(phoneRegx))
      toast.error("Phone number is not valid");
    if (
      phone.match(phoneRegx) &&
      (!email || (email && !email.match(emailRegx)))
    )
      toast.error("Email address not Valid");

    if (!pin) toast.error("Invalid Passcode");
    if (
      name &&
      phone.match(phoneRegx) &&
      email &&
      email.match(emailRegx) &&
      pin
    ) {
      try {
        const response = await loginService.signUpUser(name, phone, email, pin);
        if (response?.statusCode === 200) {
          toast.success(response?.message ?? "Success");
          setVerify(true);
          startCounter();
          setToken(response?.signUpToken);
        } else {
          toast.error(response?.errorMessage ?? "Some error occured");
        }
      } catch (error) {
        toast.error(error?.message ?? error);
      }
    }
  };

  const resendOtp = (type) => {
    loginService
      .resendOtp(type, token)
      .then(
        (res) => {
          console.log({ res });
          if (res?.statusCode === 200) {
            toast.success(res?.message ?? "Success");
            startCounter(type);
          } else {
            console.log({ res });
            toast.error(
              res?.errorMessage ?? res?.message ?? "Some error occured"
            );
          }
        },
        (err) => {
          toast.error(err);
        }
      )
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const resendUserPin = async () => {
    let phoneRegx = /^\d{10}$/;
    let emailRegx =
      /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;

    if (phone && !phone.match(phoneRegx))
      toast.error("Phone number is not valid");

    if (email && !email.match(emailRegx))
      toast.error("Email address not Valid");

    if (!email && !phone) toast.error("Please Enter Number or Email");

    if (email && phone) toast.error("Please enter either of mobile or email");

    if (
      (phone.match(phoneRegx) || email.match(emailRegx)) &&
      !(email && phone)
    ) {
      try {
        const response = await loginService.resendPin(
          email && email,
          phone && phone
        );
        if (response?.statusCode === 200) {
          toast.success(response?.message ?? "Success");
          handleTabChange("signIn");
        } else {
          toast.error(response?.errorMessage ?? "Some Error Occured");
        }
      } catch (error) {
        toast.error(error?.message ?? error ?? "Some error occured");
      }
    }
  };

  const handleTabChange = (type) => {
    type && setAuthType(type);
    setName("");
    setPhone("");
    setEmail("");
    setPin("");
    setMobResendTime(60);
    setEmailResendTime(60);
    if(verify){
      setVerify(false);
    }
  };

  return (
    <div className="grid grid-cols-6 lg:grid-cols-7">
      <div className="col-span-6 sm:col-span-4 sm:col-start-2 lg:col-span-3 lg:col-start-3">
        <div className="bg-FFFFFF bg-no-repeat rounded-md shadow-sm font-open-sans m-4 py-10 px-4 sm:px-8 lg:12 space-y-6">
          <div className="h-24 w-40 mx-auto">
            <img
              src={PhoneLoginImage}
              alt="main banner"
              className="h-full w-full"
            />
          </div>
          <div className="space-y-4">
            <div>
              {!resendPin && authType === "signIn" && (
                <div className="space-y-2">
                  <div className="font-bold text-left text-base text-black capitalize">
                    LOGIN
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="text-left text-xs capitalize">Mobile</div>
                    <div className="flex border-b-4 border-7FB069 w-full">
                      <input
                        type="text"
                        value="+91"
                        disabled
                        className="bg-FFFFFFAD bg-no-repeat border-t border-l border-gray-300 rounded-l h-10 w-14 p-3"
                      />
                      <input
                        type="number"
                        className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-10 w-full p-3 focus:outline-none"
                        value={phone}
                        onChange={(e) => {
                          if (e.target.value.length < 11)
                            setPhone(e.target.value);
                        }}
                        max={9999999999}
                      />
                    </div>
                  </div>
                  <div className="text-center text-2xs text-8B8B8B capitalize">
                    OR
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="text-left text-xs capitalize">Email</div>
                    <div className="border-b-4 border-7FB069 w-full">
                      <input
                        type="email"
                        className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-10 w-full p-3 focus:outline-none"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex items-center gap-2 py-2">
                      <div className="text-left text-xs capitalize">
                        Passcode
                      </div>
                      <OtpCard
                        checkOtpHandler={setPin}
                        otpType={"mobilePin"}
                        hideConfirm
                      />
                    </div>
                    <div className="pt-2 text-xs ">
                      Enter{" "}
                      <span className="text-black font-bold"> First Four </span>{" "}
                      digits of Your Passcode
                    </div>
                  </div>
                  <div
                    className="bg-8593FF bg-no-repeat rounded py-3 font-semibold text-center text-white text-sm cursor-pointer hover:bg-indigo-500"
                    onClick={signInUser}
                  >
                    SIGN IN
                  </div>
                </div>
              )}

              {!resendPin && authType === "signUp" && (
                <div className="space-y-4">
                  <div className="font-bold text-left text-base text-black capitalize">
                    {verify ? "VERIFY DETAIL" : "SIGN UP"}
                  </div>

                  <div className="text-left text-2xs text-8B8B8B capitalize">
                    {verify && !mobileVerified && "Enter Mobile OTP"}
                  </div>
                  <div>
                    {verify ? (
                      <div>
                        {mobileVerified ? (
                          <div className="font-bold text-center text-lg text-green-600 capitalize">
                            {emailVerified && mobileVerified
                              ? "Mobile and Email Verified Successfully"
                              : "Mobile Number verified"}
                          </div>
                        ) : (
                          <>
                            <OtpCard
                              checkOtpHandler={checkOtp}
                              inputId={otpInputId}
                              otpType={"mobile"}
                            />
                            {mobResendTime !== 60 ? (
                              <div className="text-center text-base text-D17B69">
                                Resend in {mobResendTime} sec
                              </div>
                            ) : (
                              <div
                                className="text-center text-base text-0372BC cursor-pointer"
                                onClick={() => {
                                  resendOtp("mobile");
                                }}
                              >
                                Resend OTP
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="flex items-center justify-between gap-7">
                        <div className="text-left text-2xs text-8B8B8B capitalize">
                          Name
                        </div>
                        <div className="border-b-4 border-7FB069 w-full">
                          <input
                            type="email"
                            className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-10 w-full p-3 focus:outline-none"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="text-left text-2xs text-8B8B8B capitalize">
                    {verify && !emailVerified && "Enter Email OTP"}
                  </div>
                  <div>
                    {verify ? (
                      <div>
                        {emailVerified ? (
                          !mobileVerified && (
                            <div className="font-bold text-center text-green-600 capitalize">
                              Email Address verified
                            </div>
                          )
                        ) : (
                          <>
                            <OtpCard
                              checkOtpHandler={checkOtp}
                              inputId={emailOtpInpId}
                              otpType={"email"}
                            />
                            {emailResendTime !== 60 ? (
                              <div className="text-center text-base text-D17B69">
                                Resend in {emailResendTime} sec
                              </div>
                            ) : (
                              <div
                                className="text-center text-base text-0372BC cursor-pointer"
                                onClick={() => {
                                  resendOtp("email");
                                }}
                              >
                                Resend OTP
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="flex items-center justify-between gap-4">
                        <div className="text-left text-2xs text-8B8B8B capitalize">
                          Number
                        </div>
                        <div className="flex border-b-4 border-7FB069 w-full">
                          <input
                            type="text"
                            value="+91"
                            disabled
                            className="bg-FFFFFFAD bg-no-repeat border-t border-l border-gray-300 rounded-l h-10 w-14 p-3"
                          />
                          <input
                            type="number"
                            className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-10 w-full p-3 focus:outline-none"
                            value={phone}
                            onChange={(e) => {
                              if (e.target.value.length < 11)
                                setPhone(e.target.value);
                            }}
                            max={9999999999}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {!verify && (
                    <>
                      <div className="flex items-center justify-between gap-8">
                        <div className="text-left text-2xs text-8B8B8B capitalize">
                          Email
                        </div>
                        <div className="flex border-b-4 border-7FB069 w-full">
                          <input
                            type="email"
                            className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-10 w-full p-3 focus:outline-none"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="text-left text-2xs text-8B8B8B capitalize">
                          Setup a Passcode
                        </div>
                        <OtpCard
                          checkOtpHandler={setPin}
                          otpType={"signUpPin"}
                          hideConfirm
                        />
                      </div>
                      <div
                        onClick={signUpUser}
                        className="bg-8593FF bg-no-repeat rounded py-3 font-semibold text-center text-white text-sm cursor-pointer hover:bg-indigo-500"
                      >
                        SIGN UP
                      </div>
                    </>
                  )}
                  {verify && (
                    <div
                      className="text-red-500 text-center text-sm"
                      onClick={() => handleTabChange("signUp")}
                    >
                      Cancel SignUp
                    </div>
                  )}
                </div>
              )}

              {resendPin && authType === "signIn" && (
                <div className="space-y-2">
                  <div className="font-bold text-left text-base text-black capitalize">
                    RESEND PASSCODE
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="text-left text-2xs text-8B8B8B capitalize">
                      Number
                    </div>
                    <div className="flex border-b-4 border-7FB069 w-full">
                      <input
                        type="text"
                        value="+91"
                        disabled
                        className="bg-FFFFFFAD bg-no-repeat border-t border-l border-gray-300 rounded-l h-10 w-14 p-3"
                      />
                      <input
                        type="number"
                        className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-10 w-full p-3 focus:outline-none"
                        value={phone}
                        onChange={(e) => {
                          if (e.target.value.length < 11)
                            setPhone(e.target.value);
                        }}
                        max={9999999999}
                      />
                    </div>
                  </div>
                  <div className="text-center text-2xs text-8B8B8B capitalize">
                    OR
                  </div>
                  <div className="flex items-center gap-6 pb-2">
                    <div className="text-left text-2xs text-8B8B8B capitalize">
                      Email
                    </div>
                    <div className="border-b-4 border-7FB069 w-full">
                      <input
                        type="email"
                        className="bg-FFFFFFAD bg-no-repeat border-t border-r border-l border-gray-300 rounded-r h-10 w-full p-3 focus:outline-none"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className="bg-8593FF bg-no-repeat rounded py-3 font-semibold text-center text-white text-sm cursor-pointer hover:bg-indigo-500"
                    onClick={resendUserPin}
                  >
                    RESEND PASSCODE
                  </div>
                </div>
              )}
              {!verify && (
                <div
                  className="bg-transparent border-2 border-8593FF rounded mt-2 py-3 font-semibold text-center text-sm cursor-pointer"
                  onClick={() => {
                    handleTabChange(
                      !resendPin
                        ? authType === "signIn"
                          ? "signUp"
                          : "signIn"
                        : "signIn"
                    );
                    setResendPin(false);
                  }}
                >
                  {!resendPin &&
                    (authType === "signIn" ? "SIGN UP" : "SIGN IN")}
                  {resendPin && "SIGN IN"}
                </div>
              )}
            </div>
            <div className="flex justify-around">
              {!resendPin && authType === "signIn" && (
                <div className="flex space-x-8">
                  <div
                    className="text-center text-base text-0372BC cursor-pointer"
                    onClick={() => {
                      setResendPin(true);
                    }}
                  >
                    Resend Passcode
                  </div>
                </div>
              )}
              {/*  <div className='text-center text-base text-0372BC cursor-pointer'>
                Forgot Password?
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneLogin;
