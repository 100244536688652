/** @format */

import React, { useEffect, useState, useCallback } from "react";
import SubHeader from "../../Components/Header/SubHeader";
import Student from "../CheckOut/Student";
import { useSelector } from "react-redux";

const MyChild = () => {
  const { orderInfo, credit } = useSelector((state) => state);
  if (orderInfo.storeId) {
    var linkadd = `/${orderInfo.storeId}/home`;
  } else {
    var linkadd = `/home`;
  }

  return (
    <div
      className='h-100 bg-white'
      style={{
        minHeight: "calc(100vh - 320px)",
      }}>
      <div className='hidden lg:block'>
        <SubHeader title='My Child' link={linkadd} />
      </div>
      <div className="mt-2">
        <Student props={{"hide":true}}/>
      </div>
    </div>
  );
};

export default MyChild;
