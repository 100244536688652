import { userConstants } from "../Constants";
import { updateLocalStorage } from "../../Functions/CartFunctions";
import { loginService } from "../Services";
import toast from "react-hot-toast";
import { orderActions } from "./order.actions";

function add(payload) {
  return { type: userConstants.USER_SET, payload };
}
function update(payload) {
  return { type: userConstants.USER_UPDATE, payload };
}
function remove(payload) {
  return { type: userConstants.USER_REMOVE, payload };
}

function redirectTO(payload) {
  return { type: userConstants.REDIRECT, payload };
}

export const addAddress = (req) => {
  return (dispatch, getState) => {
    dispatch(request());
    loginService.addAddress(req).then(
      (data) => {
        dispatch(success({ ...req, qrCode: data?.qrCode, id: data?.id }));
        toast.success("Added Succesfully");
      },
      (error) => {
        if (error?.statusCode === 500) {
          dispatch(authActions.userLogout());
          toast.error("Session expired");
          dispatch(authActions.redirect("/"));
        } else {
          toast.error("Problem in Adding Address ");
        }
        dispatch(failure({ ...error }));
      }
    );
  };

  function request() {
    return { type: userConstants.ADD_ADDRESS_REQUEST };
  }
  function success(payload) {
    return { type: userConstants.ADD_ADDRESS_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: userConstants.ADD_ADDRESS_FAILURE, payload };
  }
};
export const userLogin = (user) => {
  return (dispatch, getState) => {
    dispatch(add(user));
    updateLocalStorage("auth", JSON.stringify({ ...getState().auth, ...user }));
  };
};

export const redirect = (url) => {
  return (dispatch) => {
    dispatch(redirectTO(url));
  };
};

export const userLogout = () => {
  const localStorageAuth = JSON.parse(localStorage.getItem("auth"));
  return (dispatch, getState) => {
    dispatch(
      remove(
        localStorageAuth?.selectedUserAddress ?? {
          address: "",
          location: {
            lat: "",
            lng: "",
          },
        }
      )
    );
    dispatch(orderActions.resetOrderStore());
    updateLocalStorage(
      "auth",
      JSON.stringify({
        selectedUserAddress: localStorageAuth?.selectedUserAddress ?? {
          address: "",
          location: {
            lat: "",
            lng: "",
          },
        },
      })
    );
    updateLocalStorage("orderInfo", JSON.stringify({}));
    // updateUser({
    // 	selectedUserAddress: localStorageAuth.selectedUserAddress ?? {
    // 		location: {
    // 			lat: '',
    // 			lng: '',
    // 		},
    // 	},
    // });
  };
};

export const userSignOut = () => {
  return (dispatch, getState) => {
    loginService.signOut().then(
      (data) => {
        toast.success("Sign out Succesfully");
      },
      (error) => {
        toast.error("Problem in Signing out ");
      }
    );
  };
};

export const verifyAuth = async () => {
  let verified = "";
  try {
    const response = await loginService.verifyToken();
    if (response?.statusCode === 200) {
      verified = true;
    }
  } catch (error) {
    verified = false;
  }
  return verified;
};

export const updateUser = (user) => {
  return (dispatch, getState) => {
    dispatch(update(user));
    updateLocalStorage(
      "auth",
      JSON.stringify({
        ...getState().auth,
        ...user,
        // addresses: [...(user?.address ?? [])],
      })
    );
  };
};

function setOrderAddress(payload) {
  return { type: userConstants.SET_USER_ORDER_ADDRESS, payload };
}
export const authActions = {
  userLogin,
  userLogout,
  updateUser,
  redirect,
  addAddress,
  setOrderAddress,
  verifyAuth,
  userSignOut,
};
