import React, { useEffect, useState, useCallback } from "react";
import { modalActions, creditActions } from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { creditService } from "../../Redux/Services";
import toast from "react-hot-toast";
import { Loader } from "../../Components/Spinner/Loader";
import moment from "moment";

const OPTIONS = ["50", "100", "250", "500"];

const MyCredits = () => {
  const dispatch = useDispatch();
  const [credits, setCredits] = useState(0);
  const { credit } = useSelector((state) => state);

  useEffect(() => {
    dispatch(creditActions.getCreditDetails());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    closePaytm();
  }, []);

  const addCredits = () => {
    if (moment().diff(moment([2024, 2, 29]), "d") >= 0) {
      return;
    }
    if (credits >= 1) {
      dispatch(creditActions.initiateCredits({ amount: credits }));
      dispatch(modalActions.togglePaytmModal(true));
    } else {
      toast.error("Please add atleast 1 Rs.");
    }
  };

  const incrementValue = useCallback((value) => {
    return () => {
      setCredits((prevCredits) => {
        return parseInt(prevCredits ?? 0) + parseInt(value ?? 0);
      });
    };
  }, []);

  const closePaytm = () => {
    if (
      window.Paytm &&
      window.Paytm.CheckoutJS &&
      window.Paytm.CheckoutJS.close
    ) {
      window.Paytm.CheckoutJS.close();
    }
    modalActions.togglePaytmModal(false);
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setCredits(value);
  };

  return (
    <div
      className="h-100 bg-white"
      style={{
        minHeight: "calc(100vh - 320px)",
      }}
    >
      <p className="bg-white text-xl p-4 border-b-2">My Credits</p>
      <p className="bg-white p-4">
        You currently have{" "}
        <span className="font-semibold">{credit.balance ?? "-"} credits</span>
      </p>
      {!credit.isLoading && (
        <div className="flex-col justify-center align-center bg-white px-4 py-20 space-y-4">
          <div className="flex justify-center align-center">
            <input
              name="credit"
              type="number"
              step="1"
              onInput={handleInput}
              required
              className="bg-FFFFFFAD text-lg bg-no-repeat border border-gray-300 rounded-l h-12 p-3 outline-none w-60"
              min={0}
              value={credits}
            />
            <button
              className={
                "font-open-sans font-semibold h-12 text-white w-28 text-md rounded-r  hover:text-white " +
                (moment().diff(moment([2024, 2, 29]), "d") >= 0
                  ? " bg-gray-300 "
                  : " bg-8593FF hover:bg-gray-700 ")
              }
              onClick={() => {
                addCredits();
              }}
            >
              Add Credits
            </button>
          </div>
          <div className="flex justify-center align-center space-x-3">
            {OPTIONS.map((option) => {
              return (
                <button
                  onClick={incrementValue(option)}
                  className="font-open-sans font-semibold text-lg h-12 text-white w-20 bg-8593FF rounded hover:bg-gray-700 hover:text-white"
                >
                  +{option}
                </button>
              );
            })}
          </div>
          <div className="flex justify-center">
            <div className="  lg:w-2/3 w-full pt-10 text-red-700 font-semibold text-lg text-center">
              {moment().diff(moment([2024, 2, 29]), "d") >= 0 ? (
                <>
                  Add credits has been disabled as Scoolmeal is down for
                  maintenance . For any query contact scoolmeal team at
                  <span className="px-1 font-bold ">8971958284</span>
                  or email at
                  <span className="px-1 font-bold ">info@scoolmeal.com</span>
                </>
              ) : (
                <>
                  Scoolmeal services will be down for maintenance from
                  <div className="px-1 font-bold inline-block animate-pulse  text-red-500">
                    29th March 2024
                  </div>
                  . Please add credits accordingly. For any query contact
                  scoolmeal team at
                  <span className="px-1 font-bold ">8971958284</span>
                  or email at
                  <span className="px-1 font-bold ">info@scoolmeal.com</span>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {credit.isLoading && <Loader />}
    </div>
  );
};

export default MyCredits;
