/** @format */
import SubHeader from '../../Components/Header/SubHeader';
import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Star from '../../Components/Star/Star';
import SuccessImage from '../../Assets/Icons/gotcha.svg';
import Peep from '../../Assets/Icons/PeepIntoKitchen.svg';
import cooking from '../../Assets/gifs/cooking.gif';
import qualityControl from '../../Assets/gifs/quality-control.gif';
import delivery from '../../Assets/gifs/delivery.gif';
import delivered from '../../Assets/gifs/delivered.gif';
import orderPlaced from '../../Assets/gifs/order-placed.gif';
import Modal from '../../Components/Modal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import TrackingStatus from '../TrackOrder/TrackingStatus';
import { useEffect } from 'react';
import {
	feedbackActions,
	orderActions,
	authActions,
	storeActions
} from '../../Redux/Actions';
import { isStoreOpen } from '../../Functions/helpersFunctions';
// import { dispatch } from "react-hot-toast/dist/core/store";

export default function ConfirmOrder() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { restaurantId, orderId } = useParams();
	const [visible, setVisible] = useState(false);
	const { orderTrack, store, orderInfo, auth, feedback } = useSelector(
		(state) => state
	);
	const [noOfSelectedStars, setNoOfSelectedStars] = useState();

	const cancelOrder = () => {
		dispatch(
			orderActions.cancelOrder({
				orderDetails: { orderId: orderId },
				showSnackbars: false,
				showSnackbars: false,
			})
		);
	};
  
	useEffect(() => {
		dispatch(orderActions.setLoading(false));
		dispatch(orderActions.setOrderId(orderId));
		dispatch(orderActions.startTracking());
		dispatch(authActions.redirect(null));
		if(restaurantId !== store.orderId){
			dispatch(storeActions.getStore(restaurantId))
		}
		return () => {
			dispatch(orderActions.stopTracking());
		};
	}, [dispatch, orderId, restaurantId, store.orderId]);
	
	useEffect(() => {
		if (orderTrack?.orderStatus === 500 || orderTrack?.orderStatus === 700) {
			
			dispatch(orderActions.stopTracking());
		}
	}, [orderTrack.orderStatus]);

	if (auth.redirectTo) {
		history.push(auth.redirectTo);
	}

	const handleStarChange = (number) => {
		const feedback = {
			orderId: orderId,
			feedback: {
				orderRating: number,
			},
		};
		dispatch(feedbackActions.setOrderingExperience(feedback));
	};
	const orderTrackImage = () => {
		if (orderTrack.orderStatus === 100) {
			return SuccessImage;
		}
		if (orderTrack.orderStatus === 200) {
			return cooking;
		} else if (orderTrack.orderStatus === 300) {
			return qualityControl;
		} else if (orderTrack.orderStatus === 400) {
			return orderPlaced;
		} else if (orderTrack.orderStatus === 500) {
			return delivered;
		} else if (orderTrack.orderStatus === 600) {
			return delivery;
		} else if (orderTrack.orderStatus === 700) {
			return delivered;
		}
	};

	// debugger
	return (
    <>
      <div className="hidden lg:block">
        <SubHeader title="Back To Menu" link={`/${restaurantId}/home`} />
      </div>
      <div className="flex pt-1">
        <div className="hidden lg:block flex-1 bg-F8FFF5">
          <div className="flex items-center h-full justify-center">
            <div className="block">
              <img
                src={orderTrackImage()}
                alt="success"
                className={orderTrack.orderStatus === 100 ? "" : "max-h-64"}
              />
              <div className="text-D17B69 text-center font-bold">{`#Order Id:${orderId}`}</div>
              {!orderTrack?.isPaymentCOD &&
                orderTrack?.paymentStatus == "USER_PENDING" && (
                  <div className="text-D17B69 text-center font-bold">
                    Payment Confirmation pending
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="flex-1 bg-white mt-4 md:mt-14 lg:mt-0">
          <div className="flex justify-center items-center font-open-sans">
            <div className="w-10/12 py-8 lg:w-8/12">
              <div className="text-D17B69 text-center font-bold mb-4">{`#Order Id:${orderId}`}</div>
              {/* Order Level Tracking status  */}
              <TrackingStatus type="ORDER_LEVEL" />
              <div className="border-b-2 border-gray-200 py-6">
                <div className="text-base text-semibold">
                  How was your overall food experience..
                  <b>rate us !!</b>
                </div>
                <div className="space-x-2">
                  <Star
                    number={feedback.orderRating}
                    onSelect={setNoOfSelectedStars}
                    handleChange={handleStarChange}
                  />
                </div>
              </div>
              {/* Displaying Itmes that are ordered   */}
              <div className="max-h-60 overflow-y-scroll bgWhiteScrollbar">
                {Object.keys(orderTrack.items).map((itemId) => {
                  const item = orderTrack.items[itemId];
                  return (
                    <div className=" flex border-b-2 border-gray-200 py-5  items-center justify-between space-x-1">
                      <div className="block flex-grow w-3/4">
                        <div className=" text-base font-semibold line-clamp-2">
                          {item.productName}
                        </div>
                        {/* <div className=' text-xs text-AFADAD'>
                        {`QUANTITY:(serves ${item.selectedVariant.serves})`}
                      </div> */}
                      </div>
                      {/* Only if order type is not schedule you can see the item level tracking  */}
                      {orderInfo.orderType !== "schedule" && (
                        <div className="w-1/4">
                          {item.statusCode != 800 ? (
                            // <Link
                            //   to={`/${restaurantId}/track-order/${orderId}/${itemId}`}
                            // >
                            //   <img
                            //     src={Peep}
                            //     alt="Peep Into Kitchen"
                            //     className="cursor-pointer w-16 h-12"
                            //   />
                            // </Link>
                            <></>
                          ) : (
                            <span className="text-center text-sm text-medium">
                              Cancelled
                            </span>
                          )}

                          {/* <div className="text-2xs text-8593FF text-center">Peep into the kitchen</div> */}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              {(orderTrack?.orderStatus === 100 ||
                orderTrack?.orderStatus === 200 ||
                orderTrack?.orderStatus === 300) &&
                orderTrack?.paymentStatus === "TXN_SUCCESS" &&
                isStoreOpen(store?.openingTime, store?.closingTime) && (
                  <div className="flex py-5 px-2 space-x-4">
                    <button
                      className="text-sm w-full h-12 text-8593FF border-2 border-8593FF rounded"
                      onClick={() => setVisible(true)}
                    >
                      CLICK HERE TO CANCEL
                    </button>
                  </div>
                )}
              {/* <div className='flex font-open-sans font-light text-xs justify-end items-center'>
              <img src={Timmer} alt='Timmer' className='w-4 h-auto' />
              <div className='ml-1'>
                <b>01:00</b> minutes left to cancel order
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <Modal
          visible={visible}
          title={`Are You Sure you want to Cancel Order`}
          text={`Order Id: ${orderId}`}
          submit={() => {
            cancelOrder();
            setVisible(false);
          }}
          close={() => setVisible(false)}
          closingText='Close'
          submittingText='Cancel Order'
        />
      </div>
    </>
  );
}
