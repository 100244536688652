/** @format */

import React, { useState } from 'react';
import Home from '../../../Assets/Icons/Home-Outline.svg';
import AddressSlider from '../../../Containers/CheckOut/AddressSlider';
import Modal from '../../Modal/Modal';
import SideNav from '../../SideNav/SideNav';
import { clientService } from '../../../Redux/Services';
import toast from 'react-hot-toast';
import { Loader } from '../../../Components/Spinner/Loader';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { storeActions } from '../../../Redux/Actions';
import { useWindowDimensions } from '../../../Functions/utils';
import QrCode2Icon from '@mui/icons-material/QrCode2';

export default function SavedAddressCard({
	addressLine1,
	addressLine2,
	state,
	city,
	pincode,
	deliveryPossible,
	selected,
	onSelect,
	index,
	deleteAddress,
	lat,
	lng,
	email,
	name,
	qrcodeimage
}) {
	const { width } = useWindowDimensions();
	const [isaddressSliderVisible, setIsaddressSliderVisible] = useState(false);
	const [qrCodeModal, setIsQrcodeModal] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [changeDeliveryPrice, setDeliveryPriceModalOpen] = useState(false);
	const [isDeleteModal, setIsDeleteModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	let { store, orderInfo,client } = useSelector((state) => state);
	
	const checkServiceablity = () => {
		setIsLoading(true);
		// debugger;
		return clientService.checkServiceAbility({ lat, long: lng }).then(
			(res) => {
				return new Promise((resolve) => {
					resolve(res);
				});
			},
			(error) => {
				toast.error(error);
				setIsLoading(false);
			}
		);
	};

	const downloadImage = () => {
		const a = document.createElement('a');
		a.download = 'QrCode.png';
		a.href = qrcodeimage;
		a.click();
	  }

	const dispatch = useDispatch()
	// const selectAddress = (e) => {
	// 	// if (deliveryPossible) onSelect(index);
	// 	// else setIsModalOpen(true);
	// };
	let deliveryCharges = store.deliveryCharges;
	
	const setDeliveryChargesAndCheckServiceAbility = () => {
		checkServiceablity().then((res) => {
			if (get(res, 'servicable')) {
				onSelect(index);
				deliveryCharges = res['deliveryCharges']
				if (store.deliveryCharges != deliveryCharges) {
					setDeliveryPriceModalOpen(true)
					dispatch(storeActions.setStoreDeliveryCharges({deliveryCharges}));
				}
			} else {
				setIsModalOpen(true);
			}
		});
		setIsLoading(false);
	};

	return (
		<React.Fragment>
			{isLoading && <Loader />}
			<div
				id='address-box'
				onClick={width < 576 ? (e) => setDeliveryChargesAndCheckServiceAbility(e) : null}
				className={`flex px-4 sm:px-9 py-4 items-start border sm:border-none ${
					selected ? 'border-7FB069' : 'border-E9E9E9'
				} font-open-sans`}
			>
				{(client?.scanAndPay && qrcodeimage)  &&
					(<div className='flex mt-1 cursor-pointer'
					onClick={()=>{setIsQrcodeModal(true)}}>
						<QrCode2Icon/>
					</div>)
				}
				{
				(!client?.scanAndPay || !qrcodeimage) &&
				 	(<img className='mt-2' src={Home} alt='home' />)
				 }

				<div className='flex-grow mx-4 sm:ml-8 w-2/3 '>
					<div className='font-semibold text-xs sm:text-base'>
						{addressLine1} , {addressLine2}
					</div>
					{/* <div className='text-8B8B8B text-2xs sm:text-sm mt-1 sm:mt-2'>{`${lat}-${lng}`}</div> */}
					{/* <div className="text-3xs sm:text-xs mt-1 sm:mt-2"> {deliveryPossible ? "Delivery Possible Here" : "Delivery Not Possible Here"}</div> */}
					<div className=' mt-4 space-x-3 hidden sm:flex'>
						<button
							onClick={() => setIsaddressSliderVisible(true)}
							className='border border-black rounded h-10 w-1/3'
						>
							Edit
						</button>
						{/* <button onClick={() => setIsDeleteModal(true)} className="border border-black rounded h-10 w-1/3">Delete</button> */}
					</div>
				</div>
				{selected ? (
					<button className='bg-7FB069 rounded h-10 w-1/5 text-white hidden sm:block'>
						SELECTED
					</button>
				) : (
					<button
						onClick={setDeliveryChargesAndCheckServiceAbility}
						className='border border-7FB069 text-7FB069 rounded h-10 w-1/5 hidden sm:block'
					>
						SELECT
					</button>
				)}
				<div className=' flex flex-col items-end justify-between space-y-3 sm:hidden'>
					<div className='flex space-x-1'>
						<ion-icon
							onClick={(e) => {
								// console.log('clicked');
								setIsaddressSliderVisible(true);
								e.stopPropagation();
							}}
							style={{
								color: 'gray',
								fontSize: '20px',
								cursor: 'pointer',
							}}
							name='create'
						></ion-icon>
						{/* <ion-icon
              onClick={(e) => {
                setIsDeleteModal(true);
                e.stopPropagation();
              }}
              style={{ color: "brown", fontSize: "20px", cursor: "pointer" }}
              name='trash'></ion-icon> */}
					</div>

					<button
						className='bg-7FB0692C text-3xs px-2 py-1 rounded-sm font-semibold'
						onClick={setDeliveryChargesAndCheckServiceAbility}
					>
						DELIVER HERE
					</button>
				</div>
			</div>
			{/* To show Address Edititng Slider  */}
			<SideNav
				visible={isaddressSliderVisible}
				onClose={() => setIsaddressSliderVisible(false)}
				mode='dark'
			>
				<AddressSlider
					type='EDIT'
					index={index}
					addressLine1={addressLine1}
					addressLine2={addressLine2}
					state={state}
					lat={lat}
					lng={lng}
					city={city}
					pincode={pincode}
					close={() => setIsaddressSliderVisible(false)}
					mapId={`map3-${index}`}
					email= {email}
					name = {name}
				/>
			</SideNav>
			{/* Modal to prevent selecting an address where delivery is not possible  */}
			<Modal
				visible={isModalOpen}
				title={`Delivery is not possible here !`}
				text='We cannot deliver your food at this address'
				close={() => setIsModalOpen(false)}
				closingText='OK'
			/>
			<Modal
				visible={changeDeliveryPrice}
				title={`Delivery Charges has changed !`}
				text={orderInfo.orderType==='schedule' ? 'Delivery is Free':`Delivery Charges has changed to ${deliveryCharges}`}
				close={() => setDeliveryPriceModalOpen(false)}
				closingText='OK'
			/>
			{/* Modal to confirm deletion  */}
			<Modal
				visible={isDeleteModal}
				title={`Do You Want To Delete this Address Permanently`}
				close={() => setIsDeleteModal(false)}
				closingText='Cancel'
				submit={() => {
					deleteAddress(index);
					setIsDeleteModal(false);
				}}
				submittingText='Delete'
			/>
			<Modal
				visible={qrCodeModal}
				title={`Please print personalized QR code for tuck shop purchases in school against meal credits `}
				image = {qrcodeimage}
				close={() => setIsQrcodeModal(false)}
				closingText='Close'
				submit={() => {
					downloadImage(qrcodeimage);
					setIsQrcodeModal(false);
				}}
				submittingText='Download'
			/>
		</React.Fragment>
	);
}
