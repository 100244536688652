import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Banner() {
  const { client, store } = useSelector((state) => state);
  return (
    <div className='hidden bg-23232C mt-5 md:block text-white py-5 rounded-md'>
      <div className='w-6/12 justify-between flex m-auto items-center lg:pr-6 xl:pr-16'>
        <Link to="/">
          <img className='h-16 w-auto' src={client.logo} alt='logo' />
        </Link>
        <div className='text-center space-y-1'>
          <div className='text-3xl font-roboto font-bold object-right'>
            Welcome to <b>{store.storeName}</b>
          </div>
          {client.tagline && (
            <div className='text-sm font-open-sans font-normal uppercase separator'>
              "{client.tagline}"
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
