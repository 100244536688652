import React from 'react'
import BillCard from '../../Components/Cards/BillCard/BillCard';

export const Bill = () => {
    return (
        <div>
            <BillCard />
        </div>
    )
}

export default Bill;