import { cloneDeep } from "lodash";
import { Fragment } from "react";
import { cartActions, notificationActions } from "../Redux/Actions";
import toast from "react-hot-toast";

// updates local storage for both cart and cutomizations
export const updateLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};
// update customization in food Item from applied customization
export const getAppliedCustomizations = (foodItem) => {
  let selectedVariant = foodItem.selectedVariant;
  let appliedCustomization = {};

  let finalCustomisation = {
    addedItems: {},
    removedItems: {},
    replacedItems: {},
    removedProcess: [],
  };

  Object.keys(foodItem.customisation).forEach((processId) => {
    const process = foodItem.customisation[processId];
    if (process.variantIdApplicableOn.includes(selectedVariant.id.toString())) {
      let processData = [];
      if (process.processType === "REPLACEABLE") {
        processData = {
          to: processData,
          from: [],
        };
      }
      if (!process.processRemovable || process.processType !== "ADDON") {
        appliedCustomization = {
          ...appliedCustomization,
          ...{ [process.processId]: processData },
        };
      }
    }
  });
  foodItem.appliedCustomization = appliedCustomization;
  foodItem.finalCustomisation = finalCustomisation;
  return foodItem;
};

// Add According to type selected
export const addAccordingToType = (
  products,
  productId,
  customization,
  type,
  itemCount,
  setItemCount,
  dispatch,
  orderInfo,
  name = "",
  selectedVariant = {},
  comboSelection = {},
  quantity = null
) => {
  let foodItem = products[productId];
  const isSingleMeals = customization?.extraData?.selectedCategory?.categoryName
    ?.toLowerCase()
    ?.includes("single meals");
  const subType = customization?.extraData?.subCategory;
  const subTypeName =
    customization?.extraData?.subCategory?.subCategoryName?.split("-")[0];
  const cart = customization?.extraData?.cart;
  let canBeAdded = true;
  if (isSingleMeals) {
    cart?.forEach((ele) => {
      const currentSubTypeName =
        ele?.extraData?.subType?.subCategoryName?.split("-")[0];
        if (currentSubTypeName === subTypeName) {
          canBeAdded = false;
        }
        // if(sub)
      });
    }

  foodItem = { ...foodItem, subType, isSingleMeals };
  // if (isProductSpecial === 'special') {
  // 	foodItem.selectedVariant.discountedPrice =
  // 		foodItem.selectedVariant.specialDiscountedPrice ||
  // 		foodItem.selectedVariant.discountedPrice;
  // }
  // foodItem.isProductSpecial = isProductSpecial;
  if (foodItem.productType === "COMBO") {
    foodItem.appliedCustomization = foodItem.customisation;
    foodItem.finalCustomisation = cloneDeep(comboSelection);
  } else {
    if (customization.productId) {
      foodItem.appliedCustomization = cloneDeep(
        customization.productId.appliedCustomization
      );
      foodItem.finalCustomisation = cloneDeep(
        customization.productId.finalCustomisation
      );
    } else {
      foodItem = { ...getAppliedCustomizations(foodItem) };
    }
  }

  let notificationText = "";
  if (type === "bulk") {
    if (itemCount === 0) {
      // function checks the food item already present or not
      dispatch(cartActions.compareAndAddToCart(foodItem, quantity || 10, type));
      setItemCount(10);
      notificationText = (
        <Fragment>
          Hi, You Have Selected{" "}
          <b>
            {foodItem.name} for &#x20B9;{" "}
            {foodItem.selectedVariant.discountedPrice}/meal.
          </b>
        </Fragment>
      );
    } else {
      if (
        (itemCount < (foodItem?.maxQty ?? Number.MAX_SAFE_INTEGER)) &&
        canBeAdded
      ) {
        dispatch(
          cartActions.compareAndAddToCart(foodItem, quantity || 1, type)
        );
        setItemCount(itemCount + 1);
        notificationText = (
          <Fragment>
            Hi, You Have Selected{" "}
            <b>
              {foodItem.name} for &#x20B9;{" "}
              {foodItem.selectedVariant.discountedPrice}/meal.
            </b>
          </Fragment>
        );
      } else {
        toast.error(`You can add maximum  ${!canBeAdded ? ("1 "+subTypeName) : foodItem?.maxQty}`);
      }
    }
  } else if (type === "schedule") {
    if (
      itemCount < (foodItem?.maxQty ?? Number.MAX_SAFE_INTEGER) &&
      canBeAdded
    ) {
      dispatch(cartActions.compareAndAddToCart(foodItem, quantity || 1, type));
      setItemCount(itemCount + 1);
      notificationText = (
        <Fragment>
          Hi, You Have Selected{" "}
          <b>
            {foodItem.name} for &#x20B9;{" "}
            {foodItem.selectedVariant.discountedPrice}/meal.
          </b>{" "}
          for <b>{orderInfo.noOfDays} days</b>
        </Fragment>
      );
    } else {
      toast.error(`You can add maximum ${!canBeAdded ? ("1 "+subTypeName) : foodItem?.maxQty}`);
    }
  } else {
    if (
      itemCount < (foodItem?.maxQty ?? Number.MAX_SAFE_INTEGER) &&
      canBeAdded
    ) {
      dispatch(cartActions.compareAndAddToCart(foodItem, quantity || 1, type));
      setItemCount(itemCount + 1);
      notificationText = (
        <Fragment>
          Hi, You Have Selected{" "}
          <b>
            {foodItem.name}
            {foodItem.productType !== "COMBO" && (
              <>
                {" "}
                for &#x20B9;{" "}
                {foodItem.selectedVariant.discountedPrice
                  ? foodItem.selectedVariant.discountedPrice
                  : foodItem.selectedVariant.price}
                /meal
              </>
            )}
          </b>
        </Fragment>
      );
    } else {
      toast.error(`You can add maximum ${!canBeAdded ? ("1 "+subTypeName) : foodItem?.maxQty}`);
    }
  }
  dispatch(notificationActions.createNotification(notificationText));
};

// Handles deletion from cart
export const removeFromCart = (
  products,
  productId,
  type,
  itemCount,
  setItemCount,
  dispatch
) => {
  let foodItem = products[productId];
  if (type === "bulk") {
    if (itemCount === 10) {
      dispatch(cartActions.compareAndRemoveFromCart(foodItem, 10));
      setItemCount(0);
    } else {
      dispatch(cartActions.compareAndRemoveFromCart(foodItem, 1));
      setItemCount(itemCount - 1);
    }
  } else if (type === "schedule") {
    dispatch(cartActions.compareAndRemoveFromCart(foodItem, 1));
    setItemCount(itemCount - 1);
  } else {
    dispatch(cartActions.compareAndRemoveFromCart(foodItem, 1));
    setItemCount(itemCount - 1);
  }
};
