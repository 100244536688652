import { updateLocalStorage } from '../../Functions/CartFunctions';
import { orderConstants } from '../Constants';

const initialState = {
	isLoading: false,
	storeId: '', // selected store Id
	orderType: 'regular', //regular, scheduled, bulk
	instructions: {
		addons: [
			{
				id: 1,
				instruction: 'Add extra cutlery',
				checked: false,
			},
			{
				id: 2,
				instruction: 'Add extra condiments',
				checked: false,
			},
		],
		comments: '',
	},
	preferences: {
		bulkPreferences: [],
		schedulePreferences: [],
	},
	startDate: '',
	endDate: '',
	time: '',
	noOfDays: 1,
	deliverNow: true,
	deliveryMode: '',
	selectedAddress: {},
	selectedOrder: {},
	pastOrders: {},
	couponAmount: 0,
	selectedPromo: null,
	frequencyDays: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
	type: 'regular',
	laterTime: '00:00',
	laterDate: '',
	orderTime: null
};

export function order(state = initialState, action) {
	// console.log(action.payload, 'action payload')
	switch (action.type) {
		case orderConstants.SET_LOADING:
			return {
				...state,
				...action.payload,
			};
		case orderConstants.ORDER_UPDATE: {
			const newState = {
				...state,
				...action.payload,
			};
			updateLocalStorage('orderInfo', JSON.stringify(newState));

			return newState;
		}
		case orderConstants.ORDER_RESET:
			return {
				...initialState,
			};
		case orderConstants.START_TRACKING:
			return {
				...state,
				...action.payload,
			};
		case orderConstants.STOP_TRACKING:
			return {
				...state,
				...action.payload,
			};
		case orderConstants.SET_ORDER_ID:
			return {
				...state,
				...action.payload,
			};
		case orderConstants.ORDER_PAYMENT_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case orderConstants.ORDER_PAYMENT_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload.error,
			};
		case orderConstants.APPLY_PROMO_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case orderConstants.APPLY_PROMO_SUCCESS:
			return {
				...state,
				isLoading: false,
				...action.payload,
			};
		case orderConstants.APPLY_PROMO_FAILURE:
			return {
				...state,
				isLoading: false,
				...action.payload,
			};
		case orderConstants.REMOVE_PROMO:
			return {
				...state,
				selectedPromo: {},
				couponAmount: 0,
			};
		case orderConstants.SET_ORDER_ADDRESS: {
			return {
				...state,
				selectedAddress: { ...action.payload },
			};
		}
		case orderConstants.CANCEL_ORDER_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case orderConstants.CANCEL_ORDER_SUCCESS: {
			return {
				...state,
				isLoading: false,
			};
		}
		case orderConstants.CANCEL_ORDER_FAILURE: {
			return {
				...state,
				isLoading: false,
			};
		}
		default:
			return state;
	}
}
