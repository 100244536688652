import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import MyOrderCard from "../../Components/Cards/MyOrderCard/MyOrderCard";
import SmallFoodCart from "../../Components/Cards/SmallFoodCard/SmallFoodCart";
import SubHeader from "../../Components/Header/SubHeader";
import AddInstructions from "../MenuCard/AddInstructions";
import toast from "react-hot-toast";
import EmptyOrder from "../MenuCard/EmptyOrder";
import SideNav from "../../Components/SideNav/SideNav";
import CustomizationSlider from "../FoodDetail/CustomizationSlider";
import { menuActions, modalActions, orderActions } from "../../Redux/Actions";
import { cloneDeep } from "lodash";
import moment from "moment";
import Modal from "../../Components/Modal/Modal";

export default function MyOrder() {
  const { restaurantId } = useParams();
  const history = useHistory();
  const { menu, orderInfo, auth, cart ,client, store} = useSelector((state) => state);
  // Open and closes the slider for customization
  const [isCustomizationsVisible, setIsCustomizationsVisible] = useState(false);
  const [selectFoodItem, setSelectFoodItem] = useState({
    foodItem: {},
    appliedCustomization: {},
    index: 0,
  });
  const dispatch = useDispatch();
  const defaultCategory = menu.menu[0]?.categoryId;

  const handleCheckout = () => {
    // console.log(restaurantId,'rest')
    if (orderInfo.orderType === "bulk") {
      if (orderInfo.preferences.bulkPreferences.length === 0) {
        toast.error("Please Select Bulk Preferences");
        history.push(
          `/${restaurantId}/menu-card/${
            orderInfo.orderType === "bulk" || orderInfo.orderType === "schedule"
              ? orderInfo.orderType + "/" + orderInfo.orderType
              : "regular/all"
          }/${defaultCategory}`
        );
        return;
      }
    }
    if (orderInfo.orderType === "schedule") {
      if (!moment(orderInfo.time, "hh:mm:ss").isValid()) {
        toast.error("Please Select Time for delivery");
        history.push(
          `/${restaurantId}/menu-card/${
            orderInfo.orderType === "bulk" || orderInfo.orderType === "schedule"
              ? orderInfo.orderType + "/" + orderInfo.orderType
              : "regular/all"
          }/${defaultCategory}`
        );
        return;
      }
    }
    if (!auth.auth) {
      dispatch(modalActions.toggleLoginModal(true));
      toast.error("Login To Continue");
      history.push(`/${restaurantId}/login`);

      return;
    }
    history.push(`/${restaurantId}/check-out`);
  };
  const [isOpen, setIsOpen] = useState(false);
  const localStorageOrder = JSON.parse(localStorage.getItem("orderInfo"));
  
  const handleCheckoutMobile = () => {
    if (store.isClosed || store.isInactive) {
      toast.error("Store is closed right now");
      return;
    } else {
      if (orderInfo.orderType === "bulk") {
        if (orderInfo.preferences.bulkPreferences.length === 0) {
          toast.error("Please Select Bulk Preferences");
          history.push(
            `/${restaurantId}/menu-card/${
              orderInfo.orderType === "bulk" || orderInfo.orderType === "schedule"
                ? orderInfo.orderType + "/" + orderInfo.orderType
                : "regular/all"
            }/${defaultCategory}`
          );
          return;
        }
      }
      if (orderInfo.orderType === "schedule") {
        if(orderInfo.time){
          if (!moment(orderInfo.time, "hh:mm:ss").isValid()) {
            toast.error("Please Select Time for delivery");
            history.push(
              `/${restaurantId}/menu-card/${
                orderInfo.orderType === "bulk" || orderInfo.orderType === "schedule"
                  ? orderInfo.orderType + "/" + orderInfo.orderType
                  : "regular/all"
              }/${defaultCategory}`
            );
            return;
          }
        }
      }
      if (localStorageOrder.orderType != orderInfo.orderType) {
        dispatch(
          orderActions.updateOrderStore({
            ...orderInfo,
            orderType: localStorageOrder.orderType,
            deliverNow: localStorageOrder.deliverNow,
            noOfDays: localStorageOrder.noOfDays,
            startDate : localStorageOrder.orderType=='regular' && localStorageOrder.deliverNow==false ? localStorageOrder.laterDate:localStorageOrder.startDate
          })
        );
      }
      if (!auth.auth) {
        dispatch(modalActions.toggleLoginModal(true));
        toast.error("Login To Continue");
        history.push(`/${restaurantId}/login`);
  
        return;
      }
      if (orderInfo.deliveryMode !== "pickup" || client.clientType == "SCHOOL" || client?.clientType == "STAFF") {
        history.push(`/${restaurantId}/check-out`);
      } else {
        history.push(`/${restaurantId}/check-out/pay`);
      }
    }
  };
  const showCustomization = (productId, appliedCustomization, index) => {
    setSelectFoodItem({
      foodItem: menu.products[productId],
      appliedCustomization: JSON.parse(JSON.stringify(appliedCustomization)),
      index,
    });
    setIsCustomizationsVisible(true);
  };
  // Handles the size change in food item
  const onSizeChange = (variant, productId) => {
    let tempMenu = cloneDeep(menu);
    tempMenu.products[productId].selectedVariant = { ...variant };
    dispatch(menuActions.updateMenu(tempMenu));
  };
  const localStoreOrdertype = JSON.parse(localStorage.getItem("orderInfo"));
  const closeModal = ()=>{
    setIsOpen(false)
}
  return (
    <>
      <div className="hidden lg:block">
        <SubHeader title="Cart" />
      </div>

      <div className="w-full sm:w-10/12 lg:w-1/2 mt-32 m-auto lg:mt-4">
        {cart.length > 0 ? (
          <>
            <MyOrderCard showCustomization={showCustomization} />
            {client.clientType != "SCHOOL" && <AddInstructions />}
          </>
        ) : (
          <EmptyOrder />
        )}
        {orderInfo.orderType !== "bulk" &&
          orderInfo.orderType !== "schedule" &&
          menu.addOn.length > 0 && (
            <div className="p-6 mb-16">
              <div className="font-open-sans font-bold">
                Do you want these items in the cart
              </div>
              <div className="mt-4 flex  space-x-3 overflow-x-scroll hide-scroll-bar">
                {menu.addOn.map((item) => {
                  const foodItem = menu.products[item];
                  if (foodItem.isProductAvailable) {
                    return (
                      <div>
                        <div className="w-32">
                          <SmallFoodCart
                            key={foodItem.productId}
                            name={foodItem.name}
                            image={foodItem.image}
                            price={foodItem.selectedVariant.price}
                            productId={foodItem.productId}
                            productType={foodItem.productType}
                            selectedVariant={foodItem.selectedVariant}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}
        {cart.length > 0 && (
          <div
            className="bg-transparent fixed bottom-0 left-0 right-0 z-10 md:px-16"
            style={{
              zIndex: "15",
            }}
          >
            {!auth.auth ? (
              <Link
                to="/login"
                className="font-open-sans bg-8593FF h-12 text-white text-center py-3 cursor-pointer block"
              >
                Proceed To Pay
              </Link>
            ) : (
              <div
                onClick={() => {
                  if (
                    orderInfo.orderType == "regular" &&
                    localStoreOrdertype.orderType == "regular"
                  ) {
                    if (
                      orderInfo.deliverNow ==
                      localStoreOrdertype.deliverNow 
                    ) {
                      handleCheckoutMobile();
                    } 
                     else {
                      setIsOpen(true);
                    }
                  } else if (
                    (orderInfo.orderType !=  localStoreOrdertype.orderType )
                  ) {
                    setIsOpen(true);
                  } else if (
                    orderInfo.orderType == "schedule" &&
                    localStoreOrdertype.orderType == "schedule"
                  ) {
                    handleCheckoutMobile();
                  }
                }}
                className="font-open-sans bg-8593FF h-12 text-white text-center py-3 cursor-pointer md:px-16 w-full"
              >
                Proceed To Pay
              </div>
            )}
          </div>
        )}
      </div>
      {/* To show customization for an item in cart  */}
      <SideNav
        onClose={() => setIsCustomizationsVisible(false)}
        visible={isCustomizationsVisible}
        closable={false}
        mode="light"
      >
        {isCustomizationsVisible && (
          <CustomizationSlider
            type="EDIT"
            index={selectFoodItem.index}
            appliedCustomization={selectFoodItem.appliedCustomization}
            image={selectFoodItem.foodItem.image}
            name={selectFoodItem.foodItem.name}
            isVeg={selectFoodItem.foodItem.isVeg}
            selectedVariant={selectFoodItem.foodItem.selectedVariant}
            variants={selectFoodItem.foodItem.variants}
            customization={selectFoodItem.foodItem.customisation}
            product={selectFoodItem.foodItem}
            handleClose={() => {
              setIsCustomizationsVisible(false);
              document.body.classList.remove("stop-scrolling");
            }}
            orderType={orderInfo.orderType}
            changeSizeHandler={onSizeChange}
          />
        )}
      </SideNav>
      <Modal
        visible={isOpen}
        title={`Your Selected Category is ${
          orderInfo.orderType == "regular"
            ? orderInfo.deliverNow == false
              ? "later"
              : "regular"
            : "subscribe"
        } it will be changed to ${
          localStoreOrdertype.orderType == "regular"
            ? localStoreOrdertype.deliverNow == false
              ? "later"
              : "regular"
            : "subscribe"
        } category`}
        text=""
        submit={() => {
          dispatch(
            menuActions.getMenu(restaurantId, {
              ...orderInfo,
              orderType: localStoreOrdertype.orderType,
              deliverNow: localStoreOrdertype.deliverNow,
              time: localStoreOrdertype.time,
            })
          );
          handleCheckoutMobile();
        }}
        close={closeModal}
        closingText="Cancel"
        submittingText="Proceed"
      />
    </>
  );
}
