import SearchIcon from '@mui/icons-material/Search';

const SearchIconComponet = () => {
  return (
    <div class='rounded-full border border-8593FF bg-8593FF p-3 cursor-pointer flex items-center justify-center'>
        <SearchIcon  className='text-white	'/>
    </div>
  )
}

export default SearchIconComponet