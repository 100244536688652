import React from "react";
import RecipeCard from "../../Components/Cards/RecipeCard/RecipeCard";

const ScrollableRecipes = ({ foodItem }) => {

  return (
    <div className='bg-no-repeat bg-white p-9 opacity-100 rounded'>
      <div className='flex flex-row justify-between'>
        <div className='text-left text-464646 not-italic font-semibold text-lg font-open-sans opacity-100'>
          How we make your {foodItem.name}?
        </div>
      </div>
      <div className=''>
        <div className='flex flex flex-col mt-4   py-4'>
          {Object.keys(foodItem.recipe).map((stepId, index) => {
            const step = foodItem.recipe[stepId];
            return (
              <div key={stepId}>
                <div className='w-96 mt-2'>
                  <RecipeCard key={index} index={index} step={step} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ScrollableRecipes;
