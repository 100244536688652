import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
// convert date obj format to am pm format
export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
const dayNames = ['sun','mon','tue','wed','thu','fri','sat'];
// calculate number of days from 2 time intervals
export const calculeteNoOfDays = (startDate, endDate, frequencyDays) => {
  let timeInMilisec = endDate.getTime() - startDate.getTime();
  let daysBetweenDates = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
  let noOfDays = 0;
  let copyOfStartDate = _.cloneDeep(startDate);
  let copyOfEndDate = _.cloneDeep(endDate);
  for(let currDate = copyOfStartDate;currDate<=copyOfEndDate;currDate.setDate(currDate.getDate()+1)){
    noOfDays = frequencyDays.includes(dayNames[currDate.getDay()])?noOfDays+1:noOfDays
  }
  return noOfDays;
};

// convert date into date month year
export const formatddmmyyyy = (t, s) => {
  if(t=='Invalid Date' || !s)
    return
  let a = [{ day: "2-digit" }, { month: "short" }, { year: "numeric" }];
  function format(m) {
    let f = new Intl.DateTimeFormat("en", m);
    return f.format(t);
  }
  return a.map(format).join(s);
};

// convert 24 hrs format to am pm format
export const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};
