import React from "react";
import CookTime from "../../Assets/Images/CookTime.svg";
import Quality from "../../Assets/Icons/Quality.svg";
import BannerCalorie from "../../Assets/Icons/BannerCalorie.svg";

const PhoneFoodDetailBanner = ({
  displayVariants,
  isQCEnabled,
  qcData,
  productId,
  productType,
  image,
  isVeg,
  name,
  cookingTime,
  variants,
  description,
  discount,
  selectedVariant,
  foodItem,
  changeSizeHandler,
  type,
  clientType,
}) => {
  // Classes for selected, unselected items sizes and hover effects
  const selectedSizeClass =
    "bg-7FB0692C hover:bg-7FB0692C bg-no-repeat border border-7FB0693B rounded-sm opacity-70 text-center not-italic font-semibold font-open-sans text-2xs sm:text-xs md:text-sm text-2F2F2F cursor-pointer h-7 pt-2 px-1 sm:h-8 sm:py-1.5 md:h-10 md:py-2";
  const unSelectedSizeClass =
    "hover:bg-7FB0692C bg-no-repeat border border-7FB0693B rounded-sm opacity-70 text-center not-italic font-semibold font-open-sans text-2xs sm:text-xs md:text-sm text-2F2F2F cursor-pointer h-7 pt-2 px-1 sm:h-8 sm:py-1.5 md:h-10 md:py-2";
  return (
    <div className="grid grid-cols-9 md:gap-x-8 bg-FFFFFF bg-no-repeat shadow-sm rounded-md px-6">
      <div className="col-span-9">
        <div
          className={`flex ${
            !cookingTime ||
            !isQCEnabled ||
            !selectedVariant.nutritionalFacts["Calories"]
              ? "justify-around"
              : "justify-between"
          } border-b border-gray-300 py-6`}
        >
          {cookingTime && (
            <div className="flex flex-col justify-center items-center font-open-sans">
              <div className="h-5 w-8 sm:h-6 sm:w-10 md:h-7 md:w-11">
                <img
                  src={CookTime}
                  alt="cooking time"
                  className="h-full w-full"
                />
              </div>
              <div className="font-bold text-center text-xs sm:text-sm md:text-base text-2F2F2F">
                {cookingTime}
              </div>
              <div className="text-center text-2xs sm:text-xs md:text-sm text-8B8B8B">
                Cooking Time
              </div>
            </div>
          )}
          {isQCEnabled && (
            <div className="flex flex-col justify-center items-center font-open-sans">
              <div className="h-5 w-5 sm:h-6 sm:w-6 md:h-7 md:w-7">
                <img src={Quality} alt="quality" className="h-full w-full" />
              </div>
              <div>
                <div className="font-bold text-center text-xs sm:text-sm md:text-base text-2F2F2F">
                  Quality Check
                </div>
                {Object.keys(selectedVariant.qcData).map((itemKey, index) => (
                  <div
                    key={index}
                    className="text-center not-italic font-open-sans text-2xs md:text-xs text-8B8B8B"
                  >
                    {itemKey + ": " + selectedVariant.qcData[itemKey]}{" "}
                    {itemKey === "Temp" ? "\u00b0C" : " gm"}
                  </div>
                ))}
              </div>
            </div>
          )}
          {selectedVariant.nutritionalFactsAvailable && (
            <div className="flex flex-col justify-center items-center font-open-sans">
              <div className="h-5 w-4 sm:h-6 sm:w-5 md:h-7 md:w-6">
                <img
                  src={BannerCalorie}
                  alt="calories"
                  className="h-full w-full"
                />
              </div>
              <div className="font-bold text-center text-xs sm:text-sm md:text-base text-2F2F2F">
                {selectedVariant.nutritionalFacts["Calories"].value +
                  " " +
                  selectedVariant.nutritionalFacts["Calories"].unit}
              </div>
              <div className="text-center text-2xs sm:text-xs md:text-sm text-8B8B8B">
                Calories
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-span-9">
        <div className="flex border-b border-gray-300  py-6 font-open-sans">
          <div className="flex-grow space-y-2">
            <div className="text-left text-xs sm:text-sm md:text-base text-181818 capitalize  ">
              {clientType === "SCHOOL"
                ? "Confirm class for meal size"
                : displayVariants
                ? "Select Size"
                : "Size"}
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex space-x-1">
                {variants.map((size, index) => (
                  <div
                    key={size.id}
                    className={
                      size.id === selectedVariant.id
                        ? selectedSizeClass
                        : unSelectedSizeClass
                    }
                    onClick={() => changeSizeHandler(size, productId)}
                  >
                    {size.displayName}
                  </div>
                ))}
              </div>
              <div className="font-semibold text-left text-2xs sm:text-xs md:text-sm text-7FB069">
                {clientType !== "SCHOOL" && selectedVariant?.serves !== null &&
                    selectedVariant?.serves !== "" &&
                    `Serves ${selectedVariant?.serves}`}
              </div>
            </div>
          </div>
          <div className="text-center">
            <div className="font-bold text-base sm:text-lg text-181818 capitalize">
              &#x20B9;
              {discount && discount > 0
                ? selectedVariant.discountedPrice
                : selectedVariant.price}
            </div>
            {discount > 0 && (
              <div className="text-xs sm:text-sm text-8B8B8B line-through">
                &#x20B9; {selectedVariant.price}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-9">
        <div className="py-6 font-open-sans space-y-2">
          <div className="font-semibold text-sm sm:text-base md:text-lg text-left text-181818 capitalize">
            About the Dish
          </div>
          <div className="text-left text-xs sm:text-sm md:text-base text-8B8B8B">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneFoodDetailBanner;
