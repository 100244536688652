import React from "react";

const PhoneNutritionalFacts = ({ nutritionalFacts }) => {
  return (
    <div className="bg-D17B69 bg-no-repeat rounded-md p-5 md:p-4 space-y-4 font-open-sans">
      <div className="font-bold text-left text-sm sm:text-base md:text-lg text-FFFFFF">
        Nutritional facts
      </div>
      <div className="flex justify-between">
        {nutritionalFacts &&
          Object.keys(nutritionalFacts).map((fact, index) => (
            <div key={index}>
              <div className="text-center capitalize font-open-sans text-xs sm:text-sm md:text-base text-D0D0D0">
                {fact}
              </div>
              <div className="text-center  font-semibold font-open-sans text-xs sm:text-sm md:text-lg text-FFFFFF">
                {nutritionalFacts[fact].value ?? nutritionalFacts[fact]}
                {fact == "calories" ? "k" : fact == "calcium" ? "mg" : "g"}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PhoneNutritionalFacts;
