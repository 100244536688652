import React, { useEffect, useState } from "react";
import SavedStudentCard from "../../Components/Cards/SavedAddressCard/SavedStudentCard";
import PlusIcon from "../../Assets/Icons/Plus.svg";
import CurrentLocation from "../../Assets/Icons/My-location.svg";
import SideNav from "../../Components/SideNav/SideNav";
import StudentSlider from "./StudentSlider";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../Redux/Actions/auth.actions";
import { orderActions, storeActions } from "../../Redux/Actions";
import { clientService } from "../../Redux/Services";
import toast from "react-hot-toast";
import { get } from "lodash";
import Modal from "../../Components/Modal/Modal";
import { Loader } from "../../Components/Spinner/Loader";
import { cloneDeep } from "lodash";

const Student = (props) => {
  console.log("props", props, props.hide);
  const { auth, store, orderInfo } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [savedAddress, setSavedAddress] = useState([]);
  const [isaddressSliderVisible, setIsaddressSliderVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const addresses = [];
    auth.addresses === null
    ? orderInfo.selectedAddress = {} 
    : auth.addresses
      .filter((add) => add.storeId === orderInfo.storeId || add.storeId == null)
      .forEach((ogAddress, index) => {
        const address = cloneDeep(ogAddress);
        if (index === 0) {
          address.selected = true;
          orderInfo.selectedAddress = address;
          dispatch(orderActions.updateOrderStore(orderInfo));
        } else {
          address.selected = false;
        }
        addresses.push(address);
      });
    //TODO set Delivery processes according
    setSavedAddress([...addresses]);
  }, [auth.addresses]);

  const changeAddress = (selectedAddressIndex) => {
    let updatedAddress = savedAddress;
    updatedAddress.forEach((address, index) => {
      if (index === selectedAddressIndex) {
        updatedAddress[index].selected = true;
      } else {
        updatedAddress[index].selected = false;
      }
    });
    setSavedAddress([...updatedAddress]);
    // Update Selected Address in OrderInfo store
    orderInfo.selectedAddress = updatedAddress[selectedAddressIndex];
    dispatch(orderActions.updateOrderStore(orderInfo));
  };

  const deleteAddress = (index) => {
    auth.addresses.splice(index, 1);
    dispatch(authActions.updateUser({ address: [...auth.addresses] }));
  };
  return (
    <div>
      {isLoading && <Loader />}
      <div className="address-box pt-4">
        {/* <div
          onClick={() => {
            setIsaddressSliderVisible(true);
          }}
          className="add-address flex bg-white px-4 sm:px-9 py-7 items-center mb-2 rounded cursor-pointer"
        >
          <img src={PlusIcon} className="w-auto h-auto" alt="Plus Icon" />
          <div className="text-base font-semibold ml-4 sm:ml-8">Add Child</div>
        </div> */}
        <div className="px-4 pb-4 font-semibold uppercase text-slate-50">To Add a Child, Please connect with Customer Care.</div>
        <div className="text-center border border-black rounded px-1 py-2 mb-3 borderWidth text-700">
          Please print personalized QR code for tuck shop purchases in school
          against meal credits.
        </div>
        {auth?.addresses && auth.addresses.length > 0 && (
          <div className="saved-address py-6 bg-white rounded px-4 sm:px-0">
            <div className="text-xs uppercase ml-2 sm:ml-20">Saved Child</div>
            {savedAddress.map((address, index) => (
              <div className="my-3 sm:my-0">
                <SavedStudentCard
                  index={index}
                  key={index}
                  hide={props.props.hide}
                  email={address?.email}
                  addressLine1={address.addressLine1}
                  addressLine2={address.addressLine2}
                  state={address.state}
                  city={address.city}
                  pincode={address.pincode}
                  onSelect={changeAddress}
                  selected={address.selected}
                  deleteAddress={deleteAddress}
                  deliveryPossible={address.deliveryPossible}
                  lat={address.lat}
                  lng={address.lng ?? address.long}
                  name={address.userName}
                  qrcodeimage={address?.qrCode}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <SideNav
        visible={isaddressSliderVisible}
        onClose={() => setIsaddressSliderVisible(false)}
        mode="dark"
      >
        <StudentSlider
          type="NEW"
          close={() => setIsaddressSliderVisible(false)}
          mapId="map2"
        />
      </SideNav>
    </div>
  );
};

export default Student;
