const windowDomain =
	process.env.NODE_ENV === 'production'
		? window.location.origin
		: 'https://dev3.resoee.com';
// const windowDomain = 'https://dev3.resoee.com/';

const domain = windowDomain + '/server.store2/v2';
const subscriptionDomain = windowDomain + '/server.subscription/subscription';
const walletDomain = windowDomain + '/server.wallet';
const urls = {
	extension: '/store',
	appConfig: domain + '/clientConfig',
	storeConfig: domain + '/store',
	deliveryStores: domain + '/deliveryStore',
	pickupStores: domain + '/pickupStore',
	generateOTP: domain + '/getOTP',
	//login and signUp urls
	verifyOTP: domain + '/signup/verifyOtp',
	signUp: `${domain}/signup`,
	signIn: `${domain}/signin`,
	loginIn: `${domain}/signin`,
	resendOtp: `${domain}/signup/resendOtp`,
	resendPin: `${domain}/resendpin`,
	changepin: `${domain}/changepin`,
	updateprofile: `${domain}/updateprofile`,
	verifyToken: `${domain}/verifyAuth`,
	signOut: `${domain}/signout`,
	//===================
	Menu: domain + '/store',
	userMenu: `${domain}/auth/store`,
	orderPlacement: domain + '/placeOrder',
	trackOrder: domain + '/trackOrder',
	address: domain + '/address',
	pastOrder: domain + '/pastOrder',
	// pastOrderDetail: domain + '/pastOrderDetail',
	checkServiceability: domain + '/checkServiceability',
	getPromoCodes: domain + '/promocodes',
	applyPromo: domain + '/validatePromoCode',
	feedback: domain + '/feedback',
	GEOCODE_API_BASE_URL:
		'https://maps.googleapis.com/maps/api/geocode/json?latlng=',
	GEOCODE_API_KEY: 'AIzaSyDSFW1Q6S3ghhiuDbhCLgaFcVuSfGr05GQ',
	orderSubscription: subscriptionDomain + '/create',
	cancelSubscription: subscriptionDomain,
	subscriptionOnlinePayment: subscriptionDomain,
	subscriptionPastOrder: subscriptionDomain,
	completedMeals: `${domain}/subscriptionItems?`,
	getMealTime: `${domain}/getMealTime`,
	fetchBalance : `${walletDomain}/credits/balance`,
	intiateCredits : `${walletDomain}/credits/balance/credit`,
	fullFillCredits : `${walletDomain}/credits/balance/credit/fulfil`,
	updateCredits : `${walletDomain}/credits/balance/credit/fail`,
	fetchCreditsHistory : `${walletDomain}/credits/transactions`,
};

export default urls;
