/** @format */

import {
  orderActions,
  creditActions,
  subscriptionActions,
} from "../../Redux/Actions";
import { store } from "../../Functions/store";

export const startPayment = (transaction) => {
  let defaultMerchantConfiguration = {
    root: "",
    flow: "DEFAULT",
    data: {
      orderId: `${transaction?.orderId}`,
      token: transaction?.txnToken,
      tokenType: "TXN_TOKEN",
    },
    merchant: {
      mid:
        process.env.REACT_APP_BUILD_TYPE !== "production"
          ? "STcvGx34879038919747"
          : "PrsuVt18779901132913",
      name: store.getState().client.companyName || "UY Store",
      redirect: false,
    },
    labels: {},
    payMode: {
      labels: {},
      filter: [],
      order: [],
    },
    handler: {
      notifyMerchant: function notifyMerchant(eventName, data) {
        console.log("notifyMerchant handler function called");
        console.log("eventName => ", eventName);
        console.log("data => ", data);
        if (eventName == "APP_CLOSED") {
          if (transaction.type) {
            if (transaction.type === "SUBS") {
              store.dispatch(
                subscriptionActions.updatePayment({
                  transactionId: transaction.txnToken,
                  subscriptionId: transaction.orderId.split("_")[1],
                  status: "FAILURE",
                })
              );
            } else if (transaction.type === "TOPUP") {
              store.dispatch(
                creditActions.updateCreditStatus({
                  orderId: transaction.orderId,
                })
              );
            }
          }
          // else {
          //   store.dispatch(
          //     orderActions.cancelOrder({
          //       orderDetails: { orderId: transaction.orderId },
          //       showSnackbars: false,
          //     })
          //   );
          // }
        }
      },
      transactionStatus: function (data) {
        if (transaction.type) {
          if (data.STATUS !== "TXN_FAILURE") {
            if (transaction.type === "SUBS") {
              store.dispatch(
                subscriptionActions.updatePayment({
                  transactionId: transaction.txnToken,
                  subscriptionId: transaction.orderId.split("_")[1],
                  status: "SUCCESS",
                })
              );
            } else if (transaction.type === "TOPUP") {
              store.dispatch(
                creditActions.fullFillCredits({ orderId: transaction.orderId })
              );
            }
          } else {
            store.dispatch(
              creditActions.updateCreditStatus({
                orderId: transaction.orderId,
              })
            );
          }
        } else {
          store.dispatch(orderActions.updatePaymentStatus(transaction));
        }
        window.Paytm.CheckoutJS.close();
      },
    },
  };
  if (window.Paytm && window.Paytm.CheckoutJS.init) {
    window.Paytm.CheckoutJS.init(defaultMerchantConfiguration)
      .then(function onSuccess() {
        store.dispatch(orderActions.setLoading(false));
        window.Paytm.CheckoutJS.invoke();
      })
      .catch(function onError(error) {
        console.log("error => ", error);
      });
  }
};
