import urls from '../../urls';
import { store } from '../../Functions/store';
import { authActions, modalActions } from '../Actions';
import { toQueryStringURL } from '../../Functions/utils';
export const clientService = {
	getClientDetails,
	getDeliveryStores,
	getPickupStores,
	checkServiceAbility,
	getDeliveryStore,
};

function getClientDetails(req = {}) {
	const { params = {} } = req;

	const url = toQueryStringURL(urls['appConfig'], params);

	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (data.status == 'Failed') {
			const error = data.message;
			if (data.message.includes('token expired')) {
				const localStorageStoreId = JSON.parse(
					localStorage.getItem('orderInfo')
				).storeId;
				store.dispatch(
					authActions.redirect(`/${localStorageStoreId}/home`)
				);
				store.dispatch(modalActions.toggleLoginModal(true));
				store.dispatch(authActions.userLogout());
			}
			return Promise.reject(error);
		}
		return data.data;
	});
}

function getDeliveryStores(pincode) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	};
	return fetch(
		urls['deliveryStores'] + '?pincode=' + pincode,
		requestOptions
	).then(handleResponse);
}

function getDeliveryStore(req) {
	const params = {
		...(req?.params ?? {}),
	};

	const url = toQueryStringURL(urls['deliveryStores'], params);

	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	};

	return fetch(url, requestOptions).then(handleResponse);
}

function getPickupStores(req) {
	const params = {
		...(req?.params ?? {}),
	};

	const url = toQueryStringURL(urls['pickupStores'], params);

	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	};
	return fetch(url, requestOptions).then(handleResponse);
}

function checkServiceAbility(req) {
	const auth = JSON.parse(localStorage.getItem('auth'));
	const store = JSON.parse(localStorage.getItem('orderInfo'));
	const requestOptions = {
		method: 'POST',
		headers: { token: auth.token, 'Content-Type': 'application/json' },
		body: JSON.stringify({ store: store.storeId, ...req }),
	};

	return fetch(urls['checkServiceability'], requestOptions).then(
		handleResponse
	);
}
