import React, { useEffect, useState } from 'react';
import SavedAddressCard from '../../Components/Cards/SavedAddressCard/SavedAddressCard';
import PlusIcon from '../../Assets/Icons/Plus.svg';
import CurrentLocation from '../../Assets/Icons/My-location.svg';
import SideNav from '../../Components/SideNav/SideNav';
import AddressSlider from './AddressSlider';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../Redux/Actions/auth.actions';
import { orderActions, storeActions } from '../../Redux/Actions';
import { clientService } from '../../Redux/Services';
import toast from 'react-hot-toast';
import { cloneDeep, get } from 'lodash';
import Modal from '../../Components/Modal/Modal';
import { Loader } from '../../Components/Spinner/Loader';


const Address = () => {
	const { auth, orderInfo } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [savedAddress, setSavedAddress] = useState([]);
	const [isaddressSliderVisible, setIsaddressSliderVisible] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const addresses = [];
		auth.addresses.forEach((ogAddress, index) => {
			const address = cloneDeep(ogAddress); 
			if (index === 0) {
				address.selected = false;
				checkServiceablity(address).then((res) => {
					setIsLoading(false);
					if (get(res, 'servicable')) {
						address.selected = true;

						orderInfo.selectedAddress = address;
						dispatch(orderActions.updateOrderStore(orderInfo));
						dispatch(storeActions.setStoreDeliveryCharges({deliveryCharges: get(res,'deliveryCharges')}))
					} else {
						orderInfo.selectedAddress = {};
						dispatch(orderActions.updateOrderStore({}));
					}
				});
			} else {
				address.selected = false;
			}
			addresses.push(address);
		});

		setSavedAddress([...addresses]);
	}, [auth]);

	const checkServiceablity = (address) => {
		setIsLoading(true);
		return clientService
			.checkServiceAbility({ lat: address.lat,long: address.lng??address.long })
			.then(
				(res) => {
					return new Promise((resolve) => {
						resolve(res);
					});
				},
				(error) => {
					toast.error(error);
					setIsLoading(false);
				}
			);
	};

	const Location =()=>{
		toast("Feature Coming Soon !!!",{
		  duration : 3000,
		})
	  }

	const changeAddress = (selectedAddressIndex) => {
		let updatedAddress = savedAddress;
		updatedAddress.forEach((address, index) => {
			if (index === selectedAddressIndex) {
				updatedAddress[index].selected = true;
			} else {
				updatedAddress[index].selected = false;
			}
		});
		setSavedAddress([...updatedAddress]);
		orderInfo.selectedAddress = updatedAddress[selectedAddressIndex];
		dispatch(orderActions.updateOrderStore(orderInfo));
	};

	const deleteAddress = (index) => {
		auth.addresses.splice(index, 1);
		dispatch(authActions.updateUser({ address: [...auth.addresses] }));
	};
	return (
		<div>
			{isLoading && <Loader />}
			<Modal
				visible={isModalOpen}
				title={`Delivery is not possible here !`}
				text='We cannot deliver your food at this address'
				close={() => setIsModalOpen(false)}
				closingText='OK'
			/>
			<div className='address-box'>
				<div
					onClick={() => {setIsaddressSliderVisible(true)}}
					className='add-address flex bg-white px-4 sm:px-9 py-7 items-center mb-2 rounded cursor-pointer'
				>
					<img
						src={PlusIcon}
						className='w-auto h-auto'
						alt='Plus Icon'
					/>
					<div className='text-base font-semibold ml-4 sm:ml-8'>
						Add Address
					</div>
				</div>

				{auth.addresses.length > 0 && (
					<div className='saved-address py-6 bg-white rounded px-4 sm:px-0'>
						<div className='text-xs uppercase ml-2 sm:ml-20'>
							Saved Address
						</div>
						{savedAddress.map((address, index) => 
							{return <div className='my-3 sm:my-0'>
								<SavedAddressCard
									index={index}
									key={index}
									addressLine1={address.addressLine1}
									addressLine2={address.addressLine2}
									state={address.state}
									city={address.city}
									pincode={address.pincode}
									email = {address.email}
									onSelect={changeAddress}
									selected={address.selected}
									deleteAddress={deleteAddress}
									deliveryPossible={address.deliveryPossible}
									lat={address.lat}
									lng={address.lng??address.long}
									name={address.userName}
									qrcodeimage = {address?.qrCode}
								/>
							</div>}
						)}
					</div>
				)}
			</div>

			<SideNav
				visible={isaddressSliderVisible}
				onClose={() => setIsaddressSliderVisible(false)}
				mode='dark'
			>
				<AddressSlider
					type='NEW'
					close={() => setIsaddressSliderVisible(false)}
					mapId='map2'
				/>
			</SideNav>
		</div>
	);
};

export default Address;
