import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Tooltip } from '@mui/material';

const DAYS = {
	SUNDAY : {
		dayname: 'Sunday',
		key: 'SUNDAY',
		label: 'S',
	},
  MONDAY : 
	{
		dayname: 'Monday',
		key: 'MONDAY',
		label: 'M',
	},
	TUESDAY : {
		dayname: 'Tuesday',
		key: 'TUESDAY',
		label: 'T',
	},
  'WEDNESDAY' : 
	{
		dayname: 'Wednesday',
		key: 'WEDNESDAY',
		label: 'W',
	},
  'THURSDAY' :
	{
		dayname: 'Thursday',
		key: 'THURSDAY',
		label: 'T',
	},
  'FRIDAY' :
	{
		dayname: 'Friday',
		key: 'FRIDAY',
		label: 'F',
	},
	'SATURDAY' : {
		dayname: 'Saturday',
		key: 'SATURDAY',
		label: 'S',
	},
};

const StyledToggleButtonGroup = withStyles((theme) => ({
	grouped: {
		// margin: theme.spacing(1),
		// padding: theme.spacing(0, 1),
		'&:not(:first-child)': {
			border: '1px solid',
			borderColor: '#692B7C',
			borderRadius: '50%',
		},
		'&:first-child': {
			border: '1px solid',
			borderColor: '#692B7C',
			borderRadius: '50%',
		},
		maxWidth: '95%',
	},
}))(ToggleButtonGroup);

const StyledToggle = withStyles({
	root: {
		color: 'white',
    background: '#BA9BC3',
    padding: '3px',
		'&:disabled$selected': {
			color: 'white',
      background: '#BA9BC3',
      padding: '3px',
      fontSize: '0.65rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems : 'center'
		},
		'&:hover': {
			borderColor: '#BA9BC3',
			background: '#BA9BC3',
		},
		'&:hover$selected': {
			borderColor: '#BA9BC3',
			background: '#BA9BC3',
		},
    '&:disabled':{
      color: '#BA9BC3',
      background: 'white',
    },
		minWidth: 20,
		maxWidth: 20,
		height: 20,
		textTransform: 'unset',
		fontSize: '0.75rem',
	},
	selected: {},
})(ToggleButton);

const SubscriptionDaysContainer = (props) => {
	const { selectedDays } = props;
	// <div className='flex w-full max-w-full justify-between	'>
	return (
		<div className='text-sm py-2'>
			<StyledToggleButtonGroup
				size='small'
				arial-label='Days of the week'
				value={selectedDays}
				style={{
					display: 'flex',
					// maxWidth: '300px',
					justifyContent: 'space-between',
					width: '100%',
          gap: '4px',
				}}
				disabled
			>
				{Object.keys(DAYS).map((day)=>(
					<StyledToggle
					key={DAYS[day].key}
					value={DAYS[day].key}
					aria-label={DAYS[day].key}
					disabled
				>
					{DAYS[day].label}
				</StyledToggle>
				))}
			</StyledToggleButtonGroup>
		</div>
	);
};
SubscriptionDaysContainer.defaultProps = {
	disabled : false
}
export default SubscriptionDaysContainer;
