import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {addAccordingToType, removeFromCart} from '../../../Functions/CartFunctions';
import { storeClosedOrNot } from "../../../Functions/helpersFunctions";
import { modalActions } from "../../../Redux/Actions";

export default function SmallFoodCart({
  name,
  image,
  price,
  rating,
  productId,
  productType,
  selectedVariant,
  type
}) {
  const [itemCount, setItemCount] = useState(0);

  const { restaurantId } = useParams();

  const { orderInfo, menu, customization, cart, store } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // fetches count from cart of the specific food item
    let count = 0;

    cart.forEach((item, index) => {
      if (item.productId === productId) {
        count = count + item.quantity;
      }
    });
    setItemCount(count);
  }, [cart]);

  const addToCart = () => {  
    if(storeClosedOrNot(orderInfo, store)){
      return
    }
    if (productType === 'COMBO') {
			dispatch(modalActions.toggleComboModal(true, { productId }));
			return;
		}
    
    addAccordingToType(
      menu.products,
      productId,
      customization,
      orderInfo.orderType,
      itemCount,
      setItemCount,
      dispatch,
      orderInfo,
    );
  }

  const onRemove = () => {
    removeFromCart(
      menu.products,
      productId,
      orderInfo.orderType,
      itemCount,
      setItemCount,
      dispatch
    );
  };

  return (
    <div className='bg-FFFFFF w-full font-open-sans'>
      <Link
        to={`/${restaurantId}/food-item/${orderInfo.orderType}/${productId}`}>
        <div className='relative'>
          <img
            className='object-cover h-20 w-full rounded-t'
            src={image}
            alt='food item'
          />
          {rating && (
            <div
              style={{ fontSize: "8px", zIndex: "1" }}
              className='h-4 px-1 bg-white absolute top-1 right-1 flex space-x-1 justify-center items-center rounded-sm'>
              <div>{rating}</div>
              <span className='fa fa-star text-7FB069' />
            </div>
          )}
          <div className='h-full w-full  absolute inset-0  bg-gray-900 opacity-40'></div>
        </div>
      </Link>
      <div className='flex flex-col space-y-1 p-2 h-28 justify-between'>
        <Link
          to={`/${restaurantId}/food-item/${orderInfo.orderType}/${productId}`}>
          <div className='text-xs font-semibold'>{name}</div>
        </Link>
        <Link
          to={`/${restaurantId}/food-item/${orderInfo.orderType}/${productId}`}>
          <div className='text-2xs text-8B8B8B'>Rs. {price}</div>
        </Link>
        {itemCount ? (
          <div className='bg-white w-full text-xs  border border-gray-500 rounded'>
            <div className='flex items-center justify-between py-1 px-2 space-x-2'>
                <ion-icon
                    onClick={onRemove}
                    style={{
                      color: "#8593FF",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    name='remove'></ion-icon>
              <div className='text-xs sm:text-sm capitalize '>
                {itemCount}
              </div>
              <ion-icon
                    style={{
                      color: "#8593FF",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={addToCart}
                    name='add'></ion-icon>
            </div>
          </div>
        ) : (
          <div onClick={addToCart}>
            {/* Add Button for bulk and regular order */}
            {orderInfo.orderType === "bulk" ? (
              <div className='bg-white w-full text-center text-xs border border-gray-300 rounded hover:bg-8593FF hover:text-white py-1 cursor-pointer'>
                <div className='font-semibold capitalize '>Add Item</div>
                <div>min: 10 quantity</div>
              </div>
            ) : (
              <div className='bg-white w-full font-semibold text-center text-xs h-7 border border-gray-300 rounded hover:bg-8593FF hover:text-white py-1 capitalize cursor-pointer'>
                Add Item
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
