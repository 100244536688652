import React, { useState } from 'react';

export default function Star({ number=null, onSelect=function(){} , viewOnly=false,handleChange=null}) {
    const [noOfSelectedStars, setNoOfSelectedStars] = useState(number)
    // console.log(number, 'number')

    const onStarChange=(number)=>{
        if(viewOnly) return
        setNoOfSelectedStars(number)
        onSelect(number)
        handleChange(number)
    }
    return (
        [1, 2, 3, 4, 5].map(star => {
            if (star <= (number || noOfSelectedStars)) {
                return <span onClick={() =>onStarChange(star) } className={`fa fa-star checked ${viewOnly?'':'cursor-pointer'}`}></span>
            }
            return <span onClick={() => onStarChange(star)} className={`fa fa-star unchecked ${viewOnly?'':'cursor-pointer'}`}></span>
        })
    )
}
