/** @format */

import user from "../../Assets/Icons/user-circle.svg";
import cart from "../../Assets/Icons/shopping-bag.svg";
import credit from "../../Assets/Icons/CreditCard.png";
import location from "../../Assets/Icons/location-on.svg";
import logoutIcon from "../../Assets/Icons/account-logout.svg";
import changePinIcon from "../../Assets/Icons/changePin.svg";
import kids from "../../Assets/Icons/kids.svg";
import { useHistory, NavLink, useParams } from "react-router-dom";
import {
  authActions,
  modalActions,
  notificationActions,
} from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import toast from "react-hot-toast";

const SideMenu = ({ onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth, client } = useSelector((state) => state);
  const { restaurantId } = useParams();
  const { walletEnabled, clientType } = client;
  const logout = () => {
    dispatch(authActions.userLogout());
    onClose();
    document.body.classList.remove("stop-scrolling");
    if (restaurantId) {
      history.push(`/${restaurantId}/home`);
    } else {
      history.push("/");
    }
  };

  const signOut = () => {
    dispatch(authActions.userSignOut());
  };

  const closeSlider = () => {
    onClose();
    document.body.classList.remove("stop-scrolling");
  };

  const handleChangePinPopUp = () => {
    dispatch(modalActions.toggleChangePinModal(true));
    onClose();
  };

  const handleEditProfilePopUp = () => {
    dispatch(modalActions.toggleEditProfileModal(true));
    onClose();
  };

  return (
    <div className="w-9/12 mt-24 mx-auto font-open-sans">
      <div className="flex py-4 border-b border-gray-200 space-x-6 items-center">
        <div>
          <img src={user} className="w-9 h-9" alt="user" />
        </div>
        <div className="flex-grow">
          <div className="font-bold">Hello {auth.name}</div>
          <div className="text-gray-400 text-xs">+91 {auth.user}</div>
          <div
            className="text-blue-400 cursor-pointer text-xs"
            onClick={handleEditProfilePopUp}
          >
            Edit Profile
          </div>
        </div>
      </div>
      {/* 
      {clientType !== "SCHOOL" && <NavLink
        onClick={closeSlider}
        to={`/my-addresses`}>
			
        <div className='flex space-x-4 my-6'>
          <img
            src={location}
            className='h-6 w-6'
            alt='location'
          />
          <div className='font-semibold'>
            Your Addresses
          </div>
        </div>
      </NavLink>} */}

      {clientType === "SCHOOL" && (
        <NavLink onClick={closeSlider} to={`/my-child`}>
          <div className="flex space-x-4 my-6">
            <img src={kids} className="h-6 w-6" alt="location" />
            <div className="font-semibold">Your Children</div>
          </div>
        </NavLink>
      )}

      {clientType === "STAFF" && (
        <NavLink onClick={closeSlider} to={`/my-staff`}>
          <div className="flex space-x-4 my-6">
            <img src={kids} className="h-6 w-6" alt="location" />
            <div className="font-semibold">Staff</div>
          </div>
        </NavLink>
      )}

      <div className="mt-8">
        <div className="flex space-x-4 my-6">
          <img src={cart} className="h-6 w-6" alt="cart" />
          <div className="font-semibold">Your Orders</div>
        </div>
        <div className="mx-2">
          <NavLink
            onClick={closeSlider}
            activeClassName="border-l-4 border-8593FF flex text-8593FF py-2 font-semibold "
            className="border-l-2 border-gray-300 flex py-2"
            to={`/my-orders/past`}
          >
            <div className="ml-4 text-sm">Past Orders</div>
          </NavLink>
          <NavLink
            onClick={closeSlider}
            activeClassName="border-l-4 border-8593FF flex text-8593FF py-2"
            className="border-l-2 border-gray-300 flex py-2"
            to={`/my-orders/current`}
          >
            <div className="ml-4 text-sm">Active Orders</div>
          </NavLink>
          <NavLink
            onClick={closeSlider}
            activeClassName="border-l-4 border-8593FF flex text-8593FF py-2"
            className="border-l-2 border-gray-300 flex py-2"
            to={`/my-orders/cancelled`}
          >
            <div className="ml-4 text-sm">Cancelled Orders</div>
          </NavLink>
          <NavLink
            onClick={closeSlider}
            activeClassName="border-l-4 border-8593FF flex text-8593FF py-2"
            className="border-l-2 border-gray-300 flex py-2"
            to={`/my-orders/scheduled`}
          >
            <div className="ml-4 text-sm">Subscription</div>
          </NavLink>
        </div>
      </div>
      {walletEnabled && (
        <div className="mt-8">
          <div className="flex space-x-4 my-6">
            <img src={credit} className="h-6 w-6" alt="wallet" />
            <div className="font-semibold">Meal Credits</div>
          </div>
          <div className="mx-2">
            <NavLink
              onClick={(e) => {
                closeSlider();
              }}
              activeClassName="border-l-4 border-8593FF flex text-8593FF py-2 font-semibold "
              className={"border-l-2 border-gray-300 flex py-2 "}
              to={`/my-credits`}
            >
              <div className="ml-4 text-sm">Add Credits</div>
            </NavLink>
            <NavLink
              onClick={closeSlider}
              activeClassName="border-l-4 border-8593FF flex text-8593FF py-2 font-semibold "
              className="border-l-2 border-gray-300 flex py-2"
              to={`/my-credits-history`}
            >
              <div className="ml-4 text-sm">Passbook</div>
            </NavLink>
          </div>
        </div>
      )}
      <div
        className="flex space-x-4 my-6 cursor-pointer"
        onClick={handleChangePinPopUp}
      >
        <img src={changePinIcon} className="h-6 w-6" alt="changePin" />
        <div className="font-semibold">Change Passcode</div>
      </div>
      <div className="flex space-x-4 my-6 cursor-pointer" onClick={logout}>
        <img src={logoutIcon} className="h-6 w-6" alt="logout" />
        <div className="font-semibold">Logout</div>
      </div>
      {/* <div className="flex space-x-4 my-6 cursor-pointer" onClick={signOut}>
        <img src={logoutIcon} className="h-6 w-6" alt="logout" />
        <div className="font-semibold">Remove Token</div>
      </div> */}
    </div>
  );
};

export default SideMenu;
