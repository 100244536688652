import React from 'react'
import { useSelector } from 'react-redux';
import empty from "../../Assets/Icons/Group 159550.svg";

export default function EmptyOrder() {
    const {orderInfo} = useSelector(state=>state)
    return (
        <div>
            <div className='flex flex-col items-center  py-8 px-8 bg-white'>
                <div className='text-center uppercase text-7FB069 py-4 border-b-2 w-full'>
                    {orderInfo.orderType==='schedule' ? 'Your Subscription'  : 'YOUR ORDER'}
                </div>
                <img src={empty} className='w-1/3 mt-6' alt='no order placed' />
                <div className='mt-4 text-center capitalize font-reenie-beanie text-xl mb-16'>
                    No Order is given yet!! <br />
                    Go ahead order something..
                </div>
            </div>
        </div>
    )
}
