import React from "react";
import styles from "./SelectVariantPopup.module.css";

function SelectVariantPopup(props) {
  const { variants, onSelect, closePopup } = props;

  const handleSelect = (item) => {
    closePopup();
    onSelect(item);
  };

  return (
    <div className={styles.root}>
      <div className={styles["arrow-container"]}>
        <div />
      </div>
      <p>Select Variant: </p>
      <ul className={styles.variants}>
        {variants?.map((item) => (
          <li onClick={() => handleSelect(item)}>{item?.displayName}</li>
        ))}
      </ul>
    </div>
  );
}

export default SelectVariantPopup;
