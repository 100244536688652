/** @format */

import React, { useEffect, useState, useCallback } from "react";
import { creditActions } from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import SubHeader from "../../Components/Header/SubHeader";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const MyCreditsHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);

  const handleChange = (event) => {
    setFilters(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const { orderInfo, credit } = useSelector((state) => state);

  useEffect(() => {
    var CurrentDate = moment().format("MM-DD-YYYY");
    dispatch(
      creditActions.fetchCreditsHistory({ fromDate: null, toDate: null,txnTypes:filters?[filters]:null,status:statusFilter?[statusFilter]:null })
    );
  }, [filters,statusFilter,]);

  if (orderInfo.storeId) {
    var linkadd = `/${orderInfo.storeId}/home`;
  } else {
    var linkadd = `/home`;
  }

  let tnxDOM = [];
  tnxDOM = credit.transactions
  .map((txn, idx) => {
    return (
      <tr style={{ textAlign: "center" }} className="border-b border-gray-300">
        <td>
          {moment(txn.updatedAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm")}
        </td>
        <td>{txn.orderId}</td>
        <td>{txn.otherDetails}</td>
        <td>{txn.mealDetails ?? "-"}</td>
        <th
          className={
            txn.status == "SUCCESS"
              ? "text-green-600"
              : txn.status == "FAILED"
              ? "text-red-600"
              : "text-yellow-400"
          }
        >
          {txn.status}
        </th>
        <td>{txn.txnType ?? "-"}</td>

        <td
          className={
            txn.status == "SUCCESS" &&
            (txn.txnType == "DEBIT" || txn.txnType == "PENALTY"
              ? "text-red-600"
              : "text-green-600")
          }
        >
          {txn.txnType == "DEBIT" || txn.txnType == "PENALTY" ? "-" : "+"}{" "}
          {txn.amount}
        </td>
        <td>{txn.status == "SUCCESS" ? txn.postTxnBalance : "-"}</td>
        <td>
          {txn.status == "SUCCESS" && txn.txnType == "ORDERPAY" ? (
            <VisibilityIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(
                  `/${orderInfo.storeId}/track-order/${txn.orderId}`
                );
              }}
            />
          ) : (
            "-"
          )}
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className='hidden lg:block'>
        <SubHeader title='My Credit Passbook' link={linkadd} />
      </div>
      <div className='cssflex bg-white mt-2 sm:space-x-2 pt-5 m:mt-6 px-6 sm:px-12 sm:py-4 border-b border-gray-300'>
        <div className='cssflex flex-grow font-open-sans'>
          <div className='flex'>
          <div className="flex items-center text-464646 capitalize not-italic font-open-sans text-l">Transcation Status :</div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={statusFilter}
                onChange={handleStatusChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}>
                <MenuItem value={null}>
                  <em>All</em>
                </MenuItem>
                <MenuItem value={"SUCCESS"}>Success</MenuItem>
                <MenuItem value={"PENDING"}>Pending</MenuItem>
                <MenuItem value={"FAILED"}>Failed</MenuItem>
              </Select>
            </FormControl>

            <div className="flex items-center text-464646 capitalize not-italic font-open-sans text-l">Transcation Type :</div>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={filters}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}>
                <MenuItem value={null}>
                  <em>All</em>
                </MenuItem>
                <MenuItem value={"BONUS"}>Bonus</MenuItem>
                <MenuItem value={"DEBIT"}>Debit</MenuItem>
                <MenuItem value={"ORDERPAY"}>Order Paid</MenuItem>
                <MenuItem value={"PENALTY"}>Penalty</MenuItem>
                <MenuItem value={"TOPUP"}>Top - Up</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='flex flex-col justify-evenly h-full'>
            <table class='table-fixed'>
              <thead className='border-b border-gray-300'>
                <tr>
                  <th>Txn Date</th>
                  <th>OrderId</th>
                  <th>Other Details</th>
                  <th>Meal Details</th>
                  <th>Txn Status</th>
                  <th>Txn Type</th>
                  <th>Amount</th>
                  <th>Closing Balance</th>
                  <th>View</th>
                </tr>
              </thead>
              {tnxDOM.length > 0 ? (
                <tbody style={{ textAlign: "center", borderSpacing: "5px" }}>
                  {tnxDOM}
                </tbody>
              ) : (
                <p className='no-orders-text px-3 py-3'>
                  No Transcation to Display
                </p>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCreditsHistory;
