import { storeConstants } from '../Constants';

const initialState = {
	storeId: '',
	storeName: '',
	promotions: [],
	address: '',
	openingTime: '',
	closingTime: '',
	isClosed: false,
	orderPlacementNumber: '',
	customerSupport: '',
	minCartValue: '',
	maxCartValue: '',
	fssaiNumber: '',
	gstEnabled: false,
	gst: '',
	paymentCOD: false,
	isInactive: false,
	loading: false,
	deliveryEnabled: true,
	now: true, 
	pickupEnabled: true,
	packagingCharges: 0,
	deliveryCharges: 0,
	bulkOrdering: true,
	scheduling: true,
	mealScheduling: true,
	mealSubscription: true,
	paymentDigital: true,
	isTracking: true,
	isDelivery: true,
	isSelfDelivery: false,
	isServisable: true,
	geoCoordinate: { latitude: '', longitude: '' },
	promoCodes: [],
	pickupStores: [],
};

export function store(state = initialState, action) {
	switch (action.type) {
		case storeConstants.STORE_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case storeConstants.STORE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case storeConstants.STORE_FAILURE:
			return {
				...state,
				loading: false,
			};
		case storeConstants.GET_PROMO_CODES_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case storeConstants.GET_PROMO_CODES_SUCCESS:
			return {
				...state,
				isLoading: false,
				...action.payload,
			};
		case storeConstants.GET_PROMO_CODES_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case storeConstants.REMOVE_PROMO:
			return {
				...state,
				selectedPromo: {},
				couponAmount: 0,
			};
		case storeConstants.SET_STORE_AVILABILITY:
			return {
				...state,
				isClosed: action.payload,
			};
		case storeConstants.SET_STORE_DELIVERY_CHARGES:
			return {
				...state,
				...action.payload,
			};
		case storeConstants.SET_PICKUP_STORES:
			return {
				...state,
				pickupStores: action.payload,
			};
		default:
			return state;
	}
}
