import CancelledOrder from '../../Assets/Icons/cancelled-order.svg';
import CurrentOrder from '../../Assets/Icons/current-order.svg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ConfirmSubscriptionOrder({ order, type }) {
	const history = useHistory();
	// type --> past (past Orders) , current ( current orders ) cancelled ( cancelled orders )

	const {store} = useSelector((state)=>state)
	return (
		<div className='flex sm:space-x-6 py-8 border-b border-gray-300 mx-4 px-2 bg-white'>
			<div className='flex-grow font-open-sans'>
				<div className='flex flex-col justify-evenly h-full'>
					<div
						className={`text-xs sm:text-sm ${
							type === 'cancelled' || type === 'past'
								? 'text-gray-400'
								: 'text-7FB069'
						} font-semibold`}
					>
						#Order id {order.orderId}
					</div>
					<p className='text-xs sm:text-sm'>
						{order.items.map((item, index) => {
							return index < 3
								? `${item.productName} ${
										index != order.items.length - 1
											? ' , '
											: ''
								  }`
								: null;
						})}
						{order.items.length > 3
							? `....${order.items.length - 3} items more`
							: null}
					</p>
					{/* <div className='text-xs  sm:text-sm font-semibold'>
						&#x20B9; {order.orderValue}
					</div> */}
					<div className=' text-xs sm:text-sm'>
						<span className='mr text-gray-500'>
							{type === 'scheduled' && 'Order Placed On '}
						</span>
						{order.placedAt}
						{/* <span className='ml-2 text-gray-400'>
							{order.address}
						</span> */}
					</div>
					<div className=' cursor-pointer flex space-x-4 sm:space-x-8 items-center mt-2'>
							<div
								className='text-8593FF text-xs sm:text-sm uppercase'
								onClick={() =>
									history.push(
										`/${store.storeId}/track-order/${order.orderId}`
									)
								}
							>
								View Details
							</div>
						
						{type === 'past' && order.invoice && (
							<>
								{/* <button className='text-xs sm:text-sm text-8593FF border border-8593FF px-3 py-1 rounded '>
                  Re-order
                </button> */}
								<div
									className='text-8593FF text-xs sm:text-sm cursor-pointer'
									onClick={() => {
										window.open(order.invoice);
									}}
								>
									Download invoice
								</div>
							</>
						)}
						{/* {type === "cancelled" && (
              <button className='text-xs sm:text-sm text-8593FF border border-8593FF px-6 py-1 rounded'>
                Order Now
              </button>
            )} */}
					</div>
				</div>
			</div>
			<div>
				<div
					className={`flex flex-col justify-evenly h-full text-center items-center ml-4 text-xs sm:text-sm my-2 ${
						type !== 'past' && 'md:mr-10'
					}`}
				>
					<div className=''>Status</div>
					{(order.orderStatus === 'PENDING' ) && (
						<div className='font-semibold'>In Process</div>
					)}
          {(order.orderStatus === 'CANCELLED' ) && (
						<div className='font-semibold '>Cancelled</div>
					)}
					{order?.orderStatus ===  'FULFILLED' && (
						<div className='font-semibold'>
							Delivered <br />
							On {order?.lastupdateAt}
						</div>
					)}
					{order.orderStatus === 'PENDING' && (
						<img
							src={CurrentOrder}
							className='w-10'
							alt='current order'
						/>
					)}
					{order.orderStatus === 'CANCELLED' && (
						<img
							src={CancelledOrder}
							className='w-10'
							alt='cancelled'
						/>
					)}
					{type === 'past' && (
						<div className='w-8 h-8 rounded-full bg-7FB069 flex justify-center items-center'>
							<i className='fas fa-check text-white'></i>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
