/** @format */

import { isEmpty } from "lodash";
import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { formatAMPM, formatddmmyyyy } from "../../../Functions/GetTime";
import { getCustomizationTotal } from "../../../Redux/Services";

const BillCard = () => {
  let totalAmount = 0;

  const { orderInfo, menu, store } = useSelector((state) => state);
  const [orderItems, setOrderItems] = useState([]);

  const time = formatAMPM(new Date());

  useEffect(() => {
    let cart = JSON.parse(localStorage.getItem("cart"));
    if (cart.length) {
      setOrderItems(cart);
    }
  }, []);

  if (isEmpty(menu.products)) return <></>;

  const calculateTotalAmount = (value, qty, item) => {
    let amt = 0;
    amt = amt + value * qty * orderInfo.noOfDays;
    if (item?.taxIncluded) {
      amt = amt - getItemTax(item);
    }
    let customizationTotal = getCustomizationTotal(item) * qty;
    totalAmount = totalAmount + amt + getItemTax(item) + customizationTotal;
    return amt + customizationTotal;
  };

  const getPrice = (item) => {
    let calculatedTotalAmount = calculateTotalAmount(
      item.selectedVariant.discountedPrice
        ? item.selectedVariant.discountedPrice
        : item.selectedVariant.price,
      item.quantity,
      item
    );
    return calculatedTotalAmount;
  };

  // const getCustomizationTotal = (item,qty) => {
  //   let total = 0;
  //   Object.keys(item.finalCustomisation.addedItems).forEach((itemkey) => {
  //     item.appliedCustomization[itemkey].forEach((option) => {
  //       total = total + option.price[item.selectedVariant.id];
  //     });
  //   });

  //   Object.keys(item.finalCustomisation.replacedItems).forEach((processId)=>{
  //     item.appliedCustomization[processId]["to"].forEach((option)=>{
  //       for (const items of item.finalCustomisation.replacedItems[processId]['to']){
  //         if (option.id == items){
  //           total = total + option.price[item.selectedVariant.id];
  //         }
  //       }
  //     })
  //   })

  //   Object.keys(item.finalCustomisation.replacedItems).forEach((processId)=>{
  //     item.appliedCustomization[processId]["from"].forEach((option)=>{
  //       for (const items of item.finalCustomisation.replacedItems[processId]['from']){
  //         if (option.id == items){
  //           total = total - option.price[item.selectedVariant.id];
  //         }
  //       }
  //     })
  //   })
  //   console.log(total,qty,orderInfo, 'noOfDays')
  //   return total*qty* orderInfo.noOfDays;
  // };

  const getItemTax = (item) => {
    const price = item.selectedVariant.discountedPrice
      ? item.selectedVariant.discountedPrice
      : item.selectedVariant.price;
    const getCustomizationPrice = getCustomizationTotal(item) * item.quantity;
    const productObj = menu.products[item.productId];
    return store.gstEnabled
      ? (productObj.tax *
          (price + getCustomizationPrice) *
          item.quantity *
          orderInfo.noOfDays) /
          100
      : 0;
  };

  return (
    <div className="flex flex-col bg-white bg-no-repeat rounded py-8 px-9 space-y-4 bill-section">
      <div className="font-typewritter text-left text-base text-black capitalize">
        {store.storeName}
      </div>
      <div className="font-typewritter text-left text-base text-black capitalize">
        Bill Detail
      </div>
      <div className="flex items-end justify-between">
        <div className="space-y-2">
          {/* <div className='font-typewritter font-semibold text-left text-sm text-82B26D  capitalize'>
            Order Id:{" "}
            <div className='inline-block tracking-wider'>{orderId}</div>
          </div> */}
          {orderInfo.orderType === "schedule" && (
            <div className="font-typewritter font-semibold text-left text-xs text-black  capitalize">
              scheduled for:{" "}
              <div className="inline-block tracking-wider text-2xs">
                {formatddmmyyyy(new Date(orderInfo.startDate), "/")}-
                {formatddmmyyyy(new Date(orderInfo.endDate), "/")}
              </div>
            </div>
          )}
        </div>
        <div className="font-typewritter font-semibold text-left text-2xs text-black">
          <div>
            {orderInfo.deliverNow === false
              ? formatddmmyyyy(new Date(orderInfo.laterDate), "/")
              : formatddmmyyyy(new Date(), "/")}
          </div>
          {orderInfo.deliverNow !== false && <div>{time}</div>}
        </div>
      </div>
      <div className="flex text-left text-sm text-AFADAD capitalize font-typewritter">
        <div className="bill-items-name text-left">Items</div>
        <div className="bill-items-qty text-center">Qty</div>
        <div className="bill-items-tax text-center">Tax</div>
        <div className="bill-items-amt text-right">Amt</div>
      </div>
      <div className="border-b-2 border-dashed border-gray-300 pb-4 font-typewritter">
        {orderItems.length > 0 &&
          orderItems.map((item, index) => (
            <div className="mb-2">
              <div className="flex flex-col">
                <div className="flex space-y-2 items-end">
                  <div className="bill-items-name text-left text-sm text-black capitalize">
                    {item.name}({item.selectedVariant.displayName})
                  </div>
                  <div className="bill-items-qty text-center text-sm text-black capitalize ">
                    {item.quantity}{" "}
                    {/* {orderInfo.orderType === "schedule" &&
                      `X ${orderInfo.noOfDays}`} */}
                  </div>
                  <div className="bill-items-tax text-center text-sm text-black capitalize ">
                    &#x20B9; {parseFloat(getItemTax(item)).toFixed(2)}
                  </div>
                  <div className="bill-items-amt text-right text-sm text-black capitalize">
                    &#x20B9; {parseFloat(getPrice(item)).toFixed(2)}
                  </div>
                </div>
                {item.productType === "COMBO"
                  ? Object.keys(item.finalCustomisation).map((comboGroupId) => {
                      const comboGroupItem =
                        item.finalCustomisation[comboGroupId];
                      return (
                        <Fragment key={index}>
                          <div className="bill-items-name text-left text-sm text-8B8B8B capitalize">
                            - {comboGroupItem.name}
                          </div>
                        </Fragment>
                      );
                    })
                  : Object.keys(item.finalCustomisation.addedItems).map(
                      (itemkey) => {
                        return item.appliedCustomization[itemkey].map(
                          (option) => (
                            <Fragment>
                              <div className="bill-items-name text-left text-sm text-8B8B8B capitalize">
                                - {option.name}
                              </div>
                            </Fragment>
                          )
                        );
                      }
                    )}
                {item.productType !== "COMBO" &&
                  Object.keys(item.finalCustomisation.replacedItems).map(
                    (itemkey) => {
                      return item.appliedCustomization[itemkey].to.map(
                        (option) => (
                          <Fragment>
                            <div className="bill-items-name text-left text-sm text-8B8B8B capitalize">
                              - {option.name}
                            </div>
                          </Fragment>
                        )
                      );
                    }
                  )}
              </div>
            </div>
          ))}
      </div>
      <div className="flex flex-col space-y-2 pb-4 border-b-2 border-dashed border-gray-300 font-typewritter">
        <div className="flex justify-between">
          <div className="text-left text-sm text-black capitalize">
            Item Total
          </div>
          <div className="font-open-sans font-semibold text-left text-sm text-black capitalize">
            &#x20B9; {parseFloat(totalAmount).toFixed(2)}
          </div>
        </div>
        {orderInfo.deliveryMode === "delivery" && (
          <div className="flex justify-between">
            <div className="text-left text-sm text-black capitalize">
              Delivery Fee
            </div>
            <div
              className={`text-left text-sm ${
                store.deliveryCharges === 0 ||
                orderInfo.orderType === "schedule"
                  ? "text-green-800"
                  : "text-black"
              } capitalize`}
            >
              &#x20B9;{" "}
              {store.deliveryCharges === 0 || orderInfo.orderType === "schedule"
                ? "Free"
                : store.deliveryCharges}
            </div>
          </div>
        )}
        <div className="flex justify-between">
          <div className="text-left text-sm text-black capitalize">
            Packaging Fee
          </div>
          <div
            className={`text-left text-sm ${
              store.packagingCharges === 0 || orderInfo.orderType === "schedule"
                ? "text-green-800"
                : "text-black"
            } capitalize`}
          >
            &#x20B9;{" "}
            {store.packagingCharges === 0 || orderInfo.orderType === "schedule"
              ? "Free"
              : parseFloat(store.packagingCharges).toFixed(2)}
          </div>
        </div>
        {/* <div className='flex justify-between'>
          <div className='text-left text-sm text-black capitalize'>
            Taxes and charges
          </div>
          <div className='text-left text-sm text-black capitalize'>
            {orderInfo.tax}
          </div>
        </div> */}
      </div>

      {orderInfo.couponAmount > 0 && orderInfo.selectedPromo && (
        <div className="flex justify-between font-typewritter">
          <div className="font-bold text-left text-base text-181818 capitalize">
            Coupon Discount Applied
          </div>
          <div className="font-bold text-left text-xl text-green-800 capitalize">
            - &#x20B9; {orderInfo.couponAmount}
          </div>
        </div>
      )}

      <div className="flex justify-between font-typewritter">
        <div className="font-bold text-left text-base text-181818 capitalize">
          Grand Total
        </div>
        <div className="font-bold text-left text-xl text-181818 capitalize">
          &#x20B9;{" "}
          {orderInfo.deliveryMode === "delivery"
            ? parseFloat(
                totalAmount +
                  (orderInfo.orderType === "schedule"
                    ? 0
                    : store.packagingCharges) +
                  (orderInfo.orderType === "schedule"
                    ? 0
                    : parseFloat(store.deliveryCharges)) -
                  orderInfo.couponAmount
              ).toFixed(2)
            : parseFloat(
                totalAmount + store.packagingCharges - orderInfo.couponAmount
              ).toFixed(2)}
          <div className="text-center text-sm text-181818">
            {orderInfo.orderType === "schedule" && "Per day"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillCard;
