export const feedbackConstants = {
    SET_ITEM: 'SET_ITEM',

    INIT_ORDERING_EXPERIENCE: 'INIT_ORDERING_EXPERIENCE',

    ORDERING_EXPERIENCE_REQUEST: 'ORDERING_EXPERIENCE_REQUEST',
    ORDERING_EXPERIENCE_SUCCESS: 'ORDERING_EXPERIENCE_SUCCESS',
    ORDERING_EXPERIENCE_FAILURE: 'ORDERING_EXPERIENCE_FAILURE',

    DELIVERY_EXPERIENCE_REQUEST: 'DELIVERY_EXPERIENCE_REQUEST',
    DELIVERY_EXPERIENCE_SUCCESS: 'DELIVERY_EXPERIENCE_SUCCESS',
    DELIVERY_EXPERIENCE_FAILURE: 'DELIVERY_EXPERIENCE_FAILURE',

    ITEM_FEEDBACK_REQUEST: 'ITEM_FEEDBACK_REQUEST',
    ITEM_FEEDBACK_SUCCESS: 'ITEM_FEEDBACK_SUCCESS',
    ITEM_FEEDBACK_FAILURE: 'ITEM_FEEDBACK_FAILURE',

}