import React, { useState } from "react";
import Searchbar from "./SearchBar/SearchBar";
import SearchIconComponet from "./SearchIconContainer/SearchIconContainer";

const SearchBarMobile = (props) => {
  const { setQuery, setShowSearch, query, showSearch } = props;
  const handleSearch = (tempQuery) => {
    setQuery(tempQuery);
  };
  return (
    <div className="block lg:hidden">
      <Searchbar
        setShowSearch={setShowSearch}
        showSearch={showSearch}
        placeholder="Search For Dishes"
        query={query}
        handleSearch={handleSearch}
      />
      {!showSearch && <div className="fixed bottom-24 right-5 sm:right-8 md:right-24 z-20 flex items-end justify-end">
        {/* notification container  */}
        {/* notification icon  */}
        <div
          className="flex items-center justify-center rounded-full"
          onClick={() => {
            setShowSearch(!showSearch);
            setQuery("");
          }}
        >
          <SearchIconComponet />
        </div>
      </div>}
    </div>
  );
};

export default SearchBarMobile;
