import { useSelector } from "react-redux";
import insta from "../../Assets/Icons/Path 1.svg";
import twitter from "../../Assets/Icons/Path 2.svg";
import facebook from "../../Assets/Icons/Path 3.svg";
import logo from "../../Assets/Images/resoee1.png";

export default function Footer({
  address,
  email,
  gst,
  fssai,
  customerSupport,
  registrationDate
}) {
  const client = useSelector((state) => state.client);
  const { privacyPolicy, terms, facebookUrl, twitterUrl, instagramUrl, companyName } =
    client;

  return (
    <div className="relative z-0 bottom-0">
      <div className="grid grid-cols-4 gap-2 bg-white p-6">
        {/* <div className='col-span-4 md:col-span-2'>
          <div className='w-full  flex flex-col space-y-2 font-sm font-open-sans'>
            <p className='font-bold'>Subscribe to uncle's Kitchen via email</p>
            <p className='text-747474'>Schedule YOUR MEAL WITH US!</p>
            <div className='flex flex-wrap md:flex-row pt-5 md:space-x-3'>
              <input
                className='w-full p-2 border-2 border-D17B69 rounded appearance-none text-gray-700 text-sm md:w-auto focus:outline-none focus:placeholder-transparent'
                type='email'
                placeholder='Email Address'
                aria-label='Email Address'
              />
              <button className='mt-2 md:mt-0 text-white h-10 w-full  p-2 text-sm bg-D17B69 rounded font-semibold uppercase md:w-auto hover:bg-gray-700'>
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div> */}
        <div className="sm:hidden col-span-4 text-xs font-bold mt-4">
          <div className="text-747474">
            {customerSupport} {email}
          </div>
          <div className="text-747474 mt-2">{address}</div>
        </div>
        <div className="col-span-4 md:col-span-4">
          <div className="space-x-0 sm:space-x-4 w-full flex space-y-4 sm:space-y-0">
            <div className="hidden sm:flex break-words font-open-sans font-bold text-xs w-full sm:w-1/3  flex-col space-y-4">
              {customerSupport && (
                <a href={`tel:${customerSupport}`} className="text-747474">
                  {customerSupport}
                </a>
              )}
              {email && (
                <a href="mailTo:Unclekitchen@mail.com" className="text-747474">
                  {email}
                </a>
              )}
              {address && <div className="text-747474">{address}</div>}
            </div>
            {(gst || fssai) && (
              <div className="pr-4 sm:pr-0 font-open-sans font-bold text-xs w-full sm:w-1/3 flex flex-col space-y-4">
                {/* <div  className='text-747474'>
                CONTACT US
              </div> */}
                {gst && <div className="text-747474">GST:{gst}</div>}
                {fssai && <div className="text-747474">FSSAI:{fssai}</div>}
                {/* <a href={`/${restaurantId}`} className='text-747474'>
                RETURNS
              </a>
              <a href={`/${restaurantId}`} className='text-747474'>
                FAQ
              </a>
              <a href={`/${restaurantId}`} className='text-747474'>
                SIZING GUIDE
              </a> */}
              </div>
            )}
            {companyName && <div className="font-open-sans font-bold text-xs w-full sm:w-1/3">
              <div className="text-747474">
                Company Name : {companyName}
              </div>
            </div>}
            <div className="font-open-sans font-bold text-xs w-full sm:w-1/3 flex flex-col space-y-4">
              {/* <a href={`/${restaurantId}`} className='text-747474'>
                WORK WITH US
              </a> */}
              {privacyPolicy && (
                <a href={privacyPolicy} className="text-747474">
                  PRIVACY POLICY
                </a>
              )}
              {terms && (
                <a href={terms} className="text-747474">
                  TERMS & CONDITIONS
                </a>
              )}
              {/* <a href={`/${restaurantId}`} className='text-747474'>
                PRESS ENQUIRIESS
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="p-2 bg-D17B69 flex justify-between text-white items-center">
        <div className="ml-4 text-2xs sm:text-base">@ {client.name} 2022</div>
        <div className="flex space-x-6 mr-4">
          {instagramUrl && (
            <a href={instagramUrl}>
              <img alt="instagram" src={insta} />
            </a>
          )}
          {twitterUrl && (
            <a href={twitterUrl}>
              <img alt="twitter" src={twitter} />
            </a>
          )}
          {facebookUrl && (
            <a href={facebookUrl}>
              <img alt="facebook" src={facebook} />
            </a>
          )}
          <div className=" flex justify-end items-center px-6">
            <div className="text-2xs ">powered by</div>
            <img className="ml-2 h-8 w-auto" src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
