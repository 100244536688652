import { orderTrackConstants } from "../Constants";

const initialState = {
  orderId: "",
  orderStatus: "",
  stages: [100, 200, 300, 400],
  items: {},
  riderDetails: null,
  orderType: "DELIVERY",
  placedAt: "",
  orderValue: "",
  isPaymentCOD: true,
  invoice: null,
  totalDiscount: "",
  deliveryCharges: "",
  packagingCharges: "",
  orderCancellationNumber: "",
  taxCharges: 0,
  orderRating: 0,
  deliveryRating: 0,
  paymentStatus: "",
  pastOrders:{}
};

export function orderTrack(state = initialState, action) {
  switch (action.type) {
    case orderTrackConstants.ORDER_SET:
      return {
        ...state,
        ...action.payload,
      };
    case orderTrackConstants.START_TRACKING:
        return {
            ...state,
            ...action.payload,
        }
    case orderTrackConstants.STOP_TRACKING:
        return {
            ...state,
            ...action.payload
        }
        case orderTrackConstants.ORDER_TRACKING_SUCCESS:
        return {
            ...state,
            ...action.payload,
        }
    case orderTrackConstants.ORDER_TRACKING_FAILURE:
        return {
            ...state,
            ...action.payload
        }
    case orderTrackConstants.SET_ORDER_ID:
        return {
            ...state,
            ...action.payload
        }
        case orderTrackConstants.PAST_ORDERS_REQUEST:
          return {
              ...state,
              isLoading: true
          }
      case orderTrackConstants.PAST_ORDERS_SUCCESS:
            return {
              ...state,
              isLoading: false,
              pastOrders:action.payload
          }
      case orderTrackConstants.PAST_ORDERS_FAILURE:
          return {
              ...state,
              isLoading: false,
              pastOrders:{},
              error: action.payload,
          }
      case orderTrackConstants.PAST_ORDER_DETAIL_REQUEST:
          return {
              ...state,
              isLoading: true
          }
      case orderTrackConstants.PAST_ORDER_DETAIL_SUCCESS:
          return {
              ...state,
              isLoading: false,
              ...action.payload
          }
      case orderTrackConstants.PAST_ORDER_DETAIL_FAILURE:
          return {
              ...state,
              isLoading: false,
              ...action.payload
          }
    default:
      return state;
  }
}
