import { menuService } from "../Services";
import { menuConstants } from "../Constants";
import { authActions } from "../Actions";
import toast from "react-hot-toast";
import { history, isProductAvailable } from "../../Functions/helpersFunctions";
import { cloneDeep } from "lodash";
import moment from "moment";
import { formatTimeDate } from "../../Functions/utils";
// const checkForNextAvailable = (timeSlots) => {};
// const checkForNextAvailableLater = (timeSlots, orderDate, orderTime) => {

// };
// const checkForSubscription = (
// 	productSubscriptionStartsOn,
// 	productSubscriptionEndsOn,
// 	userSubscriptionStartsFrom,
// 	userSubscriptionEndsOn,
// 	userSubscriptionTime
// ) => {

// };
const timeSlotAvailable = (product) => {
  return product.timeSlots["slot0"].startAt ? true : false;
};
// const foodItemNotAvailableDueToDate = (
// 	subscriptionFrom,
// 	subscriptionTo,
// 	startDate,
// 	endDate
// ) => {
// 	console.log(startDate, 'startDate');
// 	if (!subscriptionFrom || !subscriptionTo) return true;
// 	let startDateCopy = cloneDeep(startDate);
// 	let endDateCopy = cloneDeep(endDate);
// 	startDateCopy = moment(startDateCopy, 'YYYY-MM-DD').format('YYYY-MM-DD');
// 	endDateCopy = moment(endDateCopy, 'YYYY-MM-DD').format('YYYY-MM-DD');
// 	let subscriptionFromCopy = cloneDeep(subscriptionFrom);
// 	let subscriptionToCopy = cloneDeep(subscriptionTo);
// 	subscriptionFromCopy = moment(
// 		subscriptionFromCopy,
// 		'YYYY-MM-DD HH:mm:ss'
// 	).format('YYYY-MM-DD');
// 	subscriptionToCopy = moment(
// 		subscriptionToCopy,
// 		'YYYY-MM-DD HH:mm:ss'
// 	).format('YYYY-MM-DD');
// 	if (
// 		moment(startDateCopy).isBetween(
// 			subscriptionFromCopy,
// 			subscriptionToCopy
// 		) &&
// 		moment(endDateCopy).isBetween(subscriptionFromCopy, subscriptionToCopy)
// 	)
// 		return false;
// 	return true;
// };
const foodItemNotAvailableDueToTime = (
  subscriptionFrom,
  subscriptionTo,
  subscriptionTime,
  startDate,
  endDate,
  name
) => {
  if (!subscriptionFrom && !subscriptionTo) {
    return true;
  }
  let startDateCopy = cloneDeep(startDate);
  let endDateCopy = cloneDeep(endDate);
  // console.log(startDateCopy, 'start date cpy');
  startDateCopy = moment(
    new Date(startDateCopy).toLocaleDateString("en-US"),
    "MM/DD/YYYY"
  )
    .format("YYYY-MM-DD")
    .concat(` ${subscriptionTime}`);
  endDateCopy = moment(
    new Date(endDateCopy).toLocaleDateString("en-US"),
    "MM/DD/YYYY"
  )
    .format("YYYY-MM-DD")
    .concat(` ${subscriptionTime}`);
  let subscriptionFromCopy = cloneDeep(subscriptionFrom);
  let subscriptionToCopy = cloneDeep(subscriptionTo);
  // console.log(subscriptionToCopy, subscriptionFromCopy, startDateCopy, endDateCopy);
  subscriptionFromCopy = moment(
    subscriptionFromCopy,
    "DD-MM-YY HH:mm:ss"
  ).format("YYYY-MM-DD HH:mm:ss");
  subscriptionToCopy = moment(subscriptionToCopy, "DD-MM-YY HH:mm:ss").format(
    "YYYY-MM-DD HH:mm:ss"
  );
  // console.log(startDateCopy <= subscriptionToCopy, "first");
  // console.log(endDateCopy <= subscriptionToCopy, "second");
  // console.log(startDateCopy >= subscriptionFromCopy, "3");
  // console.log(endDateCopy >= subscriptionFromCopy, "4");
  if (
    startDateCopy <= subscriptionToCopy &&
    startDateCopy >= subscriptionFromCopy &&
    endDateCopy <= subscriptionToCopy &&
    endDateCopy >= subscriptionFromCopy
    // moment(startDateCopy).isBetween(
    // 	subscriptionFromCopy,
    // 	subscriptionToCopy
    // ) &&
    // moment(endDateCopy).isBetween(subscriptionFromCopy, subscriptionToCopy)
  )
    return true;
  return false;
};

function getUpdatedMenu(menu, orderDetails) {
  var categorySlot = true;
  var subCategorySlot = true;
  var productSlot = true;
  var time = new Date();
  // console.log(orderDetails, 'orderDetails');
  if (orderDetails.deliveryMode == "delivery") {
    if (
      orderDetails.orderType == "regular" &&
      orderDetails.deliverNow == false
    ) {
      // console.log('in menu actions');
      time = orderDetails.laterTime;
    }
    if (orderDetails.orderType == "schedule") {
      time = orderDetails.time;
    }
  }
  const categoryAvailableId = {
    kids: [],
    healthy: [],
    schedule: [],
  };
  const availableProductId = {};
  menu?.menu?.map((categoryObj) => {
    categorySlot =
      orderDetails.orderType !== "schedule"
        ? isProductAvailable(categoryObj?.timeSlots, time)
        : isProductAvailable(categoryObj?.timeSlots, orderDetails?.time);
    categoryObj.subCategory.map((subCategory, index) => {
      subCategorySlot =
        orderDetails?.orderType !== "schedule"
          ? isProductAvailable(subCategory?.timeSlots, time)
          : isProductAvailable(subCategory?.timeSlots, orderDetails?.time);
      categoryObj.kidsMenuAvailable = false;
      categoryObj.healthyMenuAvailable = false;
      categoryObj.subscriptionMenuAvailable = false;
      subCategory.products.map((foodItemId, index) => {
        const foodItem = menu?.products[foodItemId];
        if (orderDetails?.orderType === "schedule") {
          foodItem.variants = foodItem?.variants?.map((currVariant) => {
            currVariant.discountedPrice =
              currVariant?.subscriptionPrice ||
              currVariant?.discountedPrice ||
              currVariant?.price;
            return currVariant;
          });
        }

        foodItem.subCategoryTimeSlots = subCategory?.timeSlots;
        foodItem.categoryTimeSlots = categoryObj?.timeSlots;
        productSlot =
          orderDetails.orderType !== "schedule"
            ? isProductAvailable(foodItem?.timeSlots, time)
            : foodItem?.subscriptionEnabled
            ? foodItemNotAvailableDueToTime(
                foodItem?.subscriptionFrom,
                foodItem?.subscriptionTo,
                orderDetails?.time,
                orderDetails?.startDate,
                orderDetails?.endDate,
                foodItem?.name
              )
            : false;
        if (orderDetails?.orderType === "schedule") {
          foodItem.selectedVariant.discountedPrice =
            foodItem?.selectedVariant?.subscriptionPrice ||
            foodItem?.selectedVariant?.discountedPrice ||
            foodItem?.selectedVariant?.price;
        }
        //  if(foodItem.timeSlots['slot0'].startAt!==null){
        // 	foodItem.isProductAvailable =	productSlot;
        // }else{
        foodItem.isProductAvailable =
          categorySlot && subCategorySlot && productSlot;
        if (!foodItem.isProductAvailable) {
          if (timeSlotAvailable(categoryObj)) {
            foodItem.modifiedTimeSlots = categoryObj?.timeSlots;
          } else if (timeSlotAvailable(subCategory)) {
            foodItem.modifiedTimeSlots = subCategory?.timeSlots;
          }
        }
        // foodItem.notAvailableDueToDate = orderDetails.deliverNow
        // 	? false
        // 	: orderDetails.orderType === 'schedule'
        // 	? foodItemNotAvailableDueToDate(
        // 			foodItem.subscriptionFrom,
        // 			foodItem.subscriptionTo,
        // 			orderDetails.startDate,
        // 			orderDetails.endDate
        // 	  )
        // 	: !foodItem.isProductAvailable;
        // foodItem.notAvailableDueToTime = orderDetails.deliverNow
        // 	? false
        // 	: orderDetails.orderType === 'schedule'
        // 	? foodItemNotAvailableDueToTime(
        // 			foodItem.subscriptionFrom,
        // 			foodItem.subscriptionTo,
        // 			orderDetails.time,
        // 			orderDetails.startDate,
        // 			orderDetails.endDate
        // 	  )
        // 	: !foodItem.isProductAvailable;
        // foodItem.message =
        // 	!foodItem.isProductAvailable?(orderDetails.orderType === 'schedule'
        // 		? foodItem.subscriptionEnabled
        // 			? checkForSubscription(
        // 					foodItem.subscriptionFrom,
        // 					foodItem.subscriptionTo,
        // 					orderDetails.startDate,
        // 					orderDetails.endDate,
        // 					orderDetails.time
        // 			  )
        // 			: 'Product Not Available for subscription'
        // 		: orderDetails.orderType === 'regular'
        // 		? checkForNextAvailable(foodItem.timeSlots)
        // 		: checkForNextAvailableLater(
        // 				foodItem.timeSlots,
        // 				orderDetails.startDate,
        // 				orderDetails.time
        // 		  )):''
        const endsOn = moment(
          menu?.specialMenu?.endsOn,
          "DD-MM-YYYY HH:mm"
        ).format("YYYY-MM-DD HH:mm");
        let currTime = moment(new Date(), "DD-MM-YYYY HH:mm").format(
          "YYYY-MM-DD HH:mm"
        );
        // console.log(
        // 	moment(currTime).isBefore(endsOn),
        // 	currTime,
        // 	endsOn,
        // 	'currTime is before ends on'
        // );
        if (foodItem?.menuTags?.includes("Kids Menu")) {
          if (
            !categoryObj?.kidsMenuAvailable &&
            (orderDetails?.orderType === "regular" ||
              foodItem?.subscriptionEnabled)
          ) {
            categoryAvailableId["kids"]?.push(categoryObj?.categoryId);
            categoryObj.kidsMenuAvailable = true;
          }
        }
        if (
          foodItem?.menuTags?.includes("Healthy Menu") &&
          (orderDetails?.orderType === "regular" ||
            foodItem?.subscriptionEnabled)
        ) {
          if (!categoryObj.healthyMenuAvailable) {
            categoryAvailableId["healthy"]?.push(categoryObj?.categoryId);
            categoryObj.healthyMenuAvailable = true;
          }
        }
        if (
          orderDetails?.orderType === "schedule" &&
          foodItem?.subscriptionEnabled
        ) {
          if (!categoryObj?.subscriptionMenuAvailable) {
            categoryAvailableId["schedule"]?.push(categoryObj?.categoryId);
            categoryObj.subscriptionMenuAvailable = true;
          }
        }
        if (
          foodItem?.specialProduct
          // && moment(currTime).isBefore(endsOn)
        ) {
          if (
            orderDetails?.orderType === "regular" &&
            orderDetails?.deliverNow === true &&
            moment(currTime).isBefore(endsOn)
          ) {
            foodItem.variants = foodItem?.variants?.map((currFoodItem) => {
              return {
                ...currFoodItem,
                discountedPrice:
                  currFoodItem?.specialDiscountedPrice ||
                  currFoodItem?.discountedPrice ||
                  currFoodItem?.price,
              };
            });
            foodItem.selectedVariant.discountedPrice =
              foodItem?.selectedVariant?.specialDiscountedPrice ||
              foodItem?.selectedVariant?.discountedPrice ||
              foodItem?.selectedVariant?.price;
          } else if (
            orderDetails?.orderType === "regular" &&
            orderDetails?.deliverNow === false
          ) {
            const laterTime = orderDetails?.laterTime;
            const laterDate = orderDetails?.startDate;
            let laterDateCopy = cloneDeep(laterDate);
            laterDateCopy = moment(laterDateCopy, "YYYY-MM-DD")
              .format("YYYY-MM-DD")
              .concat(` ${laterTime}`);
            // console.log(laterDateCopy, 'laterDateCopy 123')
            if (moment(laterDateCopy).isBefore(endsOn)) {
              foodItem.variants = foodItem?.variants?.map((currFoodItem) => {
                return {
                  ...currFoodItem,
                  discountedPrice:
                    currFoodItem?.specialDiscountedPrice ||
                    currFoodItem?.discountedPrice ||
                    currFoodItem?.price,
                };
              });
              foodItem.selectedVariant.discountedPrice =
                foodItem?.selectedVariant?.specialDiscountedPrice ||
                foodItem?.selectedVariant?.discountedPrice ||
                foodItem?.selectedVariant?.price;
            }
          }
        }
        if (orderDetails.orderType === "schedule") {
          if (foodItem?.subscriptionEnabled) {
            availableProductId[foodItemId] = true;
          }
        } else {
          availableProductId[foodItemId] = true;
        }
        if (
          foodItem?.subscriptionEnabled
          // && foodItem.isProductAvailable
        ) {
          foodItem?.menuTags?.push("Subscription Menu");
        }
      });
    });
  });
  return { menu, categoryAvailableId, availableProductId };
}

function getMenu(storeId, orderDetails) {
  return (dispatch, getState) => {
    dispatch(request([]));
    const { userBasedMenu } = getState().client;
    const laterDateTime = formatTimeDate(
      orderDetails.laterDate,
      orderDetails.laterTime
    );
    menuService
      .getMenu(storeId, userBasedMenu, {
        data:
          orderDetails.orderType !== "schedule" && orderDetails.type === "later"
            ? orderDetails.laterTime !== null
              ? { orderTime: laterDateTime }
              : { orderDate: laterDateTime }
            : {},
        params:
          orderDetails.orderType === "schedule"
            ? {
                context: "SUBSCRIPTION",
              }
            : {},
      })
      .then(
        (store) => {
          // debugger;
          let { menu, categoryAvailableId, availableProductId } =
            getUpdatedMenu(store, orderDetails);
          let menuTags =
            menu &&
            [...menu?.menuTags].filter((currentMenuTag) => {
              return currentMenuTag != "Bulk Order Menu";
            });
          menu = {
            ...menu,
            menuTags: [...new Set(menuTags)],
            categoryAvailableId,
            availableProductId,
          };
          dispatch(success(menu));
          if (orderDetails?.orderType == "schedule") {
            dispatch(
              authActions.redirect(
                `/${getState().store.storeId}/menu-card/schedule/schedule/${
                  store?.menu[0]?.categoryId
                }`
              )
            );
          } else if (menu?.menuTags?.length === 0) {
            dispatch(
              authActions.redirect(
                `/${getState().store.storeId}/menu-card/regular/all/${
                  store?.menu[0]?.categoryId
                }`
              )
            );
          }
        },
        (error) => {
          dispatch(failure(error));
          toast.error(error);
          history.push('/');
        }
      );
  };
  function request(payload) {
    return { type: menuConstants.MENU_REQUEST, payload };
  }
  function success(payload) {
    return { type: menuConstants.MENU_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: menuConstants.MENU_FAILURE, payload };
  }
}

function updateMenu(data) {
  return (dispatch) => {
    dispatch(success(data));
  };
  function success(payload) {
    return { type: menuConstants.MENU_UPDATE, payload };
  }
}

export const menuActions = {
  getMenu,
  updateMenu,
};
