import { Route, Redirect, useHistory } from "react-router";
import { checkStoreId, safeJSONParse, useAccess } from '../../Functions/utils'
import { useEffect } from "react";
;

// A - Auth
// S - Store
// AS - Auth and Store


const ProtectedRoute = ({
	
	type,
	render,
	// generateNotification,
	...rest
}) => {
	const { allowed } = useAccess(rest?.computedMatch?.params);
	const isLoggedIn = safeJSONParse(localStorage.getItem('auth'))?.auth ?? false;
	const history = useHistory();
		
	useEffect(() => {
		checkStoreId(history);
	}, [history]);

	return (
		<Route
			{...rest}
			render={(props) => {
				let isAllowed = false;
				// let message = '';
				switch (type) {
					case 'A':
						isAllowed = isLoggedIn;
						break;
					case 'AS':
						isAllowed = isLoggedIn && allowed;
						break;
					case 'S':
						isAllowed = allowed;
						break;
					default:
						break;
				}

				if (isAllowed) {
					return render(props);
				}
				return (
					<Redirect
						to={{
							pathname: '/',
							state: {
								from: props.location,
							},
						}}
					/>
				);
			}}
		/>
	);
};

export default ProtectedRoute;
